import React from "react";

import {
  CumulativeOffersScreenProps,
  ROUTES,
} from "app/partner-ordering-ui/routes/types";
import useSearch from "app/partner-ordering-ui/hooks/useSearch";
import RenderProductList from "app/partner-ordering-ui/components/productList/list";
import { useAppSelector } from "app/hooks/reduxHooks";

import { selectCumulativeCouponInfo } from "../store/reducer/cart";
import CumulativeOfferHeader from "../components/productDetail/CumulativeOfferHeader";
import { Slab } from "../models/Product";

const CumulativeOffers = ({ route }: CumulativeOffersScreenProps) => {
  const { products, loadingNext, fetchNext, getProducts } = useSearch(
    JSON.parse(route.params.query)
  );
  const info = useAppSelector(
    selectCumulativeCouponInfo(route.params.identifier)
  );

  return (
    <RenderProductList
      loadingNext={loadingNext}
      fetchNext={fetchNext}
      products={products}
      listHeader={
        <CumulativeOfferHeader
          info={info}
          slabs={
            route.params.slabs
              ? (JSON.parse(route.params.slabs) as never as Slab[])
              : undefined
          }
          products={products}
        />
      }
      showAlternateItems={false}
      showComboDrawer={false}
      navigatingFrom={ROUTES.CUMULATIVE_OFFERS}
      getProducts={() =>
        getProducts(
          undefined,
          undefined,
          undefined,
          undefined,
          JSON.parse(route.params.query)
        )
      }
    />
  );
};

export default CumulativeOffers;
