import { get } from "../axios/index";
import ApiResponse from "app/sell-to-farmer/models/apiResponse";
import { Order } from "app/sell-to-farmer/models/orders";

const fetchOrders = async (
  page: number,
  orderType?: string,
  status?: string,
  startDate?: string,
  endDate?: string,
  lang = "en"
) => {
  try {
    const response = await get<ApiResponse<Order>>(
      "/hyperlocal/vendor/v3/orders",
      {
        page,
        order_type: orderType,
        status,
        lang,
        creation_date_lte: startDate,
        creation_date_gte: endDate,
        page_size: 20,
      }
    );
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default fetchOrders;
