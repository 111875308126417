import React, { useEffect, useRef, useState } from "react";
import { View, Text, FlatList, Keyboard } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COLORS,
  COMMON_STYLES,
  CrossIcon,
  FloatingLabelInput,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import RecentSearches from "app/partner-ordering-ui/components/RecentSearches";
import { STORAGE_KEYS } from "app/partner-ordering-ui/constants/storage";
import fetchProductsSearchSuggestions from "app/partner-ordering-ui/apis/fetchProductsSearchSuggestions";
import useDebounce from "app/partner-ordering-ui/hooks/useDebounce";
import BackCaret from "app/partner-ordering-ui/icons/BackCaret";
import useSearch from "app/partner-ordering-ui/hooks/useSearch";
import NoSearchResult from "app/partner-ordering-ui/components/productList/search/NoResult";
import ShimmerProductCardHz from "app/partner-ordering-ui/components/cards/ProductCardHzShimmer";
import RenderSearchResults from "app/partner-ordering-ui/components/productList/search";
import SearchedItemStrip from "app/partner-ordering-ui/components/productList/search/SearchedItemStrip";
import SuggestionItem from "app/partner-ordering-ui/components/productList/search/SuggestionItem";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import OutstandingAmountDrawer from "app/partner-ordering-ui/components/drawers/OutstandingAmountDrawer";
import {
  RecentSearch,
  selectRecentProductsSearch,
  setRecentProductSearch,
} from "app/partner-ordering-ui/store/reducer/app";
import { getData } from "app/utils/storage";
import store from "app/store";
import {
  logEvent,
  startOutstandingAmountPaymentFlow,
  getFromRemoteConfig,
} from "app/utils/appConfig";
import { ProductSearchScreenProps, ROUTES } from "../routes/types";
import {
  MIXPANEL_EVENT,
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "../constants/eventNames";
import { Product } from "../models/Product";
import { getDisplayVariant } from "../utils/helpers/product";
import LogErrorModal from "../components/LogError";
import { fireClickedRecentSearchItem } from "../utils/helpers/mixpanel";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import { isAIMS } from "../utils/helpers/isAIMSApp";
import { defaultBackPressCallback } from "../utils/helpers/common";

const ProductSearch = ({ route, navigation }: ProductSearchScreenProps) => {
  const { navigatingFrm } = route?.params;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedQuery = useDebounce(inputValue);
  const [suggestions, setSuggestions] = useState<{ text: string }[]>();
  const dc = useAppSelector(selectDCData);

  const getQuery = (searchQuery = "") => {
    const query: Record<string, string> = { search: searchQuery };
    if (isAIMS()) {
      query.partner_id = dc.partner_id;
    }
    return query;
  };

  const {
    error,
    products,
    loading,
    fetchNext,
    getProducts,
    loadingNext,
    setError,
  } = useSearch(getQuery(searchQuery), false);

  const recentSearchHistory = useAppSelector(selectRecentProductsSearch);

  const [open, setOpen] = useState(false);
  const [searchIndex, setSearchIndex] = useState(0);
  const autoCompletePositionClicked = useRef(0);
  const maxRecentSearch = getFromRemoteConfig("max_recent_search_limit").then(
    (value: number) => value
  );

  useObserverInternetActivity();

  useEffect(() => {
    if (debouncedQuery) {
      const getSuggestionList = async () => {
        const response = await fetchProductsSearchSuggestions(
          debouncedQuery,
          0
        );
        if (response) {
          setSuggestions(response.results);
        }
      };
      getSuggestionList();
    } else {
      setSuggestions([]);
    }
  }, [debouncedQuery]);

  const getDisplayString = (index: number) => {
    return {
      productCount: `Search Result ${index} Alternate Products Count`,
      available: `Search Result ${index} Availability`,
      productId: `Search Result ${index} Product ID`,
    };
  };

  useEffect(() => {
    if (products?.length > 0) {
      const inStockProductIds: Array<string> = [];
      const outStockProductsIds: Array<string> = [];
      let searchResult = {};
      const latestSearchProduct = products?.slice(searchIndex, products.length);

      latestSearchProduct?.forEach((product: Product, index: number) => {
        const displayVariant = getDisplayVariant(product?.variants);
        const isOutOfStock =
          displayVariant.orderable_quantity <
          Number(displayVariant.min_moq_value || 1);
        const { productCount, available, productId } = getDisplayString(
          index + 1
        );
        if (isOutOfStock) {
          outStockProductsIds.push(product.id);
          searchResult = {
            ...searchResult,
            [productCount]: product?.similar_product_count,
            [available]: "OOS",
            [productId]: product.id,
          };
        } else {
          inStockProductIds.push(product.id);
          searchResult = {
            ...searchResult,
            [productCount]: product?.similar_product_count,
            [available]: "Instock",
            [productId]: product.id,
          };
        }
      });
      setSearchIndex(products.length);
      const mixpanelPayload = {
        [MIXPANEL_KEYS.APP_PAGE]: ROUTES.PRODUCT_LIST,
        [MIXPANEL_KEYS.AUTO_COMPLETE_POISITION]:
          autoCompletePositionClicked.current,
        [MIXPANEL_KEYS.AUTO_COMPLETE_CLICKED]: searchQuery,
        [MIXPANEL_KEYS.INSTOCK_PRODUCT_ID_COUNT]: inStockProductIds?.length,
        [MIXPANEL_KEYS.INSTOCK_PRODUCT_ID_LIST]: inStockProductIds,
        [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
        [MIXPANEL_KEYS.OUTSTOCK_PRODUCT_ID_COUNT]: outStockProductsIds?.length,
        [MIXPANEL_KEYS.OUTSTOCK_PRODUCT_ID_LIST]: outStockProductsIds,
        [MIXPANEL_KEYS.SCREEN_NAME]: ROUTES.PRODUCT_SEARCH,
        [MIXPANEL_KEYS.SEARCH_KEYWORD]: searchQuery,
        [MIXPANEL_KEYS.SEARCH_RESULT_PRODUCT_COUNT]:
          latestSearchProduct?.length,
        [MIXPANEL_KEYS.SEARCH_TYPE]: MIXPANEL_PROPERTIES.SEARCH_AUTO_SUGGEST,
        ...searchResult,
      };
      logEvent(
        MIXPANEL_EVENT.PRODUCT_SEARCH_DONE,
        JSON.stringify(mixpanelPayload)
      );
    }
  }, [products]);

  useEffect(() => {
    fireMixapanelEvent(
      MIXPANEL_EVENT.PRODUCT_SEARCH_INITIATED,
      MIXPANEL_PROPERTIES.SEARCH_AUTO_SUGGEST
    );
  }, []);

  const updateSearchHistory = async (actual: string, suggested: string) => {
    if (
      actual &&
      !recentSearchHistory.includes({
        actual: actual,
        suggested: suggested,
      })
    ) {
      const upatedList = [
        { actual, suggested },
        ...recentSearchHistory.slice(0, (await maxRecentSearch) - 1),
      ];
      store.dispatch(setRecentProductSearch(upatedList));
    }
  };
  useEffect(() => {
    const getRecentSearchFromStore = async () => {
      const searches = await getData<RecentSearch[], []>(
        STORAGE_KEYS.RECENT_PRODUCTS_SEARCHES
      );
      if (searches) store.dispatch(setRecentProductSearch(searches));
    };
    getRecentSearchFromStore();
  }, []);

  const fireMixapanelEvent = (eventName: string, searchType: string) => {
    const mixPanelPayload = {
      [MIXPANEL_KEYS.SCREEN_NAME]: ROUTES.PRODUCT_SEARCH,
      [MIXPANEL_KEYS.APP_PAGE]: navigatingFrm,
      [MIXPANEL_KEYS.SEARCH_TYPE]: searchType,
    };
    logEvent(eventName, JSON.stringify(mixPanelPayload));
  };

  return (
    <>
      <View style={[COMMON_STYLES.flex1, BG_STYLES.bgWhite]}>
        <View style={[COMMON_STYLES.m1]}>
          <FloatingLabelInput
            isFocused
            leftIcon={<BackCaret width={24} height={24} strokeWidth={2} />}
            rightIcon={
              inputValue.length > 0 && <CrossIcon width={18} height={18} />
            }
            onRightIconClick={() => setInputValue("")}
            onLeftIconClick={defaultBackPressCallback}
            placeholder={t("search_placeholder_text")}
            value={inputValue}
            onChangeText={(val) => setInputValue(val)}
            placeholderTextColor={COLORS.neutral70}
            onSubmitEditing={() => {
              setSearchQuery(inputValue);
              updateSearchHistory(inputValue, inputValue);
              fireMixapanelEvent(
                MIXPANEL_EVENT.PRODUCT_SEARCH_CALL_INITIATED,
                MIXPANEL_PROPERTIES.SEARCH_TYPE_TEXT
              );
              setInputValue("");
            }}
            varient="search"
            onPressIn={() => setSearchQuery("")}
          />
        </View>
        {inputValue === "" && !searchQuery ? (
          <RecentSearches
            show={true}
            onItemClick={(actual: string, suggested: string, index: number) => {
              setSearchQuery(actual);
              autoCompletePositionClicked.current = index + 1;
              Keyboard.dismiss();
              fireClickedRecentSearchItem({
                rank: index + 1,
                count: recentSearchHistory.length,
                actual: actual,
                suggested: suggested,
                screenName: ROUTES.PRODUCT_SEARCH,
                isPriorityDc: dc?.is_priority_dc,
              });
            }}
          />
        ) : (
          <>
            {searchQuery ? (
              <>
                {loading || loadingNext || products.length ? (
                  <>
                    <SearchedItemStrip
                      itemName={searchQuery}
                      onClose={() => setSearchQuery("")}
                    />
                    {loading ? (
                      <ShimmerProductCardHz />
                    ) : (
                      <RenderSearchResults
                        products={products}
                        getProducts={() =>
                          getProducts(
                            searchQuery,
                            undefined,
                            undefined,
                            undefined,
                            getQuery(searchQuery)
                          )
                        }
                        fetchNext={fetchNext}
                        loadingNext={loadingNext}
                        isOrderingBlocked={dc.app_blocked}
                        handleRevealPriceClick={() => {
                          if (!isAIMS()) setOpen(true);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <NoSearchResult />
                )}
              </>
            ) : (
              <>
                {suggestions?.length ? (
                  <FlatList
                    stickyHeaderIndices={[0]}
                    ListHeaderComponentStyle={BG_STYLES.bgWhite}
                    keyboardShouldPersistTaps="always"
                    ListHeaderComponent={
                      <Text
                        style={[
                          TYPOGRAPHY.TEXT1,
                          VARIANTS_STYLES.EMPHASIZED,
                          TEXT_STYLES.textNeutral100,
                          COMMON_STYLES["mt1/4"],
                          COMMON_STYLES["mb3/4"],
                        ]}
                      >
                        {t("suggestions")}
                      </Text>
                    }
                    style={[COMMON_STYLES.ph1, BG_STYLES.bgWhite]}
                    data={suggestions}
                    renderItem={({ item: { text }, index }) => (
                      <SuggestionItem
                        key={index}
                        text={text}
                        onClick={() => {
                          setSearchQuery(text);
                          Keyboard.dismiss();
                          setInputValue("");
                          updateSearchHistory(inputValue, text);
                          fireMixapanelEvent(
                            MIXPANEL_EVENT.PRODUCT_SEARCH_CALL_INITIATED,
                            MIXPANEL_PROPERTIES.SEARCH_AUTO_SUGGEST
                          );
                        }}
                      />
                    )}
                    keyExtractor={({ text }) => text}
                  />
                ) : null}
              </>
            )}
            <OutstandingAmountDrawer
              days={dc.app_block_days}
              amount={dc.app_block_amount}
              isOpen={open}
              onClose={() => setOpen(false)}
              onCtaClick={() => {
                startOutstandingAmountPaymentFlow(dc.app_block_amount);
                setOpen(false);
              }}
            />
            <LogErrorModal
              visible={!!error}
              message={error}
              onClose={() => setError("")}
            />
          </>
        )}
      </View>
    </>
  );
};

export default ProductSearch;
