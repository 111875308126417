import React from "react";
import Svg, { Path, PathProps, SvgProps } from "react-native-svg";

interface Props {
  pathProps?: PathProps;
  svgProps: SvgProps;
}

const RecentSearchIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 18 18" {...props}>
      <Path
        fill="#5A6066"
        d="M4.974 8.066c.614-2.094 2.538-3.622 4.815-3.622 2.773 0 5.02 2.264 5.02 5.056 0 2.792-2.247 5.056-5.02 5.056a4.974 4.974 0 0 1-3.165-1.132.714.714 0 0 0-1.01.104.726.726 0 0 0 .104 1.016A6.402 6.402 0 0 0 9.79 16c3.565 0 6.454-2.91 6.454-6.5S13.353 3 9.79 3C6.67 3 4.065 5.231 3.465 8.197l-.442-.446a.714.714 0 0 0-1.014 0 .726.726 0 0 0 0 1.022l1.588 1.6c.308.31.808.31 1.116 0l1.588-1.6a.726.726 0 0 0 0-1.022.714.714 0 0 0-1.014 0l-.313.315Z"
      />
      <Path
        fill="#5A6066"
        d="M10.505 7.334a.72.72 0 0 0-.718-.723.72.72 0 0 0-.717.723v2.155a.729.729 0 0 0 .33.619l2.141 1.438c.33.22.775.131.995-.2a.725.725 0 0 0-.2-1.002l-1.831-1.23v-1.78Z"
      />
    </Svg>
  );
};

export default RecentSearchIcon;
