import { Product } from "../models/Product";
import { get } from "app/partner-ordering-ui/utils/axios/index";
import { isAIMS, isDBA } from "../utils/helpers/isAIMSApp";
import { logExceptionAndGetTraceIds } from "../utils/helpers/common";

interface Response {
  count: number;
  previous: number | null;
  next: number | null;
  results: Product[];
}

const fetchProductDetail = async (
  id: string | number,
  salesOfficeId: number,
  partnerId?: number
) => {
  const url = "/products/detail/v2";
  try {
    const response = await get<{ data: Response }>(url, {
      id,
      coupons_info: true,
      dehaat_price_gt: isDBA() ? 0 : undefined,
      untaxed_dehaat_price__gt: isDBA() ? 0 : undefined,
      include: "bundles",
      sales_office_id: salesOfficeId,
      partner_id: isAIMS() ? partnerId : undefined,
    });
    return response.data;
  } catch (e) {
    throw new Error(logExceptionAndGetTraceIds(e));
  }
};

export default fetchProductDetail;
