import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const PaynowIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 22 22" {...props}>
      <Path
        d="M4.21 12.646s2.736-2.854 6.978-4.36c0 0 1.15-.569 4.03.303 3.093.939 3.45 1.005 3.45 1.005s-3.872 3.673-6.45 5.06c-2.576 1.375-7.003 2.723-7.003 2.723l-.396.158-.608-4.889Z"
        fill="#FFC59B"
      />
      <Path
        d="m17.782 3.754-.121 2.123-.58 11.206-.108 2.171-2.086-.109-2.22-.12-2.123-.109.108-2.135.592-11.206.108-2.16 4.306.23 2.124.109Z"
        fill="#3C8655"
      />
      <Path
        d="m11.76 3.834-.765 14.685 5.602.291.764-14.684-5.601-.292Z"
        fill="#7ED187"
      />
      <Path
        opacity=".6"
        d="M14.176 13.418a2.099 2.099 0 1 0 0-4.197 2.099 2.099 0 0 0 0 4.197Z"
        fill="#3C8655"
      />
      <Path
        d="m17.764 3.75-.109 2.123a2.088 2.088 0 0 1-1.99-1.785c-.024-.157-.024-.302-.024-.446l2.123.108ZM13.497 3.51a2.102 2.102 0 0 1-1.785 2.027 2.065 2.065 0 0 1-.47.012l.108-2.159 2.147.109v.012ZM17.084 17.074l-.12 2.171-2.087-.108a2.103 2.103 0 0 1 1.785-2.039c.133-.024.278-.024.41-.024h.012ZM12.643 18.557c.024.157.024.313.012.458l-2.111-.108.108-2.136a2.12 2.12 0 0 1 1.99 1.786Z"
        fill="#3C8655"
      />
      <Path
        d="m20.625 3.852-.423 2.087-2.243 11-.434 2.124-2.051-.422-2.183-.447-2.075-.422.422-2.099 2.244-10.989.434-2.123 4.222.869 2.087.422Z"
        fill="#3C8655"
      />
      <Path
        d="m14.652 3.055-2.94 14.408 5.497 1.12 2.939-14.407-5.496-1.12Z"
        fill="#7ED187"
      />
      <Path
        opacity=".6"
        d="M15.914 12.916a2.099 2.099 0 1 0 0-4.198 2.099 2.099 0 0 0 0 4.198Z"
        fill="#3C8655"
      />
      <Path
        d="M20.622 3.85 20.2 5.937a2.098 2.098 0 0 1-1.664-2.51l2.086.423ZM16.415 3.009a2.089 2.089 0 0 1-2.063 1.737 2.01 2.01 0 0 1-.47-.048l.434-2.111 2.111.434-.012-.012ZM17.96 16.934l-.434 2.123-2.039-.422a2.1 2.1 0 0 1 2.063-1.75c.133 0 .277.013.41.037v.012ZM13.364 17.742c0 .157-.012.314-.048.459l-2.075-.423.422-2.099c.965.193 1.701 1.05 1.701 2.063Z"
        fill="#3C8655"
      />
      <Path
        d="M18.263 13.361s0 .092-.027.145c-.145.304-.476.542-.74.754-.198.158-.41.29-.62.436h-.027c-.185.132-.397.264-.635.41-2.127 1.268-3.594 2.127-4.228 2.444-.621.317-1.982 1.11-6.924.08l.171-.53.08-.065 4.955-4.136s1.586.568 2.3.568c.62 0 1.255-.397 1.836-.569.489-.145.978-.356 1.493-.422.516-.066 1.097 0 1.56.185.224.092.766.396.792.7h.014Z"
        fill="#FFC59B"
      />
      <Path
        d="M16.24 15.111c-2.127 1.269-3.594 2.128-4.228 2.445-.62.317-1.982 1.11-6.924.08l.172-.53.079-.065c.41.079 3.119.594 5.114.396 0 0 1.216-.251 3.383-1.44 2.207-1.216 3.99-2.114 4.281-2.881a.398.398 0 0 1 .172.251s.08.476-2.062 1.744h.014Z"
        fill="#F7AA92"
      />
      <Path
        d="M14.14 13.015a5.716 5.716 0 0 1-.938.357 3.27 3.27 0 0 1-1.017.145 3.176 3.176 0 0 1-1.005-.224 4.346 4.346 0 0 1-.898-.436c-.013 0-.027-.027 0-.053 0-.014.026-.014.04-.014l.95.238c.318.08.635.159.94.185.62.027 1.268-.119 1.928-.198Z"
        fill="#DD836B"
      />
      <Path
        d="m2.876 19.038 2.022-.37a.686.686 0 0 0 .555-.753l-.78-5.907a.673.673 0 0 0-.766-.581l-2.022.317a.586.586 0 0 0-.502.674l.793 6.105c.04.344.37.582.7.515Z"
        fill="#1CA9EE"
      />
    </Svg>
  );
};

export default PaynowIcon;
