import React, { useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  BottomDrawer,
  COLORS,
  COMMON_STYLES,
  RadioButton,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  Chip,
  CHIP_STATES,
  CHIP_TYPES,
} from "@dehaat/dds";

import FilterIcon from "../icons/Filter";
import { QuickFilter } from "../models/Product";
import { FILTER_VALUES } from "../constants/Common";

interface Props {
  appliedFilter: string | null;
  activeQuickFilter: QuickFilter | null;
  onApplyFilter: (filter: string) => void;
  onQuickFilterClick: (filter: QuickFilter | null) => void;
  quickFilters: QuickFilter[];
}

const Filters = ({
  activeQuickFilter,
  appliedFilter,
  quickFilters,
  onApplyFilter,
  onQuickFilterClick,
}: Props) => {
  const { t } = useTranslation();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const filterBtn = (
    <Chip
      leftIcon={
        <FilterIcon
          pathProps={{ fill: COLORS.primary100 }}
          svgProps={{ height: 10, width: 15, style: COMMON_STYLES["mr1/4"] }}
        />
      }
      label="Filter"
      labelStyle={[
        TYPOGRAPHY.TEXT2,
        VARIANTS_STYLES.SEMI_EMPHASIZED,
        TEXT_STYLES.textPrimary100,
      ]}
      onClick={() => setIsFilterModalOpen(true)}
      state={appliedFilter ? CHIP_STATES.ACTIVE : CHIP_STATES.INACTIVE}
      type={CHIP_TYPES.BADGE}
    />
  );

  const FilterList = [
    {
      radioElement: (
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral100,
          ]}
        >
          {t("all_products")}
        </Text>
      ),
      value: FILTER_VALUES.ALL_PRODUCTS,
    },
    {
      radioElement: (
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral100,
          ]}
        >
          {t("in_stock")}
        </Text>
      ),
      value: FILTER_VALUES.IN_STOCK,
    },
    {
      radioElement: (
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral100,
          ]}
        >
          {t("sort_a_z")}
        </Text>
      ),
      value: FILTER_VALUES.NAME,
    },
  ];

  return (
    <View
      style={[
        COMMON_STYLES.row,
        COMMON_STYLES.ph1,
        COMMON_STYLES["pv1/2"],
        { borderBottomWidth: 1, borderBottomColor: COLORS.neutral10 },
        BG_STYLES.bgWhite,
      ]}
    >
      {filterBtn}
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        {quickFilters.map((filter) => (
          <Chip
            key={filter.id}
            label={filter.name}
            state={
              activeQuickFilter?.id === filter.id
                ? CHIP_STATES.ACTIVE
                : CHIP_STATES.INACTIVE
            }
            onClick={() =>
              onQuickFilterClick(
                activeQuickFilter?.id === filter.id ? null : filter
              )
            }
          />
        ))}
      </ScrollView>
      <BottomDrawer
        heading="Filter"
        onClose={() => setIsFilterModalOpen(false)}
        visible={isFilterModalOpen}
      >
        <RadioButton
          selected={FilterList.findIndex(
            (filter) => filter.value === appliedFilter
          )}
          list={FilterList}
          containerStyle={[{ flexDirection: "row-reverse" }]}
          onClick={(index) => {
            onApplyFilter(FilterList[index].value);
            setIsFilterModalOpen(false);
          }}
        />
      </BottomDrawer>
    </View>
  );
};

export default Filters;
