import {
  Button,
  COMMON_STYLES,
  CenterModal,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native";

interface Props {
  isVisible: boolean;
  availableInventory?: number;
  name?: string;
  onCTAClick: () => void;
}

const InsufficientInventoryModal = ({
  isVisible,
  onCTAClick,
  availableInventory,
  name,
}: Props) => {
  const { t } = useTranslation();
  return (
    <CenterModal
      isVisible={isVisible}
      onClose={() => {}}
      allowClose={false}
      style={[
        COMMON_STYLES.row,
        COMMON_STYLES.justifyStart,
        COMMON_STYLES.alignItemsStart,
      ]}
    >
      <Text
        style={[
          TYPOGRAPHY.HEADING3,
          VARIANTS_STYLES.SEMI_EMPHASIZED,
          TEXT_STYLES.textNeutral100,
        ]}
      >
        {t("insufficient_inventory")}
      </Text>
      <Text
        style={[
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textNeutral80,
          COMMON_STYLES.mv1,
        ]}
      >
        {t("inventory_info_msg", { name, inventory: availableInventory })}
      </Text>
      <Button onClick={onCTAClick} text={"Okay"} />
    </CenterModal>
  );
};

export default InsufficientInventoryModal;
