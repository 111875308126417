import React from "react";
import { Text, View, StyleSheet } from "react-native";

import { COLORS, TEXT_STYLES, TYPOGRAPHY, VARIANTS_STYLES } from "@dehaat/dds";

type ToastProps = {
  message: string;
};

const Toast: React.FC<ToastProps> = ({ message }) => {
  return (
    <View style={styles.toast}>
      <Text
        style={[
          TEXT_STYLES.textWhite,
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.MEDIUM,
        ]}
        numberOfLines={3}
      >
        {message}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  toast: {
    position: "absolute",
    bottom: 16,
    left: "50%",
    transform: [{ translateX: -180 }],
    backgroundColor: COLORS.black,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 8,
    zIndex: 1000,
    maxWidth: "80%",
    minWidth: 360,
    textAlign: "center",
  },
});

export default Toast;
