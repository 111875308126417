import { CART_ERROR_CODES } from "../constants/Cart";
import { useCallback, useState } from "react";
import { ErrorDescription } from "../models/Cart";

const defaultState = {};

const useCartErrors = () => {
  const [error, setError] =
    useState<Partial<Record<CART_ERROR_CODES, ErrorDescription>>>(defaultState);

  const isError = Object.keys(error).length > 0;

  const resetError = useCallback(
    (key: CART_ERROR_CODES) => {
      const obj = { ...error };
      if (obj[key]) {
        delete obj[key];
        setError(obj);
      }
    },
    [error]
  );

  const updateError = useCallback(
    (key: CART_ERROR_CODES, value: ErrorDescription) => {
      setError((prev) => ({ ...prev, [key]: value }));
    },
    [error]
  );

  return {
    isError,
    error,
    updateError,
    resetError,
  };
};

export default useCartErrors;
