import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AuthToken from "app/models/Auth";
import type { RootState } from "app/store/index";

// Define the initial state using that type
const initialState = {
  accessToken: "",
  expiresIn: 0,
  idToken: "",
  refreshExpiresIn: 0,
  refreshToken: "",
};

export const authTokenSlice = createSlice({
  name: "authToken",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setToken: (state, action: PayloadAction<AuthToken>) => {
      state.accessToken = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      state.expiresIn = action.payload.expires_in;
      state.refreshExpiresIn = action.payload.refresh_expires_in;
      state.idToken = action.payload.id_token;
    },
    clearAuthData: (state) => {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.expiresIn = initialState.expiresIn;
      state.refreshExpiresIn = initialState.refreshExpiresIn;
      state.idToken = initialState.idToken;
    },
  },
});

export const { setToken, clearAuthData } = authTokenSlice.actions;

export const selectAccessToken = (state: RootState) =>
  state.authToken.accessToken;

export const selectRefreshToken = (state: RootState) =>
  state.authToken.refreshToken;

export const selectAccessTokenExpiry = (state: RootState) =>
  state.authToken.expiresIn;

export const selectRefreshTokenExpiry = (state: RootState) =>
  state.authToken.refreshExpiresIn;

export const selectAuthToken = (state: RootState) => state.authToken;

export default authTokenSlice.reducer;
