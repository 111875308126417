import React from "react";
import { Text, View } from "react-native";
import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { useTranslation } from "react-i18next";
import LicenceUnderReviewIcon from "app/partner-ordering-ui/icons/LicenseUnderReview";
import { LICENSE_STATUS } from "app/partner-ordering-ui/constants/Product";

const LicenseInfoTag = ({
  licenseStatus,
  style = [COMMON_STYLES.mh1, COMMON_STYLES["mb1"]],
}: any) => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        COMMON_STYLES["p3/4"],
        BG_STYLES.bgNeutral10,

        COMMON_STYLES["br1/2"],
        COMMON_STYLES.row,
        COMMON_STYLES.alignItemsCenter,
        style,
      ]}
    >
      {licenseStatus === LICENSE_STATUS.SUBMITTED && (
        <LicenceUnderReviewIcon
          height={24}
          width={24}
          style={COMMON_STYLES["mr0.5"]}
        />
      )}
      <Text
        style={[
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.BASE,
          TEXT_STYLES.textNeutral90,
          COMMON_STYLES.flex1,
        ]}
      >
        {licenseStatus === LICENSE_STATUS.PENDING
          ? t("upload_license_warning")
          : t("license_in_review_text")}
      </Text>
    </View>
  );
};

export default LicenseInfoTag;
