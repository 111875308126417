import { GrowthPlanBasketProduct } from "../models/Product";
import { get } from "app/partner-ordering-ui/utils/axios/index";
import { logExceptionAndGetTraceIds } from "../utils/helpers/common";

interface Response {
  count: number;
  previous: number | null;
  next: number | null;
  results: GrowthPlanBasketProduct[];
}

const fetchGrowthBasketProductsList = async () => {
  const url = `/sale-management/growth-plan-basket/v2`;
  try {
    const response = await get<{ data: Response }>(url, {
      coupons_info: true,
    });
    return response.data;
  } catch (e) {
    throw new Error(logExceptionAndGetTraceIds(e));
  }
};

export default fetchGrowthBasketProductsList;
