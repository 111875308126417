import React from "react";
import Svg, { Path, Rect, SvgProps } from "react-native-svg";

const CashbackIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 24 24" fill="none" {...props}>
      <Rect x="2" y="4" width="18" height="14" rx="3" fill="#DAF5E6" />
      <Rect
        x="2.5"
        y="7.5"
        width="17"
        height="2"
        fill="#36B16B"
        stroke="#36B16C"
      />
      <Path
        d="M20 12.328V7C20 5.34315 18.6569 4 17 4H5C3.34315 4 2 5.34315 2 7V15C2 16.6569 3.34315 18 5 18H14.4088"
        stroke="#36B16C"
        strokeWidth="1.5"
      />
      <Rect
        x="16.5"
        y="17.2998"
        width="7"
        height="1.5"
        rx="0.1"
        fill="#0F8040"
      />
      <Rect
        x="19.25"
        y="21.5"
        width="7"
        height="1.5"
        rx="0.1"
        transform="rotate(-90 19.25 21.5)"
        fill="#0F8040"
      />
    </Svg>
  );
};

export default CashbackIcon;
