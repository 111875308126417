import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const ExpiryClockIcon = (props: SvgProps) => {
  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <Path
        d="M10 2C5.6 2 2 5.6 2 10C2 14.4 5.6 18 10 18C14.4 18 18 14.4 18 10C18 5.6 14.4 2 10 2ZM13.36 13.36L9.2 10.8V6H10.4V10.16L14 12.32L13.36 13.36Z"
        fill="#E05109"
      />
    </Svg>
  );
};

export default ExpiryClockIcon;
