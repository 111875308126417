function formateDateandTime(dateString: string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    // Parse the input date string
    const date = new Date(dateString);
  
    // Get day, month, year, hours, and minutes
    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Adjust for 0 hour to 12
  
    // Format hours
    const formattedHours = hours.toString().padStart(2, '0');
  
    // Construct final formatted string
    return `${day} ${month} ${year} | ${formattedHours}:${minutes} ${ampm}`;
  }

  export default formateDateandTime
