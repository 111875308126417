import { ORDER_STATUS } from "app/sell-to-farmer/constants/common";
import { patch } from "../axios/index";
import { OrderCancelReasonObj } from "app/sell-to-farmer/models/orders";

/**
 * Updates the status of an order via a PATCH request.
 *
 * @param {string} orderId - The ID of the order to update.
 * @param {Partial<Params>} body - Contains `status` (required) and optional `reason`.
 *
 * @returns {Promise<boolean>} - Returns `true` on success, otherwise `false`.
 *
 * @example
 * Accepting Pickup order :- patchOrder('order123', { status: "VENDOR_CONFIRMED" });
 * Confirming Pickup:- patchOrder('order123', { status: "DELIVERED", reason: "delivered through self_pickup" });
 */

interface Params {
  status: ORDER_STATUS;
  reason: string | OrderCancelReasonObj;
}

const patchOrder = async (orderId: number, body: Partial<Params>) => {
  try {
    const response = await patch(`/hyperlocal/vendor/v3/orders/${orderId}`, {
      ...body,
    });
    return Boolean(response);
  } catch (e) {
    console.log(e);
    return false;
  }
};

export default patchOrder;
