import { AxiosError, AxiosResponse } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import i18next from "i18next";
import * as Sentry from "@sentry/react";

import store from "app/store";
import Config from "app/utils/config";

import {
  InputAxiosConfig,
  KhetiAxiosConfig,
  configureAuthAxiosInstance,
  configureInputAxiosInstance,
  configureKhetiAxiosInstance,
} from "./axios";
import { createUUID } from "../partner-ordering-ui/utils/helpers/common";
import axiosInstanceOfInput from "../partner-ordering-ui/utils/axios/config";
import axiosInstanceOfKheti from "../sell-to-farmer/utils/axios/config";
import refreshToken from "../partner-ordering-ui/utils/helpers/refreshToken";
import { Platform } from "react-native";

const interceptorCb = (axiosConfig: any) => {
  const copy = { ...axiosConfig };
  const token = store.getState().authToken.accessToken;
  if (copy.headers) {
    copy.headers["X-AUTH-SCHEME"] = "keycloak";
    copy.headers["X-LANGUAGE"] = i18next.language;

    if (token) {
      copy.headers.Authorization = `Bearer ${token}`;
    }
  }
};

const responseInterceptorSuccessCb = (response: AxiosResponse) => response;

const responseInterceptorErrCb = (err: AxiosError) => err;

const authConfig = {
  authUrl: Config.OIDC_URL as string,
};

const inputConfig: InputAxiosConfig = {
  appCode: Config.APP_CODE as string,
  inputUrl: Config.INPUT_BACKEND_URL as string,
  requestInterceptorSuccessCb: (inputConfig: any) => {
    const copy = { ...inputConfig };

    if (copy.headers) {
      const apiTraceId = createUUID();
      copy.headers["X-Request-ID"] = apiTraceId;
      copy.headers["api-request-trace-id"] = apiTraceId;
      if (Platform.OS === "android" || Platform.OS === "ios") {
        copy.headers["X-APP-VERSION"] = Config.VERSION_CODE;
        // done because getUniqueIdSync give issue when debugging.
        // So we can ignore this correct device-id logic for development.
        copy.headers["DEVICE-ID"] = createUUID();
      }
    }
    interceptorCb(copy);
  },
  responseInterceptorSuccessCb: responseInterceptorSuccessCb,
  responseInterceptorErrCb: responseInterceptorErrCb,
};

const khetiConfig: KhetiAxiosConfig = {
  appCode: Config.APP_CODE as string,
  khetiUrl: Config.KHETI_URL as string,
  requestInterceptorSuccessCb: interceptorCb,
  responseInterceptorSuccessCb: responseInterceptorSuccessCb,
  responseInterceptorErrCb: responseInterceptorErrCb,
};

export function initAxiosInstances() {
  const refreshTokenWrapper = async (failedRequest: any) =>
    await refreshToken(failedRequest);

  // configure the axios instance
  configureAuthAxiosInstance(authConfig);
  configureInputAxiosInstance(inputConfig);
  configureKhetiAxiosInstance(khetiConfig);
  createAuthRefreshInterceptor(axiosInstanceOfInput, refreshTokenWrapper);
  createAuthRefreshInterceptor(axiosInstanceOfKheti, refreshTokenWrapper);
}

export function initSentry() {
  if (process.env.NODE_ENV === "production" && Config.SENTRY_DSN) {
    Sentry.init({
      dsn: Config.SENTRY_DSN,
      tracesSampleRate: 0.25,
      environment: "prod",

      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      _experiments: {
        // The sampling rate for profiling is relative to TracesSampleRate.
        // In this case, we'll capture profiles for 10% of transactions.
        profilesSampleRate: 0.1,
      },
      release: "dba-web@" + Config.VERSION_NAME,
    });
    // Set the platform tag as web
    Sentry.setTag("platform", "web");
  }
}
