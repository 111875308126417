import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { BG_STYLES, COLORS, COMMON_STYLES } from "@dehaat/dds";

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
}

const ProductCardVerticalShimmer = ({ containerStyle }: Props) => (
  <View style={[BG_STYLES.bgWhite, COMMON_STYLES["p1/2"], containerStyle]}>
    <SkeletonPlaceholder backgroundColor={COLORS.neutral10}>
      <View>
        <SkeletonPlaceholder.Item height={120} width={140} borderRadius={8} />
        <View style={COMMON_STYLES["mt1/2"]}>
          <SkeletonPlaceholder.Item height={16} width={60} borderRadius={4} />
          <SkeletonPlaceholder.Item
            height={14}
            width={140}
            borderRadius={4}
            marginTop={8}
          />
          <SkeletonPlaceholder.Item
            height={20}
            width={140}
            borderRadius={4}
            marginTop={8}
          />
          <SkeletonPlaceholder.Item
            height={18}
            width={140}
            borderRadius={4}
            marginTop={16}
          />
          <SkeletonPlaceholder.Item
            height={36}
            width={140}
            borderRadius={4}
            marginTop={8}
          />
        </View>
      </View>
    </SkeletonPlaceholder>
  </View>
);

export default ProductCardVerticalShimmer;
