import React from "react";
import { Path, Svg, SvgProps } from "react-native-svg";

const OrderInProgressIcon = (props: SvgProps) => {
  return (
    <Svg viewBox={"0 0 120 120"} fill="none" {...props}>
      <Path
        d="M96.62 68.22c0 9.76-3.94 18.61-10.3 25.02-1.99 2.18-4.63 4.17-8.2 5.91L61.09 68V32.67c19.63 0 35.54 15.9 35.54 35.54l-.01.01Z"
        fill="#60B3B0"
      />
      <Path
        d="M61.09 19.06c-27.15 0-49.16 22.01-49.16 49.16 0 27.15 22.01 49.16 49.16 49.16 27.15 0 49.16-22.01 49.16-49.16 0-27.15-22-49.16-49.16-49.16Zm0 88.94c-21.96 0-39.78-17.82-39.78-39.78s17.81-39.78 39.78-39.78c21.97 0 39.78 17.81 39.78 39.78 0 21.97-17.81 39.78-39.78 39.78Z"
        fill="#153145"
      />
    </Svg>
  );
};

export default OrderInProgressIcon;
