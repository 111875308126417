import { formatCurrency } from "@dehaat/dds";
import { MOQ_TYPE } from "../../constants/Cart";
import { LICENSE_STATUS, PRODUCT_CONSTANTS } from "../../constants/Product";
import {
  Batch,
  BundledProduct,
  ClearanceSaleProductVariant,
  GrowthPlanBasketProductVariant,
  Product,
  ProductVariant,
} from "../../models/Product";
import store from "../../../store";
import { LabelImage } from "node_modules/@dehaat/dds/dist/types/models/Image";

export const getUnitPriceLabel = (
  unitPrice: string | number,
  unitOfMeasure: string
) => (unitPrice ? `@${formatCurrency(unitPrice)}/${unitOfMeasure}` : "");

export const getProductDetails = (product: Product) => {
  const availableVariants = getInStockVariant(product.variants);
  const isOutOfStock = !availableVariants.length;
  let displayVariant = isOutOfStock
    ? product.variants[0]
    : availableVariants[0];

  return {
    isOutOfStock,
    displayVariant,
    hasMultipleVariants: product.variants.length > 1,
  };
};

export const getCombinedLicenseDetails = (categoryTypes: BundledProduct[]) => {
  const categoryTypeArr = categoryTypes.map((item) => ({
    categoryType: item.category.type,
    subCategoryId: item.sub_category.id,
  }));
  return getLicenceDetails(categoryTypeArr);
};

/**
 *
 * @param info
 * @returns
 */
export const getLicenceDetails = (
  info:
    | { categoryType: string; subCategoryId: string }
    | { categoryType: string; subCategoryId: string }[]
) => {
  let licenseStatus: string = "";
  let blockOrder = false;
  let featureCheck = false;
  const dc = store.getState().native.dc;
  
  const fertilizerSubCatIdsList =
    (store.getState().native.fertilizerSubCategoryIdsList as string[]) || [];

  const types = Array.isArray(info) ? info : [info];

  for (const { categoryType, subCategoryId } of types) {
    switch (categoryType) {
      case PRODUCT_CONSTANTS.SEEDS:
        licenseStatus = dc.seed_license_status;
        featureCheck = dc.lic_bill_feature.seed;
        break;
      case PRODUCT_CONSTANTS.CROP_PROTECTION:
        licenseStatus = dc.pesticide_license_status;
        featureCheck = dc.lic_bill_feature.pesticide;
        break;
      case PRODUCT_CONSTANTS.FERTILIZERS:
        licenseStatus = dc.fertilizer_license_status;
        featureCheck =
          dc.lic_bill_feature.fertilizer &&
          fertilizerSubCatIdsList.includes(subCategoryId);
        break;
      default:
        licenseStatus = "";
        featureCheck = false;
    }
    //If feature check is true and license status is pending or submitted,
    if (
      featureCheck &&
      (licenseStatus === LICENSE_STATUS.PENDING ||
        licenseStatus === LICENSE_STATUS.SUBMITTED)
    ) {
      blockOrder = true;
      break;
    }
  }

  return {
    licenseStatus,
    blockOrder,
    featureCheck,
  };
};

export const getBundleImages = (
  bundleProducts: BundledProduct[],
  showUnit = true
): LabelImage[] => {
  return bundleProducts.map((product) => ({
    url: product.image,
    text: `${product.units}${showUnit ? " pc" : ""}`,
  }));
};

export const moqFormatter = (
  value: string | null,
  type: MOQ_TYPE | null,
  uom: string
) => {
  if (value)
    switch (type) {
      case MOQ_TYPE.VALUE:
        return formatCurrency(value);
      case MOQ_TYPE.WEIGHT:
        return `${value} ${uom}`;
      default:
        return `${value} units`;
    }
};

export const isVariantInStock = (
  variant: ProductVariant | GrowthPlanBasketProductVariant
) => {
  return (
    variant.orderable_quantity >
    (variant.min_moq_value ? Number(variant.min_moq_value) - 1 : 0)
  );
};

export const getInStockVariant = (
  variants: (ProductVariant | GrowthPlanBasketProductVariant)[]
) => {
  return variants?.filter((variant) => isVariantInStock(variant)) || [];
};

export const checkIsMultipleVariants = (product: Product) => {
  const inStockVariant = getInStockVariant(product.variants);
  return inStockVariant?.length > 1;
};

export const isBatchInStock = (batch: Batch) => batch.quantity > 0;

export const getInStockBatch = (batches: Batch[]) => {
  return batches.filter((batch) => isBatchInStock(batch));
};

export const checkIsMultipleBatches = (batches: Batch[]) => {
  const inStockBatch = getInStockBatch(batches);
  return inStockBatch.length > 1;
};

export const findVariantAddedInCart = (
  variants: (ProductVariant | GrowthPlanBasketProductVariant)[]
) => {
  const items = store.getState().cart.items;
  const varinatsAddedInCart = variants.filter((v) => items[v.id]?.qty > 0);
  return varinatsAddedInCart;
};

export const findBatchAddedInCart = (batches: Batch[]) => {
  const clearanceItems = store.getState().cart.clearanceItems;
  const batchesAddedInCart = batches.filter(
    (b) => clearanceItems[b.batchId]?.qty > 0
  );
  return batchesAddedInCart;
};

export const findPrepaidOnlyVariants = (
  variants: (ProductVariant | GrowthPlanBasketProductVariant)[]
) => {
  return variants.filter((v) => v.is_prepaid && isVariantInStock(v));
};

export const getDisplayVariant = (
  variants: (ProductVariant | GrowthPlanBasketProductVariant)[]
) => {
  const inStockVariants = getInStockVariant(variants);
  if (inStockVariants.length > 0) {
    const variantsAddedInCart = findVariantAddedInCart(inStockVariants);
    if (variantsAddedInCart.length > 0) {
      return variantsAddedInCart[0];
    }
    const prepaidOnlyVaraints = findPrepaidOnlyVariants(inStockVariants);
    if (prepaidOnlyVaraints.length) {
      return prepaidOnlyVaraints[0];
    }
    return inStockVariants[0];
  }
  return variants?.[0] || {};
};

export const getComboVisible = (comboList: Product[]) => {
  for (const product of comboList) {
    if (product.bundles[0].orderable_quantity) {
      return true;
    }
  }
};

export const getDisplayBatch = (batches: Batch[]) => {
  const inStockBatches = getInStockBatch(batches);
  if (inStockBatches.length > 0) {
    const batchesAddedToCart = findBatchAddedInCart(inStockBatches);
    if (batchesAddedToCart.length > 0) {
      return batchesAddedToCart[0];
    }
    return inStockBatches[0];
  }
  return batches[0];
};

export const getClearanceListBatches = (
  variants: ClearanceSaleProductVariant[]
) => {
  const batches: Batch[] = [];
  variants.forEach((variant) =>
    variant.clearance_sales.forEach((batch) =>
      batch.inventory.forEach((quantity) =>
        batches.push({
          packAttribute: variant.pack_attribute,
          expiry: batch.expiry,
          quantity: quantity.quantity,
          price: variant.dehaat_price,
          badgeTags: variant.badge_tags,
          clearanceInventory: variant.clearance_inventory,
          infoTags: variant.info_tags,
          constituentUom: variant.constituent_uom,
          discountPrice: batch.dehaat_price,
          id: variant.id,
          batchId: batch.batch_id,
          minQty: batch.min_qty,
        })
      )
    )
  );
  return batches;
};
