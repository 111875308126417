import { ORDER_STATUS } from "app/sell-to-farmer/constants/common";
import { patch } from "../axios";
import { OrderCancelReasonObj } from "app/sell-to-farmer/models/orders";

export type ORDER_REQ_ACTION = "accept" | "reject";

interface Params {
  status: ORDER_STATUS;
  reason: string | OrderCancelReasonObj;
  reject_order_request: boolean;
}

const patchOrderRequest = async (
  action: ORDER_REQ_ACTION,
  orderReqId: number,
  body: Partial<Params>
) => {
  try {
    const response = await patch(
      `/hyperlocal/v1/order-request/${action}/${orderReqId}`,
      {
        ...body,
      }
    );
    return Boolean(response);
  } catch (e) {
    console.log("e");
    return false;
  }
};

export default patchOrderRequest;
