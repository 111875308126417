import React from "react";
import { Modal } from "react-native";

import ErrorScreen from "./resuable/Error";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  message: string;
  onClose: () => void;
}

const LogErrorModal = ({ visible, message, onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal visible={visible}>
      <ErrorScreen
        message={message}
        cta={{
          text: t("okay"),
          onClick: onClose,
        }}
      />
    </Modal>
  );
};

export default LogErrorModal;
