import React from "react";
import { Text } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  Button,
  BUTTON_TYPE,
  SIZE,
  SUBHEADING_STYLES,
  TEXT_STYLES,
} from "@dehaat/dds";

import CustomBackButton from "app/partner-ordering-ui/components/resuable/CustomBackButton";
import { ROUTES as ORDERING_ROUTES } from "app/partner-ordering-ui/routes/types";
import NoInternet from "app/partner-ordering-ui/screens/noInternet";
import { ROUTES as STFRoutes } from "app/sell-to-farmer/routes/types";
import ProtectedRoute from "app/components/ProtectedRoute";
import AppProvider from "app/components/AppProvider";
import Login from "app/screens/login";
import Otp from "app/screens/otp";
import { backHandler, logout } from "app/utils/common";

import { RootStackParamList, ROUTES } from "./types";
import { routesOrdering, routesSTF } from "./Routes";

const RootStack = createNativeStackNavigator<RootStackParamList>();

export const RootStackNavigator = () => (
  <RootStack.Navigator
    initialRouteName={ROUTES.LOGIN}
    screenOptions={() => {
      return {
        headerLeft: () => <CustomBackButton onBackPress={backHandler} />,
        headerBackVisible: false,
        headerRight: () => (
          <Button
            size={SIZE.large}
            type={BUTTON_TYPE.TERTIARY}
            onClick={logout}
            text="Logout"
          />
        ),
        headerTitle: ({ children }) => (
          <Text
            style={[SUBHEADING_STYLES.MEDIUM_S3, TEXT_STYLES.textNeutral90]}
          >
            {children}
          </Text>
        ),
        animation: "none",
      };
    }}
  >
    <RootStack.Screen
      name={ROUTES.LOGIN}
      component={Login}
      options={{ headerShown: false }}
    />
    <RootStack.Screen
      name={ROUTES.OTP}
      component={Otp}
      options={{ headerShown: false }}
    />
    <RootStack.Screen
      name={ORDERING_ROUTES.NO_INTERNET}
      component={NoInternet}
      options={{ headerShown: false }}
    />

    {/** Screen related to sell to farmer */}
    {Object.keys(routesSTF).map((route) => (
      <RootStack.Screen
        key={route}
        name={route as STFRoutes}
        options={routesSTF[route].options}
      >
        {(props) => {
          const Component = routesSTF[route].component;
          return (
            <ProtectedRoute {...props}>
              <Component {...props} />
            </ProtectedRoute>
          );
        }}
      </RootStack.Screen>
    ))}

    {/** Screen related to ordering */}
    {Object.keys(routesOrdering).map((route) => (
      <RootStack.Screen
        key={route}
        name={route as ORDERING_ROUTES}
        options={routesOrdering[route].options}
      >
        {(props) => {
          const Component = routesOrdering[route].component;
          return (
            <ProtectedRoute {...props}>
              <AppProvider>
                <Component {...props} />
              </AppProvider>
            </ProtectedRoute>
          );
        }}
      </RootStack.Screen>
    ))}
  </RootStack.Navigator>
);
