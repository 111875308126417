import React from "react";
import { Path, Svg, SvgProps } from "react-native-svg";

export default function InfoIcon(props: SvgProps) {
  return (
    <Svg viewBox="0 0 16 17" fill="none" {...props}>
      <Path
        fill="#0E7A41"
        d="M15 8.503v-.05a6.99 6.99 0 0 0-.424-2.359l-.013-.033a6.341 6.341 0 0 0-.109-.275l-.001-.004a7.01 7.01 0 0 0-6.399-4.281L8.003 1.5h-.055a6.98 6.98 0 0 0-2.514.485l-.013.005-.143.06-.09.038-.064.028a7.008 7.008 0 0 0-4.123 6.332L1 8.498v.053a6.98 6.98 0 0 0 .485 2.513c.01.027.02.053.032.08l.03.075a7.01 7.01 0 0 0 6.405 4.28l.045.001h.051a6.987 6.987 0 0 0 2.194-.367l.086-.03.073-.025a7.012 7.012 0 0 0 4.598-6.53L15 8.502Zm-6.805-4.8c.681-.005 1.26.582 1.257 1.275a1.27 1.27 0 0 1-1.26 1.271c-.693 0-1.26-.568-1.262-1.266a1.271 1.271 0 0 1 1.265-1.28Zm1.7 8.742c-.437.483-.97.784-1.631.829-.36.024-.721.048-1.077-.05-.65-.178-1.086-.773-1.003-1.441.083-.662.21-1.317.32-1.975.096-.572.199-1.143.293-1.715a.995.995 0 0 0-.007-.286.323.323 0 0 0-.281-.295c-.094-.018-.194-.008-.285-.032-.13-.035-.27-.068-.376-.143-.174-.123-.14-.373.058-.457a.867.867 0 0 1 .319-.06c.411-.008.823-.003 1.235-.003v.003c.42 0 .841-.002 1.262 0 .275.002.405.12.463.388.05.233-.015.452-.057.676-.176.943-.35 1.887-.521 2.83-.054.292-.102.584-.15.877-.008.049-.006.1-.005.15.006.574.369.652.785.485.117-.047.22-.127.334-.188.194-.104.298-.107.375-.017.094.11.085.272-.051.424Z"
      />
    </Svg>
  );
}
