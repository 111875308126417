import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { STORAGE_KEYS } from "app/partner-ordering-ui/constants/storage";
import { RootState } from "app/store";
import { storeData } from "app/utils/storage";

export interface RecentSearch {
  actual: string;
  suggested: string;
}

interface AppState {
  recentProductSearches: RecentSearch[];
  isInternetAvailable: boolean;
  sessionExpired: boolean;
}

const initialState: AppState = {
  recentProductSearches: [],
  isInternetAvailable: true,
  sessionExpired: false,
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setRecentProductSearch: (state, action: PayloadAction<RecentSearch[]>) => {
      if (action.payload) {
        const uniqueItems: { [key: string]: RecentSearch } = {};
        action.payload.forEach((item) => {
          uniqueItems[item.suggested.toUpperCase()] = item;
        });
        const uniqueData = Object.values(uniqueItems);
        state.recentProductSearches = uniqueData;
        storeData(STORAGE_KEYS.RECENT_PRODUCTS_SEARCHES, uniqueData);
      } else {
        state.recentProductSearches = [];
      }
    },
    updateInternetAvailability: (state, action: PayloadAction<boolean>) => {
      state.isInternetAvailable = action.payload;
    },
    setSessionExpired: (state, action: PayloadAction<boolean>) => {
      state.sessionExpired = action.payload;
    },
  },
});
export const {
  setRecentProductSearch,
  updateInternetAvailability,
  setSessionExpired,
} = app.actions;

export const selectRecentProductsSearch = (state: RootState) =>
  state.app.recentProductSearches;

export const selectInternetAvailability = (state: RootState) =>
  state.app.isInternetAvailable;

export const selectIsSessionExpired = (state: RootState) =>
  state.app.sessionExpired;

export default app.reducer;
