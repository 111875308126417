import React from "react";
import { ClipPath, Defs, G, Path, Rect, Svg, SvgProps } from "react-native-svg";

const RupeeSymbolIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 12 12" fill="none" {...props}>
      <Path
        d="m10.944 6.5.644-1.146a.616.616 0 0 0 .059-.456.6.6 0 0 0-.272-.365L10.26 3.87V2.548a.61.61 0 0 0-.171-.424.576.576 0 0 0-.413-.176H8.39L7.747.8a.591.591 0 0 0-.354-.28.564.564 0 0 0-.444.06l-1.115.663L4.719.58a.57.57 0 0 0-.444-.06.586.586 0 0 0-.354.28l-.645 1.148H1.992a.576.576 0 0 0-.413.176.61.61 0 0 0-.171.424V3.87l-1.116.664a.588.588 0 0 0-.272.365.616.616 0 0 0 .06.456L.723 6.5.079 7.646a.618.618 0 0 0-.058.456c.04.154.137.285.27.365l1.116.664v1.32c0 .16.062.313.171.425.11.113.258.176.413.176h1.285l.645 1.148c.051.09.125.166.214.219a.572.572 0 0 0 .584 0l1.114-.663 1.116.663a.57.57 0 0 0 .443.06.587.587 0 0 0 .355-.28l.644-1.147h1.284a.576.576 0 0 0 .413-.176.61.61 0 0 0 .171-.424V9.13l1.116-.664a.59.59 0 0 0 .272-.365.617.617 0 0 0-.06-.456L10.945 6.5Zm-6.57-3.01c-.582.095-.35.409-.186.578.164.17.185.226.185.465 0 .239.262-.015.098.154-.164.17.232.21 0 .21.434-.76-.594-1.223-.758-1.392-.164-.169-.216 1.126-.216.886 0-.239 1.145.97 1.31.802.164-.17-.666-1.703-.434-1.703Zm.175 5.767.159.03 3.504-4.806-.16-.03L4.55 9.258Zm2.744.24c-.115 0-.343-2.116-.45-2.162-.105-.045-.546 1.875-.17 1.898-.08-.083 1.49-1.429 1.447-1.538-.044-.11-1.703 1.02-1.703.901 0-.118 1.841.724 1.885.614.044-.109-1.71-1.168-1.628-1.251a.875.875 0 0 1 .285-.196c.106-.045.134 2.01.25 2.01.232 0 .54-1.983.705-1.814-.62-1.056-1.8.262-1.8.501 0 .24 1.459.938 1.8.774-.474-1.109-.388.264-.62.264Z"
        fill="#fff"
      />
      <G clipPath="url(#a)">
        <Path
          d="M4 7.167h1"
          stroke="#4C2C7A"
          strokeWidth=".667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 3.5a.333.333 0 1 0 0 .667h1c.465 0 .79.17 1.001.409.146.164.246.369.296.59H4a.333.333 0 1 0 0 .667h2.297c-.05.222-.15.427-.296.591-.211.238-.536.41-1 .41A.333.333 0 1 0 5 7.5c.645 0 1.154-.246 1.499-.633a2.01 2.01 0 0 0 .475-1.034H8a.333.333 0 1 0 0-.666H6.975a2.018 2.018 0 0 0-.446-1H8A.333.333 0 1 0 8 3.5H4Zm-.242 3.438a.333.333 0 0 1 .47-.014l2.834 2.667a.333.333 0 1 1-.457.485L3.772 7.41a.333.333 0 0 1-.014-.47Z"
          fill="#4C2C7A"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Rect x="2" y="2.833" width="8" height="8" rx="4" fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default RupeeSymbolIcon;
