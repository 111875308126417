import React, { ReactNode, useRef, useState } from "react";
import { RefreshControl, Text, View } from "react-native";
import {
  BG_STYLES,
  COMMON_STYLES,
  ProductCardHorizontal,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  TEXT_STYLES,
  BUTTON_TYPE,
  Strip,
  ProductTags,
  BUTTON_STATUS,
  BORDER_STYLES,
  ProductComboCardHz,
} from "@dehaat/dds";
import ProductComboList from "./ProductComboList";
import ProductPriceInfo from "../ProductPriceInfo";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import useCart from "../../../hooks/useCart";
import {
  GrowthPlanBasketProduct,
  InventoryErrorModalState,
  Product,
  ProductVariant,
} from "../../../models/Product";
import { ROUTES } from "../../../routes/types";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "../../../store/reducer/cart";

import {
  CartBundleSKUInfo,
  CartClearanceSKUInfo,
  CartItem,
  CartSingleSKUInfo,
  SingleProduct,
} from "../../../models/Cart";
import ComboPriceInfo from "../ComboPriceInfo";
import AlternateItemsList from "./AlternateItemsList";
import ShimmerProductCardHz from "../../cards/ProductCardHzShimmer";
import {
  getBundleImages,
  checkIsMultipleVariants,
  getLicenceDetails,
  getDisplayVariant,
  moqFormatter,
  getCombinedLicenseDetails,
} from "../../../utils/helpers/product";
import { rnNavigation } from "../../../../utils/appConfig";

import RenderList from "../RenderList";
import OfferStrip from "./OfferStrip";
import LockIcon from "../../../icons/LockIcon";
import { openWhatsApp } from "../../../utils/helpers/common";
import { useTranslation } from "react-i18next";
import RupeeClearanceIcon from "../../../icons/RupeeClearance";
import { MAX_INVENTORY_ALLOWED } from "../../../constants/Common";
import LicenseInfoTag from "../../resuable/LicenseInfoTag";
import {
  LICENSE_STATUS,
  PRODUCT_TYPE,
} from "app/partner-ordering-ui/constants/Product";
import {
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "app/partner-ordering-ui/constants/eventNames";
import { selectDCData } from "app/store/reducer/native";
import {
  fireClickOnOfferStripEvent,
  fireProductClickedEvent,
  fireProductViewedEvent,
  fireProductAddToCartEvent,
  fireComboProductAddToCartEvent,
  fireRemoveCartItem,
  fireCurartedProductClicked,
} from "app/partner-ordering-ui/utils/helpers/mixpanel";
import { ViewProductType } from "app/partner-ordering-ui/models/MixpanelEvent";
import InfoIcon from "app/partner-ordering-ui/icons/InfoIcon";
import ProductInventoryBottomDrawer from "app/partner-ordering-ui/components/productList/ProductInventoryBottomDrawer";
import {
  errorArray,
  isAIMS,
} from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import { getOfferHeadingCta } from "app/partner-ordering-ui/utils/helpers/offer";

interface Props {
  loadingNext: boolean;
  fetchNext: () => void;
  products: (Product | GrowthPlanBasketProduct)[];
  getProducts: () => void;
  isViewOnlyCard?: boolean;
  listHeader?: ReactNode;
  isHeaderSticky?: boolean;
  isOrderingBlocked?: boolean;
  handleRevealPriceClick?: () => void;
  showComboDrawer?: boolean;
  showAlternateItems?: boolean;
  navigatingFrom: string;
  eventObject?: {
    listId: string;
    groupId: string;
    listName?: string;
  };
}

const RenderProductList = ({
  loadingNext,
  products,
  getProducts,
  fetchNext,
  isViewOnlyCard,
  listHeader = null,
  isHeaderSticky = false,
  isOrderingBlocked = false,
  handleRevealPriceClick,
  showComboDrawer = true,
  showAlternateItems = true,
  navigatingFrom,
  eventObject,
}: Props) => {
  const dispatch = useAppDispatch();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const { cartItems, cartInfo, cartData } = useCart();
  const { t } = useTranslation();
  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });
  const [selectedProductInventory, setSelectedProductInventory] = useState<{
    visible: boolean;
    preOrderQuantity?: number;
    totalQuantity?: number;
  }>({ visible: false });
  const dc = useAppSelector(selectDCData);
  const loadedItems = useRef(new Set());

  const isProductInStock = (variant: ProductVariant) =>
    variant.orderable_quantity < Number(variant.min_moq_value || 1) &&
    !dc.app_blocked;

  const onViewableItemsChanged = (options: ViewProductType) =>
    options.viewableItems.forEach((viewItemConfig) => {
      if (
        viewItemConfig.isViewable &&
        !loadedItems.current.has(viewItemConfig.key)
      ) {
        const viewItem = viewItemConfig?.item;
        const displayVariant = getDisplayVariant(viewItem.variants);
        fireProductViewedEvent({
          product: viewItem,
          variant: displayVariant,
          isPriorityDc: dc?.is_priority_dc,
          screenName: navigatingFrom,
          isProductInStock: isProductInStock(displayVariant),
          productPosition: viewItemConfig.index + 1,
        });
        loadedItems.current.add(viewItemConfig.key);
      }
    });

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: {
        itemVisiblePercentThreshold: 100,
      },
      onViewableItemsChanged: onViewableItemsChanged,
    },
  ]);

  const renderListItem = (product: Product, index: number) => {
    if (product.type === "bundle") {
      // no need to handle price lock case for bundles
      const {
        id,
        name,
        bundled_products,
        actual_price,
        discounted_price,
        orderable_quantity,
      } = product.bundles[0];
      const quantity = cartItems[id]?.qty;
      const images = getBundleImages(bundled_products);
      const { licenseStatus, blockOrder } =
        getCombinedLicenseDetails(bundled_products);

      const onCardClick = () => {
        rnNavigation({
          name: ROUTES.COMBO_DETAIL,
          params: { id: product.id },
        });

        if (eventObject) {
          fireCurartedProductClicked({
            product: product,
            isPriorityDc: dc?.is_priority_dc,
            listId: eventObject.listId,
            groupId: eventObject.groupId,
            listName: eventObject.listName!,
            screenName: "Home",
          });
        }
        fireProductClickedEvent(
          {
            product: product,
            variant: displayVariant,
            productPosition: index + 1,
            isPriorityDc: dc?.is_priority_dc,
            screenName: ROUTES.PRODUCT_LIST,
            isOfferStrip: false,
          },
          {
            [MIXPANEL_KEYS.SKU_ID]: product.bundles[0].id,
            [MIXPANEL_KEYS.TEMPALTE_ID]: product.id,
            [MIXPANEL_KEYS.PREPAID_TAG]: product.bundles[0].is_prepaid,
          }
        );
      };
      const onQtyUpdate = (qty: number) => {
        const availableQty = Math.min(
          MAX_INVENTORY_ALLOWED,
          orderable_quantity
        );
        if (qty <= availableQty) dispatch(updateCartItem({ id, qty }));
        else {
          dispatch(updateCartItem({ id, qty: availableQty }));
          setInventoryErrorState({
            isVisible: true,
            name: product.name,
            availableInventory: availableQty,
          });
        }
      };

      return orderable_quantity ? (
        <ProductComboCardHz
          key={id}
          containerStyle={COMMON_STYLES["mb0.5"]}
          images={images}
          productName={name}
          offerLabel={t("combo_offer")}
          onDeleteClick={() => dispatch(deleteCartItem({ id }))}
          onPlusClick={() => onQtyUpdate(quantity + 1)}
          onMinusClick={() => onQtyUpdate(quantity - 1)}
          onQunatityUpdate={(qty) => onQtyUpdate(qty)}
          cta={{
            onClick: () => {
              if (isOrderingBlocked) {
                if (!isAIMS) {
                  handleRevealPriceClick?.();
                }
              } else if (
                blockOrder &&
                licenseStatus === LICENSE_STATUS.PENDING
              ) {
                openWhatsApp?.();
              } else {
                dispatch(addItemToCart({ id, product }));
                fireComboProductAddToCartEvent(
                  {
                    product: product?.bundles[0],
                    isPriorityDc: dc?.is_priority_dc,
                    screenName: ROUTES.PRODUCT_LIST,
                    productPosition: index + 1,
                  },
                  {
                    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]:
                      product?.similar_product_count,
                    [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: false,
                    [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: false,
                  }
                );
              }
            },
            text: isOrderingBlocked
              ? t("price")
              : blockOrder
              ? t("upload_license")
              : t("add"),
            iconRight: isOrderingBlocked ? (
              <LockIcon height={16} width={16} />
            ) : undefined,
            type:
              isOrderingBlocked || blockOrder
                ? BUTTON_TYPE.SECONDARY
                : BUTTON_TYPE.PRIMARY,
            buttonStyle: isOrderingBlocked
              ? BORDER_STYLES.borderBlue10
              : blockOrder && licenseStatus === LICENSE_STATUS.SUBMITTED
              ? COMMON_STYLES.displayNone
              : undefined,
            textStyle: blockOrder ? TYPOGRAPHY.CAPTION1 : null,
          }}
          priceInfo={
            <ComboPriceInfo
              actualPrice={Number(actual_price)}
              discountPrice={Number(discounted_price)}
              priceLocked={isOrderingBlocked}
            />
          }
          onCardClick={() => onCardClick()}
          quantity={quantity}
          availableInventory={Math.min(
            orderable_quantity,
            MAX_INVENTORY_ALLOWED
          )}
        >
          {!isOrderingBlocked && blockOrder && (
            <LicenseInfoTag
              licenseStatus={licenseStatus}
              style={[COMMON_STYLES.mt1, COMMON_STYLES["mb1/2"]]}
            />
          )}
        </ProductComboCardHz>
      ) : null;
    }

    const displayVariant = getDisplayVariant(product.variants);
    const errorRemark = errorArray(displayVariant);

    const infoFromCart = cartInfo.products.find(
      (item) =>
        item.id === displayVariant.id &&
        item.product_type === PRODUCT_TYPE.REGULAR
    ) as SingleProduct;

    const quantity = cartItems[displayVariant.id]?.qty || 0;
    const id = displayVariant.id;
    const { licenseStatus, blockOrder } = getLicenceDetails({
      categoryType: product.category.type,
      subCategoryId: product.sub_category.id,
    });

    const isOutOfStock =
      displayVariant.orderable_quantity <
      Number(displayVariant.min_moq_value || 1);

    const handleQtyUpdate = (qty: number) => {
      const availableQty = Math.min(
        displayVariant.orderable_quantity,
        MAX_INVENTORY_ALLOWED
      );
      if (qty <= availableQty) dispatch(updateCartItem({ id, qty }));
      else {
        dispatch(updateCartItem({ id, qty: availableQty }));
        setInventoryErrorState({
          isVisible: true,
          name: product.name,
          availableInventory: availableQty,
        });
      }
    };
    const onCardClick = () => {
      rnNavigation({
        name: ROUTES.PRODUCT_DETAIL,
        params: {
          id: product.id,
          variantId: displayVariant?.id,
          navigationFrom: navigatingFrom,
        },
      });
      if (eventObject) {
        fireCurartedProductClicked({
          product: product,
          isPriorityDc: dc?.is_priority_dc,
          listId: eventObject.listId,
          groupId: eventObject.groupId,
          listName: eventObject.listName!,
          screenName: "Home",
        });
      }
      fireProductClickedEvent({
        product: product,
        variant: displayVariant,
        productPosition: index + 1,
        isPriorityDc: dc?.is_priority_dc,
        screenName: ROUTES.PRODUCT_LIST,
        isOfferStrip: !blockOrder && !isOrderingBlocked,
      });
    };

    const onOfferStripClick = (
      stripType: string,
      offerStripDetails: { cta: string; heading: string }
    ) => {
      rnNavigation({
        name: ROUTES.PRODUCT_DETAIL,
        params: {
          id: product.id,
          variantId: id.toString(),
          scrollKey: stripType,
        },
      });
      fireProductClickedEvent({
        product: product,
        variant: displayVariant,
        productPosition: index + 1,
        isPriorityDc: dc?.is_priority_dc,
        isOfferStrip: true,
        screenName: ROUTES.PRODUCT_LIST,
        navigationFrm: MIXPANEL_PROPERTIES.OFFER_STRIP,
      });
      fireClickOnOfferStripEvent({
        product: product,
        variant: displayVariant,
        isPriorityDc: dc?.is_priority_dc,
        screenName: ROUTES.PRODUCT_LIST,
        offerStripName: offerStripDetails.cta,
      });
    };

    const addUnitInfoTag = (
      totalQuantity: number,
      preOrderQuantity: number,
      orderableQuantity: number,
      infoTags: ProductTags[]
    ) => {
      const unitTag: ProductTags[] = isAIMS()
        ? [
            {
              icon: <InfoIcon width={16} height={16} />,
              key: "units",
              name: `${orderableQuantity.toLocaleString("en-IN")} Units`,
              rank: 2,
              onClick: () =>
                setSelectedProductInventory({
                  visible: true,
                  preOrderQuantity: preOrderQuantity,
                  totalQuantity: totalQuantity,
                }),
            },
          ]
        : [];

      const tags: ProductTags[] = unitTag.concat(infoTags);

      return tags;
    };

    const onDelete = () => {
      if (displayVariant) {
        dispatch(deleteCartItem({ id: displayVariant.id }));
        let removedData:
          | CartSingleSKUInfo
          | CartBundleSKUInfo
          | CartClearanceSKUInfo
          | undefined;
        let removedItem: Product | undefined;
        cartData &&
          Object.values(cartData)?.forEach((item) => {
            if (parseInt(item.id) === displayVariant.id) {
              removedData = item;
            }
          });
        cartItems &&
          Object.values(cartItems)?.forEach((item: CartItem) => {
            if (item?.query?.id === displayVariant.id) {
              removedItem = item?.detail;
            }
          });
        fireRemoveCartItem({
          removedData: removedData,
          removedItem: removedItem,
          screenName: ROUTES.PRODUCT_DETAIL,
          isPriorityDc: dc?.is_priority_dc,
        });
      }
    };

    return (
      <ProductCardHorizontal
        isViewOnlyCard={isViewOnlyCard}
        badgeTags={displayVariant.badge_tags}
        brandName={product.brand?.name}
        containerStyle={[COMMON_STYLES["mb1/2"], BG_STYLES.bgWhite]}
        infoTags={
          addUnitInfoTag(
            displayVariant.total_quantity,
            displayVariant.pre_order_quantity,
            displayVariant.orderable_quantity,
            displayVariant.info_tags
          ) || []
        }
        imageUrl={product.image || ""}
        key={product.id}
        hasMultipleVariants={checkIsMultipleVariants(product)}
        onCardClick={() => onCardClick()}
        discountInfo={
          errorRemark.length > 0 ? null : (
            <ProductPriceInfo
              constituentUnitPrice={displayVariant.constituent_unit_price}
              constituentUom={displayVariant.constituent_uom}
              price={displayVariant.dehaat_price}
              priceLocked={isOrderingBlocked}
              discountsInfo={(infoFromCart || displayVariant).discounts_info}
            />
          )
        }
        productName={product.name}
        onDeleteClick={() => onDelete()}
        remark={
          errorRemark.length > 0 ? (
            <Text
              style={[
                VARIANTS_STYLES.BASE,
                TYPOGRAPHY.CAPTION1,
                TEXT_STYLES.textError100,
              ]}
            >
              {t(errorRemark[0]) || null}
              {errorRemark[1] ? "\n" + t(errorRemark[1]) : null}
            </Text>
          ) : null
        }
        onPlusClick={() => dispatch(updateCartItem({ id, qty: quantity + 1 }))}
        onMinusClick={() => dispatch(updateCartItem({ id, qty: quantity - 1 }))}
        onQunatityUpdate={handleQtyUpdate}
        packAttribute={displayVariant.pack_attribute}
        unitOfMeasure={displayVariant.constituent_uom}
        quantity={quantity}
        handleVariantSelect={() => setSelectedProduct(product)}
        totalAddedQuantity={infoFromCart?.total_added_quantity || 0}
        isOutOfStock={isOutOfStock && !isOrderingBlocked}
        showUploadLicenseCta={
          !isOrderingBlocked && !isViewOnlyCard && blockOrder
        }
        onUploadLicenceCTAClick={
          !isOrderingBlocked &&
          blockOrder &&
          licenseStatus === LICENSE_STATUS.PENDING
            ? openWhatsApp
            : undefined
        }
        minimumOrderQuanity={
          errorRemark.length > 0
            ? 0
            : parseInt(displayVariant?.min_moq_value || "1")
        }
        cta={{
          onClick: () => {
            if (isAIMS() && errorRemark.length > 0) {
              return;
            }
            if (isAIMS()) {
              if (!isOrderingBlocked) {
                setSelectedProductInventory({
                  visible: true,
                  preOrderQuantity: displayVariant.pre_order_quantity,
                  totalQuantity: displayVariant.total_quantity,
                });
              }
            }
            if (isOrderingBlocked) {
              if (!isAIMS) {
                handleRevealPriceClick?.();
              }
            } else {
              dispatch(addItemToCart({ id, product }));
              fireProductAddToCartEvent({
                product: product,
                variant: displayVariant,
                isPriorityDc: dc?.is_priority_dc,
                screenName: ROUTES.PRODUCT_LIST,
                productPosition: index + 1,
              });
            }
          },
          text: isOrderingBlocked ? t("price") : t("add"),
          iconRight: isOrderingBlocked ? (
            <LockIcon height={16} width={16} />
          ) : undefined,
          type: isOrderingBlocked ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY,
          buttonStyle: isOrderingBlocked
            ? BORDER_STYLES.borderBlue10
            : undefined,
          status:
            isAIMS() && errorRemark.length > 0
              ? BUTTON_STATUS.DISABLED
              : BUTTON_STATUS.ACTIVE,
        }}
        moqDisplayValue={moqFormatter(
          displayVariant.moq_label_value,
          displayVariant.moq_type,
          displayVariant.constituent_uom
        )}
        availableInventory={Math.min(
          displayVariant.orderable_quantity,
          MAX_INVENTORY_ALLOWED
        )}
      >
        {product.is_clearance_sale && (
          <Strip
            heading={t("get_this_item_cheaper")}
            subHeading={t("this_item_is_available_in_clearance_sale")}
            actionTxt={t("view_sale")}
            imageOrIcon={<RupeeClearanceIcon height={16} width={12} />}
            bgColor="bgOrange20"
            textColor="orange120"
            onClick={() =>
              rnNavigation({
                name: ROUTES.CLEARANCE_SALE_LIST,
                params: { id: product.id },
              })
            }
            containerStyle={[COMMON_STYLES.mh1, COMMON_STYLES["mb0.5"]]}
          />
        )}
        {!isViewOnlyCard && !blockOrder && !isOrderingBlocked && (
          <View style={[COMMON_STYLES.mh1, COMMON_STYLES.mb1]}>
            {!isOutOfStock && (
              <OfferStrip
                id={product.id}
                isBundle={product.bundles_available}
                discountInfo={(infoFromCart || displayVariant).discounts_info}
                constituentUom={displayVariant.constituent_uom}
                quantity={quantity}
                onClick={(stripType) => {
                  const offerStripDetails = getOfferHeadingCta({
                    isBundle: product.bundles_available,
                    discountInfo: (infoFromCart || displayVariant)
                      .discounts_info,
                    constituentUom: displayVariant.constituent_uom,
                    quantity: quantity,
                  });
                  onOfferStripClick(stripType, offerStripDetails);
                }}
              />
            )}
            {showComboDrawer && product.bundles_available && quantity > 0 && (
              <ProductComboList
                id={product.id}
                currentScreen={ROUTES.PRODUCT_LIST}
                eventData={{
                  product: product,
                  variant: displayVariant,
                  productPosition: index,
                }}
              />
            )}
            {showAlternateItems &&
            isOutOfStock &&
            product.similar_product_count ? (
              <AlternateItemsList
                id={product.id}
                noOfAlternateProducts={product.similar_product_count}
                technicalContents={product.technical_contents}
                containerStyle={COMMON_STYLES["mt5/4"]}
              />
            ) : null}
          </View>
        )}
        {!isOrderingBlocked && !isViewOnlyCard && blockOrder && (
          <LicenseInfoTag licenseStatus={licenseStatus} />
        )}
      </ProductCardHorizontal>
    );
  };

  return (
    <>
      <RenderList
        selectedProduct={selectedProduct}
        listProps={{
          data: products,
          renderItem: ({ item, index }) => renderListItem(item, index),
          onEndReached: fetchNext,
          ListFooterComponent: loadingNext ? <ShimmerProductCardHz /> : null,
          ListHeaderComponent: () => listHeader,
          stickyHeaderIndices: isHeaderSticky ? [0] : undefined,
          viewabilityConfigCallbackPairs:
            viewabilityConfigCallbackPairs.current,
          refreshControl: (
            <RefreshControl refreshing={false} onRefresh={getProducts} />
          ),
        }}
        removeSelectedProduct={() => setSelectedProduct(undefined)}
        inventoryModalState={inventoryErrorState}
        resetModalState={() => setInventoryErrorState({ isVisible: false })}
        landingFrom={ROUTES.PRODUCT_DETAIL}
      />
      <ProductInventoryBottomDrawer
        visible={selectedProductInventory.visible}
        onClose={() =>
          setSelectedProductInventory({
            visible: false,
          })
        }
        preOrderQuantity={selectedProductInventory.preOrderQuantity}
        totalQuantity={selectedProductInventory.totalQuantity}
      />
    </>
  );
};

export default RenderProductList;
