import React from 'react';
import {Path, Svg, SvgProps} from 'react-native-svg';

const RoundTickIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 12 13" fill="none" {...props}>
      <Path
        d="M6 .5a6 6 0 1 0 6 6 6.012 6.012 0 0 0-6-6Zm2.856 4.95L5.475 8.68a.467.467 0 0 1-.64 0l-1.69-1.615a.462.462 0 1 1 .634-.669l1.373 1.31 3.07-2.925a.461.461 0 0 1 .634.669Z"
        fill="#43A314"
      />
    </Svg>
  );
};

export default RoundTickIcon;
