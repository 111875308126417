import type { NativeStackScreenProps } from "@react-navigation/native-stack";

export enum ROUTES {
  FARMER_ORDERS = "FarmerOrders",
  ORDER_DETAILS = "OrderDetails",
}

export type RootStackParamList = {
  [ROUTES.FARMER_ORDERS]: undefined;
  [ROUTES.ORDER_DETAILS]: {
    orderId: string;
  };
};

export type FarmerOrdersScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.FARMER_ORDERS
>;

export type OrdersDetailsScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.ORDER_DETAILS
>;
