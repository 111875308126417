import React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const BackCaret = (props: SvgProps) => {
  return (
    <Svg fill="none" stroke="#101010" viewBox="0 0 25 25" {...props}>
      <Path d="m15.003 19.504-7.501-7.502 7.501-7.5" />
    </Svg>
  );
};

export default BackCaret;
