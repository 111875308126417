import {
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import {
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  PriceInfo,
} from "@dehaat/dds";
import {
  BlurPrice,
  ClearanceProduct,
} from "app/partner-ordering-ui/assets/images";
import ExpiryClockIcon from "app/partner-ordering-ui/icons/ExpiryClock";
import React, { PropsWithChildren } from "react";
import ExpiryNote from "./ExpiryNote";

interface Props {
  conatinerStyle: StyleProp<ViewStyle>;
  packAttribute: string;
  discountedPrice: string | null;
  price: number | string;
  unitOfMeasure: string | null;
  isPriceLocked: boolean;
  onCardClick?: () => void;
  remark: string[] | null;
  expiry: string;
}

const ClearanceListVariantCard = ({
  conatinerStyle,
  packAttribute,
  discountedPrice,
  price,
  unitOfMeasure,
  isPriceLocked,
  onCardClick,
  expiry,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Pressable
      onPress={onCardClick}
      style={[COMMON_STYLES["p1/2"], COMMON_STYLES["br1/2"], conatinerStyle]}
    >
      <View style={[COMMON_STYLES.row, COMMON_STYLES.flex1]}>
        <Image source={ClearanceProduct} style={styles.image} />
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textBlack,
            COMMON_STYLES["ml1/4"],
          ]}
        >
          {packAttribute}
        </Text>
        {isPriceLocked ? (
          <Image
            source={BlurPrice}
            style={[COMMON_STYLES.flex1, COMMON_STYLES.alignItemsEnd]}
          />
        ) : (
          <View style={[COMMON_STYLES.flex1, COMMON_STYLES.alignItemsEnd]}>
            <PriceInfo
              price={price}
              unitOfMeasure={unitOfMeasure!}
              discountPrice={discountedPrice!}
            />
          </View>
        )}
      </View>
      <View style={[COMMON_STYLES.row]}>
        <ExpiryClockIcon />
        <View style={[COMMON_STYLES["ml1/4"], COMMON_STYLES.alignSelfCenter]}>
          <ExpiryNote timeInSeconds={expiry} />
        </View>
      </View>
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 16,
    height: 16,
  },
});

export default ClearanceListVariantCard;
