import React from "react";
import Svg, { Path, Rect, SvgProps } from "react-native-svg";

const CashbackDisabledIcon = (props: SvgProps) => {
  return (
    <Svg  viewBox="0 0 24 24" fill="none" {...props}>
      <Rect width="18" height="14" x="1" y="1" fill="#CED8E0" rx="3" />
      <Path fill="#80868C" stroke="#80868C" d="M1.5 5.5h17v2h-17z" />
      <Path
        stroke="#80868C"
        strokeWidth="1.5"
        d="M19 9.328V4a3 3 0 0 0-3-3H4a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h9.409"
      />
      <Rect width="7" height="1.5" x="15.5" y="14.3" fill="#5A6066" rx=".1" />
      <Rect
        width="7"
        height="1.5"
        x="18.25"
        y="18.5"
        fill="#5A6066"
        rx=".1"
        transform="rotate(-90 18.25 18.5)"
      />
    </Svg>
  );
};

export default CashbackDisabledIcon;
