import React from "react";
import { useTranslation } from "react-i18next";
import { Image, StyleSheet, Text, View } from "react-native";
import {
  BUTTON_STATUS,
  BUTTON_TYPE,
  Button,
  COMMON_STYLES,
  CenterModal,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { DuplicateOrderItemPopUpIcon } from "app/partner-ordering-ui/assets/images";

interface Props {
  onCtaClick: () => void;
}

const DuplicateOrderModal = ({ onCtaClick }: Props) => {
  const { t } = useTranslation();

  return (
    <CenterModal allowClose={false} onClose={() => {}} isVisible={true}>
      <View
        style={[COMMON_STYLES.alignItemsCenter, COMMON_STYLES.justifyCenter]}
      >
        <Image
          source={DuplicateOrderItemPopUpIcon}
          style={[styles.image80x80, COMMON_STYLES["mt1/4"]]}
        />
        <View style={[COMMON_STYLES.mt1, COMMON_STYLES["pb1/2"]]}>
          <Text
            style={[
              TEXT_STYLES.textMulberry100,
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              styles.titleStyle,
            ]}
          >
            {t("duplicate_order")}
          </Text>

          <Text
            style={[
              TEXT_STYLES.textNeutral90,
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.MEDIUM,
              styles.titleStyle,
            ]}
          >
            {t("duplicate_order_msg")}
          </Text>

          <Button
            buttonStyle={[COMMON_STYLES.mr1, COMMON_STYLES.mt1]}
            onClick={onCtaClick}
            text={t("check_order_history")}
            type={BUTTON_TYPE.PRIMARY}
            size={SIZE.small}
            status={BUTTON_STATUS.ACTIVE}
          />
        </View>
      </View>
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  image80x80: {
    width: 80,
    height: 80,
  },
  margin20: {
    margin: 20,
  },
  titleStyle: {
    textAlign: "center",
    width: 275,
  },
});

export default DuplicateOrderModal;
