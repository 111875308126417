import {
  DefaultProductImage,
  FONT_FAMILY_SEMIBOLD,
  LazyImage,
  formatCurrency,
} from "@dehaat/dds";
import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  ImageSourcePropType,
} from "react-native";
import { isDesktop } from "../constants/common";

interface Props {
  imageSrc: string;
  productName: string;
  attribute: string;
  price: string;
  qty: number;
}

const ProductInfo = ({
  imageSrc,
  productName,
  attribute,
  price,
  qty,
}: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <LazyImage
          imageUrl={imageSrc || ""}
          fallBackIcon={
            <Image
              source={DefaultProductImage as ImageSourcePropType}
              style={styles.image}
            />
          }
          imgStyles={styles.image}
        />
        <View>
          <Text style={{ maxWidth: isDesktop ? "auto" : 100 }}>
            {productName}
          </Text>
          <Text style={{ marginTop: 10 }}>{attribute}</Text>
        </View>
      </View>
      <View style={styles.innerContainer}>
        <View>
          <Text>Price/unit</Text>
          <Text style={styles.boldText}>{formatCurrency(price)}</Text>
        </View>
        <View>
          <Text>Order Qty</Text>
          <Text style={styles.boldText}>{qty} unit</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  innerContainer: {
    flexDirection: "row",
    gap: isDesktop ? 31 : 15,
    alignItems: "center",
  },
  image: {
    height: 90,
    width: 54,
  },
  boldText: {
    fontFamily: FONT_FAMILY_SEMIBOLD,
    marginTop: 16,
  },
});

export default ProductInfo;
