import React from "react";
import { useTranslation } from "react-i18next";
import { View, Text, TouchableOpacity } from "react-native";
import {
  COMMON_STYLES,
  TYPOGRAPHY,
  TEXT_STYLES,
  VARIANTS_STYLES,
  Button,
  BUTTON_TYPE,
} from "@dehaat/dds";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import RecentSearchIcon from "app/partner-ordering-ui/icons/RecentSearchIcon";
import {
  selectRecentProductsSearch,
  setRecentProductSearch,
} from "app/partner-ordering-ui/store/reducer/app";
import { fireClickedClearAll } from "../utils/helpers/mixpanel";
import { selectDCData } from "app/store/reducer/native";
import { ROUTES } from "../routes/types";

interface Props {
  show: boolean;
  onItemClick: (actual: string, suggested: string, index: number) => void;
}

const RecentSearches = ({ show, onItemClick }: Props) => {
  const { t } = useTranslation();
  const history = useAppSelector(selectRecentProductsSearch);
  const dc = useAppSelector(selectDCData);
  const dispatch = useAppDispatch();
  if (!show || history.length === 0) {
    return null;
  }
  return (
    <View style={COMMON_STYLES.ph1}>
      <View
        style={[
          COMMON_STYLES.alignItemsCenter,
          COMMON_STYLES.justifyBetween,
          COMMON_STYLES.mb1,
          COMMON_STYLES.row,
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.CAPTION1,
            TEXT_STYLES.textNeutral100,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
          ]}
        >
          {t("your_previous_searches")}
        </Text>
        <Button
          onClick={() => {
            dispatch(setRecentProductSearch([]));
            fireClickedClearAll({
              screenName: ROUTES.PRODUCT_SEARCH,
              isPriorityDc: dc?.is_priority_dc,
            });
          }}
          text={t("clear_all")}
          type={BUTTON_TYPE.TERTIARY}
        />
      </View>
      {history.map((text, index) => (
        <TouchableOpacity
          key={index}
          style={[
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES.row,
            COMMON_STYLES["mb3/2"],
          ]}
          onPress={() => onItemClick(text.actual, text.suggested, index)}
        >
          <RecentSearchIcon height={18} width={18} />
          <Text
            style={[
              COMMON_STYLES["ml0.5"],
              TYPOGRAPHY.TEXT1,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {text.suggested}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default RecentSearches;
