import React from "react";
import { View, Image, Text } from "react-native";
import { Trans, useTranslation } from "react-i18next";
import {
  COMMON_STYLES,
  Label,
  SIZE,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  TEXT_STYLES,
  formatCurrency,
  BG_STYLES,
} from "@dehaat/dds";

import { Coin } from "../../assets/images";

interface Props {
  bestPrice: number;
  bestConstituentPrice: number;
  slabUom: string | null;
  uom?: string | null;
  slabText?: string;
  isPrepaid: boolean;
  discountPerUnit: number;
}

const BestDiscountHeader = ({
  bestPrice,
  bestConstituentPrice,
  slabUom,
  uom = null,
  slabText,
  isPrepaid,
  discountPerUnit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <View style={COMMON_STYLES.row}>
        <Label
          size={SIZE.large}
          leftIcon={<Image source={Coin} style={{ width: 16, height: 16 }} />}
          text={t("offers_for_you")}
          bgColor="bgPurple120"
          textColor="textWhite"
          containerStyle={[COMMON_STYLES["br1/4"]]}
        />
      </View>
      <View
        style={[
          BG_STYLES.bgPurple10,
          COMMON_STYLES["p1/2"],
          COMMON_STYLES["mt3/4"],
          COMMON_STYLES["br1/4"],
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.CAPTION2,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
            TEXT_STYLES.textPurple100,
          ]}
        >
          {t("lowest_price_offer")}
        </Text>
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.justifyBetween,
            COMMON_STYLES["mt1/2"],
            COMMON_STYLES.alignItemsCenter,
          ]}
        >
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {t("get_for")}
            <Text
              style={[
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textPurple120,
              ]}
            >
              {` ${formatCurrency(bestPrice)}`}
            </Text>
            {uom && bestConstituentPrice
              ? ` (@${formatCurrency(bestConstituentPrice)}/${uom})`
              : ""}
          </Text>
        </View>
        <View style={COMMON_STYLES.row}>
          {slabText ? (
            <Trans
              i18nKey={"best_slab_offer_prefix"}
              values={{ prefix: slabText }}
              parent={Text}
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.BASE,
                TEXT_STYLES.textNeutral80,
              ]}
              components={{
                styled: (
                  <Text
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.SEMI_EMPHASIZED,
                      TEXT_STYLES.textNeutral100,
                    ]}
                  />
                ),
              }}
            />
          ) : null}
          <Trans
            i18nKey={`best_offer_text${isPrepaid ? "_prepaid" : ""}${
              slabUom ? "_uom" : ""
            }`}
            values={{ amount: formatCurrency(discountPerUnit), uom: slabUom }}
            parent={Text}
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral80,
            ]}
            components={{
              styled: (
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.SEMI_EMPHASIZED,
                    TEXT_STYLES.textNeutral100,
                  ]}
                />
              ),
            }}
          />
        </View>
      </View>
    </>
  );
};

export default BestDiscountHeader;
