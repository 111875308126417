import { COLORS, FONT_FAMILY_SEMIBOLD } from "@dehaat/dds";
import React from "react";
import { View, Text, StyleSheet } from "react-native";
import formatDate2 from "../utils/helpers/formatDate2";

interface Props {
  createdAt: string;
  deliveredOn: string | null;
  isCancelled?: boolean;
}
const OrderTimeline = ({
  createdAt,
  deliveredOn,
  isCancelled = false,
}: Props) => {
  return (
    <View style={styles.container}>
      <View>
        <View style={{ ...styles.circle, borderColor: COLORS.primary100 }}>
          <View style={styles.innerCircle} />
        </View>
        <View style={styles.line} />
        <View
          style={{
            ...styles.circle,
            borderColor: isCancelled
              ? COLORS.error100
              : deliveredOn
              ? COLORS.primary100
              : COLORS.neutral10,
          }}
        >
          {deliveredOn || isCancelled ? (
            <View
              style={{
                ...styles.innerCircle,
                backgroundColor: isCancelled
                  ? COLORS.error100
                  : COLORS.primary100,
              }}
            />
          ) : null}
        </View>
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.text}>{`Ordered on ${formatDate2(
          createdAt
        )}`}</Text>
        <Text
          style={{ ...styles.text, marginTop: 24, color: COLORS.neutral70 }}
        >
          {isCancelled
            ? "Order Cancelled"
            : deliveredOn
            ? `Delivered on ${formatDate2(deliveredOn, 0, false)}`
            : `Expected delivery date, ${formatDate2(createdAt, 5, false)}`}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.white,
    flexDirection: "row",
    paddingTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 16,
    borderRadius: 12,
  },
  circle: {
    borderRadius: 20,
    width: 20,
    height: 20,
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  innerCircle: {
    borderRadius: 12,
    width: 12,
    height: 12,
    borderWidth: 1,
    backgroundColor: COLORS.primary100,
  },
  line: {
    backgroundColor: COLORS.neutral70,
    width: 1,
    height: 24,
    alignSelf: "center",
  },
  textContainer: {
    marginLeft: 12,
  },
  text: {
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
});

export default OrderTimeline;
