import React from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COMMON_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  TEXT_STYLES,
  Input,
  COLORS,
} from "@dehaat/dds";
import { useNavigation } from "@react-navigation/native";

import LensIcon from "../../icons/Lens";
import { LocalSearchScreenProps, ROUTES } from "../../routes/types";
import { SCREEN_WIDTH } from "../../utils/helpers/common";
import {
  CumulativeCoupon,
  GrowthPlanBasketProduct,
  Product,
  Slab,
} from "../../models/Product";
import { SLAB_DISCOUNT_TYPE } from "../../constants/Product";
import { DEFAULT_CUMULATIVE_DATA } from "app/partner-ordering-ui/constants/Common";

interface Props {
  info?: CumulativeCoupon;
  slabs?: Slab[];
  products: (Product | GrowthPlanBasketProduct)[];
}

const CumulativeOfferHeader = ({ info, slabs, products }: Props) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<LocalSearchScreenProps["navigation"]>();

  const sections = info?.sections || DEFAULT_CUMULATIVE_DATA;

  const getSlabText = (slab: Slab) => {
    let text = slab.min_qty;
    if (slab.slab_uom === SLAB_DISCOUNT_TYPE.rupee && slab.slab_uom) {
      text = `${slab.slab_uom}${text}`;
    }
    if (slab.max_qty) {
      text = `${text} to ${slab.max_qty}`;
      if (slab.slab_uom !== SLAB_DISCOUNT_TYPE.rupee && slab.slab_uom) {
        text = `${text} ${slab.slab_uom}`;
      }
    } else {
      if (slab.slab_uom !== SLAB_DISCOUNT_TYPE.rupee && slab.slab_uom) {
        text = `${text} ${slab.slab_uom}`;
      }
      text = `${text} and above`;
    }
    return text;
  };

  const handleNavigation = () =>
    navigate(ROUTES.LOCAL_SEARCH, {
      itemList: products,
      searchKey: "name",
      navigatingFrm: ROUTES.CUMULATIVE_OFFERS,
    });

  const getDiscountValue = (slab: Slab) => {
    return slab.discount_type === SLAB_DISCOUNT_TYPE.percent
      ? `${slab.discount_rate}${slab.discount_type}`
      : `${slab.discount_type}${slab.discount_rate} ${
          slab.uom ? `/${slab.uom}` : ""
        }`;
  };

  return (
    <>
      <View style={[BG_STYLES.bgPurple10, COMMON_STYLES.divider4x]}>
        <View
          style={[COMMON_STYLES.ph1, COMMON_STYLES.pv1, BG_STYLES.bgPurple20]}
        >
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {t("cumulative_offer_text")}
          </Text>
          <View
            style={[
              COMMON_STYLES.row,
              COMMON_STYLES.justifyBetween,
              COMMON_STYLES["mt1/2"],
            ]}
          >
            <View style={COMMON_STYLES.row}>
              <Text style={[TYPOGRAPHY.CAPTION1, VARIANTS_STYLES.BASE]}>
                {sections[0].name}
              </Text>
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.SEMI_EMPHASIZED,
                  COMMON_STYLES["ml0.5"],
                ]}
              >
                {sections[0].value}
              </Text>
            </View>
            <View style={COMMON_STYLES.row}>
              <Text style={[TYPOGRAPHY.CAPTION1, VARIANTS_STYLES.BASE]}>
                {sections[1].name}
              </Text>
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.SEMI_EMPHASIZED,
                  COMMON_STYLES["ml0.5"],
                ]}
              >
                {sections[1].value}
              </Text>
            </View>
          </View>
          <View style={[COMMON_STYLES.row, COMMON_STYLES["mt1/2"]]}>
            <Text style={[TYPOGRAPHY.CAPTION1, VARIANTS_STYLES.BASE]}>
              {sections[2].name}
            </Text>
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                COMMON_STYLES["ml0.5"],
              ]}
            >
              {sections[2].value}
            </Text>
          </View>
        </View>
        {slabs?.length ? (
          <View style={[COMMON_STYLES.ph1, COMMON_STYLES.pv1]}>
            <Text
              style={[
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textNeutral90,
              ]}
            >
              {t("discount_slabs")}
            </Text>
            <View
              style={[
                COMMON_STYLES.row,
                COMMON_STYLES.justifyBetween,
                COMMON_STYLES["mt1/2"],
              ]}
            >
              <Text
                style={[
                  TYPOGRAPHY.TEXT2,
                  VARIANTS_STYLES.SEMI_EMPHASIZED,
                  TEXT_STYLES.textNeutral80,
                ]}
              >
                {t("order_qty")}
              </Text>
              <Text
                style={[
                  TYPOGRAPHY.TEXT2,
                  VARIANTS_STYLES.SEMI_EMPHASIZED,
                  TEXT_STYLES.textNeutral80,
                ]}
              >
                {t("discount")}
              </Text>
            </View>
            {slabs?.map((slab, index) => (
              <View
                key={slab.slab_id || index}
                style={[
                  COMMON_STYLES.row,
                  COMMON_STYLES.justifyBetween,
                  COMMON_STYLES["mt1/2"],
                ]}
              >
                <Text
                  style={[
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.BASE,
                    TEXT_STYLES.textNeutral80,
                  ]}
                >
                  {getSlabText(slab)}
                </Text>
                <Text
                  style={[
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.BASE,
                    TEXT_STYLES.textNeutral80,
                  ]}
                >
                  {getDiscountValue(slab)}
                </Text>
              </View>
            ))}
            <Text
              style={[
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.BASE,
                TEXT_STYLES.textNeutral80,
                COMMON_STYLES["mt1/2"],
              ]}
            >
              {info?.cumulative_discount_note}
            </Text>
          </View>
        ) : undefined}
      </View>
      <Pressable
        onFocus={handleNavigation}
        style={[COMMON_STYLES.ph1, BG_STYLES.bgWhite, COMMON_STYLES.divider4x]}
      >
        <Input
          leftLabelIcon={
            <LensIcon
              svgProps={{
                height: 16,
                width: 16,
                style: COMMON_STYLES["mr1/4"],
              }}
            />
          }
          label={t("cumulative_search_placeholder")}
          containerStyle={styles.inputContainer}
          onFocus={handleNavigation}
        />
      </Pressable>
    </>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    width: SCREEN_WIDTH - 40,
    height: 42,
    marginVertical: 16,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: COLORS.neutral20,
    backgroundColor: COLORS.white,
    marginHorizontal: 4,
  },
});

export default CumulativeOfferHeader;
