import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Trans, useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import LockBannerSvg from "../../icons/LockBannerSvg";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";

interface Props {
  days: number;
  amount: number;
  onCTAClick: () => void;
  onCardClick: () => void;
}

const OutstandingCard = ({ days, amount, onCTAClick, onCardClick }: Props) => {
  const { t } = useTranslation();
  return (
    <TouchableOpacity
      style={[
        BG_STYLES.bgError100,
        COMMON_STYLES.row,
        COMMON_STYLES.justifyBetween,
        COMMON_STYLES.alignItemsCenter,
      ]}
      activeOpacity={isAIMS() ? 1 : 0}
      onPress={() => {
        if (!isAIMS()) {
          onCardClick();
        }
      }}
    >
      <View style={[COMMON_STYLES["pv3/4"], COMMON_STYLES.ph1, styles.width80]}>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
            TEXT_STYLES.textWhite,
          ]}
        >
          {t("see_prices")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.CAPTION1,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textWhite,
          ]}
        >
          <Trans
            i18nKey={"pending_outstanding"}
            values={{ amount: formatCurrency(amount), days }}
            parent={Text}
            style={[
              TYPOGRAPHY.CAPTION1,
              TEXT_STYLES.textNeutral10,
              TEXT_STYLES.textWhite,
            ]}
            components={{
              styled: <Text />,
            }}
          />
        </Text>
        {!isAIMS() ? (
          <TouchableOpacity
            style={[COMMON_STYLES["mt1/4"], styles.alignSelfStart]}
            onPress={onCTAClick}
          >
            <Text
              style={[
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textWhite,
              ]}
            >
              {t("pay", { amount: formatCurrency(amount) })}
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
      <LockBannerSvg width={"20%"} height={"100%"} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  width80: {
    width: "80%",
  },
  alignSelfStart: {
    alignSelf: "flex-start",
  },
});

export default OutstandingCard;
