import React from "react";
import {
  Image,
  ImageSourcePropType,
  Modal,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import {
  Carousel,
  COMMON_STYLES,
  CrossIcon,
  DefaultProductImage,
  LazyImage,
  RightArrowIcon,
} from "@dehaat/dds";
import { DIMENSION_HEIGHT, DIMENSION_WIDTH } from "../../utils/helpers/common";

interface Props {
  onClose: () => void;
  images: string[];
  isVisible: boolean;
}
const ModalImageCarousal = ({ onClose, images, isVisible }: Props) => {
  return (
    <Modal animationType="fade" visible={isVisible}>
      <View
        style={[
          COMMON_STYLES.relative,
          COMMON_STYLES.flex1,
          COMMON_STYLES.justifyCenter,
          COMMON_STYLES.alignItemsCenter,
        ]}
      >
        <TouchableOpacity
          style={[
            COMMON_STYLES.absolute,
            COMMON_STYLES.ph1,
            styles.arrowLeftButton,
          ]}
        >
          <RightArrowIcon
            width={32}
            height={32}
            strokeColor={"black"}
            style={styles.arrowInvertStyle}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            COMMON_STYLES.absolute,
            COMMON_STYLES.ph1,
            styles.arrowRightButton,
          ]}
        >
          <RightArrowIcon width={32} height={32} strokeColor={"black"} />
        </TouchableOpacity>
        <Carousel
          data={images}
          renderItem={(imageUrl, index) => (
            <TouchableOpacity
              key={index}
              style={[
                COMMON_STYLES.pv1,
                COMMON_STYLES.alignItemsCenter,
                COMMON_STYLES.fullWidth,
                { width: DIMENSION_WIDTH },
              ]}
            >
              <LazyImage
                imageUrl={imageUrl || ""}
                fallBackIcon={
                  <Image
                    source={DefaultProductImage as ImageSourcePropType}
                    style={[COMMON_STYLES.fullWidth, styles.modalImage]}
                  />
                }
                imgStyles={[COMMON_STYLES.fullWidth, styles.modalImage]}
              />
            </TouchableOpacity>
          )}
        />
        <TouchableOpacity
          onPress={onClose}
          style={[COMMON_STYLES.absolute, styles.crossStyle]}
        >
          <CrossIcon width={32} height={32} />
        </TouchableOpacity>
      </View>
    </Modal>
  );
};
export default ModalImageCarousal;
const styles = StyleSheet.create({
  arrowLeftButton: {
    top: "50%",
    left: 0,
    zIndex: 100,
  },
  arrowRightButton: {
    top: "49%",
    right: 0,
    zIndex: 100,
  },
  crossStyle: { top: 16, right: 16 },
  modalImage: {
    height: (DIMENSION_HEIGHT * 80) / 100,
    resizeMode: "contain",
  },
  arrowInvertStyle: { transform: [{ rotate: "180deg" }] },
});
