import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import { OrderSuccessScreenProps } from "app/partner-ordering-ui/routes/types";
import PaymentSuccessIcon from "app/partner-ordering-ui/icons/PaymentSucess";
import { ORDER_HISTORY_REDIRETION_TIME_IN_SECONDS } from "app/partner-ordering-ui/constants/Common";
import { useTranslation } from "react-i18next";
import { finishCurrentActivity, openOrderHistory } from "app/utils/appConfig";

const OrderSuccess = ({ route }: OrderSuccessScreenProps) => {
  const { id, amount } = route.params;
  const { t } = useTranslation();

  const [counter, setCounter] = useState(
    ORDER_HISTORY_REDIRETION_TIME_IN_SECONDS
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (counter > 0) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      openOrderHistory();
      finishCurrentActivity();
    }

    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  return (
    <View
      style={[
        COMMON_STYLES["ph5/4"],
        BG_STYLES.bgWhite,
        COMMON_STYLES.justifyBetween,
        COMMON_STYLES.flex1,
      ]}
    >
      <View style={COMMON_STYLES.alignItemsCenter}>
        <PaymentSuccessIcon
          width={120}
          height={124}
          style={styles.iconPosition}
        />
        <Text
          style={[
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral90,
            COMMON_STYLES["mt5/4"],
            styles.priceStyle,
          ]}
        >
          {formatCurrency(amount)}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textBlack,
            COMMON_STYLES["mt1/4"],
            COMMON_STYLES.mb1,
          ]}
        >
          {t("your_order_is_successfully_placed")}
        </Text>
        <View
          style={[
            COMMON_STYLES.fullWidth,
            BORDER_STYLES.borderNeutral10,
            styles.border,
          ]}
        >
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
              COMMON_STYLES.textCenter,
              COMMON_STYLES.pt1,
            ]}
          >
            {t("order_id")}
            {id}
          </Text>
        </View>
      </View>
      <Text
        style={[
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textBlack,
          COMMON_STYLES.textCenter,
          COMMON_STYLES.mb2,
        ]}
      >
        {t("redirecting_message", { counter })}
      </Text>
    </View>
  );
};

export default OrderSuccess;

const styles = StyleSheet.create({
  iconPosition: {
    marginTop: 160,
  },
  priceStyle: {
    fontSize: 32,
  },
  border: {
    borderTopWidth: 1,
  },
});
