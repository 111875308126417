import { AxiosRequestHeaders } from "axios";

import axios from "./config";
import i18next from "i18next";

export async function get<T>(
  url: string,
  params: Record<string, unknown> = {},
  headers?: AxiosRequestHeaders,
  signal?: AbortSignal
) {
  return (
    await axios.get<T>(url, {
      params: {
        ...params,
        lang: i18next.language || "en",
      },
      headers,
      signal,
    })
  ).data;
}

export async function post<T>(
  url: string,
  body: Record<string, unknown> | FormData,
  headers?: AxiosRequestHeaders,
  params: Record<string, unknown> = {}
) {
  return (
    await axios.post<T>(url, body, {
      headers,
      params: { lang: i18next.language, ...params },
    })
  ).data;
}

export async function put<T>(
  url: string,
  body: Record<string, unknown>,
  headers?: AxiosRequestHeaders
) {
  return (await axios.put<T>(url, body, { headers })).data;
}

export async function patch<T>(
  url: string,
  body: Record<string, unknown>,
  headers?: AxiosRequestHeaders
) {
  return (await axios.patch<T>(url, body, { headers })).data;
}

export async function remove<T>(
  url: string,
  params: Record<string, unknown> = {},
  headers?: AxiosRequestHeaders
) {
  return (await axios.delete<T>(url, { params, headers })).data;
}
