import React from "react";
import { Ellipse, Path, Rect, Svg, SvgProps } from "react-native-svg";

const PaymentSuccessIcon = (props: SvgProps) => {
  return (
    <Svg viewBox={"0 0 120 124"} fill="none" {...props}>
      <Ellipse cx="85.578" cy="18.646" rx="5.49" ry="6.216" fill="#E0FEEE" />
      <Ellipse cx="35.386" cy="112.664" rx="3.921" ry="3.885" fill="#E0FEEE" />
      <Ellipse cx="33.818" cy="21.751" rx="2.353" ry="3.108" fill="#5A9AE4" />
      <Ellipse cx="116.164" cy="74.592" rx="3.137" ry="3.108" fill="#07BD5D" />
      <Path
        d="m97.188 33.168 2.67-1.215-.055-2.845 3.102-1.149-1.063-3.02 3.445-1.457-.607-2.89 3.067-1.905.772 1.094-2.283 1.416.64 3.053-3.311 1.4 1.075 3.07-3.424 1.273.045 2.782-2.47 1.125.44 1.821-1.368.28-.675-2.833Z"
        fill="#F7CC7F"
      />
      <Path
        d="m62.41 18.288 1.514-2.496-1.664-2.318 1.908-2.682-2.597-1.9 2.016-3.128-2.146-2.048L62.89.429l1.26.472-1.08 2.446 2.265 2.163L63.4 8.516l2.635 1.936-2.104 2.963 1.62 2.272-1.399 2.309 1.4 1.259-.971.994-2.17-1.961Z"
        fill="#186A81"
      />
      <Rect
        width="3.369"
        height="23.14"
        rx="1.685"
        transform="matrix(-.47869 -.87799 .88167 -.47186 2.84 101.088)"
        fill="#E95122"
      />
      <Rect
        width="1.774"
        height="12.005"
        rx=".887"
        transform="rotate(-98.111 74.095 -23.8) skewX(-.147)"
        fill="#E95122"
      />
      <Rect
        width="1.783"
        height="11.945"
        rx=".892"
        transform="matrix(.74813 -.66355 .67042 .74198 106.477 99.266)"
        fill="#07BD5D"
      />
      <Path
        d="m18.301 48.143-3.277 2.209-4.112-4.654-5.216 2.7-3.668-2.983-1.606 1.864 4.905 3.969 4.96-2.535 4.273 4.847 4.941-3.34-1.2-2.077Z"
        fill="#50BFA5"
      />
      <Path
        d="m86.955 122.837.902-3.736-3.399-.842.737-3.703-3.132-1.211.1-2.83-1.458.042-.122 3.785 2.97 1.148-.762 3.856 3.342.825-.82 3.38 1.642-.714Z"
        fill="#F48C7F"
      />
      <Ellipse cx="54.992" cy="117.325" rx="3.137" ry="2.331" fill="#07BD5D" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.121 95.171c18.734 0 33.92-15.046 33.92-33.607 0-18.56-15.186-33.607-33.92-33.607-18.733 0-33.92 15.046-33.92 33.607 0 18.56 15.187 33.607 33.92 33.607Zm16.545-40.733a4 4 0 1 0-5.585-5.727L53.406 65.948l-5.335-5.285a4 4 0 1 0-5.63 5.683l8.127 8.052 2.793 2.768 2.815-2.746 20.49-19.982Z"
        fill="#219653"
      />
    </Svg>
  );
};

export default PaymentSuccessIcon;
