import React, { useEffect, useState } from "react";
import { Image, LayoutRectangle, ScrollView, Text, View } from "react-native";
import {
  BORDER_STYLES,
  BUTTON_TYPE,
  COMMON_STYLES,
  Label,
  ProductCardVertical,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import fetchSimiliarProducts from "../../apis/fetchSimiliarProducts";
import { Product, ProductVariant } from "../../models/Product";
import useCart from "../../hooks/useCart";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import { deleteCartItem, updateCartItem } from "../../store/reducer/cart";
import { ROUTES } from "../../routes/types";
import { getProductDetails } from "../../utils/helpers/product";
import RupeeSymbolIcon from "../../icons/RupeeSymbol";
import {
  rnNavigation,
  startOutstandingAmountPaymentFlow,
} from "../../../utils/appConfig";

import ProductCardVerticalShimmer from "../cards/ProductCardVerticalShimmer";
import { useTranslation } from "react-i18next";
import { selectDCData } from "app/store/reducer/native";
import LockIcon from "app/partner-ordering-ui/icons/LockIcon";
import { BlurPrice } from "app/partner-ordering-ui/assets/images";
import OutstandingAmountDrawer from "../drawers/OutstandingAmountDrawer";
import { MIXPANEL_PROPERTIES } from "app/partner-ordering-ui/constants/eventNames";
import { fireClickViewAlternateProduct } from "app/partner-ordering-ui/utils/helpers/mixpanel";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";

interface Props {
  id: number | string;
  noOfAlternateProducts: number;
  technicalContents: string[];
  captureYOffset: (layout: LayoutRectangle) => void;
  parentProductVariant?: ProductVariant;
  navigationFrom: string;
}

const SimiliarProductsList = ({
  id,
  noOfAlternateProducts,
  technicalContents,
  captureYOffset,
  parentProductVariant,
  navigationFrom,
}: Props) => {
  const { cartItems } = useCart();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dc = useAppSelector(selectDCData);

  useEffect(() => {
    const getSimiliarItems = async () => {
      const response = await fetchSimiliarProducts(
        id,
        dc.sales_office_id,
        dc.partner_id
      );
      if (response) {
        setProducts(response.results);
      }
      setIsLoading(false);
    };
    getSimiliarItems();
  }, []);

  const isProductInStock = () =>
    (parentProductVariant?.orderable_quantity || 0) >
      Number(parentProductVariant?.min_moq_value || 1) && !dc.app_blocked;

  const onCardClick = (
    product: Product,
    displayVariant: ProductVariant,
    index: number
  ) => {
    rnNavigation({
      name: ROUTES.PRODUCT_DETAIL,
      params: {
        id: product.id,
        navigationFrom: MIXPANEL_PROPERTIES.ALTERNATE_PRODUCTS,
      },
      replace: true,
    });
    fireClickViewAlternateProduct({
      parantVariant: parentProductVariant,
      alternateProductVariant: displayVariant,
      isPriorityDc: dc?.is_priority_dc,
      productPosition: index + 1,
      parantVariantStatus: isProductInStock()
        ? MIXPANEL_PROPERTIES.INSTOCK
        : MIXPANEL_PROPERTIES.OOS,
      previousStep: navigationFrom,
      screenName: ROUTES.PRODUCT_DETAIL,
    });
  };

  return (
    <View
      style={COMMON_STYLES.p1}
      onLayout={(e) => captureYOffset(e.nativeEvent.layout)}
    >
      <Text
        style={[
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.SEMI_EMPHASIZED,
          TEXT_STYLES.textNeutral90,
        ]}
      >
        {t("alternate_product_available", { noOfAlternateProducts })}
      </Text>
      {technicalContents.length ? (
        <ScrollView>
          <View
            style={[
              COMMON_STYLES.row,
              COMMON_STYLES["mt1/2"],
              COMMON_STYLES["mr0.5"],
            ]}
          >
            {technicalContents.map((content, index) => (
              <Label
                key={index}
                text={content}
                size={SIZE.large}
                bgColor="bgNeutral00"
              />
            ))}
          </View>
        </ScrollView>
      ) : null}
      <ScrollView
        horizontal
        style={COMMON_STYLES["mt3/4"]}
        showsHorizontalScrollIndicator={false}
      >
        {isLoading ? (
          <>
            {[1, 2, 3].map((key) => (
              <ProductCardVerticalShimmer
                key={key}
                containerStyle={COMMON_STYLES.mr1}
              /> //TODO: ADD SHIMMER From DDS
            ))}
          </>
        ) : (
          <View style={COMMON_STYLES.row}>
            {products.map((product, index) => {
              const { displayVariant } = getProductDetails(product);
              const { id } = displayVariant;
              const quantity = cartItems[id]?.qty;

              const handleQuantityUpdate = (qty: number) =>
                dispatch(updateCartItem({ id, qty }));

              function handleRevealPriceClick() {
                if (!isAIMS()) setOpen(true);
              }

              return (
                <ProductCardVertical
                  containerStyle={[
                    index !== products.length - 1 && COMMON_STYLES.mr1,
                    COMMON_STYLES.b1,
                    BORDER_STYLES.borderNeutral20,
                  ]}
                  key={id}
                  imageUrl={product.image}
                  productName={product.name}
                  onDeleteClick={() => dispatch(deleteCartItem({ id }))}
                  onPlusClick={() => handleQuantityUpdate(quantity + 1)}
                  onMinusClick={() => handleQuantityUpdate(quantity - 1)}
                  onQunatityUpdate={(qty) => handleQuantityUpdate(qty)}
                  discountInfo={
                    dc.app_blocked ? (
                      <Image source={BlurPrice} />
                    ) : (
                      <Text
                        style={[
                          TYPOGRAPHY.TEXT1,
                          VARIANTS_STYLES.EMPHASIZED,
                          TEXT_STYLES.textNeutral100,
                          COMMON_STYLES["mt1/2"],
                        ]}
                      >
                        {formatCurrency(displayVariant.dehaat_price)}
                      </Text>
                    )
                  }
                  quantity={quantity}
                  onCardClick={() =>
                    onCardClick(product, displayVariant, index)
                  }
                  brandName={product.brand?.name}
                  packAttribute={displayVariant.pack_attribute}
                  labelInfo={
                    !dc.app_blocked &&
                    displayVariant?.discounts_info?.best_price ? (
                      <Label
                        textColor="textWhite"
                        bgColor="bgPurple120"
                        text={`Get for ${formatCurrency(
                          displayVariant.discounts_info.best_price
                        )}`}
                        leftIcon={<RupeeSymbolIcon height={16} width={16} />}
                      />
                    ) : null
                  }
                  cta={{
                    type: BUTTON_TYPE.SECONDARY,
                    text: dc.app_blocked ? t("price") : t("view_product"),
                    onClick: () => {
                      if (dc.app_blocked) handleRevealPriceClick?.();
                      else onCardClick(product, displayVariant, index);
                    },
                    iconRight: dc.app_blocked ? (
                      <LockIcon height={16} width={16} />
                    ) : undefined,
                    buttonStyle: dc.app_blocked
                      ? BORDER_STYLES.borderBlue10
                      : undefined,
                  }}
                />
              );
            })}
          </View>
        )}
      </ScrollView>
      <OutstandingAmountDrawer
        days={dc.app_block_days}
        amount={dc.app_block_amount}
        isOpen={open}
        onClose={() => setOpen(false)}
        onCtaClick={() => {
          startOutstandingAmountPaymentFlow(dc.app_block_amount);
          setOpen(false);
        }}
      />
    </View>
  );
};

export default SimiliarProductsList;
