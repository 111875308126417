import React, { useEffect } from "react";
import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  Button,
  ButtonProps,
  COMMON_STYLES,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { ErrorCow } from "app/partner-ordering-ui/assets/images";
import { redirectToHome } from "app/utils/appConfig";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  selectIsSessionExpired,
  setSessionExpired,
} from "app/partner-ordering-ui/store/reducer/app";

interface Props {
  message?: string;
  cta?: ButtonProps;
  containerStyle?: StyleProp<ViewStyle>;
}

const ErrorScreen = ({ cta, message, containerStyle }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isSessionExpired = useAppSelector(selectIsSessionExpired);

  useEffect(() => {
    if (isSessionExpired) {
      cta?.onClick();
    }
    return () => {
      if (isSessionExpired) dispatch(setSessionExpired(false));
    };
  }, [isSessionExpired]);

  return (
    <View
      style={[
        COMMON_STYLES.flex1,
        COMMON_STYLES.justifyCenter,
        BG_STYLES.bgNeutral10,
        COMMON_STYLES.relative,
        containerStyle,
      ]}
    >
      <View style={COMMON_STYLES.alignItemsCenter}>
        <Image source={ErrorCow} style={styles.img160X280} />
        <Text
          style={[
            TYPOGRAPHY.HEADING2,
            VARIANTS_STYLES.EMPHASIZED,
            TEXT_STYLES.textNeutral90,
            COMMON_STYLES.mt1,
          ]}
        >
          {t("unknown_error")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral70,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {t("retry_message")}
        </Text>
      </View>
      {message ? (
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral70,
            COMMON_STYLES.m1,
            COMMON_STYLES.textCenter,
          ]}
        >
          {message}
        </Text>
      ) : null}
      <View
        style={[
          COMMON_STYLES.fullWidth,
          COMMON_STYLES.justifyCenter,
          COMMON_STYLES.absolute,
          COMMON_STYLES.bottom,
          BG_STYLES.bgWhite,
          styles.h88,
          COMMON_STYLES["ph5/4"],
        ]}
      >
        <Button
          size={SIZE.large}
          text={t("go_to_home")}
          onClick={redirectToHome}
          {...cta}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  h88: {
    height: 88,
  },
  img160X280: {
    height: 160,
    width: 280,
  },
});

export default ErrorScreen;
