import { STORAGE_KEYS } from "../../constants/storage";
import {
  clearStorage,
  getData,
  multiGet,
  removeData,
  storeData,
} from "../../../utils/storage";
import { CartState, updateCart } from "../../store/reducer/cart";
import store from "../../../store";

/**
 *
 * @param key key is the dc id to maintain uniqueness in cart in respect of dc
 * @returns storage data
 */
export const loadStorageAndUpdateStore = async (key: string, user?: string) => {
  const dataFromStorage = await multiGet([
    `${STORAGE_KEYS.MY_CART}${key}`,
    STORAGE_KEYS.USER_ID,
  ]);

  // storage data
  const sd: any = {
    myCartData:
      (dataFromStorage[`${STORAGE_KEYS.MY_CART}${key}`] as CartState) || null,
    userId: (dataFromStorage[STORAGE_KEYS.USER_ID] as string) || "",
  };

  if (sd.userId) {
    // check is the stored user is same as logged in user
    // clear store in case of different user
    if (sd.userId !== user) {
      sd.userId = user || "";
      sd.myCartData = null;

      store.dispatch(updateCart(null));
      clearStorage().then(() => storeData(STORAGE_KEYS.USER_ID, user));
      return sd; //return back to init
    }
  } else {
    if (user) {
      // first time log in, store the user
      sd.userId = user;
      storeData(STORAGE_KEYS.USER_ID, user);
    } else {
      // No user, throw error
      throw "User details are empty!";
    }
  }

  if (!sd.myCartData) {
    //check if no cart data exists, try to find the data from old format cart
    const legecyCartData = await getData<CartState, null>(
      STORAGE_KEYS.MY_CART,
      null
    );
    if (legecyCartData) {
      // if data exists, replace the cart data with new keys
      sd.myCartData = legecyCartData;
      // move the old cart to new mapped key / remove old key
      await Promise.all([
        storeData(`${STORAGE_KEYS.MY_CART}${key}`, legecyCartData),
        removeData(STORAGE_KEYS.MY_CART),
      ]);
    }
  }

  // Store the data fetched async storage in redux store
  store.dispatch(updateCart(sd.myCartData));

  return sd;
};
