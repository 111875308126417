import { Dimensions } from "react-native";

export const windowWidth = Dimensions.get("window").width;

export const isDesktop = windowWidth > 1024;

export enum ORDER_TYPE {
  DOORSTEP = "DOORSTEP",
  SELF_PICKUP = "SELF_PICKUP",
}

export const enum ORDER_STATUS {
  DeHaatCancelled = "DEHAAT_CANCELLED",
  Delivered = "DELIVERED",
  DeliveryFailed = "FAILED_DELIVERY",
  OutForDelivery = "OUT_FOR_DELIVERY",
  Pending = "PENDING",
  ReadyForDelivery = "READY_FOR_DELIVERY",
  UserCancelled = "USER_CANCELLED",
  VendorCancelled = "VENDOR_CANCELLED",
  VendorConfirmed = "VENDOR_CONFIRMED",
  VendorRejected = "VENDOR_REJECTED",
  PreOrder = "PRE_ORDER",
  Success = "SUCCESS",
  PaymentFailed = "PAYMENT_FAILED",
  Failed = "FAILED",
  OrderFailed = "ORDER_FAILED",
}

export enum ORDER_REQ_STATUS {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export enum ORDER_ACTIONS {
  ACCEPT = 1,
  REJECT = 2,
  CONFIRM_PICKUP = 3,
}

export enum RENDER_ACTION {
  ACEEPT_REJECT = 1,
  CONFIRM_PICKUP = 2,
  DONWLOAD_OTPIONS = 3,
}
