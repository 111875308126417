import React, { PropsWithChildren } from "react";
import { useNavigation } from "@react-navigation/native";

import useAuth from "app/hooks/useAuth";
import { LoginScreenProps, ROUTES } from "app/routes/types";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import { ScreenStackProps } from "react-native-screens";

const ProtectedRoute = ({ children }: PropsWithChildren<ScreenStackProps>) => {
  const { isAuthenticated } = useAuth();
  const navigation = useNavigation<LoginScreenProps["navigation"]>();

  if (!isAuthenticated) {
    navigation.navigate(ROUTES.LOGIN);
    return <Spinner />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
