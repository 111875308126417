import React from "react";
import { BottomDrawer } from "@dehaat/dds";
import { Batch, ClearanceProduct } from "../../../models/Product";
import ClearanceVariantSelect from "./ClearanceVariantSelect";

interface Props {
  onClose: () => void;
  batches: Batch[];
  product: ClearanceProduct;
  displayBatch: Batch;
  onVariantSelect: (batch: Batch) => void;
}

const ClearanceVariantSelectDropDown = ({
  onClose,
  batches,
  product,
  displayBatch,
  onVariantSelect,
}: Props) => {
  const onCardClick = (batch: Batch) => onVariantSelect(batch);
  const selectedIndex = batches.findIndex(
    (b) => b.batchId === displayBatch.batchId
  );
  return (
    <BottomDrawer heading={product.name} onClose={onClose} visible={true}>
      <ClearanceVariantSelect
        onCardClick={onCardClick}
        selectedIndex={selectedIndex}
        batches={batches}
      />
    </BottomDrawer>
  );
};

export default ClearanceVariantSelectDropDown;
