import React, { PropsWithChildren, useState } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  BUTTON_TYPE,
  Button,
  COLORS,
  COMMON_STYLES,
  DefaultProductImage,
  DeleteIcon,
  Label,
  LazyImage,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import ZoomLensIcon from "app/partner-ordering-ui/icons/ZoomLens";
import ModalImageCarousal from "../resuable/ModalImageCarousal";

interface Props {
  name: string;
  packAttribute?: string;
  imageUrl: string;
  handleRemoveCartItem: () => void;
  label: string;
}

const InactiveCartItem = ({
  name,
  packAttribute,
  imageUrl,
  handleRemoveCartItem,
  label,
  children,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleImageClick = () => {
    setIsVisible(true);
  };

  return (
    <View
      style={[
        BG_STYLES.bgWhite,
        COMMON_STYLES.br1,
        COMMON_STYLES["mb1/2"],
        COMMON_STYLES.pb1,
      ]}
    >
      <View style={[COMMON_STYLES.ph1, COMMON_STYLES["pt3/4"]]}>
        {label ? (
          <View style={COMMON_STYLES.row}>
            {
              <Label
                text={label}
                textColor="textError100"
                bgColor="bgNeutral10"
              />
            }
          </View>
        ) : null}

        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.justifyBetween,
            COMMON_STYLES["mt3/4"],
          ]}
        >
          <View style={[COMMON_STYLES.row, COMMON_STYLES.flex1]}>
            <TouchableOpacity onPress={handleImageClick}>
              <LazyImage
                imageUrl={imageUrl}
                fallBackIcon={
                  <Image
                    source={DefaultProductImage}
                    style={styles.productImage}
                  />
                }
                imgStyles={styles.productImage}
              />
              <ZoomLensIcon
                width={14}
                height={14}
                style={[COMMON_STYLES.absolute, styles.zoomStyle]}
              />
            </TouchableOpacity>
            <ModalImageCarousal
              isVisible={isVisible}
              onClose={() => setIsVisible(false)}
              images={[imageUrl]}
            />
            <View style={COMMON_STYLES.flex1}>
              <View style={COMMON_STYLES.row}>
                <View style={[COMMON_STYLES["ml1/4"], COMMON_STYLES.flex1]}>
                  <Text
                    numberOfLines={2}
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.SEMI_EMPHASIZED,
                      TEXT_STYLES.textNeutral100,
                    ]}
                  >
                    {name}
                  </Text>
                  {packAttribute ? (
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral80,
                        COMMON_STYLES["mt1/8"],
                      ]}
                    >
                      {packAttribute}
                    </Text>
                  ) : null}
                </View>
                <Button
                  type={BUTTON_TYPE.SECONDARY}
                  text={t("remove")}
                  onClick={handleRemoveCartItem}
                  iconLeft={
                    <DeleteIcon
                      svgProps={styles.image16x16}
                      pathProps={{ stroke: COLORS.primary100 }}
                    />
                  }
                 size={SIZE.small}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      {children}
    </View>
  );
};

export default InactiveCartItem;

const styles = StyleSheet.create({
  productImage: {
    width: 48,
    height: 48,
    resizeMode: "contain",
  },
  image16x16: { height: 16, width: 16 },
  zoomStyle: { top: 34, left: 29 },
});
