import React from "react";
import { Path, Svg, SvgProps } from "react-native-svg";

const OfferAppliedIcon = (props: SvgProps) => (
  <Svg width="14" height="14" fill="none" {...props}>
    <Path
      d="M13.116 5.426c-.235-.246-.48-.5-.571-.723-.085-.204-.09-.543-.095-.871-.01-.61-.02-1.301-.5-1.782-.48-.48-1.172-.49-1.782-.5-.328-.005-.667-.01-.871-.095-.223-.092-.477-.336-.723-.571C8.143.469 7.653 0 7 0c-.652 0-1.142.47-1.574.884-.246.235-.5.48-.723.571-.203.085-.543.09-.871.095-.61.01-1.301.02-1.782.5-.48.48-.487 1.172-.5 1.782-.005.328-.01.667-.095.871-.092.223-.336.477-.571.723C.469 5.857 0 6.347 0 7c0 .652.47 1.142.884 1.574.235.246.48.5.571.723.085.204.09.543.095.871.01.61.02 1.301.5 1.782.48.48 1.172.49 1.782.5.328.005.667.01.871.095.223.092.477.336.723.571.431.415.921.884 1.574.884.652 0 1.142-.47 1.574-.884.246-.235.5-.48.723-.571.204-.085.543-.09.871-.095.61-.01 1.301-.02 1.782-.5.48-.48.49-1.172.5-1.782.005-.328.01-.667.095-.871.092-.223.336-.477.571-.723C13.531 8.143 14 7.653 14 7c0-.652-.47-1.142-.884-1.574Zm-3.262.428-3.5 3.5a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L6 8.293l3.146-3.147a.5.5 0 1 1 .708.708Z"
      fill="#43A314"
    />
  </Svg>
);

export default OfferAppliedIcon;
