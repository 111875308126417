import { Product } from "../models/Product";
import { get } from "app/partner-ordering-ui/utils/axios/index";
import { isAIMS } from "../utils/helpers/isAIMSApp";

interface Response {
  count: number;
  previous: number | null;
  next: number | null;
  results: Product[];
}

const fetchProductsList = async (
  productId: string | number,
  salesOfficeId: number,
  partnerId?: number
) => {
  const url = `/products/${productId}/bundles`;
  try {
    const response = await get<{ data: Response }>(url, {
      sales_office_id: salesOfficeId,
      partner_id: isAIMS() ? partnerId : undefined,
      page_size: 20,
      page_number: 1,
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default fetchProductsList;
