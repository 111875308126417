import React from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, Text, View, ViewStyle } from "react-native";

import {
  COMMON_STYLES,
  RupeeCircleIcon,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

interface Props {
  unit?: "combo" | "unit";
  amount?: number | null;
  style?: StyleProp<ViewStyle>
}

const Cashback = ({ amount, unit = "unit", style }: Props) => {
  const { t } = useTranslation();
  if (amount) {
    return (
      <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter, COMMON_STYLES["mt1/4"], style]}>
        <RupeeCircleIcon
          width={14}
          height={14}
          style={COMMON_STYLES["mr1/4"]}
        />
        <Text
          style={[
            TYPOGRAPHY.CAPTION1,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
            TEXT_STYLES.textPurple100,
          ]}
        >
          {t("cashback_per_combo", {
            value: formatCurrency(amount),
            uom: unit,
          })}
        </Text>
      </View>
    );
  }
};

export default Cashback;
