import { AppRegistry } from "react-native";
import App from "app/routes/AppRoutes";

import appJson from "./app.json";
import "./index.css";
import "./i18n.config";

AppRegistry.registerComponent(appJson.name, () => App);
AppRegistry.runApplication(appJson.name, {
  initialProps: {},
  rootTag: document.getElementById("root"),
});
