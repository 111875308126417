import React from "react";
import { ColorValue } from "react-native";
import Svg, { Defs, LinearGradient, Path, Stop } from "react-native-svg";

interface Props {
  stopColor1: ColorValue;
  stopColor2: ColorValue;
}

const SchemeRupeeIcon = ({ stopColor1, stopColor2 }: Props) => {
  return (
    <Svg width="16" height="16" fill="none">
      <Path
        d="M7.994 0a8 8 0 1 0 .012 16 8 8 0 0 0-.012-16Zm1.398 6.001h1.026a.418.418 0 0 1 0 .837H9.392a2.534 2.534 0 0 1-2.506 2.091h-.363l2.774 2.624a.419.419 0 1 1-.575.61L5.183 8.817a.42.42 0 0 1 .288-.725h1.415a1.727 1.727 0 0 0 1.652-1.255H5.471a.418.418 0 1 1 0-.837h3.068a1.71 1.71 0 0 0-1.653-1.324H5.471a.418.418 0 1 1 0-.837h4.947a.418.418 0 0 1 0 .837H8.769c.328.374.544.833.623 1.324Z"
        fill="url(#a)"
      />
      <Defs>
        <LinearGradient
          id="a"
          x1="-.859"
          y1="0"
          x2="27.925"
          y2="7.583"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.284} stopColor={stopColor1} />
          <Stop offset={1} stopColor={stopColor2} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default SchemeRupeeIcon;
