import React, { useEffect, useState } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  BUTTON_TYPE,
  COLORS,
  COMMON_STYLES,
  CaretDownIcon,
  CarousalIndicator,
  Label,
  ProductCardVertical,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import { useTranslation } from "react-i18next";
import fetchSimiliarProducts from "app/partner-ordering-ui/apis/fetchSimiliarProducts";
import { Product } from "app/partner-ordering-ui/models/Product";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import { getProductDetails } from "app/partner-ordering-ui/utils/helpers/product";
import ProductCardVerticalShimmer from "../../cards/ProductCardVerticalShimmer";
import RupeeSymbolIcon from "app/partner-ordering-ui/icons/RupeeSymbol";
import { rnNavigation } from "app/utils/appConfig";
import { selectDCData } from "app/store/reducer/native";

interface Props {
  id: number | string;
  noOfAlternateProducts: number;
  technicalContents: string[];
  containerStyle?: StyleProp<ViewStyle>;
  allowCollaplse?: boolean;
}

const AlternateItemsList = ({
  id,
  noOfAlternateProducts,
  technicalContents,
  containerStyle,
  allowCollaplse = true,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const { cartItems } = useCart();
  const dispatch = useAppDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);
  const dc = useAppSelector(selectDCData);

  const handleScrollEvent = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const visibleWidth = e.nativeEvent.layoutMeasurement.width;
    const contentWidth = e.nativeEvent.contentSize.width;
    setScrollPosition(
      e.nativeEvent.contentOffset.x / (contentWidth - visibleWidth)
    );
  };

  useEffect(() => {
    const getSimiliarItems = async () => {
      const response = await fetchSimiliarProducts(
        id,
        dc.sales_office_id,
        dc.partner_id
      );
      if (response) {
        setProducts(response.results);
      }
      setIsLoading(false);
    };
    if (isOpen && !products.length) {
      getSimiliarItems();
    }
  }, [isOpen]);

  return (
    <View
      style={[
        BG_STYLES.bgNeutral00,
        COMMON_STYLES.b1,
        BORDER_STYLES.borderNeutral10,
        COMMON_STYLES["br1/2"],
        containerStyle,
      ]}
    >
      <TouchableOpacity
        style={[COMMON_STYLES.ph1, COMMON_STYLES["pv3/4"]]}
        onPress={() => allowCollaplse && setIsOpen((prev) => !prev)}
      >
        <View style={[COMMON_STYLES.row, COMMON_STYLES.justifyBetween]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {t('alternate_product_available',{noOfAlternateProducts})}
          </Text>
          {allowCollaplse && (
            <CaretDownIcon
              svgProps={{
                style: {
                  height: 24,
                  width: 24,
                  transform: [{ rotate: isOpen ? "180 deg" : "0 deg" }],
                },
              }}
              pathProps={{ stroke: COLORS.black }}
            />
          )}
        </View>

        {isOpen && technicalContents.length ? (
          <ScrollView horizontal>
            <View
              style={[
                COMMON_STYLES.row,
                COMMON_STYLES["mt1/2"],
                COMMON_STYLES["mr0.5"],
              ]}
            >
              {technicalContents.map((content, index) => (
                <Label
                  key={index}
                  text={content}
                  size={SIZE.large}
                  bgColor="bgNeutral10"
                />
              ))}
            </View>
          </ScrollView>
        ) : null}
      </TouchableOpacity>
      {isOpen && (
        <View style={[BG_STYLES.bgBlue20, COMMON_STYLES.p1]}>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            onScroll={handleScrollEvent}
          >
            {isLoading ? (
              <>
                {[1, 2, 3].map((key) => (
                  <ProductCardVerticalShimmer
                    key={key}
                    containerStyle={COMMON_STYLES.mr1}
                  /> //TODO: ADD SHIMMER From DDS
                ))}
              </>
            ) : (
              <>
                {products.map((product, index) => {
                  const { displayVariant } = getProductDetails(product);
                  const { id } = displayVariant;
                  const quantity = cartItems[id]?.qty;

                  const handleQuantityUpdate = (qty: number) =>
                    dispatch(updateCartItem({ id, qty }));

                  return (
                    <ProductCardVertical
                      containerStyle={[
                        index !== products.length - 1 && COMMON_STYLES.mr1,
                        COMMON_STYLES.b1,
                        BORDER_STYLES.borderNeutral20,
                      ]}
                      key={id}
                      imageUrl={product.image}
                      productName={product.name}
                      onDeleteClick={() => dispatch(deleteCartItem({ id }))}
                      onPlusClick={() => handleQuantityUpdate(quantity + 1)}
                      onMinusClick={() => handleQuantityUpdate(quantity - 1)}
                      onQunatityUpdate={(qty) => handleQuantityUpdate(qty)}
                      discountInfo={
                        <Text
                          style={[
                            TYPOGRAPHY.TEXT1,
                            VARIANTS_STYLES.EMPHASIZED,
                            TEXT_STYLES.textNeutral100,
                            COMMON_STYLES["mt1/2"],
                          ]}
                        >
                          {formatCurrency(displayVariant.dehaat_price)}
                        </Text>
                      }
                      quantity={quantity}
                      brandName={product.brand?.name}
                      packAttribute={displayVariant.pack_attribute}
                      labelInfo={
                        displayVariant?.discounts_info?.best_price ? (
                          <Label
                            textColor="textWhite"
                            bgColor="bgPurple120"
                            text={`Get for ${formatCurrency(
                              displayVariant.discounts_info.best_price
                            )}`}
                            leftIcon={
                              <RupeeSymbolIcon height={16} width={16} />
                            }
                          />
                        ) : null
                      }
                      minimumOrderQuanity={
                        displayVariant.min_moq_value
                          ? Number(displayVariant.min_moq_value)
                          : undefined
                      }
                      cta={{
                        text: t('view_product'),
                        onClick: () =>
                          rnNavigation({
                            name: ROUTES.PRODUCT_DETAIL,
                            params: { id: product.id },
                          }),
                        type: BUTTON_TYPE.SECONDARY,
                      }}
                      onCardClick={() =>
                        rnNavigation({
                          name: ROUTES.PRODUCT_DETAIL,
                          params: { id: product.id },
                        })
                      }
                    />
                  );
                })}
              </>
            )}
          </ScrollView>
          <View
            style={[
              COMMON_STYLES.fullWidth,
              COMMON_STYLES.alignItemsCenter,
              COMMON_STYLES["pt3/2"],
            ]}
          >
            <CarousalIndicator
              scrollPosition={scrollPosition}
              containerWidth={35}
              indicatorStyle={BG_STYLES.bgPrimary100}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default AlternateItemsList;
