import React from "react";
import Svg, { Path, Defs, SvgProps, G, ClipPath } from "react-native-svg";

export default function ErrorIcon(props: SvgProps) {
  return (
    <Svg viewBox="0 0 24 24" fill="none" {...props}>
      <G clipPath="url(#a)">
        <Path
          d="M12.002 21.003a9.002 9.002 0 1 0 0-18.003 9.002 9.002 0 0 0 0 18.003Z"
          stroke="#7A0515"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <Path
          d="M12.002 7.501v5.251"
          stroke="#7A0515"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12.002 17.253a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
          fill="#7A0515"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24.004v24.004H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
