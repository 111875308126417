import { COLORS } from "@dehaat/dds";
import React from "react";
import Svg, { Circle, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  color?: keyof typeof COLORS;
}

const DotSeperatorIcon = ({ color = "purple120", ...props }: Props) => {
  return (
    <Svg width="4" height="4" viewBox="0 0 4 4" fill="none" {...props}>
      <Circle cx="2" cy="2" r="2" fill={COLORS[color]} />
    </Svg>
  );
};

export default DotSeperatorIcon;
