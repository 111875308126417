import React from "react";
import { View } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { BG_STYLES, COLORS, COMMON_STYLES } from "@dehaat/dds";
import { DIMENSION_WIDTH } from "app/partner-ordering-ui/utils/helpers/common";

interface Props {
  noOfShimmers?: number;
}

const ShimmerProductCardHz = ({ noOfShimmers = 3 }: Props) => (
  <View style={[BG_STYLES.bgWhite, COMMON_STYLES.p1, COMMON_STYLES.flex1]}>
    {Array.from(Array(noOfShimmers).keys()).map((item) => (
      <SkeletonPlaceholder key={item} backgroundColor={COLORS.neutral20}>
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.mt1,
            COMMON_STYLES.divider,
            COMMON_STYLES.pb1,
          ]}
        >
          <SkeletonPlaceholder.Item height={100} width={100} borderRadius={8} />
          <View style={COMMON_STYLES.ml1}>
            <SkeletonPlaceholder.Item height={16} width={60} borderRadius={4} />
            <SkeletonPlaceholder.Item
              height={20}
              width={DIMENSION_WIDTH * 60 / 100}
              borderRadius={4}
              marginTop={8}
            />
            <SkeletonPlaceholder.Item
              height={20}
              width={100}
              borderRadius={4}
              marginTop={8}
            />
          </View>
        </View>
      </SkeletonPlaceholder>
    ))}
  </View>
);

export default ShimmerProductCardHz;
