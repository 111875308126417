import React from "react";
import { Text, View } from "react-native";
import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { useTranslation } from "react-i18next";

interface Props {
  plannedQty: number;
  orderedQty: number;
}

const GrowthBasketProductStrip = ({ plannedQty, orderedQty }: Props) => {
  const { t } = useTranslation();

  return (
    <View
      style={[
        COMMON_STYLES["mb1/2"],
        COMMON_STYLES.row,
        COMMON_STYLES.justifyBetween,
        COMMON_STYLES["p1/2"],
        COMMON_STYLES["br1/2"],
        BG_STYLES.bgYellow20,
      ]}
    >
      <Text
        style={[
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textNeutral100,
        ]}
      >
        {t('plan_units',{plannedQty})}
      </Text>
      <Text
        style={[
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textNeutral100,
        ]}
      >
        {t('ordered_units',{orderedQty})}
      </Text>
    </View>
  );
};

export default GrowthBasketProductStrip;
