import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View, Image, Pressable } from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  COLORS,
  COMMON_STYLES,
  DeleteIcon,
  PlusMinusButtonWithInput,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import { DeliveryInfo, ErrorCardItem, PriceError } from "../../models/Cart";
import { DATE_FORMATS, formatDate } from "../../utils/helpers/date";
import CartItemPriceInfo from "./CartItemPriceInfo";
import { DeliveryIcon } from "../../assets/images";
import WarningRedIcon from "../../icons/WarningRed";
import { MAX_INVENTORY_ALLOWED } from "../../constants/Common";
import RenderComboImages from "./RenderComboImages";

interface Props {
  name: string;
  unitPrice: number;
  totalPrice: number;
  images: {
    url: string;
    text: string;
  }[]; //Todo: Export from dds
  quantity: number;
  discountedPrice?: number | null;
  handleQuantityUpdate: (qty: number) => void;
  handleRemoveCartItem: () => void;
  deliveryInfo?: DeliveryInfo[];
  totalInventory?: number;
  priceError?: PriceError;
  error?: ErrorCardItem;
}

const ComboCartItem = ({
  name,
  unitPrice,
  totalPrice,
  images,
  quantity,
  discountedPrice = 0,
  handleQuantityUpdate,
  handleRemoveCartItem,
  deliveryInfo,
  totalInventory = MAX_INVENTORY_ALLOWED,
  priceError,
  error = {},
}: Props) => {
  const { t } = useTranslation();
  return (
    <View
      style={[BG_STYLES.bgWhite, COMMON_STYLES.br1, COMMON_STYLES["mb1/2"]]}
    >
      <RenderComboImages images={images} />
      <View style={[COMMON_STYLES.ph1, COMMON_STYLES["pt3/4"]]}>
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.justifyBetween,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          <View style={[COMMON_STYLES.row, COMMON_STYLES.flex1]}>
            <View style={COMMON_STYLES.flex1}>
              <View style={COMMON_STYLES.row}>
                <View style={[COMMON_STYLES["ml1/4"], COMMON_STYLES.flex1]}>
                  <Text
                    numberOfLines={2}
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.SEMI_EMPHASIZED,
                      TEXT_STYLES.textNeutral100,
                    ]}
                  >
                    {name}
                  </Text>
                  <CartItemPriceInfo
                    price={unitPrice}
                    constituentUnitPrice={0}
                    constituentUom={""}
                    discountPrice={discountedPrice || 0}
                    discountConstituentUnitPrice={0}
                    isCashback // ALLOWING REWARD TYPE CASHBACK ONLY
                  />
                </View>
                <View style={[COMMON_STYLES.ml1, styles.width120]}>
                  <PlusMinusButtonWithInput
                    onDeleteClick={handleRemoveCartItem}
                    onMinusClick={() => handleQuantityUpdate(quantity - 1)}
                    onPlusClick={() => handleQuantityUpdate(quantity + 1)}
                    onQuantityUpdate={handleQuantityUpdate}
                    quantity={quantity}
                    size={SIZE.small}
                    maximumQty={Math.min(totalInventory, MAX_INVENTORY_ALLOWED)}
                  />
                  {discountedPrice ? (
                    <View
                      style={[
                        COMMON_STYLES.row,
                        COMMON_STYLES.justifyEnd,
                        COMMON_STYLES.alignItemsCenter,
                        COMMON_STYLES["mt1/2"],
                      ]}
                    >
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          TEXT_STYLES.textNeutral80,
                        ]}
                      >
                        {t("billing_price")}
                      </Text>
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.SEMI_EMPHASIZED,
                          TEXT_STYLES.textNeutral100,
                          COMMON_STYLES["ml0.5"],
                        ]}
                      >
                        {formatCurrency(unitPrice)}
                      </Text>
                    </View>
                  ) : null}
                  <View
                    style={[
                      COMMON_STYLES.row,
                      COMMON_STYLES.justifyEnd,
                      COMMON_STYLES.alignItemsCenter,
                      COMMON_STYLES["mt1/4"],
                    ]}
                  >
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral80,
                      ]}
                    >
                      {t("item_total")}
                    </Text>
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.SEMI_EMPHASIZED,
                        TEXT_STYLES.textNeutral100,
                        COMMON_STYLES["ml0.5"],
                      ]}
                    >
                      {formatCurrency(totalPrice)}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        {totalInventory && totalInventory < quantity ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.BASE,
                TEXT_STYLES.textOrange100,
              ]}
            >
              {t("max_qty_error", { count: totalInventory })}
            </Text>
          </View>
        ) : null}
        {priceError ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            <View style={[COMMON_STYLES.row]}>
              <WarningRedIcon style={COMMON_STYLES["mr1/4"]} />
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                  TEXT_STYLES.textOrange100,
                ]}
              >
                {t("price_change_error", {
                  previous: formatCurrency(priceError.previous),
                  current: formatCurrency(priceError.current),
                })}
              </Text>
            </View>
          </View>
        ) : null}

        {Object.values(error).length ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            <View style={[COMMON_STYLES.row]}>
              <WarningRedIcon />
              <View style={[COMMON_STYLES.flex1, COMMON_STYLES["ml1/4"]]}>
                {Object.values(error).map((value, index) => (
                  <Text
                    key={index}
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.BASE,
                      TEXT_STYLES.textOrange100,
                    ]}
                  >
                    {t(value.key, value.params)}
                  </Text>
                ))}
              </View>
            </View>
          </View>
        ) : null}

        {deliveryInfo ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            {deliveryInfo.map((info, index) => {
              const date = info.eta_to
                ? formatDate(info.eta_to, DATE_FORMATS["DD Mmm"])
                : "";
              return (
                <View
                  key={index}
                  style={[COMMON_STYLES.row, index !== 0 && COMMON_STYLES.mt1]}
                >
                  <Image source={DeliveryIcon} style={styles.image20x20} />
                  <View
                    style={[
                      COMMON_STYLES.row,
                      COMMON_STYLES.alignItemsCenter,
                      COMMON_STYLES.justifyBetween,
                      COMMON_STYLES.flex1,
                      COMMON_STYLES["ml0.5"],
                    ]}
                  >
                    <View>
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          TEXT_STYLES.textNeutral80,
                        ]}
                      >
                        {info.product_name}
                      </Text>
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          TEXT_STYLES.textNeutral80,
                        ]}
                      >
                        {t("delivery_by")}
                        <Text
                          style={[
                            TYPOGRAPHY.CAPTION1,
                            VARIANTS_STYLES.EMPHASIZED,
                            TEXT_STYLES.textNeutral90,
                          ]}
                        >
                          {date}
                        </Text>
                      </Text>
                    </View>
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.EMPHASIZED,
                        TEXT_STYLES.textNeutral100,
                        COMMON_STYLES["ml1/4"],
                      ]}
                    >
                      {t("bundle_sku_delivery_info", {
                        count: info.quantity,
                        isMulipleUnits: info.quantity > 1 ? "s" : "",
                        date,
                      })}
                    </Text>
                  </View>
                </View>
              );
            })}
          </View>
        ) : null}
      </View>
      <View
        style={[
          COMMON_STYLES.b1,
          BORDER_STYLES.borderNeutral10,
          COMMON_STYLES["mt3/4"],
        ]}
      >
        <Pressable
          onPress={handleRemoveCartItem}
          style={({ pressed }) => [
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES.justifyCenter,
            COMMON_STYLES["pv1/2"],
            COMMON_STYLES.ph1,
            pressed && COMMON_STYLES["opacity1/2"],
          ]}
        >
          <DeleteIcon
            svgProps={styles.image16x16}
            pathProps={{ stroke: COLORS.neutral90 }}
          />
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral90,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("remove")}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

export default ComboCartItem;

const styles = StyleSheet.create({
  image16x16: { height: 16, width: 16 },
  image20x20: { height: 20, width: 20 },
  width120: { width: 120 },
});
