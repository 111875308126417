import React from "react";
import { Text } from "react-native";
import { Trans, useTranslation } from "react-i18next";
import {
  BottomDrawer,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
  Button,
  SIZE,
} from "@dehaat/dds";

interface Props {
  amount: number;
  isOpen: boolean;
  onClose: () => void;
  onCtaClick: () => void;
  days: number;
}

const OutstandingAmountDrawer = ({
  isOpen,
  onClose,
  amount,
  onCtaClick,
  days,
}: Props) => {
  const { t } = useTranslation();
  return (
    <BottomDrawer
      onClose={onClose}
      visible={isOpen}
      heading={t("you_are_not_able_to_see_prices")}
      //dismissActionType={"collapse"} TODO: Add Modal Action Type to index export
    >
      <Trans
        i18nKey={"pending_outstanding"}
        values={{ amount: formatCurrency(amount), days }}
        parent={Text}
        style={[
          COMMON_STYLES.mb1,
          TEXT_STYLES.textNeutral100,
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.BASE,
        ]}
        components={{
          styled: (
            <Text
              style={[
                TEXT_STYLES.textNeutral100,
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.EMPHASIZED,
              ]}
            />
          ),
        }}
      />
      <Button
        onClick={onCtaClick}
        text={t("pay_outstanding_now", { amount: formatCurrency(amount) })}
        size={SIZE.large}
      />
    </BottomDrawer>
  );
};

export default OutstandingAmountDrawer;
