import React, { useEffect, useState } from "react";
import CuratedTabList from "./CuratedTabList";
import { CuratedListMetaData } from "app/partner-ordering-ui/models/CuratedList";
import { FILTER_VALUES } from "app/partner-ordering-ui/constants/Common";
import Filters from "../../Filters";
import CuratedItemsList from "./RenderCuretedItems";
import Spinner from "../../resuable/Spinner";

interface Props {
  data: string;
  widgetId?: string
}

const ProductGroupCuratedList = ({ data, widgetId }: Props) => {
  const listData = JSON.parse(data) as CuratedListMetaData;
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(listData.tabs[0]);
  const [appliedFilter, setAppliedFilter] = useState(
    FILTER_VALUES.ALL_PRODUCTS
  );

  useEffect(() => {
    if (listData.selectedCategoryId) {
      const tab = listData.tabs.find(
        (tab) => tab.id === listData.selectedCategoryId
      );
      if (tab) setActiveTab(tab);
    }
    setLoading(false);
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <CuratedTabList
        activeCategory={activeTab}
        categories={listData.tabs}
        onTabPress={(tab) => setActiveTab(tab)}
      />
      <Filters
        appliedFilter={appliedFilter}
        activeQuickFilter={null}
        onApplyFilter={(filter) => setAppliedFilter(filter as FILTER_VALUES)}
        onQuickFilterClick={() => {}}
        quickFilters={[]}
      />
      
      <CuratedItemsList
        id={activeTab.id}
        filter={appliedFilter}
        navigatingFrom={listData?.screenTitle}
        widgetId={widgetId}
      />
    </>
  );
};

export default ProductGroupCuratedList;
