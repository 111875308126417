const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export enum DATE_FORMATS {
  "DD Mmm" = "DD Mmm",
  "DD Mmm YYYY" = "DD Mmm YYYY",
  "DD-Mmm-YYYY" = "DD-Mmm-YYYY",
}

export const formatDate = (timeInMs: number, format: DATE_FORMATS) => {
  const fullDate = new Date(0);
  fullDate.setUTCSeconds(timeInMs);

  const month = shortMonths[fullDate.getMonth()];
  const date = fullDate.getDate();
  const year = fullDate.getFullYear();

  if (format === DATE_FORMATS["DD Mmm"]) {
    return `${date} ${month}`;
  }
  if (format === DATE_FORMATS["DD Mmm YYYY"]) {
    return `${date} ${month} ${year}`;
  }
  if (format === DATE_FORMATS["DD-Mmm-YYYY"]) {
    return `${date}-${month}-${year}`;
  }
  return fullDate.toLocaleDateString();
};

export const getCurrentMonthName = () => {
  const month = new Date().getMonth();
  return fullMonths[month];
};

export const formatStringDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = fullMonths[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const toNumber = (value: string, defaultNumber = 0): number => {
  const parsed = Number(value);
  return isNaN(parsed) ? defaultNumber : parsed;
};

export const getOrNull = <T>(array: T[], index: number): T | null => {
  return index >= 0 && index < array.length ? array[index] : null;
};

export const getLastItem = <T>(array: T[]): T | null => {
  if (array.length) {
    return array[array.length - 1];
  }
  return null;
};
