import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Text } from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  BUTTON_STATUS,
  BUTTON_TYPE,
  COMMON_STYLES,
  PriceInfo,
  ProductCardHorizontal,
  ProductTags,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import { BlurPrice } from "app/partner-ordering-ui/assets/images";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import { LICENSE_STATUS } from "app/partner-ordering-ui/constants/Product";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import InfoIcon from "app/partner-ordering-ui/icons/InfoIcon";
import LockIcon from "app/partner-ordering-ui/icons/LockIcon";
import {
  ClearanceItem,
  SingleProduct,
} from "app/partner-ordering-ui/models/Cart";
import {
  Batch,
  ClearanceProduct,
  InventoryErrorModalState,
} from "app/partner-ordering-ui/models/Product";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import { openWhatsApp } from "app/partner-ordering-ui/utils/helpers/common";
import {
  errorArray,
  isAIMS,
} from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import { fireProductAddToCartEvent } from "app/partner-ordering-ui/utils/helpers/mixpanel";
import {
  checkIsMultipleBatches,
  getClearanceListBatches,
  getDisplayBatch,
  getLicenceDetails,
} from "app/partner-ordering-ui/utils/helpers/product";
import { selectDCData } from "app/store/reducer/native";
import ExpiryNote from "./ExpiryNote";
import LicenseInfoTag from "../../resuable/LicenseInfoTag";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import ClearanceVariantSelectDropDown from "./ClearanceVariantSelectDropDown";
import InsufficientInventoryModal from "../../resuable/InsufficientInventoryModal";

interface Props {
  isOrderingBlocked?: boolean;
  handleRevealPriceClick?: () => void;
  product: ClearanceProduct;
  index: number;
}

const RenderClearanceListItem = ({
  isOrderingBlocked = false,
  handleRevealPriceClick,
  product,
  index,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clearanceItems, cartInfo } = useCart();
  const [selectedProduct, setSelectedProduct] = useState<ClearanceProduct>();

  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });
  const dc = useAppSelector(selectDCData);

  const batches = getClearanceListBatches(product.variants);

  const [displayBatch, setDisplayBatch] = useState<Batch>(
    getDisplayBatch(batches) || {}
  );

  if (product.type === "bundle") return <></>; //Returing empty node in case of bundle)
  // Only Regular Products Is Rendered Currenlty As Clearance Products

  const id = displayBatch.id;
  const batchId = displayBatch.batchId;

  const variant = product.variants.find((variant) => variant.id === id);
  const errorRemark = errorArray(variant!);

  const infoFromCart = cartInfo.products.find(
    (item) => batchId === (item as ClearanceItem).batch_id
  ) as SingleProduct;

  const quantity = clearanceItems[batchId]?.qty || 0;

  const { licenseStatus, blockOrder } = getLicenceDetails({
    categoryType: product.category.type,
    subCategoryId: product.sub_category.id,
  });

  const handleQtyUpdate = (qty: number) => {
    const availableQty = Math.min(displayBatch.quantity, MAX_INVENTORY_ALLOWED);
    if (qty <= availableQty) dispatch(updateCartItem({ id, qty, batchId }));
    else {
      dispatch(updateCartItem({ id, qty: availableQty, batchId }));
      setInventoryErrorState({
        isVisible: true,
        name: product.name,
        availableInventory: availableQty,
      });
    }
  };

  const addUnitInfoTag = (
    orderableQuantity: number,
    infoTags: ProductTags[]
  ) => {
    const unitTag: ProductTags[] = isAIMS()
      ? [
          {
            icon: <InfoIcon width={16} height={16} />,
            key: "units",
            name: `${orderableQuantity.toLocaleString("en-IN")} Units`,
            rank: 2,
          },
        ]
      : [];

    const tags: ProductTags[] = unitTag.concat(infoTags);

    return tags;
  };

  return (
    <>
      <ProductCardHorizontal
        badgeTags={displayBatch.badgeTags}
        brandName={product.brand.name}
        containerStyle={[COMMON_STYLES["mb1/2"], BG_STYLES.bgWhite]}
        infoTags={
          addUnitInfoTag(
            displayBatch.clearanceInventory,
            displayBatch.infoTags
          ) || []
        }
        imageUrl={product.image || ""}
        key={product.id}
        hasMultipleVariants={checkIsMultipleBatches(batches)}
        discountInfo={
          errorRemark.length > 0 ? null : (
            <>
              {isOrderingBlocked ? (
                <Image source={BlurPrice} />
              ) : (
                <>
                  {displayBatch.price ? (
                    <PriceInfo
                      price={displayBatch?.price}
                      unitOfMeasure={displayBatch?.constituentUom}
                      discountPrice={displayBatch.discountPrice}
                    />
                  ) : null}
                </>
              )}
            </>
          )
        }
        remark={
          errorRemark.length > 0 ? (
            <Text
              style={[
                VARIANTS_STYLES.BASE,
                TYPOGRAPHY.CAPTION1,
                TEXT_STYLES.textError100,
              ]}
            >
              {t(errorRemark[0])}
              {errorRemark[1] ? "\n" + t(errorRemark[1]) : ""}
            </Text>
          ) : (
            <ExpiryNote timeInSeconds={displayBatch.expiry} />
          )
        }
        productName={product.name}
        onDeleteClick={() => dispatch(deleteCartItem({ id, batchId }))}
        onPlusClick={() =>
          dispatch(updateCartItem({ id, qty: quantity + 1, batchId }))
        }
        onMinusClick={() =>
          dispatch(updateCartItem({ id, qty: quantity - 1, batchId }))
        }
        onQunatityUpdate={handleQtyUpdate}
        packAttribute={displayBatch.packAttribute}
        unitOfMeasure={
          infoFromCart?.constituent_uom || displayBatch.constituentUom
        }
        quantity={quantity}
        handleVariantSelect={() => setSelectedProduct(product)}
        totalAddedQuantity={infoFromCart?.total_added_quantity || 0}
        isOutOfStock={
          (!displayBatch.quantity || displayBatch.quantity < 1) &&
          !isOrderingBlocked
        }
        minimumOrderQuanity={
          errorRemark.length > 0 ? 0 : displayBatch.minQty || 1
        }
        showUploadLicenseCta={!isOrderingBlocked && blockOrder}
        onUploadLicenceCTAClick={
          !isOrderingBlocked &&
          blockOrder &&
          licenseStatus === LICENSE_STATUS.PENDING
            ? openWhatsApp
            : undefined
        }
        cta={{
          onClick: () => {
            if (isAIMS() && errorRemark.length > 0) {
              return;
            }
            if (isOrderingBlocked) {
              handleRevealPriceClick?.();
            } else {
              dispatch(addItemToCart({ id, product, batchId }));
              fireProductAddToCartEvent({
                product: product,
                variant: variant!,
                screenName: ROUTES.CLEARANCE_SALE_LIST,
                productPosition: index + 1,
                isPriorityDc: dc?.is_priority_dc,
              });
            }
          },
          text: isOrderingBlocked ? t("price") : t("add"),
          iconRight: isOrderingBlocked ? (
            <LockIcon height={16} width={16} />
          ) : undefined,
          type: isOrderingBlocked ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY,
          buttonStyle: isOrderingBlocked
            ? BORDER_STYLES.borderBlue10
            : undefined,
          status:
            isAIMS() && errorRemark.length > 0
              ? BUTTON_STATUS.DISABLED
              : BUTTON_STATUS.ACTIVE,
        }}
        availableInventory={displayBatch.quantity}
      >
        {!isOrderingBlocked && blockOrder && (
          <LicenseInfoTag licenseStatus={licenseStatus} />
        )}
      </ProductCardHorizontal>

      {selectedProduct !== undefined && (
        <ClearanceVariantSelectDropDown
          onClose={() => setSelectedProduct(undefined)}
          batches={batches}
          product={selectedProduct}
          displayBatch={displayBatch}
          onVariantSelect={(batch) => {
            setDisplayBatch(batch);
            setSelectedProduct(undefined);
          }}
        />
      )}

      <InsufficientInventoryModal
        isVisible={inventoryErrorState.isVisible}
        onCTAClick={() => setInventoryErrorState({ isVisible: false })}
        name={inventoryErrorState.name}
        availableInventory={inventoryErrorState.availableInventory}
      />
    </>
  );
};

export default RenderClearanceListItem;
