import { post } from "app/partner-ordering-ui/utils/axios/index";
import { logAPIExceptionAtSentry } from "../utils/helpers/common";

interface Response {
  order_request_id: number;
  order_request_status: number;
}

const createOrderRequest = async (request: any) => {
  const url = "/sale-management/order-requests/v2";
  try {
    const response = await post<{ data: Response }>(url, { ...request });
    return response.data;
  } catch (e) {
    logAPIExceptionAtSentry(e); // log the exception in case of create order failed
    console.error(e);
  }
  return null;
};

export default createOrderRequest;
