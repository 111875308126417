import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const CalenderDisabledIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 22 22" {...props} fill="none">
      <Path
        fill="#C5CED6"
        d="M19.487 6.7v10.744a2.573 2.573 0 0 1-2.573 2.573H3.032A2.828 2.828 0 0 1 .516 17.5V6.714H19.5l-.014-.014Z"
      />
      <Path
        fill="#80868C"
        d="M19.485 4.672V7.26H.5V4.672a2.724 2.724 0 0 1 2.742-2.728h1.612v1.102a1.11 1.11 0 0 0 1.117 1.117 1.11 1.11 0 0 0 1.117-1.117V1.944h5.81v1.102a1.11 1.11 0 0 0 1.117 1.117 1.11 1.11 0 0 0 1.116-1.117V1.944h1.612a2.733 2.733 0 0 1 2.742 2.728Z"
      />
      <Path
        fill="#5A6066"
        d="M7.093 1.357v2.106c0 .75-.495 1.358-1.117 1.358S4.86 4.213 4.86 3.463V1.357C4.86.607 5.354 0 5.976 0s1.117.608 1.117 1.357ZM15.124 1.357v2.106c0 .75-.495 1.358-1.117 1.358s-1.116-.608-1.116-1.358V1.357c0-.75.494-1.357 1.116-1.357.622 0 1.117.608 1.117 1.357Z"
      />
      <Path
        fill="#fff"
        d="M5.944 10.367h-.99c-.395 0-.704.367-.704.805 0 .439.322.806.705.806h.99c.395 0 .704-.367.704-.806 0-.438-.321-.805-.705-.805ZM10.53 10.367h-.99c-.395 0-.704.367-.704.805 0 .439.321.806.705.806h.99c.395 0 .704-.367.704-.806 0-.438-.321-.805-.705-.805ZM15.093 10.367h-.99c-.395 0-.705.367-.705.805 0 .439.322.806.705.806h.99c.395 0 .704-.367.704-.806 0-.438-.321-.805-.704-.805ZM15.093 14.71h-.99c-.395 0-.705.368-.705.806 0 .438.322.806.705.806h.99c.395 0 .704-.367.704-.806 0-.438-.321-.806-.704-.806ZM5.944 14.71h-.99c-.395 0-.704.368-.704.806 0 .439.322.806.705.806h.99c.395 0 .704-.367.704-.805 0-.439-.321-.806-.705-.806ZM10.53 14.71h-.99c-.395 0-.704.368-.704.806 0 .438.321.806.705.806h.99c.395 0 .704-.367.704-.806 0-.438-.321-.806-.705-.806Z"
      />
    </Svg>
  );
};

export default CalenderDisabledIcon;
