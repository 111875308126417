import { post } from "app/partner-ordering-ui/utils/axios/index";
import { CartInfo } from "../store/reducer/cart";
import { isAIMS } from "../utils/helpers/isAIMSApp";

const fetchProductsCartInfo = async (
  products: Record<string, any>[],
  ibCustomerId: string,
  schemeId: string | null
) => {
  const url = "/products/cart/v2"
  const body = { scheme_id: schemeId, products };
  const queryParams: any = {is_bundle_coupon_allowed : true}
  if(isAIMS()){
    queryParams.ib_customer_id = ibCustomerId
  }
  
  try {
    const response = await post<{ data: CartInfo }>(url, body, undefined, queryParams);
    return response;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default fetchProductsCartInfo;
