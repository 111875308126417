import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { OrdersDetailsScreenProps } from "../routes/types";
import OrderTimeline from "../components/OrderTimeline";
import {
  ORDER_ACTIONS,
  ORDER_STATUS,
  ORDER_TYPE,
  RENDER_ACTION,
  isDesktop,
} from "../constants/common";
import { COLORS, CenterModal, FONT_FAMILY_SEMIBOLD } from "@dehaat/dds";
import OrderSummary from "../components/OrderSummary";
import { Order, OrderCancelReasonObj } from "../models/orders";
import fetchOrderDetails from "../utils/apis/fetchOrderDetails";
import ProductInfo from "../components/ProductInfo";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import BillDetails from "../components/BillDetails";
import ActionButtons from "../components/ActionButtons";
import OTPContainer from "app/components/OTPContainer";
import { DIMENSION_WIDTH } from "app/partner-ordering-ui/utils/helpers/common";
import generateAndValidateOtp from "../utils/apis/generateAndValidateOtp";
import ToastAndroid from "app/mocks/ToastAndroid";
import patchOrder from "../utils/apis/patchOrder";
import patchOrderRequest from "../utils/apis/patchOrderRequest";
import RejectReasonsModal from "../components/RejectReasonsModal";

const OrderDetails = ({ route }: OrdersDetailsScreenProps) => {
  const [orderDetails, setOrderDetails] = useState<Order | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState<RENDER_ACTION>();
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const { orderId } = route.params;
  const totalOrderItems = orderDetails?.order_details.length || 0;
  const isPickupOrder = orderDetails?.type === ORDER_TYPE.SELF_PICKUP;

  useEffect(() => {
    const getOrder = async () => {
      setLoading(true);
      const res = await fetchOrderDetails(orderId);
      if (res) setOrderDetails(res);
      setLoading(false);
    };
    getOrder();
  }, [orderId]);

  if (loading) return <Spinner />;

  const getPaymentMode = () => {
    if (orderDetails!.is_credit_order) return "Credit";
    else if (+orderDetails!.payment?.paid_amount == 0)
      return "Cash on delivery";
    else if (
      +orderDetails!.payment?.paid_amount > 0 &&
      +orderDetails!.payment?.paid_amount < +orderDetails!.grand_total
    )
      return "Partially Paid";
    else return "Prepaid";
  };

  const handleActionClick = async (action: ORDER_ACTIONS) => {
    if (action === ORDER_ACTIONS.CONFIRM_PICKUP) {
      const res = await generateAndValidateOtp("generate", {
        farmer_id: orderDetails!.farmer_details.id,
        templates: {
          sms: "pick_up_at_store_handshake_hi_v1",
        },
      });
      if (res) {
        ToastAndroid.show("OTP generated successfully", ToastAndroid.SHORT);
        setShowOtpModal(true);
      } else {
        ToastAndroid.show(
          "Error in generating OTP, please try again later",
          ToastAndroid.SHORT
        );
      }
    } else if (action === ORDER_ACTIONS.REJECT) {
      setShowReasonModal(true);
    } else if (action === ORDER_ACTIONS.ACCEPT) {
      handleAcceptOrder();
    }
  };

  const handleOtpSubmit = async (otp: string) => {
    try {
      const res = await generateAndValidateOtp("validate", {
        farmer_id: orderDetails!.farmer_details.id,
        otp,
      });
      if (res) {
        const isConfirmed = await patchOrder(orderDetails!.id, {
          status: ORDER_STATUS.Delivered,
          reason: "delivered through self_pickup",
        });

        if (isConfirmed) {
          setShowOtpModal(false);
          setCurrentAction(RENDER_ACTION.DONWLOAD_OTPIONS);
          ToastAndroid.show(
            "OTP validated successfully, Pickup is confirmed",
            ToastAndroid.SHORT
          );
        } else {
          ToastAndroid.show(
            "Unable to confirm pickup, please try again later.",
            ToastAndroid.SHORT
          );
        }
      }
    } catch {
      ToastAndroid.show(
        "Otp validation failed, Please try again.",
        ToastAndroid.SHORT
      );
    }
  };

  const handleReject = async (reason: OrderCancelReasonObj) => {
    const payload = {
      status: isPickupOrder || !orderDetails?.order_request_number
        ? ORDER_STATUS.VendorCancelled
        : ORDER_STATUS.VendorRejected,
      reason,
      reject_order_request: true, // This can be changed once we start checking for SO creation for Out of Stock products
    };
    const res = isPickupOrder || !orderDetails?.order_request_number
      ? await patchOrder(orderDetails!.id, payload)
      : await patchOrderRequest(
          "reject",
          orderDetails!.order_request_number,
          payload
        );

    if (res) {
      setShowReasonModal(false);
      setCurrentAction(RENDER_ACTION.DONWLOAD_OTPIONS);
      ToastAndroid.show("Order is successfully rejected", ToastAndroid.SHORT);
    } else
      ToastAndroid.show("Error in rejecting the order", ToastAndroid.SHORT);
  };

  const handleAcceptOrder = async () => {
    const payload = {
      status: ORDER_STATUS.VendorConfirmed,
      reason: "",
    };
    const res = isPickupOrder || !orderDetails?.order_request_number
      ? await patchOrder(orderDetails!.id, payload)
      : await patchOrderRequest(
          "accept",
          orderDetails!.order_request_number,
          payload
        );

    if (res) {
      isPickupOrder
        ? setCurrentAction(RENDER_ACTION.CONFIRM_PICKUP)
        : setCurrentAction(RENDER_ACTION.DONWLOAD_OTPIONS);
      ToastAndroid.show("Order is successfully accepted", ToastAndroid.SHORT);
    } else
      ToastAndroid.show("Error in accepting the order", ToastAndroid.SHORT);
  };

  return orderDetails ? (
    <View style={styles.container}>
      <Text style={styles.heading}>Order Status</Text>
      <OrderTimeline
        createdAt={orderDetails.created_at}
        deliveredOn={orderDetails.delivered_on}
        isCancelled={
          orderDetails.status === ORDER_STATUS.UserCancelled ||
          orderDetails.status === ORDER_STATUS.VendorCancelled ||
          orderDetails.status === ORDER_STATUS.DeHaatCancelled
        }
      />
      <Text style={styles.heading}>Order Summary</Text>
      <OrderSummary
        createdAt={orderDetails.created_at}
        address={orderDetails.farmer_details.full_display_address}
        farmerName={orderDetails.farmer_details.full_name}
        mobileNumber={orderDetails.farmer_details.phone_number}
        paymentMode={getPaymentMode()}
        orderType={
          orderDetails.type === ORDER_TYPE.SELF_PICKUP
            ? "Pickup at store"
            : "Home Delivery"
        }
        shippingBy={
          isPickupOrder || orderDetails.status === ORDER_STATUS.Pending
            ? ""
            : orderDetails.shipping_label_link
            ? "Third Party"
            : "Dehaat Owned"
        }
        orderId={orderId}
      />
      <Text style={styles.heading}>
        {totalOrderItems}
        {totalOrderItems > 1 ? " items" : " item"} in this order
      </Text>
      <View style={styles.productInfoContainer}>
        {orderDetails.order_details.map(
          (
            {
              id,
              product_name,
              unit_selling_price,
              quantity,
              variant_attributes: { attribute_value },
              product: { image_url },
            },
            index
          ) => (
            <ProductInfo
              imageSrc={image_url || ""}
              productName={product_name}
              qty={quantity}
              price={unit_selling_price}
              attribute={attribute_value[0].name}
              key={`${id}-${index}`}
            />
          )
        )}
      </View>
      <Text style={styles.heading}>Bill Details</Text>
      <BillDetails
        itemsTotal={+orderDetails.price - (+orderDetails.discount || 0)}
        deliveryCharges={orderDetails.delivery_fee}
        couponDiscount={orderDetails.coupon.discount}
        subTotal={orderDetails.grand_total || orderDetails.total_price}
      />
      <View style={styles.btnContainer}>
        <ActionButtons
          status={orderDetails.status}
          shippingLink={orderDetails.shipping_label_link}
          manifestLink={orderDetails.manifest_link}
          isPickupOrder={isPickupOrder}
          expiresAt={orderDetails.expires_at}
          onActionClick={handleActionClick}
          action={currentAction}
        />
      </View>
      <CenterModal
        style={styles.otpModal}
        isVisible={showOtpModal}
        onClose={() => setShowOtpModal(false)}
      >
        <OTPContainer
          number={orderDetails.farmer_details.phone_number}
          maxLength={4}
          onSubmit={handleOtpSubmit}
        />
      </CenterModal>
      <CenterModal
        style={styles.otpModal}
        isVisible={showReasonModal}
        onClose={() => setShowReasonModal(false)}
      >
        <RejectReasonsModal handleReject={handleReject} />
      </CenterModal>
    </View>
  ) : (
    <Text>No Order Details Found</Text>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: isDesktop ? 56 : 0,
    backgroundColor: "#F2F2F2",
  },
  heading: {
    fontSize: 16,
    paddingTop: 16,
    paddingLeft: 16,
    paddingBottom: 8,
    marginTop: 8,
    fontFamily: FONT_FAMILY_SEMIBOLD,
    backgroundColor: isDesktop ? "intial" : COLORS.white,
  },
  productInfoContainer: {
    padding: 30,
    backgroundColor: COLORS.white,
    borderRadius: 12,
    gap: 16,
  },
  btnContainer: {
    padding: 24,
    marginTop: 5,
    backgroundColor: COLORS.white,
  },
  otpModal: { width: isDesktop ? 420 : DIMENSION_WIDTH },
});
export default OrderDetails;
