import { ProductTags } from "@dehaat/dds";

import {
  MIXPANEL_EVENT,
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "app/partner-ordering-ui/constants/eventNames";
import {
  AmountDetails,
  CartData,
  CartSingleSKUInfo,
} from "app/partner-ordering-ui/models/Cart";
import {
  ComboProductViewdEvent,
  OfferStripType,
  ComboProductEvent,
  ProductClickOptionsType,
  ProductVariantChangeType,
  ProductViewdEvent,
  AlternateProductViewdEvent,
  ClickPlaceOrderEvent,
  ClickedPlaceOrderEvent,
  ClickedViewOptionsQuickLinkEvent,
  RemoveCartItemEvent,
  ClickToZoomImageEvent,
  ClickedRecentSearchItemEvent,
  MixpanelEvent,
  ClickedCuratedProductEvent,
  ClickedExpandComboCarousel,
  OrderPlacedEvent,
  PaymentInitiatedEvent,
} from "app/partner-ordering-ui/models/MixpanelEvent";
import { PaymentMethods } from "app/partner-ordering-ui/models/Payment";
import { BundledProduct } from "app/partner-ordering-ui/models/Product";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import store from "app/store/index";
import { logEvent } from "app/utils/appConfig";
import { getCartType, getCartTypeFromCartData } from "./common";

export const fireProductClickedEvent = (
  options: ProductClickOptionsType,
  object?: Record<string, unknown>
) => {
  const {
    product,
    variant,
    isOfferStrip,
    productPosition,
    isPriorityDc,
    navigationFrm,
    screenName,
  } = options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.BEST_SELLER_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.BEST_SELLER
    ),
    [MIXPANEL_KEYS.DEHAAT_CHOICE_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.DEHAAT_CHOICE
    ),
    [MIXPANEL_KEYS.FREE_INSURANCE_TAG]: !!variant?.info_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.FREE_INSURANCE
    ),
    [MIXPANEL_KEYS.IS_ALTERNATE]: product.similar_product_count > 0,
    [MIXPANEL_KEYS.IS_BUNDLE]: product?.type == "bundle",
    [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: product?.bundles_available,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: product?.bundles_available,
    [MIXPANEL_KEYS.FROM_PROGRESSIVE_NUDGE]:
      navigationFrm === MIXPANEL_PROPERTIES.OFFER_STRIP,
    [MIXPANEL_KEYS.FROM_BOTTOM_SHEET]:
      navigationFrm === MIXPANEL_PROPERTIES.VARIANT_BOTTOM_SHEET,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.OFFER_STRIP]: isOfferStrip,
    [MIXPANEL_KEYS.PREPAID_TAG]: variant?.is_prepaid,
    [MIXPANEL_KEYS.PRODUCT_POSITION]: productPosition,
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: variant?.product_id,
    ...object,
  };
  logEvent(MIXPANEL_EVENT.PRODUCT_CLICKED, JSON.stringify(mixPanelPayload));
};

export const fireProductVariantChangeEvent = (
  options: ProductVariantChangeType
) => {
  const {
    selectedVariant,
    prevVariant,
    isPriorityDc,
    screenName,
    previousStep,
  } = options;
  const mixpanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.NEW_PACK_SIZE]: selectedVariant.pack_attribute,
    [MIXPANEL_KEYS.NEW_SKU_ID]: selectedVariant.id,
    [MIXPANEL_KEYS.OLD_PACK_SIZE]: prevVariant?.pack_attribute,
    [MIXPANEL_KEYS.OLD_SKU_ID]: prevVariant?.id,
    [MIXPANEL_KEYS.PRODUCT_TEMPLATE_ID]: selectedVariant?.product_id,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.PREVIOUS_STEP]: previousStep,
  };
  logEvent(
    MIXPANEL_EVENT.PRODUCT_VARIANT_CHANGED,
    JSON.stringify(mixpanelPayload)
  );
};

export const fireProductViewedEvent = (options: ProductViewdEvent) => {
  const {
    variant,
    product,
    isPriorityDc,
    screenName,
    isProductInStock,
    productPosition,
  } = options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]: product?.similar_product_count,
    [MIXPANEL_KEYS.ATTRIBUTES]: variant?.pack_attribute,
    [MIXPANEL_KEYS.BEST_PRICE]: variant?.discounts_info?.best_price,
    [MIXPANEL_KEYS.BEST_SELLER_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.BEST_SELLER
    ),
    [MIXPANEL_KEYS.BRAND]: product?.brand?.name,
    [MIXPANEL_KEYS.CLEARACNE_SALE_TAGE]: product?.is_clearance_sale,
    [MIXPANEL_KEYS.DEHAAT_CHOICE_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.DEHAAT_CHOICE
    ),
    [MIXPANEL_KEYS.FEW_QTY_LEFT_TAG]: false,
    [MIXPANEL_KEYS.FREE_INSURANCE_TAG]: !!variant?.info_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.FREE_INSURANCE
    ),
    [MIXPANEL_KEYS.IS_ALTERNATE]: product.similar_product_count > 0,
    [MIXPANEL_KEYS.IS_BUNDLE]: product?.bundles_available,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: false,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: false,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.IS_PRODUCT_INSTOCK]: isProductInStock,
    [MIXPANEL_KEYS.IS_RETURNABLE]: variant?.is_returnable,
    [MIXPANEL_KEYS.LISTING_PRICE]: variant?.dehaat_price,
    [MIXPANEL_KEYS.OFFER_STRIP]: false,
    [MIXPANEL_KEYS.OFFER_STRIP_CTA_NAME]: null,
    [MIXPANEL_KEYS.PREPAID_TAG]: variant?.is_prepaid,
    [MIXPANEL_KEYS.PRICE_PER_UOM]: variant?.constituent_unit_price,
    [MIXPANEL_KEYS.PRODUCT_CATEGORY]: product?.category?.name,
    [MIXPANEL_KEYS.PRODUCT_POSITION]: productPosition,
    [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: product?.sub_category?.name,
    [MIXPANEL_KEYS.PRODUCT_TECHNICAL_NAME]: product?.technical_contents?.[0],
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: product?.id,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.VIEW_ALTERNATE_CTA_AVAILABLE]: false,
  };
  logEvent(MIXPANEL_EVENT.PRODUCT_VIEWED, JSON.stringify(mixPanelPayload));
};

export const fireProductDescriptionViewEvent = (options: ProductViewdEvent) => {
  const { variant, product, isPriorityDc, prevStep } = options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]: product?.similar_product_count,
    [MIXPANEL_KEYS.ATTRIBUTES]: variant?.pack_attribute,
    [MIXPANEL_KEYS.BEST_SELLER_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.BEST_SELLER
    ),
    [MIXPANEL_KEYS.BRAND]: product?.brand?.name,
    [MIXPANEL_KEYS.DEHAAT_CHOICE_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.DEHAAT_CHOICE
    ),
    [MIXPANEL_KEYS.FREE_INSURANCE_TAG]: !!variant?.info_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.FREE_INSURANCE
    ),
    [MIXPANEL_KEYS.IS_BUNDLE]: product?.bundles_available,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: product?.bundles_available,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: product?.bundles_available,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.IS_RETURNABLE]: variant?.is_returnable,
    [MIXPANEL_KEYS.LISTING_PRICE]: variant?.dehaat_price,
    [MIXPANEL_KEYS.CURATED_LIST_ID]: "",
    [MIXPANEL_KEYS.CURATED_LIST_NAME]: "",
    [MIXPANEL_KEYS.FLAT_COUPON_AVAILABLE]: false,
    [MIXPANEL_KEYS.FLAT_PREPAID_COUPON_AVAILABLE]: false,
    [MIXPANEL_KEYS.IMAGE_AVAILABLE]: !!product?.image,
    [MIXPANEL_KEYS.PREPAID_TAG]: variant?.is_prepaid,
    [MIXPANEL_KEYS.FLAT_PREPAID_COUPON_AVAILABLE]: false,
    [MIXPANEL_KEYS.PREVIOUS_STEP]: prevStep,
    [MIXPANEL_KEYS.PRODUCT_CATEGORY]: product?.category?.name,
    [MIXPANEL_KEYS.PRODUCT_DESCRIPTION_AVAILABLE]: !!product?.id,
    [MIXPANEL_KEYS.PRODUCT_GROUP_ID]: "",
    [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: product?.sub_category?.name,
    [MIXPANEL_KEYS.PRODUCT_TECHNICAL_NAME]: product?.technical_contents?.[0],
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.TEMPALTE_ID]: product?.id,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.SCREEN_NAME]: MIXPANEL_PROPERTIES.PRODUCT_DESCRIPTION,
    [MIXPANEL_KEYS.VIEW_ALTERNATE_CTA_AVAILABLE]:
      product?.similar_product_count > 0,
    [MIXPANEL_KEYS.VIEW_BUNDLE_CTA_AVAILABLE]: product?.bundles_available,
    [MIXPANEL_KEYS.VOLUMN_RATE_COUPON_AVAILABLE]: false,
  };
  logEvent(
    MIXPANEL_EVENT.PRODUCT_DESCRIPTION_SCREEN_VIEWED,
    JSON.stringify(mixPanelPayload)
  );
};

export const fireProductListScreenViewedEvent = (isPriorityDc: boolean) => {
  const mixpanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.PREVIOUS_STEP]: MIXPANEL_PROPERTIES.BUY_PRODUCTS_PAGE,
    [MIXPANEL_KEYS.PRODUCT_LIST_NAME]: MIXPANEL_PROPERTIES.BUY_PRODUCTS,
    [MIXPANEL_KEYS.PRODUCT_LIST_VIEW_TYPE]: ROUTES.PRODUCT_LIST,
    [MIXPANEL_KEYS.SCREEN_NAME]: ROUTES.PRODUCT_LIST,
  };
  logEvent(
    MIXPANEL_EVENT.PRODUCT_LIST_SCREEN_VIEWD,
    JSON.stringify(mixpanelPayload)
  );
};

export const fireClickOnOfferStripEvent = (options: OfferStripType) => {
  const {
    variant,
    product,
    isPriorityDc,
    screenName,
    offerStripName,
    isFromVariantBottomDrawer = false,
  } = options;
  const mixpanelPayload = {
    [MIXPANEL_KEYS.BEST_PRICE]: variant?.discounts_info?.best_price,
    [MIXPANEL_KEYS.IS_FROM_VARIANT_BOTTOM_SHEET]: isFromVariantBottomDrawer,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.LISTING_PRICE]: variant?.dehaat_price,
    [MIXPANEL_KEYS.OFFER_STRIP_CTA_NAME]: offerStripName,
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: product?.id,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
  };
  logEvent(
    MIXPANEL_EVENT.CLICK_ON_OFFER_STRIP,
    JSON.stringify(mixpanelPayload)
  );
};

export const fireProductAddToCartEvent = (options: ProductViewdEvent) => {
  const { variant, product, isPriorityDc, screenName, productPosition } =
    options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]: product?.similar_product_count,
    [MIXPANEL_KEYS.ATTRIBUTES]: variant?.pack_attribute,
    [MIXPANEL_KEYS.BEST_SELLER_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.BEST_SELLER
    ),
    [MIXPANEL_KEYS.BRAND]: product?.brand?.name,
    [MIXPANEL_KEYS.CAR_WIDGET_TYPE]: "",
    [MIXPANEL_KEYS.CLEARACNE_SALE_TAGE]: product?.is_clearance_sale,
    [MIXPANEL_KEYS.DEHAAT_CHOICE_BADGE]: !!variant?.badge_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.DEHAAT_CHOICE
    ),
    [MIXPANEL_KEYS.FEW_QTY_LEFT_TAG]: false,
    [MIXPANEL_KEYS.FREE_INSURANCE_TAG]: !!variant?.info_tags?.find(
      (item: ProductTags) => item.key === MIXPANEL_PROPERTIES.FREE_INSURANCE
    ),
    [MIXPANEL_KEYS.IS_ALTERNATE]: product.similar_product_count > 0,
    [MIXPANEL_KEYS.IS_BUNDLE]: product?.bundles_available,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: false,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: false,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.IS_RETURNABLE]: variant?.is_returnable,
    [MIXPANEL_KEYS.OFFER_STRIP]: false,
    [MIXPANEL_KEYS.PREPAID_TAG]: variant?.is_prepaid,
    [MIXPANEL_KEYS.PRODUCT_CATEGORY]: product?.category?.name,
    [MIXPANEL_KEYS.PRODUCT_POSITION]: productPosition,
    [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: product?.sub_category?.name,
    [MIXPANEL_KEYS.PRODUCT_TECHNICAL_NAME]: product?.technical_contents?.[0],
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: product?.id,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.SHOW_STOCKS_RUNNING_OUT]: false,
  };
  logEvent(MIXPANEL_EVENT.PRODUCT_ADD_TO_CART, JSON.stringify(mixPanelPayload));
};

export const fireComboProductAddToCartEvent = (
  options: ComboProductEvent,
  paylaod?: Record<string, unknown>
) => {
  const { product, isPriorityDc, screenName, productPosition } = options;
  if (product) {
    const attributes = product.bundled_products?.map(
      (product) => product.pack_attribute
    );
    const brand = product.bundled_products?.map(
      (product) => product.brand.name
    );
    const category = product.bundled_products?.map(
      (product) => product.category.name
    );
    const subCategory = product.bundled_products?.map(
      (product) => product.sub_category.name
    );
    const name = product.bundled_products?.map((product) => product.name);
    const skuId = product.id;
    const templateId = product.product_id;
    const mixPanelPayload = {
      [MIXPANEL_KEYS.ATTRIBUTES]: attributes,
      [MIXPANEL_KEYS.BEST_SELLER_BADGE]: false,
      [MIXPANEL_KEYS.BRAND]: brand,
      [MIXPANEL_KEYS.CAR_WIDGET_TYPE]: "", // Need to implement after firebase integration
      [MIXPANEL_KEYS.CLEARACNE_SALE_TAGE]: false,
      [MIXPANEL_KEYS.DEHAAT_CHOICE_BADGE]: false,
      [MIXPANEL_KEYS.FEW_QTY_LEFT_TAG]: false,
      [MIXPANEL_KEYS.FREE_INSURANCE_TAG]: false,
      [MIXPANEL_KEYS.IS_ALTERNATE]: false,
      [MIXPANEL_KEYS.IS_BUNDLE]: true,
      [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
      [MIXPANEL_KEYS.IS_RETURNABLE]: false, // will do after backend implementation
      [MIXPANEL_KEYS.OFFER_STRIP]: false,
      [MIXPANEL_KEYS.PREPAID_TAG]: product.is_prepaid,
      [MIXPANEL_KEYS.PRODUCT_CATEGORY]: category,
      [MIXPANEL_KEYS.PRODUCT_POSITION]: productPosition,
      [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: subCategory,
      [MIXPANEL_KEYS.PRODUCT_TECHNICAL_NAME]: "N.A.",
      [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: name,
      [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
      [MIXPANEL_KEYS.TEMPALTE_ID]: templateId,
      [MIXPANEL_KEYS.SKU_ID]: skuId,
      [MIXPANEL_KEYS.SHOW_STOCKS_RUNNING_OUT]: false,
      ...paylaod,
    };
    logEvent(
      MIXPANEL_EVENT.PRODUCT_ADD_TO_CART,
      JSON.stringify(mixPanelPayload)
    );
  }
};

export const fireComboProductViewedEvent = (
  options: ComboProductViewdEvent
) => {
  const { combo, isPriorityDc, screenName, isProductInStock, productPosition } =
    options;
  let pack_attribute = "";
  let brand = "";
  let product_category = "";
  let sub_category = "";

  combo?.bundles[0]?.bundled_products?.forEach(
    (item: BundledProduct, index: number) => {
      pack_attribute += item.pack_attribute;
      brand += item?.brand?.name;
      product_category += item?.category?.name;
      sub_category += item?.sub_category?.name;
      if (index < combo?.bundles[0]?.bundled_products?.length - 1) {
        pack_attribute += ",";
        brand += ",";
        product_category += ",";
        sub_category += ",";
      }
    }
  );
  const mixPanelPayload = {
    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]: 0,
    [MIXPANEL_KEYS.ATTRIBUTES]: pack_attribute?.trim(),
    [MIXPANEL_KEYS.BEST_SELLER_BADGE]: false,
    [MIXPANEL_KEYS.BRAND]: brand?.trim(),
    [MIXPANEL_KEYS.CLEARACNE_SALE_TAGE]: combo?.is_clearance_sale,
    [MIXPANEL_KEYS.DEHAAT_CHOICE_BADGE]: false,
    [MIXPANEL_KEYS.FEW_QTY_LEFT_TAG]: false,
    [MIXPANEL_KEYS.FREE_INSURANCE_TAG]: false,
    [MIXPANEL_KEYS.IS_ALTERNATE]: false,
    [MIXPANEL_KEYS.IS_BUNDLE]: true,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: true,
    [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: true,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.IS_PRODUCT_INSTOCK]: isProductInStock,
    [MIXPANEL_KEYS.IS_RETURNABLE]: false,
    [MIXPANEL_KEYS.OFFER_STRIP]: false,
    [MIXPANEL_KEYS.PREPAID_TAG]: false,
    [MIXPANEL_KEYS.PRODUCT_CATEGORY]: product_category,
    [MIXPANEL_KEYS.PRODUCT_POSITION]: productPosition,
    [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: sub_category,
    [MIXPANEL_KEYS.PRODUCT_TECHNICAL_NAME]: null,
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: combo?.name,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: combo?.id,
    [MIXPANEL_KEYS.SKU_ID]: combo?.bundles[0]?.id,
    [MIXPANEL_KEYS.VIEW_ALTERNATE_CTA_AVAILABLE]: false,
  };
  logEvent(MIXPANEL_EVENT.PRODUCT_VIEWED, JSON.stringify(mixPanelPayload));
};

export const fireCuratedListScreenViewEvent = (data: string) => {
  const dc = store.getState().native.dc;
  const analyticsData = JSON.parse(data);
  const mixpanelPayload = {
    [MIXPANEL_KEYS.CURATED_LIST_ID]: analyticsData?.curatedListId,
    [MIXPANEL_KEYS.CURATED_LIST_NAME]: analyticsData?.title,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
    [MIXPANEL_KEYS.POSITION_CLICKED]: analyticsData?.position,
    [MIXPANEL_KEYS.PREVIOUS_STEP]: analyticsData?.previousStep,
    [MIXPANEL_KEYS.PRODUCT_GROUP_ID]: analyticsData?.productGroupId,
    [MIXPANEL_KEYS.PRODUCT_LIST_ID]: analyticsData?.productListId,
    [MIXPANEL_KEYS.PRODUCT_LIST_NAME]: analyticsData?.title,
    [MIXPANEL_KEYS.PRODUCT_LIST_VIEW_TYPE]: analyticsData?.productListViewType,
    [MIXPANEL_KEYS.SCREEN_NAME]: analyticsData?.screenName,
  };
  logEvent(
    MIXPANEL_EVENT.PRODUCT_LIST_SCREEN_VIEWD,
    JSON.stringify(mixpanelPayload)
  );
};

export const fireClickViewAlternateProduct = (
  options: AlternateProductViewdEvent
) => {
  const {
    parantVariant,
    alternateProductVariant,
    isPriorityDc,
    screenName,
    productPosition,
    parantVariantStatus,
    previousStep,
  } = options;
  const mixpanelPayload = {
    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_PACK_SIZE_PRICE]:
      alternateProductVariant?.pack_attribute,
    [MIXPANEL_KEYS.INSTOCK_SKUS_COUNT]:
      alternateProductVariant?.orderable_quantity,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.PARENT_SKU_INSTOCK_STATUS]: parantVariantStatus,
    [MIXPANEL_KEYS.PARENT_SKU_PACK_SIZE]: parantVariant?.pack_attribute,
    [MIXPANEL_KEYS.PARENT_SKU_PRIZE]: parantVariant?.dehaat_price,
    [MIXPANEL_KEYS.PARENT_TEMPLATE_ID]: parantVariant?.product_id,
    [MIXPANEL_KEYS.PRICE_SHOWN_ON_PRODUCT_TEMPLATE]:
      alternateProductVariant?.dehaat_price,
    [MIXPANEL_KEYS.PRODUCT_TEMPLATE_ID]: alternateProductVariant?.product_id,
    [MIXPANEL_KEYS.PRODUCT_TEMPLATE_POSITION]: productPosition,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.PREVIOUS_STEP]: previousStep,
  };
  logEvent(
    MIXPANEL_EVENT.CLICK_ON_ALTERNATE_PRODUCT,
    JSON.stringify(mixpanelPayload)
  );
};

export const fireClickPlaceOrderButtonEvent = (
  options: ClickPlaceOrderEvent
) => {
  const { credit, prepaid, noOfCartItem, selectedIndex, isPriorityDc } =
    options;
  const selectedPaymentMode = selectedIndex ? credit : prepaid;
  const mixpanelPayload = {
    [MIXPANEL_KEYS.FINAL_MINIUM_AMOUNT_ON_CTA]:
      selectedPaymentMode?.net_payable,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.PAYABLE_AMOUNT]: selectedPaymentMode?.payable_amount,
    [MIXPANEL_KEYS.PAYMENT_METHOD_CHOSEN]: selectedIndex
      ? PaymentMethods.CREDIT
      : PaymentMethods.PREPAID,
    [MIXPANEL_KEYS.POSTPAID_EFFECTIVE_AMOUNT]: credit?.effective_amount,
    [MIXPANEL_KEYS.PREPAID_EFFECTIVE_AMOUNT]: prepaid?.effective_amount,
    [MIXPANEL_KEYS.SCREEN_NAME]: MIXPANEL_PROPERTIES.PAYMENT_METHOD_SELECTION,
    [MIXPANEL_KEYS.ITEM_COUNT]: noOfCartItem,
    [MIXPANEL_KEYS.ORDER_VALUE]: selectedPaymentMode?.payable_amount,
  };
  logEvent(
    MIXPANEL_EVENT.CLICK_ON_PLACE_ORDER_BUTTON_PAYMENT_PAGE,
    JSON.stringify(mixpanelPayload)
  );
};

export const fireClickedOnPlaceOrderEvent = (
  options: ClickedPlaceOrderEvent
) => {
  const { dc, cartData, payAmount } = options;
  const creditLimitExhausted =
    Number(dc.availableCreditLimit) >= 0 &&
    dc?.overdueAmount <= dc?.agedCreditLimit;
  const mixpanelPayload = {
    [MIXPANEL_KEYS.IS_DCF]: dc?.lms_activated,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
    [MIXPANEL_KEYS.SCREEN_NAME]: ROUTES.MY_CART,
    [MIXPANEL_KEYS.CART_TYPE]: MIXPANEL_PROPERTIES.REGULAR,
    [MIXPANEL_KEYS.CREDIT_LIMIT_EXHAUSTED]: creditLimitExhausted,
    [MIXPANEL_KEYS.ITEM_COUNT]: Object.keys(cartData)?.length,
    [MIXPANEL_KEYS.ORDER_VALUE]: payAmount,
  };
  logEvent(MIXPANEL_EVENT.CLICKED_PLACE_ORDER, JSON.stringify(mixpanelPayload));
};

export const fireClickedViewCombosQuickLink = (
  options: ClickedViewOptionsQuickLinkEvent
) => {
  const { isPriorityDc, product, variant, screenName } = options;

  const mixPanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.PRODUCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: variant?.product_id,
  };
  logEvent(
    MIXPANEL_EVENT.CLICKED_VIEW_COMBOS_QUICK_LINK,
    JSON.stringify(mixPanelPayload)
  );
};

export const fireClickedViewAlternateQuickLink = (
  options: ClickedViewOptionsQuickLinkEvent
) => {
  const { isPriorityDc, product, variant, screenName } = options;

  const mixPanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.PRODUCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: variant?.product_id,
  };
  logEvent(
    MIXPANEL_EVENT.CLICKED_VIEW_ALTERNATE_QUICK_LINK,
    JSON.stringify(mixPanelPayload)
  );
};

export const fireRemoveCartItem = (options: RemoveCartItemEvent) => {
  const { removedData, removedItem, screenName, isPriorityDc } = options;
  const mixpanelPayload = {
    [MIXPANEL_KEYS.ATTRIBUTES]: removedData?.variantInfo?.pack_attribute,
    [MIXPANEL_KEYS.BRAND]: removedItem?.brand?.name,
    [MIXPANEL_KEYS.CLEARACNE_SALE_TAGE]: removedItem?.is_clearance_sale,
    [MIXPANEL_KEYS.FEW_QTY_LEFT_TAG]: false,
    [MIXPANEL_KEYS.IS_BUNDLE]: removedItem?.type == "bundle",
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.IS_RETURNABLE]: removedData?.variantInfo?.is_returnable,
    [MIXPANEL_KEYS.PRODUCT_CATEGORY]: removedItem?.category?.name,
    [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: removedData?.subCategory,
    [MIXPANEL_KEYS.PRODUCT_TAG]: getCartType(removedData?.paymentModes || []),
    [MIXPANEL_KEYS.PRODUCT_TECHNICAL_NAME]:
      removedItem?.technical_contents?.[0],
    [MIXPANEL_KEYS.PRODUCT_TEMPLATE_NAME]: removedItem?.name,
    [MIXPANEL_KEYS.SKU_ID]: removedData?.variantInfo?.id,
    [MIXPANEL_KEYS.SKU_REMOVE_BUTTON_CART_CLICKED]: true,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: removedData?.productId,
  };
  logEvent(
    MIXPANEL_EVENT.PRODUCT_REMOVED_FROM_CART,
    JSON.stringify(mixpanelPayload)
  );
};

export const fireClickToZoomImage = (
  options: ClickToZoomImageEvent,
  object?: Record<string, unknown>
) => {
  const { isPriorityDc, product, variant, screenName } = options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.PRODUCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.TEMPALTE_ID]: variant?.product_id,
    ...object,
  };
  logEvent(
    MIXPANEL_EVENT.CLICK_TO_ZOOM_THE_IMAGE,
    JSON.stringify(mixPanelPayload)
  );
};

export const fireClickedRecentSearchItem = (
  options: ClickedRecentSearchItemEvent
) => {
  const { rank, count, actual, suggested, screenName, isPriorityDc } = options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.ACTUAL_SEARCH_TERM]: actual,
    [MIXPANEL_KEYS.SUGGESTED_SEARCH_TERM]: suggested,
    [MIXPANEL_KEYS.RECENT_SEARCH_ITEM_RANK]: rank,
    [MIXPANEL_KEYS.RECENT_SEARCH_ITEM_COUNT]: count,
  };
  logEvent(
    MIXPANEL_EVENT.RECENT_SEARCH_ITEM_CLICKED,
    JSON.stringify(mixPanelPayload)
  );
};

export const fireClickedClearAll = (options: MixpanelEvent) => {
  const { isPriorityDc, screenName } = options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
  };
  logEvent(MIXPANEL_EVENT.CLEAR_ALL_CLICKED, JSON.stringify(mixPanelPayload));
};

export const fireCurartedProductClicked = (
  options: ClickedCuratedProductEvent
) => {
  const { isPriorityDc, screenName, groupId, listId, listName, product } =
    options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.CURATED_LIST_ID]: listId,
    [MIXPANEL_KEYS.CURATED_LIST_NAME]: listName,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.PRODUCT_GROUP_ID]: groupId,
    [MIXPANEL_KEYS.PRODUCT_TYPE]: product?.type,
    [MIXPANEL_KEYS.TEMPALTE_ID]: product?.id,
  };
  logEvent(
    MIXPANEL_EVENT.CURATED_PRODUCT_CLICKED,
    JSON.stringify(mixPanelPayload)
  );
};

export const fireExpandComboCarousal = (
  options: ClickedExpandComboCarousel
) => {
  const {
    isPriorityDc,
    screenName,
    product,
    variant,
    productPosition,
    showBundlesOnly,
  } = options;
  const mixPanelPayload = {
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: isPriorityDc,
    [MIXPANEL_KEYS.SCREEN_NAME]: screenName,
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: product?.name,
    [MIXPANEL_KEYS.SKU_ID]: variant?.id,
    [MIXPANEL_KEYS.TEMPALTE_ID]: variant?.product_id,
    [MIXPANEL_KEYS.REMOTE_CONFIG_FOR_BUNDLE]: showBundlesOnly,
    [MIXPANEL_KEYS.PRODUCT_POSITION]: productPosition,
  };
  logEvent(
    MIXPANEL_EVENT.CLICKED_ADD_TO_EXPAND_COMBO_CAROUSEL,
    JSON.stringify(mixPanelPayload)
  );
};
export const fireOrderPlacedEvent = (options: OrderPlacedEvent) => {
  const { item, paymentMethod, dc } = options;
  let couponName: Array<string> = [];
  let couponDiscountAmount: number = 0;
  (item as CartSingleSKUInfo)?.appliedDiscounts?.forEach((element) => {
    couponName.push(element.identifier);
    couponDiscountAmount += element.total_benefit;
  });
  const payload = {
    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]:
      item.eventData?.alternateProductCount,
    [MIXPANEL_KEYS.ATTRIBUTES]: item.eventData?.attributes,
    [MIXPANEL_KEYS.BRAND]: item.eventData?.brandName,
    [MIXPANEL_KEYS.CLEARACNE_SALE_TAGE]: item.eventData?.clearanceTag,
    [MIXPANEL_KEYS.COUPON_DISCOUNT_AMOUNT]: couponDiscountAmount,
    [MIXPANEL_KEYS.COUPON_NAME]: couponName,
    [MIXPANEL_KEYS.DC_CREDIT_LINE_STATUS]: dc?.credit_line_status,
    [MIXPANEL_KEYS.DC_OVERDUE_FLAG]: dc?.overdue_amount > dc?.agedCreditLimit,
    [MIXPANEL_KEYS.DC_PREPAID_FLAG]: paymentMethod === PaymentMethods?.PREPAID,
    [MIXPANEL_KEYS.FEW_QTY_LEFT_TAG]: false,
    [MIXPANEL_KEYS.IS_BUNDLE]: item?.eventData?.bundleAvailable,
    [MIXPANEL_KEYS.IS_COUPON_APPLIED]: couponDiscountAmount > 0,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
    [MIXPANEL_KEYS.IS_RETURNABLE]: item.eventData?.isReturnable,
    [MIXPANEL_KEYS.ORDER_AMOUNT]: (item?.discountedPrice || 0) * item.quantity,
    [MIXPANEL_KEYS.ORDER_QUANTITY]: item.quantity,
    [MIXPANEL_KEYS.PAYMENT_METHOD_CHOSEN]: paymentMethod,
    [MIXPANEL_KEYS.PRODUCT_CATEGORY]: item.eventData?.category?.name,
    [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: item.subCategory?.name,
    [MIXPANEL_KEYS.PRODUCT_TAG]: getCartType(item.paymentModes),
    [MIXPANEL_KEYS.PRODUCT_TECHNICAL_NAME]: (item as CartSingleSKUInfo)
      ?.technicalContents?.[0],
    [MIXPANEL_KEYS.PRODCT_TEMPLATE_NAME]: item.name,
    [MIXPANEL_KEYS.SKU_ID]: item.id,
    [MIXPANEL_KEYS.SCREEN_NAME]: MIXPANEL_PROPERTIES.PAYMENT_METHOD_SELECTION,
    [MIXPANEL_KEYS.TEMPALTE_ID]: item.productId,
    [MIXPANEL_KEYS.ADDED_TO_CART_PAGE]: MIXPANEL_PROPERTIES.PLP_ROUTE,
  };

  logEvent(MIXPANEL_EVENT.ORDER_PLACED, JSON.stringify(payload));
};

export const firePaymentInitiatedEvent = (options: PaymentInitiatedEvent) => {
  const { dc, cartData, effAmount } = options;
  const creditLimitExhausted =
    Number(dc.availableCreditLimit) >= 0 &&
    dc?.overdueAmount <= dc?.agedCreditLimit;
  const payload = {
    [MIXPANEL_KEYS.IS_DCF]: dc?.lms_activated,
    [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
    MIXPANEL_KEYS: ROUTES.MY_CART,
    [MIXPANEL_KEYS.CART_TYPE]: MIXPANEL_PROPERTIES.REGULAR,
    [MIXPANEL_KEYS.CREDIT_LIMIT_EXHAUSTED]: creditLimitExhausted,
    [MIXPANEL_KEYS.ITEM_COUNT]: Object.keys(cartData)?.length,
    [MIXPANEL_KEYS.ORDER_VALUE]: effAmount,
  };
  logEvent(MIXPANEL_EVENT.PAYMENT_INITIATED, JSON.stringify(payload));
};

export const firePrepaidPaymentInitiatedEvent = (
  amountDetailsObj: AmountDetails,
  cartData: CartData
) => {
  const mPayloadPPI: { [key: string]: any } = {};

  if (amountDetailsObj) {
    const breakup = amountDetailsObj.payment_details.payment_breakup;
    if (breakup) {
      breakup.map((item) => {
        mPayloadPPI[item.key] = item.breakup;
      });
    }
    mPayloadPPI[MIXPANEL_KEYS.TOTAL_PAYABLE_AMOUNT] =
      amountDetailsObj.payment_details.amount_payable;

    mPayloadPPI[MIXPANEL_KEYS.CART_TYPE] = getCartTypeFromCartData(cartData);
  }

  logEvent(
    MIXPANEL_EVENT.PREPAID_PAYMENT_INITIATED,
    JSON.stringify(mPayloadPPI)
  );
};
