import React from "react";
import { Pressable, Text, View } from "react-native";
import {
  COMMON_STYLES,
  CrossIcon,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

interface Props {
  itemName: string;
  onClose: () => void;
}

const SearchedItemStrip = ({ itemName, onClose }: Props) => {
  return (
    <View
      style={[
        COMMON_STYLES.ph1,
        COMMON_STYLES["pv1/2"],
        COMMON_STYLES.row,
        { backgroundColor: "#FDF2D4" }, //TODO: ASK FROM DDS
        COMMON_STYLES.justifyBetween,
      ]}
    >
      <Text style={[TYPOGRAPHY.CAPTION1, VARIANTS_STYLES.BASE]}>
        Search result for "{itemName}"
      </Text>
      <Pressable
        style={({ pressed }) => pressed && COMMON_STYLES["opacity1/2"]}
        onPress={onClose}
      >
        <CrossIcon height={16} width={16} />
      </Pressable>
    </View>
  );
};

export default SearchedItemStrip;
