import React from "react";
import { ClipPath, Defs, G, Path, Svg, SvgProps } from "react-native-svg";

export default function LockIcon(props: SvgProps) {
  return (
    <Svg viewBox="0 0 16 16" fill="none" {...props}>
      <G>
        <Path
          d="M12.735 7.643c-.062-.195-.14-.333-.246-.43a.734.734 0 0 0-.177-.114.722.722 0 0 0-.1-.034l-.006-.002a1.057 1.057 0 0 0-.14-.024h-.01c-.03-.004-.063-.004-.095-.005h-.285v-.509h-.013c.005-.523.03-1.04-.008-1.553-.102-1.374-1.162-2.568-2.512-2.873-.183-.041-.37-.067-.555-.099h-.426c-.096.016-.192.033-.289.047-1.585.23-2.8 1.625-2.805 3.217v1.77H4.79c-.033 0-.066.001-.097.004l-.01.001a1.05 1.05 0 0 0-.139.024l-.005.001a.737.737 0 0 0-.101.035.735.735 0 0 0-.178.115c-.105.097-.183.235-.245.429l-.003.004A.732.732 0 0 0 4 7.77v5.494a.74.74 0 0 0 .014.14c.013.038.025.075.039.111.09.234.26.383.5.455.015.004.03.01.045.016a.733.733 0 0 0 .14.014h7.273c.05 0 .097-.005.144-.014.044-.015.088-.03.131-.047a.733.733 0 0 0 .434-.49c.004-.016.01-.031.016-.046a.736.736 0 0 0 .014-.137c0-1.87-.015-3.754-.015-5.623Zm-3.871 3.15c-.066.048-.085.097-.085.174.002.35.002.7.002 1.051v.002a.406.406 0 0 1-.812 0v-1.057c.001-.071-.014-.119-.077-.165a.797.797 0 0 1-.265-.986.82.82 0 0 1 1.568.323.768.768 0 0 1-.331.657Zm1.41-4.268v.509H6.469v-.378a.187.187 0 0 0 .007-.06c-.001-.288-.004-.574 0-.86.004-.248-.007-.498.028-.74.142-.962 1.026-1.65 2.018-1.583.951.063 1.73.874 1.748 1.828.009.428.003.856.004 1.284Z"
          fill="#0E7A41"
        />
      </G>
    </Svg>
  );
}
