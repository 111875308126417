import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { EmitterSubscription } from "react-native";

import {
  logEvent,
  openPaymentGatewayActivity,
  openOfflinePaymentSuccessActivity,
  finishCurrentActivity,
  initializeEventEmitter,
  PAYMENT_GATEWAY_RESULT,
  IS_OFFLINE_PAYMENT,
  SUCCESS,
  FAILED,
  PENDING,
} from "app/utils/appConfig";
import { selectDCData } from "app/store/reducer/native";
import ToastAndroid from "app/mocks/ToastAndroid";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  MyCartScreenProps,
  ROUTES,
} from "app/partner-ordering-ui/routes/types";
import useFocusHook from "app/partner-ordering-ui/hooks/useFocusHook";
import CustomBackButton from "app/partner-ordering-ui/components/resuable/CustomBackButton";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import RenderCart from "app/partner-ordering-ui/components/cart/RenderCart";
import HeaderIcon from "app/partner-ordering-ui/components/cart/HeaderIcon";

import { resetCart, setCartId } from "../store/reducer/cart";
import { PaymentMethods } from "../models/Payment";
import {
  createAmountDetails,
  createOrderItemsQuery,
} from "../utils/helpers/cart";
import { AmountDetails, CartData, CartSingleSKUInfo } from "../models/Cart";
import createOrderRequest from "../apis/createOrderRequest";
import createSession from "../apis/createSession";

import {
  MIXPANEL_EVENT,
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "../constants/eventNames";
import {
  defaultBackPressCallback,
  getCartTypeFromCartData,
} from "../utils/helpers/common";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import {
  fireOrderPlacedEvent,
  firePaymentInitiatedEvent,
  firePrepaidPaymentInitiatedEvent,
} from "../utils/helpers/mixpanel";
import { BroadCastEvents } from "../constants/Cart";

const MyCart = ({ navigation, route }: MyCartScreenProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const eventEmitter = initializeEventEmitter();
  const dispatch = useAppDispatch();
  const [isCartLoaded, setIsCartLoaded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [orderId, setOrderId] = useState<number>();
  const [orderAmount, setOrderAmount] = useState<number>();

  const isFocused = useFocusHook();

  const customBackBehaviour = () => {
    activeIndex ? setActiveIndex(0) : defaultBackPressCallback();
    return true;
  };

  const dc = useAppSelector(selectDCData);

  const onOrderSuccess = (
    orderId: number,
    orderAmount: number,
    isOfflinePayment: boolean
  ) => {
    if (orderId && orderAmount) {
      eventEmitter?.emit(BroadCastEvents.ORDER_REQUEST_NUMBER, orderId);
      dispatch(resetCart()); //reset cart on order success only for now
      if (isOfflinePayment) {
        finishCurrentActivity();
        openOfflinePaymentSuccessActivity(
          orderId.toString(),
          dc?.partner_id?.toString(),
          dc?.ib_id?.toString(),
          dc?.name,
          "order_request"
        );
      } else {
        navigation.replace(ROUTES.ORDER_SUCCESS, {
          id: orderId,
          amount: orderAmount,
        });
      }
    }
  };

  const handleCreateOrder = (
    cartData: CartData,
    amountDetails: any,
    cartId: string,
    paymentMethod: PaymentMethods,
    isWalletApplied: boolean,
    ibId: string
  ) => {
    setIsLoading(true);
    if (amountDetails) {
      const request = {
        cart_id: cartId,
        products: createOrderItemsQuery(cartData),
        wallet_applied: isWalletApplied,
        order_payment_mode: paymentMethod,
        ib_customer_id: ibId,
        amount_details: createAmountDetails(amountDetails, paymentMethod),
      };

      const effAmount =
        amountDetails.payment_mode_breakup[paymentMethod]?.effective_amount ||
        0;
      createOrderRequest(request)
        .then((response) => {
          if (response) {
            const netpayable =
              (amountDetails.payment_mode_breakup[paymentMethod]?.net_payable ||
                0) * 100;
            if (netpayable > 0) {
              // Event 1
              logEvent(
                MIXPANEL_EVENT.MAKE_PAYMENT,
                JSON.stringify({ [MIXPANEL_KEYS.SOURCE]: "Prepaid" })
              );

              // Event 2
              firePaymentInitiatedEvent({
                dc,
                cartData,
                effAmount,
              });

              //Event 3
              firePrepaidPaymentInitiatedEvent(
                amountDetails as AmountDetails,
                cartData
              );

              // logEvent(MIXPANEL_EVENT.PREPAID_PAYMENT_INITIATED);

              setOrderId(response.order_request_id);
              setOrderAmount(effAmount);
              // Initiate payment flow
              createSessionApiCall(
                response.order_request_id,
                response.order_request_status,
                netpayable
              );
            } else {
              //redirect to success screen
              onOrderSuccess(response.order_request_id, effAmount!, false);
            }
            let category: string[] = [];
            let subCategory: string[] = [];
            let productList: string[] = [];
            let appliedDiscount = 0;
            Object.values(cartData)?.forEach((item) => {
              const discount =
                (item as CartSingleSKUInfo).appliedDiscounts?.length ?? 0;
              appliedDiscount += discount;
              productList.push(item?.id);
              subCategory.push(item?.subCategory?.name);
              category.push(item?.eventData?.category?.name);
              fireOrderPlacedEvent({
                item,
                paymentMethod,
                dc,
              });
            });
            const mixpanelPayload = {
              [MIXPANEL_KEYS.SKU_COUPON_APPLIED]: appliedDiscount,
              [MIXPANEL_KEYS.DC_CREDIT_LINE_STATUS]: dc?.credit_line_status,
              [MIXPANEL_KEYS.DC_OVERDUE_FLAG]:
                dc?.overdue_amount > dc?.agedCreditLimit,
              [MIXPANEL_KEYS.DC_PREPAID_FLAG]:
                paymentMethod === PaymentMethods?.PREPAID,
              [MIXPANEL_KEYS.IS_PARTIAL_ORDER]: false,
              [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
              [MIXPANEL_KEYS.ORDER_AMOUNT]: effAmount,
              [MIXPANEL_KEYS.ORDER_ID]: response.order_request_id,
              [MIXPANEL_KEYS.PAYMENT_METHOD_CHOSEN]: paymentMethod,
              [MIXPANEL_KEYS.PRODUCT_CATEGORY]: category,
              [MIXPANEL_KEYS.PRODUCT_LIST]: productList,
              [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: subCategory,
              [MIXPANEL_KEYS.SCREEN_NAME]:
                MIXPANEL_PROPERTIES.PAYMENT_METHOD_SELECTION,
              [MIXPANEL_KEYS.CART_TYPE]: getCartTypeFromCartData(cartData),
            };
            logEvent(
              MIXPANEL_EVENT.ORDER_PLACED_OVERALL,
              JSON.stringify(mixpanelPayload)
            );
          } else {
            //redirect to error screen in case of error
            navigation.replace(ROUTES.ORDER_FAILURE);
          }
        })
        .catch((err) => {
          console.log(err);
          ToastAndroid.show(
            "Unable to create transaction at this moment. Please try again later",
            ToastAndroid.SHORT
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      ToastAndroid.show("Something went wrong", ToastAndroid.SHORT);
    }
  };

  const createSessionApiCall = async (
    order_request_id: number,
    status: number,
    amount: number
  ) => {
    setIsLoading(true);
    const response = await createSession(
      order_request_id,
      dc?.partner_id,
      amount
    );
    const eventPayload = {
      [MIXPANEL_KEYS.ENTITY_TYPE]: "order_request",
      [MIXPANEL_KEYS.ENTITY_ID]: order_request_id,
      [MIXPANEL_KEYS.PG_TYPE]: "PAYMENT_SERVICE",
    };

    if (!response) {
      eventPayload[MIXPANEL_KEYS.SESSION_ERROR] =
        "PG Error: invalid session data";
    }

    logEvent(
      MIXPANEL_EVENT.CREATE_PG_SESSION_RESULT,
      JSON.stringify(eventPayload)
    );

    if (response) {
      if (response.pg_type === "RAZORPAY") {
        // Resetting ID to avoid same order request at backend
        dispatch(setCartId({})).then(() => setActiveIndex(0));
        ToastAndroid.show(
          "Something went wrong. Please try again later.",
          ToastAndroid.SHORT
        );
      } else {
        openPaymentGatewayActivity(
          amount,
          order_request_id.toString(),
          "order_request",
          status.toString(),
          response.url,
          response.success_url,
          response.failure_url,
          response.pending_url,
          0
        );
      }
    } else {
      // Resetting ID to avoid same order request at backend
      dispatch(setCartId({})).then(() => setActiveIndex(0));
      ToastAndroid.show(
        "Something went wrong. Please try again later.",
        ToastAndroid.SHORT
      );
    }
    setIsLoading(false);
  };

  const paymentGatwayCallback = useCallback(
    (event: any) => {
      switch (event[PAYMENT_GATEWAY_RESULT]) {
        case SUCCESS:
          onOrderSuccess(orderId!, orderAmount!, event[IS_OFFLINE_PAYMENT]);
          break;
        case FAILED:
          dispatch(setCartId({})); // Resetting ID to avoid same order request at backend
          navigation.replace(ROUTES.ORDER_FAILURE);
          break;
        case PENDING:
          if (orderId && orderAmount) {
            dispatch(resetCart())
              .unwrap()
              .then(() =>
                navigation.replace(ROUTES.ORDER_PENDING, {
                  id: orderId!,
                  amount: orderAmount!,
                })
              );
          }
          break;
      }
    },
    [orderId, orderAmount]
  );

  useObserverInternetActivity();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: activeIndex ? "Payment" : "Cart",
      headerLeft: () => (
        <CustomBackButton
          onBackPress={customBackBehaviour}
          dependency={activeIndex.toString()}
        />
      ),
      headerRight: () => (!activeIndex ? <HeaderIcon /> : undefined),
    });
  }, [navigation, activeIndex]);

  useEffect(() => {
    let eResult: EmitterSubscription | null = null;
    if (eventEmitter && !eventEmitter?.listenerCount(PAYMENT_GATEWAY_RESULT)) {
      eResult = eventEmitter!.addListener(PAYMENT_GATEWAY_RESULT, (event) =>
        paymentGatwayCallback(event)
      );
    }
    return () => {
      eResult?.remove();
    };
  }, [orderId, orderAmount]);

  useEffect(() => {
    if (isFocused) {
      setActiveIndex(0);
      setIsCartLoaded(true);
    }
    return () => {
      isFocused && setIsCartLoaded(false);
    };
  }, [isFocused]);

  if (!isCartLoaded) {
    return <Spinner />;
  }
  return (
    <RenderCart
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      onCreateOrder={handleCreateOrder}
      loading={isLoading}
      setLoading={setIsLoading}
      landingFrom={route.params.landingFrom || ""}
    />
  );
};

export default MyCart;
