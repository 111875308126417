import { post } from "../axios/auth";
import AuthToken from "app/models/Auth";
const validateOtp = async (
  clientId: string,
  mobileNumber: string,
  otp: string
) => {
  const formData = new URLSearchParams();
  formData.append("mobile_number", mobileNumber);
  formData.append("otp", otp);
  formData.append("grant_type", "password");
  formData.append("client_id", clientId);

  return await post<AuthToken>(
    "realms/dehaat/protocol/openid-connect/token",
    formData.toString()
  );
};

export default validateOtp;
