import React from "react";
import { FlatList, FlatListProps } from "react-native";

import {
  ClearanceProduct,
  Product,
} from "app/partner-ordering-ui/models/Product";

import CheckoutStrip from "../CheckoutStrip";
import VariantSelectDropDown from "./VariantSelectDropdown";
import InsufficientInventoryModal from "../resuable/InsufficientInventoryModal";
import SchemeProgressiveNudge from "./SchemeProgressiveNudge";
import useCart from "app/partner-ordering-ui/hooks/useCart";

interface Props {
  selectedProduct?: Product | ClearanceProduct;
  listProps: FlatListProps<Product | ClearanceProduct>;
  removeSelectedProduct: () => void;
  inventoryModalState: {
    isVisible: boolean;
    name?: string;
    availableInventory?: number;
  };
  resetModalState: () => void;
  landingFrom: string;
}

const RenderList = ({
  listProps,
  selectedProduct,
  removeSelectedProduct,
  inventoryModalState,
  resetModalState,
  landingFrom,
}: Props) => {
  const { cartInfo, schemeId } = useCart();

  const shouldRenderSchemeProgressiveNudge = () => {
    return (
      cartInfo !== undefined &&
      cartInfo.scheme_info &&
      schemeId &&
      schemeId === cartInfo.scheme_info.scheme_id
    );
  };

  return (
    <>
      <FlatList {...listProps} />
      {shouldRenderSchemeProgressiveNudge() && (
        <SchemeProgressiveNudge schemeInfo={cartInfo.scheme_info!} />
      )}
      <CheckoutStrip landingFrom={landingFrom} />
      {selectedProduct !== undefined && (
        <VariantSelectDropDown
          visible={selectedProduct !== undefined}
          onClose={removeSelectedProduct}
          variants={selectedProduct.variants}
          productImageUrl={selectedProduct.image || ""}
          productName={selectedProduct.name}
          productId={selectedProduct.id}
          isBundle={selectedProduct.bundles_available}
          product={selectedProduct}
        />
      )}
      <InsufficientInventoryModal
        isVisible={inventoryModalState.isVisible}
        onCTAClick={resetModalState}
        name={inventoryModalState.name}
        availableInventory={inventoryModalState.availableInventory}
      />
    </>
  );
};

export default RenderList;
