import React from "react";
import { Image, StyleSheet } from "react-native";
import { COMMON_STYLES, PriceInfo } from "@dehaat/dds";

import { BlurPrice } from "app/partner-ordering-ui/assets/images";
import { DiscountsInfo } from "app/partner-ordering-ui/models/Product";
import Cashback from "../resuable/Cashback";

interface Props {
  priceLocked?: boolean;
  price: string;
  constituentUnitPrice: number;
  constituentUom: string;
  discountsInfo: DiscountsInfo;
}

const ProductPriceInfo = ({
  price,
  priceLocked = false,
  constituentUnitPrice,
  constituentUom,
  discountsInfo,
}: Props) => {
  const {
    discount_constituent_unit_price,
    discount_unit_price = 0,
    cashback_per_unit,
  } = discountsInfo || {};

  if (priceLocked)
    return (
      <Image source={BlurPrice} style={[styles.h36, COMMON_STYLES.halfWidth]} />
    );

  if (!price) {
    return null;
  }

  return (
    <>
      <PriceInfo
        price={price}
        discountPrice={discount_unit_price || ""}
        unitPrice={
          discount_constituent_unit_price || constituentUnitPrice || ""
        }
        unitOfMeasure={constituentUom}
      />
      <Cashback amount={cashback_per_unit} />
    </>
  );
};

const styles = StyleSheet.create({
  h36: {
    height: 36,
  },
});

export default ProductPriceInfo;
