import {
  BORDER_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

interface Props {
  cashback: string;
}

const CurrentCashback = ({ cashback }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <View
        style={[
          COMMON_STYLES.mh1,
          COMMON_STYLES.mt1,
          COMMON_STYLES.b1,
          BORDER_STYLES.borderOrange10,
          COMMON_STYLES["br1/2"],
          COMMON_STYLES["p1/2"],
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textOrange120,
            COMMON_STYLES["ml1/4"],
          ]}
        >
          {t("current_cashback_slab")}
          <Text style={[VARIANTS_STYLES.SEMI_EMPHASIZED]}>{cashback}</Text>
        </Text>
      </View>
    </>
  );
};

export default CurrentCashback;
