import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { OrderMoreNudge } from "app/partner-ordering-ui/models/Scheme";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";

interface Props {
  orderMoreNudge: OrderMoreNudge;
}

const EarnCashbackNudge = ({ orderMoreNudge }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <View
        style={[
          COMMON_STYLES.mh1,
          COMMON_STYLES.mt1,
          COMMON_STYLES["br1/2"],
          COMMON_STYLES["p1/2"],
          BG_STYLES.bgOrange10,
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textOrange120,
            COMMON_STYLES["ml1/4"],
          ]}
        >
          {t("earn_cashback_upto")}
          <Text style={[VARIANTS_STYLES.SEMI_EMPHASIZED]}>
            {orderMoreNudge.lastSlabCashback}
          </Text>
        </Text>

        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral90,
            COMMON_STYLES["mt1/4"],
            COMMON_STYLES["ml1/4"],
            COMMON_STYLES["mr1/4"],
          ]}
        >
          {orderMoreNudge.isLastSlab ? (
            <>
              {t("keep_ordering_nudge")}
              <Text style={[VARIANTS_STYLES.SEMI_EMPHASIZED]}>
                {orderMoreNudge.currentCashback}
              </Text>
            </>
          ) : (
            <>
              {t("order_items")}
              <Text style={{ fontWeight: "bold" }}>
                {orderMoreNudge.valueToReachNextSlab}
              </Text>
              {t("more_to_avail")}
              <Text style={{ fontWeight: "bold" }}>
                {orderMoreNudge.nextCashBack}
              </Text>
              {t("cashback_small_case")}
            </>
          )}
        </Text>
      </View>
    </>
  );
};

export default EarnCashbackNudge;
