import { post } from "app/utils/axios/auth/index";

interface Request {
  client_id: string;
  refresh_token: string;
  client_secret?: string;
}

const keycloakLogout = async (
  refreshToken: string,
  clientId: string,
  clientSecret?: string
) => {
  const body: Request = {
    client_id: clientId,
    refresh_token: refreshToken,
  };
  if (clientSecret) {
    body.client_secret = clientSecret;
  }
  try {
    post("realms/dehaat/custom/logout", { ...body });
    return true;
  } catch (e) {
    return false;
  }
};

export default keycloakLogout;
