import {COLORS, COMMON_STYLES} from '@dehaat/dds';
import React from 'react';
import {
  ActivityIndicator,
  ActivityIndicatorProps,
  Modal,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  type?: 'in-screen' | 'on-screen';
  spinnerProps?: ActivityIndicatorProps;
  containerStyle?: StyleProp<ViewStyle>;
}

const Spinner = ({type = 'in-screen', containerStyle, spinnerProps}: Props) => {
  if (type === 'in-screen') {
    return (
      <View
        style={[
          COMMON_STYLES.flex1,
          COMMON_STYLES.alignItemsCenter,
          COMMON_STYLES.justifyCenter,
          containerStyle,
        ]}>
        <ActivityIndicator
          color={COLORS.primary100}
          size="large"
          {...spinnerProps}
        />
      </View>
    );
  }
  return (
    <Modal animationType="fade" transparent visible>
      <View style={styles.background}>
        <View
          style={[
            COMMON_STYLES.flex1,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES.justifyCenter,
            containerStyle,
          ]}>
          <ActivityIndicator
            color={COLORS.primary100}
            size="large"
            {...spinnerProps}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  background: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
});

export default Spinner;
