import * as React from "react";
import Svg, {
  Path,
  Defs,
  LinearGradient,
  Stop,
  SvgProps,
} from "react-native-svg";

function RupeeIcon(props: SvgProps) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...props}>
      <Path
        d="M21.2 10.983c0 5.632-4.566 10.197-10.198 10.197C5.37 21.18.805 16.615.805 10.983.805 5.351 5.37.786 11.002.786 16.634.786 21.2 5.35 21.2 10.983z"
        fill="url(#paint0_linear_9554_21768)"
      />
      <Path
        d="M6.922 4.865h8.098v1.412h-2.71a3.3 3.3 0 01.903 1.741h1.807v1.413h-1.774c-.247 1.511-1.495 2.644-3.647 2.907l4.008 3.515-1.183 1.249-5.305-4.879.542-1.05h.838c1.626 0 2.677-.625 2.94-1.742H6.922V8.018h4.517c-.23-1.117-1.035-1.74-2.316-1.74H6.922V4.864z"
        fill="#fff"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_9554_21768"
          x1={0.804687}
          y1={0.785645}
          x2={25.9138}
          y2={1.88682}
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.0474373} stopColor="#9A5EC9" />
          <Stop offset={0.574996} stopColor="#582084" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export default RupeeIcon;
