import React from "react";
import { Svg, Path, SvgProps } from "react-native-svg";

const LicenceUnderReviewIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M18.94 9.72c-.34-.13-.49-.49-.34-.81.41-.89.25-1.97-.47-2.7-.73-.73-1.82-.88-2.7-.47-.32.15-.69 0-.81-.34a2.39 2.39 0 0 0-2.24-1.57c-1.03 0-1.91.66-2.24 1.57-.12.34-.49.49-.81.34-.88-.41-1.97-.26-2.69.47-.73.73-.89 1.81-.48 2.7.15.32 0 .68-.33.81-.92.33-1.57 1.21-1.57 2.24 0 1.03.65 1.9 1.57 2.24.33.12.48.48.33.81-.41.88-.25 1.96.48 2.69.72.73 1.81.89 2.69.48.32-.15.69 0 .81.33.33.92 1.21 1.57 2.24 1.57 1.03 0 1.91-.65 2.24-1.57.12-.33.49-.48.81-.33.88.41 1.97.25 2.7-.48.72-.73.88-1.81.47-2.69-.15-.33 0-.69.34-.81a2.39 2.39 0 0 0 1.56-2.24c0-1.03-.65-1.91-1.56-2.24Zm-6.56 5.78a3.54 3.54 0 0 1-3.54-3.54c0-1.95 1.58-3.55 3.54-3.55a3.55 3.55 0 0 1 3.55 3.55c0 1.96-1.59 3.54-3.55 3.54Z"
        fill="#C4C4C4"
      />
      <Path
        d="M12.38 13.73a1.77 1.77 0 1 0 0-3.54 1.77 1.77 0 0 0 0 3.54ZM23.1 6.74C21.08 2.61 16.98.04 12.38.04 8.3.04 4.61 2.07 2.42 5.42l-.09-.57c-.06-.36-.4-.6-.75-.54-.35.06-.59.39-.54.75l.38 2.37a.644.644 0 0 0 .64.55c.09 0 .18-.02.26-.05l2.16-.94a.654.654 0 0 0-.52-1.2l-.32.14c1.97-2.86 5.19-4.59 8.74-4.59 4.09 0 7.75 2.29 9.55 5.97.16.32.55.46.87.3.32-.16.46-.55.3-.87ZM23.31 16.48a.644.644 0 0 0-.33-.47.638.638 0 0 0-.57-.03l-2.16.94c-.33.14-.48.53-.34.86.14.33.53.48.86.34l.32-.14a10.543 10.543 0 0 1-8.74 4.59c-4.09 0-7.75-2.29-9.55-5.97a.653.653 0 0 0-.87-.3c-.32.16-.46.55-.3.87 2.02 4.13 6.12 6.7 10.72 6.7 4.08 0 7.78-2.03 9.96-5.38l.09.57c.05.32.33.55.64.55h.11c.35-.06.59-.39.54-.75l-.39-2.37.01-.01Z"
        fill="#04B280"
      />
    </Svg>
  );
};

export default LicenceUnderReviewIcon;
