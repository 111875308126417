import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Trans, useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COLORS,
  COMMON_STYLES,
  CaretDownIcon,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
  TooltipNew,
  EM,
} from "@dehaat/dds";
import CalenderIcon from "app/partner-ordering-ui/icons/Calender";
import CashbackIcon from "app/partner-ordering-ui/icons/Cashback";
import PaynowIcon from "app/partner-ordering-ui/icons/PayNow";
import {
  CreditDetails,
  PrepaidDetails,
} from "app/partner-ordering-ui/models/Cart";
import OfferAppliedIcon from "app/partner-ordering-ui/icons/OfferApplied";
import { PAYMENT_LABEL } from "app/partner-ordering-ui/constants/Cart";
import CalenderDisabledIcon from "app/partner-ordering-ui/icons/CalenderDisabled";
import CashbackDisabledIcon from "app/partner-ordering-ui/icons/CashbackDisabled";
import PaynowDisabledIcon from "app/partner-ordering-ui/icons/PayNowDisabled";
import OfferAppliedDisabledIcon from "app/partner-ordering-ui/icons/OfferAppliedDisabled";

interface Props {
  isEnabled: boolean;
  breakup: CreditDetails | PrepaidDetails;
  label: PAYMENT_LABEL;
  selectedIndex: number;
  onViewBreakupClick?: () => void;
}

const PaymentBreakupCard = ({
  isEnabled,
  breakup,
  label,
  selectedIndex,
  onViewBreakupClick,
}: Props) => {
  const { t } = useTranslation();
  const {
    payable_amount,
    effective_amount,
    net_payable,
    total_cashback_discount,
    paid_using_credit_limit,
    instant_discount_breakup,
    cashback_discount_breakup,
    remaining_used_wallet_balance,
    remaining_used_advance_balance,
    abs_balance_used,
  } = breakup;
  const [isBreakupVisible, setBreakupVisibility] = useState(false);

  const getPaymentTagLabel = () => {
    if ((breakup as PrepaidDetails).savings) {
      return t(selectedIndex ? "save" : "saving", {
        amount: formatCurrency((breakup as PrepaidDetails).savings),
      });
    }
    return t(selectedIndex ? "pay_now" : "paying_now");
  };

  return (
    <View style={COMMON_STYLES.ph1}>
      {label === PAYMENT_LABEL.CASH ? (
        <Text
          style={[
            isEnabled ? TEXT_STYLES.textBlack : TEXT_STYLES.textNeutral70,
            VARIANTS_STYLES.EMPHASIZED,
            TYPOGRAPHY.CAPTION1,
            COMMON_STYLES["ph1/2"],
            COMMON_STYLES["pv1/4"],
            COMMON_STYLES.absolute,
            styles.savingTextStyle,
            isEnabled
              ? selectedIndex
                ? BG_STYLES.bgYellow100
                : BG_STYLES.bgPrimary20
              : BG_STYLES.bgNeutral20,
          ]}
        >
          {getPaymentTagLabel()}
        </Text>
      ) : null}
      <Trans
        i18nKey={"get_this_for"}
        values={{ amount: formatCurrency(effective_amount) }}
        parent={Text}
        style={[
          TYPOGRAPHY.HEADING3,
          VARIANTS_STYLES.EMPHASIZED,
          isEnabled ? TEXT_STYLES.textNeutral100 : TEXT_STYLES.textNeutral70,
          COMMON_STYLES["ml0.5"],
        ]}
        components={{
          styled: (
            <Text
              style={[
                TYPOGRAPHY.HEADING3,
                VARIANTS_STYLES.EMPHASIZED,
                isEnabled
                  ? TEXT_STYLES.textNeutral100
                  : TEXT_STYLES.textNeutral70,
              ]}
            />
          ),
        }}
      />

      {net_payable ? (
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {isEnabled ? (
            <PaynowIcon height={24} width={24} />
          ) : (
            <PaynowDisabledIcon height={24} width={24} />
          )}
          <Trans
            i18nKey={"pay_now_breakup_text"}
            values={{ amount: formatCurrency(net_payable) }}
            parent={Text}
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              isEnabled ? TEXT_STYLES.textNeutral70 : TEXT_STYLES.textNeutral40,
              COMMON_STYLES["ml0.5"],
            ]}
            components={{
              styled: (
                <Text
                  style={[
                    TYPOGRAPHY.HEADING3,
                    VARIANTS_STYLES.EMPHASIZED,
                    isEnabled
                      ? TEXT_STYLES.textNeutral100
                      : TEXT_STYLES.textNeutral70,
                  ]}
                />
              ),
            }}
          />
          {label === PAYMENT_LABEL.CREDIT &&
          (breakup as CreditDetails).minimum_amount_payable ? (
            <View>
              <TooltipNew
                tiggerComponent={
                  <Text style={[{ textDecorationLine: "underline" }]}>
                    {t("know_why")}
                  </Text>
                }
              >
                <View
                  style={[
                    COMMON_STYLES.row,
                    COMMON_STYLES.justifyBetween,
                    COMMON_STYLES["mt1/4"],
                  ]}
                >
                  <Text style={[TEXT_STYLES.textWhite, TYPOGRAPHY.CAPTION1]}>
                    {t("total_pay_now")}
                  </Text>
                  <Text style={[TEXT_STYLES.textWhite, TYPOGRAPHY.CAPTION1]}>
                    {formatCurrency(
                      (breakup as CreditDetails).minimum_amount_payable
                        .prepaid_items_total
                    )}
                  </Text>
                </View>
                <View
                  style={[
                    COMMON_STYLES.row,
                    COMMON_STYLES.justifyBetween,
                    COMMON_STYLES["mv3/4"],
                  ]}
                >
                  <Text
                    style={[
                      TEXT_STYLES.textWhite,
                      TYPOGRAPHY.CAPTION1,
                      COMMON_STYLES["mr1"],
                    ]}
                  >
                    {t("more_than_credit_limit")}
                  </Text>
                  <Text style={[TEXT_STYLES.textWhite, TYPOGRAPHY.CAPTION1]}>
                    {formatCurrency(
                      (breakup as CreditDetails).minimum_amount_payable
                        .amount_exc_cred_limit
                    )}
                  </Text>
                </View>
                <View
                  style={[
                    COMMON_STYLES.row,
                    COMMON_STYLES.justifyBetween,
                    COMMON_STYLES["mb1/4"],
                  ]}
                >
                  <Text style={[TEXT_STYLES.textWhite, TYPOGRAPHY.CAPTION1]}>
                    {t("overdue")}
                  </Text>
                  <Text style={[TEXT_STYLES.textWhite, TYPOGRAPHY.CAPTION1]}>
                    {formatCurrency(
                      (breakup as CreditDetails).minimum_amount_payable.overdue
                    )}
                  </Text>
                </View>
              </TooltipNew>
            </View>
          ) : null}
        </View>
      ) : null}
      {abs_balance_used ? (
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {isEnabled ? (
            <OfferAppliedIcon
              height={16}
              width={16}
              style={COMMON_STYLES["ml1/4"]}
            />
          ) : (
            <OfferAppliedDisabledIcon
              height={16}
              width={16}
              style={COMMON_STYLES["ml1/4"]}
            />
          )}
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              isEnabled ? TEXT_STYLES.textNeutral70 : TEXT_STYLES.textNeutral40,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("abs_balance_used_text", {
              amount: formatCurrency(abs_balance_used),
            })}
          </Text>
        </View>
      ) : null}
      {remaining_used_wallet_balance ? (
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {isEnabled ? (
            <OfferAppliedIcon
              height={16}
              width={16}
              style={COMMON_STYLES["ml1/4"]}
            />
          ) : (
            <OfferAppliedDisabledIcon
              height={16}
              width={16}
              style={COMMON_STYLES["ml1/4"]}
            />
          )}
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              isEnabled ? TEXT_STYLES.textNeutral70 : TEXT_STYLES.textNeutral40,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("wallet_balance_used_text", {
              amount: formatCurrency(remaining_used_wallet_balance),
            })}
          </Text>
        </View>
      ) : null}
      {remaining_used_advance_balance ? (
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {isEnabled ? (
            <OfferAppliedIcon
              height={16}
              width={16}
              style={COMMON_STYLES["ml1/4"]}
            />
          ) : (
            <OfferAppliedDisabledIcon
              height={16}
              width={16}
              style={COMMON_STYLES["ml1/4"]}
            />
          )}
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              isEnabled ? TEXT_STYLES.textNeutral70 : TEXT_STYLES.textNeutral40,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("advance_balance_used_text", {
              amount: formatCurrency(remaining_used_advance_balance),
            })}
          </Text>
        </View>
      ) : null}
      {paid_using_credit_limit ? (
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {isEnabled ? (
            <CalenderIcon height={24} width={24} />
          ) : (
            <CalenderDisabledIcon height={24} width={24} />
          )}
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              isEnabled ? TEXT_STYLES.textNeutral70 : TEXT_STYLES.textNeutral40,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("use_credit_limit", {
              amount: formatCurrency(paid_using_credit_limit),
            })}
          </Text>
        </View>
      ) : null}

      {total_cashback_discount ? (
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {isEnabled ? (
            <CashbackIcon height={24} width={24} />
          ) : (
            <CashbackDisabledIcon height={24} width={24} />
          )}
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              isEnabled ? TEXT_STYLES.textNeutral70 : TEXT_STYLES.textNeutral40,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("receive_credit_note", {
              amount: formatCurrency(total_cashback_discount),
            })}
          </Text>
        </View>
      ) : null}
      <View
        style={[
          BG_STYLES.bgWhite,
          COMMON_STYLES["ph1/2"],
          COMMON_STYLES["pv1/4"],
          COMMON_STYLES.absolute,
          {
            top: -28,
            left: 16,
          },
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.EMPHASIZED,
            isEnabled ? TEXT_STYLES.textNeutral100 : TEXT_STYLES.textNeutral70,
          ]}
        >
          {label}
        </Text>
      </View>
      <TouchableOpacity
        style={[
          isEnabled ? BG_STYLES.bgBlue10 : BG_STYLES.bgNeutral00,
          styles["ph3/4"],
          COMMON_STYLES["mt1/2"],
          COMMON_STYLES["br1/2"],
        ]}
        onPress={() => {
          setBreakupVisibility((prev) => !prev);
          if (onViewBreakupClick) onViewBreakupClick();
        }}
      >
        <>
          <View
            style={[
              COMMON_STYLES.row,
              COMMON_STYLES.justifyBetween,
              COMMON_STYLES.alignItemsCenter,
              COMMON_STYLES["pv1/2"],
            ]}
          >
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.EMPHASIZED,
                TEXT_STYLES.textNeutral80,
              ]}
            >
              {t("view_breakup")}
            </Text>
            <CaretDownIcon
              pathProps={{ stroke: "black" }}
              svgProps={{ height: 16, width: 16 }}
            />
          </View>
          {isBreakupVisible && (
            <View style={[COMMON_STYLES["pt1/2"], styles["pb3/4"]]}>
              <View
                style={[
                  COMMON_STYLES.row,
                  COMMON_STYLES.justifyBetween,
                  styles["pb3/4"],
                ]}
              >
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.BASE,
                    isEnabled
                      ? TEXT_STYLES.textNeutral100
                      : TEXT_STYLES.textNeutral70,
                  ]}
                >
                  {t("payable_amount")}
                </Text>
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.MEDIUM,
                    isEnabled
                      ? TEXT_STYLES.textNeutral100
                      : TEXT_STYLES.textNeutral70,
                  ]}
                >
                  {formatCurrency(payable_amount)}
                </Text>
              </View>

              {instant_discount_breakup?.length > 0 && (
                <View>
                  {instant_discount_breakup.map((breakup, index) => {
                    return (
                      <View
                        key={index}
                        style={[
                          COMMON_STYLES.row,
                          COMMON_STYLES.justifyBetween,
                          COMMON_STYLES.alignItemsCenter,
                          index !== instant_discount_breakup.length &&
                            COMMON_STYLES["mb0.5"],
                        ]}
                      >
                        <View style={styles.width80}>
                          <Text
                            style={[
                              TYPOGRAPHY.CAPTION1,
                              VARIANTS_STYLES.SEMI_EMPHASIZED,
                              TEXT_STYLES.textNeutral90,
                            ]}
                          >
                            {breakup.title}
                          </Text>
                          <Text
                            style={[
                              TYPOGRAPHY.CAPTION1,
                              VARIANTS_STYLES.BASE,
                              TEXT_STYLES.textPrimary100,
                            ]}
                          >
                            {t("instant")}
                          </Text>
                        </View>
                        <Text
                          style={[
                            TYPOGRAPHY.CAPTION1,
                            VARIANTS_STYLES.BASE,
                            TEXT_STYLES.textPrimary100,
                          ]}
                        >
                          - {formatCurrency(breakup.discount_value)}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              )}

              {label === PAYMENT_LABEL.CASH && (
                <View style={[COMMON_STYLES.row, COMMON_STYLES.justifyBetween]}>
                  <Text
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.BASE,
                      isEnabled
                        ? TEXT_STYLES.textNeutral100
                        : TEXT_STYLES.textNeutral70,
                    ]}
                  >
                    {t("net_payable")}
                  </Text>
                  <Text
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.MEDIUM,
                      isEnabled
                        ? TEXT_STYLES.textNeutral100
                        : TEXT_STYLES.textNeutral70,
                    ]}
                  >
                    {formatCurrency(net_payable)}
                  </Text>
                </View>
              )}

              {cashback_discount_breakup?.length > 0 && (
                <View style={COMMON_STYLES["mv3/4"]}>
                  {cashback_discount_breakup.map((breakup, index) => {
                    return (
                      <View
                        key={index}
                        style={[
                          COMMON_STYLES.row,
                          COMMON_STYLES.justifyBetween,
                          COMMON_STYLES.alignItemsCenter,
                          index !== cashback_discount_breakup.length &&
                            COMMON_STYLES["mb0.5"],
                        ]}
                      >
                        <View style={styles.width80}>
                          <Text
                            style={[
                              TYPOGRAPHY.CAPTION1,
                              VARIANTS_STYLES.SEMI_EMPHASIZED,
                              TEXT_STYLES.textNeutral90,
                            ]}
                          >
                            {breakup.title}
                          </Text>
                          <Text
                            style={[
                              TYPOGRAPHY.CAPTION1,
                              VARIANTS_STYLES.BASE,
                              TEXT_STYLES.textBlue100,
                            ]}
                          >
                            {t("cashback")}
                          </Text>
                        </View>
                        <Text
                          style={[
                            TYPOGRAPHY.CAPTION1,
                            VARIANTS_STYLES.BASE,
                            TEXT_STYLES.textBlue100,
                          ]}
                        >
                          - {formatCurrency(breakup.discount_value)}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              )}
              <View
                style={[
                  COMMON_STYLES.row,
                  COMMON_STYLES.justifyBetween,
                  COMMON_STYLES["pt3/4"],
                  { borderTopWidth: 1, borderColor: COLORS.neutral20 },
                ]}
              >
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.BASE,
                    isEnabled
                      ? TEXT_STYLES.textNeutral100
                      : TEXT_STYLES.textNeutral70,
                  ]}
                >
                  {t("effective_amount")}
                </Text>
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.MEDIUM,
                    isEnabled
                      ? TEXT_STYLES.textNeutral100
                      : TEXT_STYLES.textNeutral70,
                  ]}
                >
                  {formatCurrency(effective_amount)}
                </Text>
              </View>
            </View>
          )}
        </>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  width80: {
    maxWidth: "80%",
  },
  "pb3/4": {
    paddingBottom: 12 /** Todo: Add pb3/4 to common style and replace   */,
  },
  "ph3/4": {
    paddingHorizontal: 12 /** Todo: Add ph3/4 to common style and replace   */,
  },
  savingTextStyle: {
    top: -((EM * 3) / 4),
    right: -((EM * 3) / 4),
    borderTopRightRadius: EM / 1.6,
    borderBottomLeftRadius: EM / 2,
  },
});

export default PaymentBreakupCard;
