import { ProductTags } from "@dehaat/dds";
import {
  Product as ProductDetail,
  DiscountsInfo,
  ClearanceProduct,
  ProductVariant,
  ClearanceSaleProductVariant,
  REWARD_TYPE,
  SubCategory,
  Category,
} from "./Product";
import { CART_ERROR_KEYS, MOQ_TYPE } from "../constants/Cart";
import { PRODUCT_TYPE } from "../constants/Product";

export interface SingleProduct extends CommonProductInfo {
  brand_name: string;
  pack_attribute: string;
  total_added_quantity: number;
  discounts_info: DiscountsInfo;
}

export interface ComboProduct extends CommonProductInfo {
  discount_unit_price: number;
  effective_unit_price: number;
}

export interface ClearanceItem extends CommonProductInfo {
  total_added_quantity: number;
  discount_unit_price: number;
  batch_id: string;
  product_expiry: string;
}

export interface PriceError {
  previous: number;
  current: number;
}

export interface CommonProductInfo {
  id: number;
  total_order_quantity: number;
  unit_price: number;
  payment_modes: string[];
  product_type: PRODUCT_TYPE;
  is_bundle: boolean;
  constituent_unit_price: number;
  constituent_uom: string;
  name: string;
  product_id: string;
  price_error?: PriceError;
}

//---------------------------------
export interface DeliveryInfo {
  product_id: number;
  product_name: string;
  eta_from: null | number;
  eta_to: number;
  quantity: number;
  type: string;
}

interface ApplicableDiscount {
  identifier: string;
  discount_text: string;
  total_benefit: number;
}

export interface AppliedDiscount {
  identifier: string;
  discount_text: string;
  total_benefit: number;
  is_max_offer_availed: boolean;
  discount_subtext: string;
  recommended_discount_text: string;
  discount_rate: string;
}

export enum PRODUCT_STATUS {
  INACTIVE = "inactive",
}

export interface Product {
  product_id: number;
  product_type: PRODUCT_TYPE;
  is_bundle: boolean;
  total_order_inventory: number;
  total_order_quantity: number;
  delivery_info: DeliveryInfo[];
  volume_total: number;
  constituent_unit_price: number;
  constituent_uom: string;
  applicable_discounts: ApplicableDiscount[];
  applied_discounts: AppliedDiscount[];
  moq_type: MOQ_TYPE | null;
  min_moq_value: number | null;
  max_moq_value: number | null;
  moq_label_value: string | null;
  product_status: PRODUCT_STATUS;
  payment_modes?: string[];
  unit_price?: number;
  discount_unit_price?: number | null;
  discount_constituent_unit_price?: number | null;
  cashback_per_unit?: number | null;
  untaxed_unit_price?: string | null;
  effective_unit_price: number | null;
  license_status?: string;
  payment_mode_discounts?: Record<string, any>[];
  batch_id?: string;
}

export interface ProductTag {
  batch_id: null | string;
  product_type: string;
  product_id: number;
  info_tags: ProductTags[];
  badge_tags: ProductTags[];
}

export interface Breakup {
  key: string;
  name: string;
  value: number;
}

interface CartBreakup extends Breakup {}

export interface CartDetails {
  order_total: number;
  cart_breakup: CartBreakup[];
}

export interface PaymentBreakup {
  key: string;
  name: string;
  value: number;
  type: "subtract" | "add";
  breakup?: Breakup[];
}

export interface PaymentDetails {
  amount_payable: number;
  payment_breakup: PaymentBreakup[];
}

export interface PrepaidDetails {
  payable_amount: number;
  net_payable: number;
  effective_amount: number;
  paid_using_credit_limit: number;
  remaining_used_advance_balance: number;
  total_cashback_discount: number;
  instant_discount_breakup: any[]; // You can define a specific interface if needed
  cashback_discount_breakup: {
    title: string;
    discount_text: string;
    discount_value: string;
  }[];
  savings: number;
  remaining_used_wallet_balance: number; //TODO: VERIFY USECASE
  abs_balance_used: number;
  enabled: boolean;
  disabled_reason?: string;
}

export interface CreditDetails {
  payable_amount: number;
  net_payable: number;
  effective_amount: number;
  remaining_used_advance_balance: number;
  paid_using_credit_limit: number;
  total_cashback_discount: number;
  instant_discount_breakup: any[]; // You can define a specific interface if needed
  cashback_discount_breakup: any[]; // You can define a specific interface if needed
  minimum_amount_payable: {
    prepaid_items_total: number;
    overdue: number;
    amount_exc_cred_limit: number;
  };
  remaining_used_wallet_balance: number; //TODO: VERIFY USECASEß
  abs_balance_used: number;
  enabled: boolean;
  disabled_reason?: string;
}

export interface PaymentModeBreakup {
  prepaid?: PrepaidDetails;
  credit?: CreditDetails;
}

export interface AmountDetails {
  cart_details: {
    order_total: number;
    delivery_fee: number;
    cart_breakup: CartBreakup[];
  };
  payment_details: PaymentDetails;
  gapl_advance_balance: number;
  payment_mode_breakup: PaymentModeBreakup;
}

export interface Slab {
  headers: string[];
  values: string[][];
}

export interface DiscountDetailInfo {
  is_slab_based: boolean;
  discount_ends_at: string;
  terms_and_conditions: string;
  slabs: Slab;
  reward_type: string;
  offer_type: string;
}

export interface AddItemToCart {
  id: string | number;
  product: ProductDetail | ClearanceProduct;
  batchId?: string;
}

export interface UpdateCartItem {
  id: string | number;
  qty: number;
  batchId?: string;
}

export interface DeleteCartItem {
  id: string | number;
  batchId?: string;
}

export interface Discount {
  scheme_id: string;
  scheme_name: string;
  product_template_ids: string[];
  sku_ids: string[];
  cart_benefit: string;
  type: string;
  benefit_pass_date: string | null;
}

export interface ValidateCart {
  products: Product[];
  discounts: Discount[];
  product_tags: ProductTag[];
  amount_details: AmountDetails;
  cart_id: string;
  wallet_applied: boolean;
  discount_detail_info: { [key: string]: DiscountDetailInfo };
  is_valid: boolean;
  moq_type: MOQ_TYPE.VALUE | null;
  min_moq_value: number | null;
  max_moq_value: number | null;
  any_payment_method_enabled: boolean;
  validation_errors?: ValidationError[];
}

export interface ValidationError {
  code: string;
  message: string;
}
export interface ClearanceSKUCartData extends CartItemData {
  discount_unit_price: string;
  product_expiry: string;
  batch_id: string;
}

export interface SingleSKUCartData extends CartItemData {
  discount_unit_price?: string;
  applied_discounts?: AppliedDiscount[];
}

export interface BundleSKUCartData extends CartItemData {
  discount_unit_price: string;
  effeffective_unit_price?: number;
  untaxed_unit_price?: number;
}

export interface CartItemData {
  id: string | number;
  total_order_quantity: number;
  product_type: PRODUCT_TYPE;
  payment_modes: string[];
  is_bundle: boolean;
  unit_price: string;
  delivery_fee?: string;
}

export interface CartItem {
  qty: number;
  detail: ProductDetail;
  query: SingleSKUCartData | BundleSKUCartData;
}

export interface ClearanceCartItem {
  qty: number;
  detail: ClearanceProduct;
  query: ClearanceSKUCartData;
}

export interface ErrorDescription {
  key: string;
  params?: Record<string, string>;
}

export type ErrorCardItem = Partial<Record<CART_ERROR_KEYS, ErrorDescription>>;
export interface CartSingleSKUInfo extends CartSKUInfo {
  image: string;
  variantInfo: ProductVariant;
  similarProductCount: number;
  technicalContents: string[];
  constituentUnitPrice: null | number;
  uom: string;
  discountedContituentPrice: number | null;
  rewardType?: REWARD_TYPE;
  untaxedUnitPrice: string;
  totalVolume?: number;
  moqType: MOQ_TYPE | null;
  moqLabelValue: string | null;
  appliedDiscounts?: AppliedDiscount[];
  discretionaryCouponScheme: {
    cashback_id: string;
    min_dehaat_price: string;
  } | null;
  negotiatedPrice?: string;
}
export interface CartBundleSKUInfo extends CartSKUInfo {
  untaxedUnitPrice: number;
  images: { url: string; text: string }[];
  deliveryInfo?: DeliveryInfo[];
  error?: ErrorCardItem;
  totalOrderInventory: number;
  variantInfo: null;
  appliedDiscounts?: AppliedDiscount[];
}

export interface CartClearanceSKUInfo extends CartSKUInfo {
  images: string[];
  constituentUnitPrice: number | null;
  uom: string | null;
  totalVolume: number | null;
  variantInfo: ClearanceSaleProductVariant;
  discountedPrice: number | null;
  discountedContituentPrice: number | null;
  expiryDate: string;
  productType: PRODUCT_TYPE;
  untaxedUnitPrice: string;
  batchId: string;
}

export interface CartSKUInfo {
  id: string;
  name: string;
  productStatus?: string;
  productType: PRODUCT_TYPE;
  unitPrice: number;
  deliveryFee: number;
  quantity: number;
  discountedPrice: number | null;
  deliveryInfo?: DeliveryInfo[];
  isBundle: boolean;
  paymentModes: string[];
  error?: ErrorCardItem;
  moq: number | null;
  totalOrderInventory: number;
  productId: string;
  licenseStatus?: string;
  subCategory: SubCategory;
  applicableDiscounts?: ApplicableDiscount[];
  paymentModeDiscounts?: Record<string, any>[];
  eventData: {
    bundleAvailable: boolean;
    alternateProductCount: number;
    attributes?: string | null;
    brandName: string;
    clearanceTag: boolean;
    isReturnable?: boolean | null;
    category: Category;
  };
}

export type CartData = Record<
  string,
  CartSingleSKUInfo | CartBundleSKUInfo | CartClearanceSKUInfo
>;

export enum CartType {
  ONLY_CREDIT = "Only Credit",
  ONLY_PREPAID = "Only Prepaid",
  BOTH_CREDIT_AND_PREPAID = "Both Credit And Prepaid",
}

export interface ISnackbar {
  visible: boolean;
  title?: string;
  message?: string;
}
