function formatDate2(dateString:string, daysToAdd = 0, showFullFormat = true) {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Create a Date object and add daysToAdd to it
    const date = new Date(dateString);
    if (daysToAdd) {
        date.setUTCDate(date.getUTCDate() + daysToAdd);
    }

    const dayOfWeek = daysOfWeek[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];

    if (showFullFormat) {
        const hours = date.getUTCHours() % 12 || 12; // Convert to 12-hour format
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const ampm = date.getUTCHours() >= 12 ? 'PM' : 'AM';
        return `${dayOfWeek}, ${day} ${month}, ${hours}:${minutes} ${ampm}`;
    } else {
        // Just return the day and month, e.g., "24 Sep"
        return `${day} ${month}`;
    }
}


export default formatDate2
