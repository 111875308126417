export enum SCHEME_TYPE {
    "ABS" = "ABS",
    "TBS" = "CVR"
}

export enum DISCOUNT_TYPE {
    "PER_UNIT" = "PER_UNIT",
    "PERCENT" = "PERCENT",
    "DISCOUNT_AMOUNT" = "DISCOUNT_AMOUNT"
}

export enum SLAB_TYPE {
  "VALUE" = "VALUE",
  "AMOUNT" = "AMOUNT",
  "QUANTITY" = "QUANTITY",
}