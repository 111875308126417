import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  BUTTON_STATUS,
  BUTTON_TYPE,
  COLORS,
  COMMON_STYLES,
  ProductCardHorizontal,
  ProductTags,
  Strip,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import {
  InventoryErrorModalState,
  Product,
} from "app/partner-ordering-ui/models/Product";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import { ROUTES } from "app/partner-ordering-ui/routes/types";

import { SingleProduct } from "app/partner-ordering-ui/models/Cart";
import ProductPriceInfo from "../ProductPriceInfo";
import OfferStrip from "../list/OfferStrip";
import ProductComboList from "../list/ProductComboList";
import ShimmerProductCardHz from "../../cards/ProductCardHzShimmer";
import LicenseInfoTag from "../../resuable/LicenseInfoTag";
import InsufficientInventoryModal from "../../resuable/InsufficientInventoryModal";
import { rnNavigation } from "app/utils/appConfig";
import LockIcon from "app/partner-ordering-ui/icons/LockIcon";
import {
  checkIsMultipleVariants,
  getDisplayVariant,
  getLicenceDetails,
  moqFormatter,
} from "app/partner-ordering-ui/utils/helpers/product";
import RupeeClearanceIcon from "app/partner-ordering-ui/icons/RupeeClearance";
import { useTranslation } from "react-i18next";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import {
  LICENSE_STATUS,
  PRODUCT_TYPE,
} from "app/partner-ordering-ui/constants/Product";
import fetchSimiliarProducts from "app/partner-ordering-ui/apis/fetchSimiliarProducts";
import { selectDCData } from "app/store/reducer/native";
import {
  errorArray,
  isAIMS,
} from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import InfoIcon from "app/partner-ordering-ui/icons/InfoIcon";
import { openWhatsApp } from "app/partner-ordering-ui/utils/helpers/common";

interface Props {
  id: number | string;
  technicalContent: string;
  onSelectProduct: (product: Product) => void;
  isOrderingBlocked?: boolean;
  handleRevealPriceClick?: () => void;
}

const AlternateItems = ({
  id,
  technicalContent,
  onSelectProduct,
  isOrderingBlocked = false,
  handleRevealPriceClick,
}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const { cartItems } = useCart();
  const dispatch = useAppDispatch();
  const { cartInfo } = useCart();
  const dc = useAppSelector(selectDCData);
  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });

  useEffect(() => {
    const getSimiliarItems = async () => {
      const response = await fetchSimiliarProducts(
        id,
        dc.sales_office_id,
        dc.partner_id
      );
      if (response) {
        setProducts(response.results);
      }
      setIsLoading(false);
    };
    if (!products.length) {
      getSimiliarItems();
    }
  }, []);

  const renderListItem = (product: Product, index: number) => {
    if (product.type === "bundle") {
      return <></>;
    }

    const displayVariant = getDisplayVariant(product.variants);
    const errorRemark = errorArray(displayVariant);

    const infoFromCart = cartInfo.products.find(
      (item) =>
        item.id === displayVariant.id &&
        item.product_type === PRODUCT_TYPE.REGULAR
    ) as SingleProduct;

    const quantity = cartItems[displayVariant.id]?.qty || 0;
    const id = displayVariant.id;

    const isOutOfStock =
      displayVariant.orderable_quantity <
      Number(displayVariant.min_moq_value || 1);

    const handleQtyUpdate = (qty: number) => {
      const availableQty = Math.min(
        displayVariant.orderable_quantity,
        MAX_INVENTORY_ALLOWED
      );
      if (qty <= availableQty) dispatch(updateCartItem({ id, qty }));
      else {
        dispatch(updateCartItem({ id, qty: availableQty }));
        setInventoryErrorState({
          isVisible: true,
          name: product.name,
          availableInventory: availableQty,
        });
      }
    };

    const addUnitInfoTag = (
      orderableQuantity: number,
      infoTags: ProductTags[]
    ) => {
      const unitTag: ProductTags[] = isAIMS()
        ? [
            {
              icon: <InfoIcon width={16} height={16} />,
              key: "units",
              name: `${orderableQuantity.toLocaleString("en-IN")} Units`,
              rank: 2,
            },
          ]
        : [];

      const tags: ProductTags[] = unitTag.concat(infoTags);

      return tags;
    };

    const { blockOrder, licenseStatus } = getLicenceDetails({
      categoryType: product.category.type,
      subCategoryId: product.sub_category.id,
    });

    return (
      <ProductCardHorizontal
        badgeTags={displayVariant.badge_tags}
        brandName={product.brand?.name}
        containerStyle={[COMMON_STYLES["mb1/2"], BG_STYLES.bgWhite]}
        infoTags={
          addUnitInfoTag(
            displayVariant.orderable_quantity,
            displayVariant.info_tags
          ) || []
        }
        imageUrl={product.image || ""}
        key={product.id}
        hasMultipleVariants={checkIsMultipleVariants(product)}
        onCardClick={() =>
          rnNavigation({
            name: ROUTES.PRODUCT_DETAIL,
            params: {
              id: product.id,
              variantId: id.toString(),
            },
          })
        }
        discountInfo={
          errorRemark.length > 0 ? null : displayVariant ? (
            <ProductPriceInfo
              price={displayVariant.dehaat_price}
              constituentUnitPrice={displayVariant.constituent_unit_price}
              constituentUom={displayVariant.constituent_uom}
              discountsInfo={(infoFromCart || displayVariant).discounts_info}
              priceLocked={isOrderingBlocked}
            />
          ) : undefined
        }
        productName={product.name}
        onDeleteClick={() =>
          dispatch(deleteCartItem({ id: displayVariant.id }))
        }
        remark={
          errorRemark.length > 0 ? (
            <Text
              style={[
                VARIANTS_STYLES.BASE,
                TYPOGRAPHY.CAPTION1,
                TEXT_STYLES.textError100,
              ]}
            >
              {t(errorRemark[0])}
              {errorRemark[1] ? "\n" + t(errorRemark[1]) : ""}
            </Text>
          ) : null
        }
        onPlusClick={() => dispatch(updateCartItem({ id, qty: quantity + 1 }))}
        onMinusClick={() => dispatch(updateCartItem({ id, qty: quantity - 1 }))}
        onQunatityUpdate={handleQtyUpdate}
        packAttribute={displayVariant.pack_attribute}
        unitOfMeasure={displayVariant.constituent_uom}
        quantity={quantity}
        handleVariantSelect={() => onSelectProduct(product)}
        totalAddedQuantity={infoFromCart?.total_added_quantity || 0}
        isOutOfStock={isOutOfStock && !isOrderingBlocked}
        showUploadLicenseCta={!isOrderingBlocked && blockOrder}
        onUploadLicenceCTAClick={
          !isOrderingBlocked &&
          blockOrder &&
          licenseStatus === LICENSE_STATUS.PENDING
            ? openWhatsApp
            : undefined
        }
        cta={{
          onClick: () => {
            if (isAIMS() && errorRemark.length > 0) {
              return;
            }
            if (isOrderingBlocked) {
              handleRevealPriceClick?.();
            } else {
              dispatch(addItemToCart({ id, product }));
            }
          },
          text: isOrderingBlocked ? t("price") : t("add"),
          iconRight: isOrderingBlocked ? (
            <LockIcon height={16} width={16} />
          ) : undefined,
          type: isOrderingBlocked ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY,
          buttonStyle: isOrderingBlocked
            ? BORDER_STYLES.borderBlue10
            : undefined,
          status:
            isAIMS() && errorRemark.length > 0
              ? BUTTON_STATUS.DISABLED
              : BUTTON_STATUS.ACTIVE,
        }}
        availableInventory={Math.min(
          displayVariant.orderable_quantity,
          MAX_INVENTORY_ALLOWED
        )}
        moqDisplayValue={moqFormatter(
          displayVariant.moq_label_value,
          displayVariant.moq_type,
          displayVariant.constituent_uom
        )}
        minimumOrderQuanity={
          errorRemark.length > 0
            ? 0
            : parseInt(displayVariant?.min_moq_value || "1")
        }
      >
        {product.is_clearance_sale && (
          <Strip
            heading={t("get_this_item_cheaper")}
            subHeading={t("this_item_is_available_in_clearance_sale")}
            actionTxt={t("view_sale")}
            imageOrIcon={<RupeeClearanceIcon height={16} width={12} />}
            bgColor="bgOrange20"
            textColor="orange120"
            onClick={() =>
              rnNavigation({
                name: ROUTES.CLEARANCE_SALE_LIST,
                params: {
                  id: product.id,
                },
              })
            }
            containerStyle={[COMMON_STYLES.mh1, COMMON_STYLES["mb0.5"]]}
          />
        )}

        {!isOrderingBlocked && !blockOrder && (
          <View style={[COMMON_STYLES.mh1, COMMON_STYLES.mb1]}>
            <OfferStrip
              id={product.id}
              isBundle={product.bundles_available}
              discountInfo={(infoFromCart || displayVariant).discounts_info}
              constituentUom={displayVariant.constituent_uom}
              quantity={quantity}
              onClick={(stripType) =>
                rnNavigation({
                  name: ROUTES.PRODUCT_DETAIL,
                  params: {
                    id: product.id,
                    variantId: id.toString(),
                    scrollKey: stripType,
                  },
                })
              }
            />
            {product.bundles_available && quantity > 0 && (
              <ProductComboList
                id={product.id}
                currentScreen={ROUTES.PRODUCT_SEARCH}
                eventData={{
                  product: product,
                  variant: displayVariant,
                  productPosition: index,
                }}
              />
            )}
          </View>
        )}
        {!isOrderingBlocked && blockOrder && (
          <LicenseInfoTag licenseStatus={licenseStatus} />
        )}
      </ProductCardHorizontal>
    );
  };

  return (
    <View style={BG_STYLES.bgNeutral00}>
      <View
        style={[
          COMMON_STYLES.ph1,
          COMMON_STYLES["pt3/4"],
          COMMON_STYLES["pb1/2"],
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
            TEXT_STYLES.textNeutral90,
          ]}
        >
          {t("alternate_item_with_techcontent", { technicalContent })}
        </Text>
      </View>
      {isLoading && <ShimmerProductCardHz />}
      {products.map((product, index) => renderListItem(product, index))}
      <View
        style={[
          COMMON_STYLES.fullWidth,
          COMMON_STYLES.row,
          COMMON_STYLES.alignItemsCenter,
          COMMON_STYLES.justifyCenter,
          COMMON_STYLES["pb1/2"],
          BG_STYLES.bgNeutral00,
        ]}
      >
        <View
          style={{
            height: 4,
            width: 60,
            backgroundColor: COLORS.neutral80,
            borderRadius: 16,
          }}
        />
      </View>
      <InsufficientInventoryModal
        isVisible={inventoryErrorState.isVisible}
        onCTAClick={() => setInventoryErrorState({ isVisible: false })}
        name={inventoryErrorState.name}
        availableInventory={inventoryErrorState.availableInventory}
      />
    </View>
  );
};

export default AlternateItems;
