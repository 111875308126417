import {
  BG_STYLES,
  BORDER_STYLES,
  COLORS,
  COMMON_STYLES,
  EM,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { SchemeDetailData } from "app/partner-ordering-ui/models/Scheme";
import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useAppSelector } from "app/hooks/reduxHooks";
import fetchSchemesForVariant from "app/partner-ordering-ui/apis/fetchSchemesForVariant";
import DropDownIcon from "app/partner-ordering-ui/icons/DropDownPurple";
import SchemesIcon from "app/partner-ordering-ui/icons/Schemes";
import { selectDCData } from "app/store/reducer/native";
import { useTranslation } from "react-i18next";
import TbsScheme from "./TbsScheme";

interface Props {
  variantId: string | number;
}

const Schemes = ({ variantId }: Props) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [schemes, setSchemes] = useState<SchemeDetailData[]>([]);
  const dc = useAppSelector(selectDCData);

  useEffect(() => {
    const getSchemesForVariant = async () => {
      const response = await fetchSchemesForVariant(variantId, dc.partnerId);
      if (response) {
        setSchemes(response);
      }
    };
    getSchemesForVariant();
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {schemes.length ? (
        <View style={[COMMON_STYLES.ph1, COMMON_STYLES["pb1/2"]]}>
          <View
            style={[
              COMMON_STYLES.b1,
              BORDER_STYLES.borderNeutral10,
              COMMON_STYLES["br1/2"],
              COMMON_STYLES.mt1,
            ]}
          >
            <Pressable onPress={toggleDropdown}>
              <View
                style={[
                  COMMON_STYLES.row,
                  COMMON_STYLES.alignItemsCenter,
                  BG_STYLES.bgOrange10,
                  COMMON_STYLES["p3/4"],
                  styles.topRoundedCorners,
                ]}
              >
                <SchemesIcon height={20} width={20} />

                <Text
                  style={[
                    TYPOGRAPHY.TEXT1,
                    VARIANTS_STYLES.SEMI_EMPHASIZED,
                    TEXT_STYLES.textOrange120,
                    ,
                    COMMON_STYLES["ml1/4"],
                  ]}
                >
                  {t("schemes_count", {
                    count: schemes.length,
                  })}
                </Text>
                <View style={[COMMON_STYLES.flex1]} />

                <DropDownIcon
                  svgProps={{
                    height: 24,
                    width: 24,
                    rotation: isDropdownOpen ? 0 : 180,
                  }}
                  pathProps={{ stroke: COLORS.orange120 }}
                />
              </View>
            </Pressable>
            {isDropdownOpen && (
              <>
                {schemes.map((scheme) => (
                  <TbsScheme key={scheme.id} scheme={scheme} />
                ))}
              </>
            )}
          </View>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  topRoundedCorners: {
    borderTopLeftRadius: EM / 2,
    borderTopRightRadius: EM / 2,
  },
});

export default Schemes;
