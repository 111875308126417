import * as React from "react"
import Svg, { G, Path, Defs, ClipPath, SvgProps } from "react-native-svg"

function TickIcon(props: SvgProps) {
  return (
    <Svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <G clipPath="url(#clip0_8661_13353)">
        <Path
          d="M20.753 6.751L10.25 17.253 5 12.003"
          stroke="#43A314"
          strokeWidth={1.50026}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_8661_13353">
          <Path
            fill="#fff"
            transform="translate(.5)"
            d="M0 0H24.0041V24.0041H0z"
          />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default TickIcon
