import { Platform } from "react-native";

import { navigationRef } from "app/partner-ordering-ui/utils/RootNavigation";
import { ROUTES } from "app/routes/types";
import store from "app/store";

import { clearStorage, removeCookies } from "./storage";
import keycloakLogout from "./apis/keycloakLogout";
import Config from "./config";

export const isMobile = () => {
  return Platform.OS === "android" || Platform.OS === "ios";
};

export const backHandler = () => {
  try {
    if (navigationRef.canGoBack()) {
      navigationRef.goBack();
    } else {
      window.history.back();
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const logout = () => {
  const refreshToken = store.getState().authToken.refreshToken;
  if (refreshToken) {
    // deactivate refresh token from keycloak
    keycloakLogout(refreshToken, Config.CLIENT_ID as string);
  }
  clearAuthCookies();
  clearStorage();
  navigationRef.reset({
    index: 0,
    routes: [{ name: ROUTES.LOGIN }],
  });
};

export const clearAuthCookies = () => {
  removeCookies([
    "access_token",
    "refresh_token",
    "expires_in",
    "id_token",
    "refresh_expires_in",
  ]);
};
