import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import { CartItem, Discount } from "../../models/Cart";
import SchemeRupeeIcon from "../../icons/SchemeRupee";
import { getApplicableSchemeText } from "../../utils/helpers/cart";
import { SCHEME_TYPE } from "app/partner-ordering-ui/constants/Scheme";
import {
  formatStringDate,
  toNumber,
} from "app/partner-ordering-ui/utils/helpers/date";

interface Props {
  discounts: Discount[];
  cartItems: Record<string, CartItem>;
}

const ApplicableSchemes = ({ discounts, cartItems }: Props) => {
  const { t } = useTranslation();

  if (discounts.length) {
    return (
      <View
        style={[
          BG_STYLES.bgWhite,
          COMMON_STYLES.mt1,
          COMMON_STYLES.pt1,
          styles["pb5/4"],
          COMMON_STYLES.br1,
        ]}
      >
        <View style={[styles["ph3/4"], COMMON_STYLES.divider]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            {t("applicable_schemes")}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              COMMON_STYLES["mt1/2"],
              COMMON_STYLES.pb1,
              TEXT_STYLES.textNeutral70,
            ]}
          >
            {t("cashback_quantities")}
          </Text>
        </View>
        <View style={[styles["ph3/4"], COMMON_STYLES["mt3/4"]]}>
          {discounts.map((discount, index) => (
            <View
              key={index}
              style={[
                COMMON_STYLES.row,
                COMMON_STYLES.justifyBetween,
                COMMON_STYLES.alignItemsCenter,
                index !== discounts.length - 1 && [
                  COMMON_STYLES.divider,
                  COMMON_STYLES["pb1/2"],
                ],
                index > 0 && [COMMON_STYLES["pt1/2"]],
              ]}
            >
              <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
                {discount.type === SCHEME_TYPE.ABS ? (
                  <SchemeRupeeIcon
                    stopColor1={"#1F5C99"}
                    stopColor2={"#012345"}
                  />
                ) : (
                  <SchemeRupeeIcon
                    stopColor1={"#F1AD3C"}
                    stopColor2={"#BF4B0A"}
                  />
                )}

                <View style={COMMON_STYLES["ml0.5"]}>
                  <Text
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.SEMI_EMPHASIZED,
                      TEXT_STYLES.textNeutral90,
                    ]}
                  >
                    {discount.scheme_name}
                  </Text>
                  <Text
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.BASE,
                      COMMON_STYLES.flexWrap,
                      TEXT_STYLES.textNeutral70,
                      styles.textWidth,
                    ]}
                  >
                    {t("applicable_on", {
                      products: getApplicableSchemeText(
                        discount.product_template_ids,
                        discount.sku_ids,
                        cartItems
                      ),
                    })}
                  </Text>

                  {discount.benefit_pass_date &&
                    toNumber(discount.cart_benefit) > 0 && (
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          COMMON_STYLES.flexWrap,
                          TEXT_STYLES.textNeutral70,
                          styles.textWidth,
                        ]}
                      >
                        {t("credit_note", {
                          date: formatStringDate(discount.benefit_pass_date),
                        })}
                      </Text>
                    )}

                  {toNumber(discount.cart_benefit) == 0 && (
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        COMMON_STYLES.flexWrap,
                        discount.type === SCHEME_TYPE.ABS
                          ? TEXT_STYLES.textBlue120
                          : TEXT_STYLES.textOrange100,
                        styles.textWidth,
                      ]}
                    >
                      {t("already_earned_cashback")}
                    </Text>
                  )}
                </View>
              </View>
              {discount.type === SCHEME_TYPE.ABS ? (
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.SEMI_EMPHASIZED,
                    TEXT_STYLES.textBlue120,
                  ]}
                >
                  {t("upto", { amount: formatCurrency(discount.cart_benefit) })}
                </Text>
              ) : (
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.SEMI_EMPHASIZED,
                    TEXT_STYLES.textOrange100,
                  ]}
                >
                  {formatCurrency(discount.cart_benefit)}
                </Text>
              )}
            </View>
          ))}
        </View>
      </View>
    );
  }
};

export default ApplicableSchemes;

const styles = StyleSheet.create({
  textWidth: { width: 208 },
  rupeeSymbol: { width: 16, height: 16 },
  "ph3/4": { paddingHorizontal: 12 }, //TODO: Add COMMON_STYLES['pr3/4'] in @dds/common
  "pr3/4": { paddingRight: 12 }, //TODO: Add COMMON_STYLES['ph3/4'] in @dds/common
  "pb5/4": { paddingBottom: 20 }, //TODO: Add COMMON_STYLES['pb5/4'] in @dds/common
});
