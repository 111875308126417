import React from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { BG_STYLES, COLORS, COMMON_STYLES } from "@dehaat/dds";

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
}

const ProductComboCardVerticalShimmer = ({ containerStyle }: Props) => (
  <View
    style={[
      BG_STYLES.bgWhite,
      COMMON_STYLES["p1/2"],
      styles.container,
      containerStyle,
    ]}
  >
    <SkeletonPlaceholder backgroundColor={COLORS.neutral10}>
      <View>
        <SkeletonPlaceholder.Item height={164} width={188} borderRadius={8} />
        <SkeletonPlaceholder.Item
          height={40}
          width={188}
          borderRadius={8}
          marginTop={8}
        />
        <SkeletonPlaceholder.Item
          height={40}
          width={94}
          borderRadius={8}
          marginTop={8}
        />
      </View>
    </SkeletonPlaceholder>
  </View>
);

const styles = StyleSheet.create({
  container: { width: 204, height: 362 },
});

export default ProductComboCardVerticalShimmer;
