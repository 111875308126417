import { post } from "../axios/index";
export type ACTION = "generate" | "validate";

export interface Params {
  farmer_id: number;
  templates?: {
    sms: string;
  };
  otp?: string;
}

const generateAndValidateOtp = async (action: ACTION, body: Params) => {
  return await post(`/hyperlocal/v1/otp/action/${action}`, { ...body });
};

export default generateAndValidateOtp;
