import React from "react";
import { View, Text } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  Button,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  COLORS,
} from "@dehaat/dds";

import { LICENSE_STATUS } from "app/partner-ordering-ui/constants/Product";
import LicenceUnderReviewIcon from "app/partner-ordering-ui/icons/LicenseUnderReview";
import UploadLicenceIcon from "app/partner-ordering-ui/icons/UploadLicence";
import { openWhatsApp } from "app/partner-ordering-ui/utils/helpers/common";

interface Props {
  status: string;
  isOrderBlocked?: boolean;
  featureCheck: boolean;
}

const LicenceWarningCard = ({
  status,
  featureCheck,
  isOrderBlocked,
}: Props) => {
  const { t } = useTranslation();
  if (
    status === LICENSE_STATUS.DONE || //license status is done
    (status === LICENSE_STATUS.SUBMITTED && !featureCheck) // feature check is disabled and submitted status
  ) {
    return null;
  }

  return (
    <View
      style={[
        COMMON_STYLES.p1,
        COMMON_STYLES.row,
        BG_STYLES.bgWhite,
        COMMON_STYLES["mb1/2"],
      ]}
    >
      {status === LICENSE_STATUS.PENDING ? (
        <UploadLicenceIcon
          height={24}
          width={24}
          arrowSvgColor={isOrderBlocked ? COLORS.error100 : undefined}
        />
      ) : (
        <LicenceUnderReviewIcon height={24} width={24} />
      )}

      <View style={[COMMON_STYLES["pl0.5"], COMMON_STYLES.flex1]}>
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
            status === LICENSE_STATUS.PENDING
              ? TEXT_STYLES[isOrderBlocked ? "textError100" : "textOrange100"]
              : TEXT_STYLES.textPrimary100,

            COMMON_STYLES["mb1/4"],
          ]}
        >
          {status === LICENSE_STATUS.PENDING
            ? t(isOrderBlocked ? "license_hard_block" : "license_warning")
            : t("license_in_review")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            COMMON_STYLES["mb3/4"],
          ]}
        >
          {featureCheck
            ? t("upload_license_category_warning")
            : t("upload_license_category_soft_warning")}
        </Text>
        {status === LICENSE_STATUS.PENDING && (
          <Button
            text={t("upload_license")}
            onClick={openWhatsApp}
            buttonStyle={[COMMON_STYLES.halfWidth, { alignSelf: "flex-end" }]}
          />
        )}
      </View>
    </View>
  );
};

export default LicenceWarningCard;
