import { formatCurrency } from "@dehaat/dds";
import {
  DISCOUNT_TYPE,
  SLAB_TYPE,
} from "app/partner-ordering-ui/constants/Scheme";
import {
  OrderMoreNudge,
  SchemeSlab,
} from "app/partner-ordering-ui/models/Scheme";
import { toNumber, getLastItem, getOrNull } from "./date";

export const getFormattedSchemeCashback = (
  discount: number,
  uom: string,
  discountType: string
) => {
  if (discountType == DISCOUNT_TYPE.PER_UNIT) {
    return `${formatCurrency(discount)}/${uom}`;
  } else if (discountType == DISCOUNT_TYPE.PERCENT) {
    return `${discount}%`;
  } else if (discountType == DISCOUNT_TYPE.DISCOUNT_AMOUNT) {
    return formatCurrency(discount);
  }
};

export const getFormattedValueToReachNextSlab = (
  value: number,
  uom: string,
  slabType: string
) => {
  if (slabType == SLAB_TYPE.VALUE) {
    return formatCurrency(value);
  }
  if (slabType == SLAB_TYPE.AMOUNT || slabType == SLAB_TYPE.QUANTITY) {
    return `${value} ${uom}`;
  }
};

export const getCurrentOrderingSlab = (
  slabs: SchemeSlab[],
  delivered: string,
  tobeDelivered: string
) => {
  const totalOrderedQty = toNumber(delivered) + toNumber(tobeDelivered);

  let finalSlab = slabs.find((slab) => {
    const minKey = toNumber(slab.min_key);
    const maxKey = slab.max_key
      ? toNumber(slab.max_key)
      : Number.MAX_SAFE_INTEGER;
    return totalOrderedQty >= minKey && totalOrderedQty <= maxKey;
  });

  if (!finalSlab) {
    slabs.forEach((slab) => {
      const maxKey = slab.max_key
        ? toNumber(slab.max_key)
        : Number.MAX_SAFE_INTEGER;
      if (totalOrderedQty > maxKey) {
        finalSlab = slab;
      }
    });
  }

  return finalSlab;
};

export const getFormattedCurrentSlabCashback = (
  slabs: SchemeSlab[],
  delivered: string,
  tobeDelivered: string,
  uom: string,
  discountType: string
) => {
  const currentSlab = getCurrentOrderingSlab(slabs, delivered, tobeDelivered);

  return getFormattedSchemeCashback(
    currentSlab?.discount_value ?? 0,
    uom,
    discountType
  );
};

export const getFormattedLastSlabCashback = (
  slabs: SchemeSlab[],
  uom: string,
  discountType: string
) => {
  return getFormattedSchemeCashback(
    getLastItem(slabs)?.discount_value ?? 0,
    uom,
    discountType
  );
};

export const getOrderMoreNudge = (
  slabs: SchemeSlab[],
  delivered: string,
  tobeDelivered: string,
  uom: string,
  discountType: string,
  slabType: string
): OrderMoreNudge => {
  const totalOrderedQty = toNumber(delivered) + toNumber(tobeDelivered);

  const currentSlab = getCurrentOrderingSlab(slabs, delivered, tobeDelivered);
  const currentSlabIndex = slabs.findIndex(
    (slab) => slab.id === currentSlab?.id
  );
  const nextSlab = getOrNull(slabs, currentSlabIndex + 1);
  const lastSlab = getLastItem(slabs);
  const valueToReachNextSlab =
    toNumber(nextSlab?.min_key || "0") - totalOrderedQty;

  return {
    currentCashback: getFormattedSchemeCashback(
      currentSlab?.discount_value ?? 0,
      uom,
      discountType
    ),
    nextCashBack: getFormattedSchemeCashback(
      nextSlab?.discount_value ?? 0,
      uom,
      discountType
    ),
    valueToReachNextSlab: getFormattedValueToReachNextSlab(
      valueToReachNextSlab,
      uom,
      slabType
    ),
    lastSlabCashback: getFormattedSchemeCashback(
      lastSlab?.discount_value ?? 0,
      uom,
      discountType
    ),
    isLastSlab: currentSlab?.id == lastSlab?.id,
    anySlabReached: currentSlabIndex != -1,
    uom: uom,
  };
};
