import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  BORDER_STYLES,
  BUTTON_STATUS,
  BUTTON_TYPE,
  Button,
  COMMON_STYLES,
  PlusMinusButtonWithInput,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import { errorArray } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";

import { getUnitPriceLabel } from "../../utils/helpers/product";
import { BlurPrice } from "../../assets/images";
import LockIcon from "../../icons/LockIcon";
import { MAX_INVENTORY_ALLOWED } from "../../constants/Common";
import Cashback from "../resuable/Cashback";

interface Props {
  discountPrice?: number | null;
  price: string | number;
  cashback?: number | null;
  uom?: string;
  constituentUnitPrice?: number;
  totalAddedQuantity?: number;
  isOutOfStock?: boolean;
  quantity: number;
  updateQuantityCallBack: (qty: number) => void;
  onDeleteBtnClick: () => void;
  moq?: number;
  isPriceLocked?: boolean;
  onRelvealPriceClick?: () => void;
  moqDisplayLabel?: string;
  avilableInventory?: number;
  unit?: "unit" | "combo";
  untaxedDehaatPrice?: string;
  dehaatPrice?: string;
}

const VariantCard = ({
  discountPrice,
  price,
  cashback = 0,
  constituentUnitPrice = 0,
  totalAddedQuantity = 0,
  uom = "",
  isOutOfStock = false,
  quantity,
  updateQuantityCallBack,
  onDeleteBtnClick,
  moq = 1,
  isPriceLocked = false,
  onRelvealPriceClick,
  moqDisplayLabel,
  avilableInventory = MAX_INVENTORY_ALLOWED,
  unit,
  untaxedDehaatPrice,
  dehaatPrice,
}: Props) => {
  const { t } = useTranslation();
  const errorMassageParames = {
    untaxed_dehaat_price: untaxedDehaatPrice,
    dehaat_price: dehaatPrice,
  };
  const errorRemark = errorArray(errorMassageParames);
  return (
    <View
      style={[
        COMMON_STYLES["p3/4"],
        COMMON_STYLES["mv1/2"],
        ...(errorRemark.length > 0
          ? [
              BORDER_STYLES.borderNeutral10,
              COMMON_STYLES.b1,
              COMMON_STYLES["br1/2"],
            ]
          : [COMMON_STYLES["br1/4"], BG_STYLES.bgNeutral00]),
      ]}
    >
      <View
        style={[
          COMMON_STYLES.row,
          COMMON_STYLES.justifyBetween,
          COMMON_STYLES.alignItemsCenter,
        ]}
      >
        {isPriceLocked ? (
          <Image
            source={BlurPrice}
            style={[
              COMMON_STYLES.fullHeight,
              COMMON_STYLES.halfWidth,
              styles.maxWidthBlurPrice,
            ]}
          />
        ) : (
          <View>
            <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
              <Text
                style={[
                  TEXT_STYLES.textNeutral100,
                  TYPOGRAPHY.HEADING3,
                  VARIANTS_STYLES.SEMI_EMPHASIZED,
                ]}
              >
                {dehaatPrice === "0.00" || dehaatPrice === null
                  ? t("price_not_available")
                  : formatCurrency(discountPrice ? discountPrice : price)}
              </Text>
              {discountPrice ? (
                <Text
                  style={[
                    TEXT_STYLES.textNeutral40,
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.BASE,
                    COMMON_STYLES["ml1/4"],
                    COMMON_STYLES.strikeText,
                  ]}
                >
                  {formatCurrency(price)}
                </Text>
              ) : null}
            </View>
            {constituentUnitPrice ? (
              <Text
                style={[
                  COMMON_STYLES["mt1/4"],
                  TEXT_STYLES.textNeutral100,
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                ]}
              >
                {getUnitPriceLabel(constituentUnitPrice, uom)}
              </Text>
            ) : null}
            <Cashback amount={cashback} unit={unit} />
          </View>
        )}
        <View
          style={[
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES.justifyCenter,
            COMMON_STYLES.row,
          ]}
        >
          {isOutOfStock ? (
            <Text
              style={[
                TYPOGRAPHY.TEXT1,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textError100,
              ]}
            >
              {t("out_of_stock")}
            </Text>
          ) : (
            <View style={[COMMON_STYLES.alignItemsEnd]}>
              {!(errorRemark.length > 0) && moq > 1 ? (
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION2,
                    VARIANTS_STYLES.BASE,
                    TEXT_STYLES.textNeutral80,
                    COMMON_STYLES["mb1/4"],
                  ]}
                >
                  {t("min_order_with_value", { value: moqDisplayLabel })}
                </Text>
              ) : null}

              {quantity ? (
                <PlusMinusButtonWithInput
                  onDeleteClick={onDeleteBtnClick}
                  onMinusClick={() => updateQuantityCallBack(quantity - 1)}
                  onPlusClick={() => updateQuantityCallBack(quantity + 1)}
                  onQuantityUpdate={updateQuantityCallBack}
                  quantity={quantity}
                  style={styles.w140}
                  minimumQty={moq}
                  maximumQty={Math.min(
                    avilableInventory,
                    MAX_INVENTORY_ALLOWED
                  )}
                />
              ) : (
                <Button
                  type={
                    isPriceLocked ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY
                  }
                  text={isPriceLocked ? t("price") : t("add")}
                  onClick={() => {
                    if (errorRemark.length <= 0) {
                      isPriceLocked
                        ? onRelvealPriceClick?.()
                        : updateQuantityCallBack(1);
                    }
                    return null;
                  }}
                  buttonStyle={styles.w140}
                  iconRight={
                    isPriceLocked ? <LockIcon height={16} width={16} /> : null
                  }
                  status={
                    errorRemark.length > 0
                      ? BUTTON_STATUS.DISABLED
                      : BUTTON_STATUS.ACTIVE
                  }
                />
              )}
              {totalAddedQuantity && quantity > 0 ? (
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION2,
                    VARIANTS_STYLES.BASE,
                    TEXT_STYLES.textNeutral80,
                    COMMON_STYLES["mt1/4"],
                  ]}
                >
                  {t("added_qty", { totalAddedQuantity, uom })}
                </Text>
              ) : null}
            </View>
          )}
        </View>
      </View>
      {errorRemark.length > 0 ? (
        <Text
          style={[
            VARIANTS_STYLES.BASE,
            TYPOGRAPHY.CAPTION1,
            TEXT_STYLES.textError100,
          ]}
        >
          {t(errorRemark[0]) || null}
          {errorRemark[1] ? "\n" + t(errorRemark[1]) : null}
        </Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  w140: {
    width: 140,
  },
  maxWidthBlurPrice: {
    maxWidth: 60,
  },
});

export default VariantCard;
