import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import inputConfig from "../partner-ordering-ui/utils/axios/config";
import authAxiosInstance from "./axios/auth/config";
import khetiAxiosInstance from "../sell-to-farmer/utils/axios/config";

interface InterceptorsCb {
  requestInterceptorSuccessCb?: (config: AxiosRequestConfig<any>) => void;
  requestInterceptorErrCb?: (error: any) => void;
  responseInterceptorSuccessCb?: (config: AxiosResponse<any>) => void;
  responseInterceptorErrCb?: (error: any) => void;
}

export interface InputAxiosConfig extends InterceptorsCb {
  appCode: string;
  inputUrl: string;
}
export interface KhetiAxiosConfig extends InterceptorsCb {
  appCode: string;
  khetiUrl: string;
}

export interface AuthAxiosConfig extends InterceptorsCb {
  authUrl: string;
}

function setUpInterceptors(
  instance: AxiosInstance,
  requestInterceptorSuccessCb?: (config: AxiosRequestConfig<any>) => void,
  requestInterceptorErrCb?: (error: any) => void,
  responseInterceptorSuccessCb?: (config: AxiosResponse<any>) => void,
  responseInterceptorErrCb?: (error: any) => void
) {
  instance.interceptors.request.use(
    (config) => {
      requestInterceptorSuccessCb && requestInterceptorSuccessCb({ ...config });
      return config;
    },
    (error) => {
      requestInterceptorErrCb && requestInterceptorErrCb(error);
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(
    (response) => {
      responseInterceptorSuccessCb && responseInterceptorSuccessCb(response);
      if (response.data.error == null) {
        return response;
      }
      return Promise.reject(response.data.error);
    },
    (error) => {
      responseInterceptorErrCb && responseInterceptorErrCb(error);
      return Promise.reject(error);
    }
  );
}

export function configureInputAxiosInstance(config: InputAxiosConfig) {
  const {
    appCode,
    inputUrl,
    requestInterceptorSuccessCb,
    requestInterceptorErrCb,
    responseInterceptorSuccessCb,
    responseInterceptorErrCb,
  } = config;
  inputConfig.defaults.baseURL = inputUrl;
  inputConfig.defaults.headers.common["APP-CODE"] = appCode;
  setUpInterceptors(
    inputConfig,
    requestInterceptorSuccessCb,
    requestInterceptorErrCb,
    responseInterceptorSuccessCb,
    responseInterceptorErrCb
  );
}

export function configureAuthAxiosInstance(config: AuthAxiosConfig) {
  const {
    authUrl,
    requestInterceptorSuccessCb,
    requestInterceptorErrCb,
    responseInterceptorSuccessCb,
    responseInterceptorErrCb,
  } = config;
  authAxiosInstance.defaults.baseURL = authUrl;
  setUpInterceptors(
    authAxiosInstance,
    requestInterceptorSuccessCb,
    requestInterceptorErrCb,
    responseInterceptorSuccessCb,
    responseInterceptorErrCb
  );
}

export function configureKhetiAxiosInstance(config: KhetiAxiosConfig) {
  const {
    appCode,
    khetiUrl,
    requestInterceptorSuccessCb,
    requestInterceptorErrCb,
    responseInterceptorSuccessCb,
    responseInterceptorErrCb,
  } = config;
  khetiAxiosInstance.defaults.baseURL = khetiUrl;
  khetiAxiosInstance.defaults.headers.common["APP-CODE"] = appCode;

  setUpInterceptors(
    khetiAxiosInstance,
    requestInterceptorSuccessCb,
    requestInterceptorErrCb,
    responseInterceptorSuccessCb,
    responseInterceptorErrCb
  );
}
