import React from "react";
import { Path, Rect, Svg, SvgProps } from "react-native-svg";

const HandIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 32 32" width="32" height="32" fill="none" {...props}>
      <Rect width="32" height="32" rx="16" fill="#F5CED3" />
      <Path
        d="M24.25 13.75v4.5a8.25 8.25 0 1 1-16.5 0V10a1.5 1.5 0 0 1 3 0v5.25a.75.75 0 1 0 1.5 0V7a1.5 1.5 0 1 1 3 0v7.5a.75.75 0 1 0 1.5 0v-6a1.5 1.5 0 1 1 3 0v7.563A4.508 4.508 0 0 0 16 20.5a.75.75 0 1 0 1.5 0 3 3 0 0 1 3-3 .75.75 0 0 0 .75-.75v-3a1.5 1.5 0 1 1 3 0Z"
        fill="#CC293F"
      />
    </Svg>
  );
};

export default HandIcon;