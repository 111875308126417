import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COLORS,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import { Coin } from "app/partner-ordering-ui/assets/images";

interface Props {
  walletBalance?: number;
  isWalletApplied: boolean;
  saving?: number;
  payableAmount?: number;
  onWalletCheck?: () => void;
}

const CartDynamicFooter = ({
  walletBalance = 0,
  isWalletApplied,
  saving,
  payableAmount = 0,
  onWalletCheck,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {walletBalance || saving ? (
        <View
          style={[
            BG_STYLES.bgBlue20,
            COMMON_STYLES["pv1/4"],
            COMMON_STYLES.alignItemsCenter,
          ]}
        >
          {walletBalance ? (
            <BouncyCheckbox
              isChecked={isWalletApplied}
              onPress={onWalletCheck}
              text={t("wallet_balance_message", {
                balance: formatCurrency(walletBalance),
              })}
              size={20}
              style={COMMON_STYLES["pv1/4"]}
              fillColor={COLORS.primary100}
              unFillColor={COLORS.white}
              iconStyle={COMMON_STYLES["br1/4"]}
              innerIconStyle={COMMON_STYLES["br1/4"]}
              textStyle={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textBlue120,
                styles.textDecorationNone,
              ]}
            />
          ) : null}
          {saving ? (
            <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
              <Image source={Coin} style={styles.image20x20} />
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.SEMI_EMPHASIZED,
                  TEXT_STYLES.textBlue120,
                  COMMON_STYLES["ml1/4"],
                ]}
              >
                {t("extra_discount_available", {
                  amount: formatCurrency(saving),
                })}
              </Text>
            </View>
          ) : null}
        </View>
      ) : null}
      {payableAmount ? (
        <View
          style={[
            BG_STYLES.bgWhite,
            COMMON_STYLES.ph1,
            styles.pv10,
            COMMON_STYLES.row,
            COMMON_STYLES.justifyBetween,
            COMMON_STYLES.alignItemsCenter,
          ]}
        >
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            {t("payable_amount")}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral100,
            ]}
          >
            {formatCurrency(payableAmount)}
          </Text>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  textDecorationNone: { textDecorationLine: "none" },
  image20x20: { width: 20, height: 20 },
  pv10: {
    paddingVertical: 10,
  },
});

export default CartDynamicFooter;
