import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { BottomDrawer, COMMON_STYLES } from "@dehaat/dds";
import {
  ClearanceProduct,
  Product,
  ProductVariant,
} from "../../models/Product";
import { ROUTES } from "../../routes/types";
import { rnNavigation } from "../../../utils/appConfig";

import OfferStrip from "./list/OfferStrip";
import VariantCard from "./VariantCard";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "../../../store/reducer/native";
import {
  fireProductClickedEvent,
  fireProductVariantChangeEvent,
} from "app/partner-ordering-ui/utils/helpers/mixpanel";
import { MIXPANEL_PROPERTIES } from "app/partner-ordering-ui/constants/eventNames";
import { getDisplayVariant } from "app/partner-ordering-ui/utils/helpers/product";
import { errorArray } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import Cashback from "../resuable/Cashback";
import { SCREEN_HEIGHT } from "app/partner-ordering-ui/utils/helpers/common";

interface Props {
  visible: boolean;
  onClose: () => void;
  variants: ProductVariant[];
  productImageUrl: string;
  productName: string;
  isBundle?: boolean;
  productId: string;
  product: Product | ClearanceProduct;
}

const VariantSelectDropDown = ({
  visible,
  onClose,
  variants,
  productImageUrl,
  productName,
  isBundle = false,
  productId,
  product,
}: Props) => {
  const dc = useAppSelector(selectDCData);
  const displayVariant = getDisplayVariant(product.variants);

  const onCardClick = (variant: ProductVariant, index: number) => {
    onClose();
    rnNavigation({
      name: ROUTES.PRODUCT_DETAIL,
      params: { id: product.id, variantId: variant.id.toString() },
    });

    fireProductClickedEvent({
      product: product,
      variant: variant,
      productPosition: undefined,
      isPriorityDc: dc?.is_priority_dc,
      isOfferStrip: true,
    });
    fireProductVariantChangeEvent({
      selectedVariant: variant,
      prevVariant: displayVariant,
      screenName: ROUTES.PRODUCT_LIST,
      isPriorityDc: dc?.is_priority_dc,
    });
  };

  const onOfferStripClick = (variant: ProductVariant, stripType: string) => {
    onClose();
    rnNavigation({
      name: ROUTES.PRODUCT_DETAIL,
      params: {
        id: productId,
        variantId: variant.id.toString(),
        scrollKey: stripType,
      },
    });
    fireProductClickedEvent({
      product: product,
      variant: variant,
      isPriorityDc: dc?.is_priority_dc,
      isOfferStrip: true,
      navigationFrm: MIXPANEL_PROPERTIES.OFFER_STRIP,
    });
    fireProductVariantChangeEvent({
      selectedVariant: variant,
      prevVariant: displayVariant,
      screenName: ROUTES.PRODUCT_LIST,
      isPriorityDc: dc?.is_priority_dc,
    });
  };

  return (
    <BottomDrawer
      heading={"Other Variants"}
      onClose={onClose}
      visible={visible}
      modalStyle={styles.modalStyle}
    >
      <ScrollView style={COMMON_STYLES["ph1/2"]}>
        {variants.map((variant, index) => {
          const isOutOfStock =
            variant.orderable_quantity < Number(variant.min_moq_value || 1);
          const errorRemark = errorArray(variant);

          if (!isOutOfStock)
            return (
              <VariantCard
                key={variant.id}
                conatinerStyle={[COMMON_STYLES["mb1/2"]]}
                imageUrl={productImageUrl}
                variantName={productName}
                packAttribute={variant.pack_attribute}
                discountedPrice={
                  errorRemark.length > 0
                    ? null
                    : variant.discounts_info?.discount_unit_price
                }
                remark={errorRemark.length > 0 ? errorRemark : null}
                price={variant.dehaat_price}
                unitOfMeasure={variant.constituent_uom}
                unitPrice={
                  variant.discounts_info?.discount_constituent_unit_price ||
                  variant.constituent_unit_price ||
                  null
                }
                isPriceLocked={dc?.app_blocked}
                onCardClick={() => onCardClick(variant, index)}
              >
                <Cashback amount={variant?.discounts_info?.cashback_per_unit} />
                {!dc.app_blocked && (
                  <View style={[COMMON_STYLES["mt1/2"]]}>
                    <OfferStrip
                      id={productId}
                      isBundle={isBundle}
                      discountInfo={variant.discounts_info}
                      constituentUom={variant.constituent_uom}
                      quantity={0}
                      onClick={(stripType) =>
                        onOfferStripClick(variant, stripType)
                      }
                    />
                  </View>
                )}
              </VariantCard>
            );
        })}
      </ScrollView>
    </BottomDrawer>
  );
};

const styles = StyleSheet.create({
  modalStyle: { height: "100%", maxHeight: SCREEN_HEIGHT * 0.7 },
});
export default VariantSelectDropDown;
