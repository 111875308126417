import React from "react";
import { StyleSheet, Text, View, TextStyle } from "react-native";
import { useTranslation } from "react-i18next";
import {
  COLORS,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import RenderBreakup from "./RenderBreakup";
import { PAYMENT_BREAKUP_KEYS } from "app/partner-ordering-ui/constants/Cart";
import { PaymentBreakup } from "app/partner-ordering-ui/models/Cart";

interface Props {
  orderTotal: number;
  amountPayable: number;
  paymentBreakup: PaymentBreakup[];
  cartBreakup: { key: string; name: string; value: number }[];
  deliveryFee: number;
  fireEvent: () => void;
}

const OrderDetail = ({
  orderTotal,
  cartBreakup,
  amountPayable,
  paymentBreakup,
  deliveryFee,
  fireEvent,
}: Props) => {
  const { t } = useTranslation();

  const Row = ({
    label,
    value,
    textColor,
  }: {
    label: string;
    value: string | number;
    textColor?: TextStyle;
  }) => {
    return (
      <View
        style={[
          COMMON_STYLES.row,
          COMMON_STYLES.justifyBetween,
          COMMON_STYLES["mt3/4"],
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            textColor,
          ]}
        >
          {label}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            textColor,
          ]}
        >
          {formatCurrency(value)}
        </Text>
      </View>
    );
  };

  const creditNodeBreakup = paymentBreakup.find(
    (breakup) => breakup.key === PAYMENT_BREAKUP_KEYS.CASHBACK
  );

  return (
    <View style={styles.container}>
      <Text
        style={[
          TYPOGRAPHY.TEXT1,
          VARIANTS_STYLES.SEMI_EMPHASIZED,
          TEXT_STYLES.textNeutral90,
          COMMON_STYLES["mb1/2"],
        ]}
      >
        {t("order_details")}
      </Text>
      <RenderBreakup
        label={t("item_total_gst")}
        value={orderTotal}
        breakup={cartBreakup}
        fireEvent={fireEvent}
      />

      {deliveryFee !== 0 && (
        <RenderBreakup
          label={t("delivery_charges")}
          value={deliveryFee}
          fireEvent={fireEvent}
        />
      )}

      {paymentBreakup.map((breakup) => {
        if (breakup.key === PAYMENT_BREAKUP_KEYS.DISCOUNT && breakup.value) {
          return (
            <RenderBreakup
              key={breakup.key}
              label={breakup.name}
              value={breakup.value}
              breakup={breakup.breakup}
              fireEvent={fireEvent}
              textStyle={TEXT_STYLES.textPrimary100}
            />
          );
        }
        if (breakup.key === PAYMENT_BREAKUP_KEYS.OVERDUE && breakup.value) {
          return (
            <Row
              key={breakup.key}
              label={breakup.name}
              value={breakup.value}
              textColor={TEXT_STYLES.textNeutral80}
            />
          );
        }
        return null;
      })}

      <View
        style={[
          COMMON_STYLES["mt3/4"],
          COMMON_STYLES["pt3/4"],
          {
            borderTopWidth: 1,
            borderStyle: "dashed",
            borderColor: COLORS.neutral20,
          },
        ]}
      >
        <View style={[COMMON_STYLES.row, COMMON_STYLES.justifyBetween]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {t("payable_amount")}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {formatCurrency(amountPayable)}
          </Text>
        </View>

        {creditNodeBreakup?.value ? (
          <Row
            label={t("cashback_credit_note")}
            value={creditNodeBreakup.value}
            textColor={TEXT_STYLES.textBlue100}
          />
        ) : null}
      </View>
    </View>
  );
};

export default OrderDetail;

const styles = StyleSheet.create({
  container: {
    ...COMMON_STYLES.ph1,
    ...COMMON_STYLES.pv1,
    backgroundColor: COLORS.white,
    ...COMMON_STYLES.mt1,
    ...COMMON_STYLES.br1,
  },
  arrowStyle: { width: 16, height: 16 },
  greenTextColor: { color: "#2E9940" },
  borderStyle: {
    borderBottomWidth: 1,
    borderStyle: "dashed",
    borderColor: "#cccccc",
  },
});
