import { useCallback, useEffect, useState } from "react";
import fetchOrders from "../utils/apis/fetchOrders";
import { Order } from "../models/orders";
import { fixNextPageUrl } from "../utils/helpers/fixNextPageUrl";

const useFarmerOrders = (
  orderType?: string,
  status?: string,
  startDate?: string,
  endDate?: string,
  lang = "en",
) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [page, setPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>("");

  const fetchNext = useCallback(async () => {
    if (nextPageUrl) {
      try {
        setLoadingNext(true);
        const response = await fetchOrders(
          page + 1,
          orderType,
          status,
          startDate,
          endDate
        );
        if (response) {
          setOrders((prev) => prev.concat(response.results));
          setPage((prev) => prev + 1);
          setNextPageUrl(fixNextPageUrl(response.next) || null);
        }
      } catch (e) {
        console.log("Error fetching next page data", e);
      } finally {
        setLoadingNext(false);
      }
    }
  }, [nextPageUrl]);

  const getOrders = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchOrders(
        1,
        orderType,
        status,
        startDate,
        endDate
      );
      if (response) {
        setOrders(response.results);
        setNextPageUrl(fixNextPageUrl(response.next) || null);
      }
    } catch (e) {
      console.log("Error in fetching orders", e);
    } finally {
      setLoading(false);
    }
  }, [orderType, status, startDate, endDate, lang]);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return {
    fetchNext,
    orders,
    loading,
    loadingNext,
  };
};

export default useFarmerOrders;
