import { setSessionExpired } from "app/partner-ordering-ui/store/reducer/app";
import store from "../../../store";
import { setToken } from "../../../store/reducer/native";
import {
  refreshAuthToken,
  showSessionExpiryDialog,
} from "../../../utils/appConfig";

// refresh token logic
// reset the stack and take user to login screen if response if falsy.
const refreshToken = async (failedRequest?: any) => {
  let logoutReason = "";
  try {
    const response = await refreshAuthToken();
    if (response) {
      const { accessToken } = JSON.parse(response);
      if (accessToken) {
        if (failedRequest) {
          failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
        }
        store.dispatch(setToken(accessToken));
        return Promise.resolve();
      } else {
        logoutReason = "Access Token Recieved Undefined";
        throw logoutReason;
      }
    } else {
      logoutReason = "Token Not Received";
      throw logoutReason;
    }
  } catch (err) {
    if (!logoutReason) logoutReason = "Refresh Token Failed";
    store.dispatch(setSessionExpired(true));
    showSessionExpiryDialog();
    throw new Error(logoutReason);
  }
};

export default refreshToken;
