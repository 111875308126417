import { get } from "app/partner-ordering-ui/utils/axios/index";

interface Response {
  partner_id: number;
  display_banner: boolean;
}

const fetchGrowthBannerData = async () => {
  const url = `/sale-management/growth-plan-banner/v1`;
  try {
    const response = await get<{ data: Response }>(url);
    return response.data;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default fetchGrowthBannerData;
