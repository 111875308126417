import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  ImageSourcePropType,
  LayoutRectangle,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  CHIP_STATES,
  COMMON_STYLES,
  Carousel,
  Chip,
  DefaultProductImage,
  Label,
  LazyImage,
  Strip,
  TEXT_STYLES,
  TYPOGRAPHY,
  Tooltip,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import CheckoutStrip from "app/partner-ordering-ui/components/CheckoutStrip";
import fetchProductDetail from "app/partner-ordering-ui/apis/fetchProductDetail";
import {
  InventoryErrorModalState,
  Product,
  ProductVariant,
  REWARD_TYPE,
  Slab,
} from "app/partner-ordering-ui/models/Product";
import VariantCard from "app/partner-ordering-ui/components/productDetail/VariantCard";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import OfferAndDiscount from "app/partner-ordering-ui/components/productDetail/OfferAndDiscount";
import {
  ProductDetailScreenProps,
  ROUTES,
} from "app/partner-ordering-ui/routes/types";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import {
  CartBundleSKUInfo,
  CartClearanceSKUInfo,
  CartSingleSKUInfo,
  SingleProduct,
  CartItem,
} from "app/partner-ordering-ui/models/Cart";
import ComboOffers from "app/partner-ordering-ui/components/productDetail/ComboOffers";
import SimiliarProductsList from "app/partner-ordering-ui/components/productDetail/SimiliarProductList";
import { DIMENSION_WIDTH } from "app/partner-ordering-ui/utils/helpers/common";
import ArrowIcon from "app/partner-ordering-ui/icons/Arrow2";
import AlternateItemsIcon from "app/partner-ordering-ui/icons/AlternateItem";
import ImageBtn from "app/partner-ordering-ui/components/productDetail/ImageBtn";
import ComboItemsIcon from "app/partner-ordering-ui/icons/Combo";
import VariantChipBadge from "app/partner-ordering-ui/components/productDetail/ChipBadge";
import { selectDCData } from "app/store/reducer/native";
import OutstandingCard from "app/partner-ordering-ui/components/cards/OutstandingCard";
import OutstandingAmountDrawer from "app/partner-ordering-ui/components/drawers/OutstandingAmountDrawer";
import LicenceWarningCard from "app/partner-ordering-ui/components/cards/LicenceWarningCard";
import {
  getDisplayVariant,
  getLicenceDetails,
  moqFormatter,
} from "app/partner-ordering-ui/utils/helpers/product";
import ZoomLensIcon from "app/partner-ordering-ui/icons/ZoomLens";
import ModalImageCarousal from "app/partner-ordering-ui/components/resuable/ModalImageCarousal";
import RupeeClearanceIcon from "app/partner-ordering-ui/icons/RupeeClearance";
import {
  PRODUCT_TYPE,
  SCROLL_VIEW_KEYS,
  SLAB_DISCOUNT_TYPE,
} from "app/partner-ordering-ui/constants/Product";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import { startOutstandingAmountPaymentFlow } from "app/utils/appConfig";
import ToastAndroid from "app/mocks/ToastAndroid";

import ComboBottomDrawer from "../components/drawers/ComboBottomDrawer";
import InsufficientInventoryModal from "../components/resuable/InsufficientInventoryModal";
import InfoIcon from "../icons/InfoIcon";
import { isAIMS } from "../utils/helpers/isAIMSApp";
import {
  fireClickToZoomImage,
  fireClickedViewAlternateQuickLink,
  fireClickedViewCombosQuickLink,
  fireProductDescriptionViewEvent,
  fireProductVariantChangeEvent,
  fireRemoveCartItem,
} from "../utils/helpers/mixpanel";
import { fireProductAddToCartEvent } from "../utils/helpers/mixpanel";
import LogErrorModal from "../components/LogError";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import ProductInventoryBottomDrawer from "../components/productList/ProductInventoryBottomDrawer";
import Schemes from "../components/productDetail/Schemes";

export interface DiscountInfoToolTip {
  show: boolean;
  slab: Slab | null;
  prepaid: boolean;
  rewardType: REWARD_TYPE;
}

const defaultTooltipState: DiscountInfoToolTip = {
  show: false,
  slab: null,
  prepaid: false,
  rewardType: "CASHBACK",
};

const initialOffset: Record<SCROLL_VIEW_KEYS, LayoutRectangle | null> = {
  [SCROLL_VIEW_KEYS.ALTERNATE]: null,
  [SCROLL_VIEW_KEYS.COMBO]: null,
  [SCROLL_VIEW_KEYS.DISCOUNT]: null,
  [SCROLL_VIEW_KEYS.SCROLL_VIEW]: null,
};

const ProductDetail = ({ route, navigation }: ProductDetailScreenProps) => {
  const { t } = useTranslation();
  const { id, variantId, scrollKey, navigationFrom } = route.params;
  const [loading, setLoading] = useState(true);
  const [productDetail, setProductDetail] = useState<Product | null>();
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
  const [selectedProductInventory, setSelectedProductInventory] =
    useState(false);
  const { cartItems, cartInfo, cartData } = useCart();
  const scrollViewRef = useRef<ScrollView>(null);

  const [yOffsets, setYOffsets] =
    useState<Record<SCROLL_VIEW_KEYS, LayoutRectangle | null>>(initialOffset);

  const [tooltip, setTooltip] =
    useState<DiscountInfoToolTip>(defaultTooltipState);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isbottomDrawerVisible, setIsBottomDrawerVisible] = useState(false);
  const [isBottomDrawerSuggested, setIsBottomDrawerSuggested] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const dc = useAppSelector(selectDCData);
  const [error, setError] = useState("");

  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });

  const infoFromCart = cartInfo.products.find(
    (p) =>
      p.id === selectedVariant?.id && p.product_type === PRODUCT_TYPE.REGULAR
  ) as SingleProduct;

  //@TODO: Discuss the different cases with DBA Team and update the logic
  const getTooltipContent = () => {
    if (tooltip.slab?.slab_id) {
      return `Order at least ${
        tooltip.slab?.slab_uom === SLAB_DISCOUNT_TYPE.rupee
          ? `${tooltip.slab?.slab_uom}${tooltip.slab?.min_qty}`
          : `${tooltip.slab?.min_qty} ${tooltip.slab?.slab_uom}`
      } ${
        tooltip.prepaid ? " & pay while ordering" : ""
      } to save ${formatCurrency(tooltip.slab?.discount_per_unit!)}  ${
        tooltip.slab?.uom ? `per ${tooltip.slab.uom}` : ""
      } ${tooltip.rewardType === "INSTANT" ? "instantly" : ""}`;
    }
    return `Order at least ${selectedVariant?.pack_attribute} ${
      tooltip.prepaid ? "& pay while ordering" : ""
    } to save ${formatCurrency(tooltip.slab?.discount_per_unit!)}${
      tooltip.slab?.uom ? ` per ${tooltip.slab?.uom}` : ""
    } ${tooltip.rewardType === "INSTANT" ? "instantly" : ""}`;
  };

  const updateYOffset = (layout: LayoutRectangle, key: SCROLL_VIEW_KEYS) => {
    setYOffsets((prev) => ({ ...prev, [key]: layout }));
  };

  const fetchAndUpdateProductDetails = async () => {
    setLoading(true);
    try {
      const response = await fetchProductDetail(
        id,
        dc.sales_office_id,
        dc.partner_id
      );
      if (response.results[0]) {
        setProductDetail(response.results[0]);
      } else {
        ToastAndroid.show("No result found", ToastAndroid.SHORT);
      }
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  };

  useObserverInternetActivity();

  useEffect(() => {
    fetchAndUpdateProductDetails();
  }, []);

  useEffect(() => {
    if (productDetail?.id && productDetail.variants.length) {
      let selectedVariant = productDetail.variants[0];
      if (variantId) {
        const variant = productDetail.variants.find(
          (v) => v.id.toString() === variantId
        );
        if (variant) {
          selectedVariant = variant;
        }
      }
      setSelectedVariant(selectedVariant);
      fireProductDescriptionViewEvent({
        product: productDetail,
        variant: selectedVariant,
        prevStep: navigationFrom,
        isPriorityDc: dc?.is_priority_dc,
      });
    }
  }, [productDetail?.id]);

  useEffect(() => {
    if (
      scrollKey &&
      yOffsets[scrollKey] &&
      yOffsets[SCROLL_VIEW_KEYS.SCROLL_VIEW]
    ) {
      handleLayoutScrollToY(yOffsets[scrollKey]!);
    }
  }, [yOffsets]);

  const handleImageClick = () => {
    setIsVisible(true);
    fireClickToZoomImage({
      screenName: ROUTES.PRODUCT_DETAIL,
      product: productDetail!,
      variant: selectedVariant!,
      isPriorityDc: dc?.is_priority_dc,
    });
  };

  const handleLayoutScrollToY = (layout: LayoutRectangle) => {
    const { y } = layout;
    scrollViewRef.current?.scrollTo({ y: y, animated: true });
  };

  if (loading) {
    return <Spinner type="on-screen" />;
  }

  if (error) {
    return (
      <LogErrorModal
        visible={!!error}
        message={error}
        onClose={() => setError("")}
      />
    );
  }

  if (productDetail) {
    const { licenseStatus, blockOrder, featureCheck } = getLicenceDetails({
      categoryType: productDetail.category.type,
      subCategoryId: productDetail.sub_category.id,
    });

    const handleQtyUpdate = (qty: number) => {
      if (selectedVariant) {
        if (
          isBottomDrawerSuggested &&
          cartItems[selectedVariant.id]?.qty === undefined
        ) {
          setIsBottomDrawerVisible(true);
          setIsBottomDrawerSuggested(false);
        }
        if (qty === 1) {
          dispatch(
            addItemToCart({
              id: selectedVariant.id,
              product: productDetail,
            })
          );
          fireProductAddToCartEvent({
            product: productDetail,
            variant: selectedVariant,
            isPriorityDc: dc?.is_priority_dc,
            productPosition: 0,
            screenName: ROUTES.PRODUCT_DETAIL,
          });
        } else {
          const availableQty = Math.min(
            selectedVariant.orderable_quantity,
            MAX_INVENTORY_ALLOWED
          );
          if (qty <= availableQty)
            dispatch(updateCartItem({ id: selectedVariant.id, qty }));
          else {
            dispatch(
              updateCartItem({ id: selectedVariant.id, qty: availableQty })
            );
            setInventoryErrorState({
              isVisible: true,
              name: productDetail.name,
              availableInventory: availableQty,
            });
          }
        }
      }
    };

    const onPressCombo = () => {
      yOffsets?.combo && handleLayoutScrollToY(yOffsets.combo);
      fireClickedViewCombosQuickLink({
        isPriorityDc: dc?.is_priority_dc,
        product: productDetail,
        variant: getDisplayVariant(productDetail.variants),
        screenName: ROUTES.PRODUCT_DETAIL,
      });
    };

    const onPressAlternate = () => {
      yOffsets?.alternate && handleLayoutScrollToY(yOffsets.alternate);
      fireClickedViewAlternateQuickLink({
        isPriorityDc: dc?.is_priority_dc,
        product: productDetail,
        variant: getDisplayVariant(productDetail.variants),
        screenName: ROUTES.PRODUCT_DETAIL,
      });
    };

    const onDelete = () => {
      if (selectedVariant) {
        dispatch(deleteCartItem({ id: selectedVariant.id }));
        let removedData:
          | CartSingleSKUInfo
          | CartBundleSKUInfo
          | CartClearanceSKUInfo
          | undefined;
        let removedItem: Product | undefined;
        cartData &&
          Object.values(cartData)?.forEach((item) => {
            if (parseInt(item.id) === selectedVariant.id) {
              removedData = item;
            }
          });
        cartItems &&
          Object.values(cartItems)?.forEach((item: CartItem) => {
            if (item?.query?.id === selectedVariant.id) {
              removedItem = item?.detail;
            }
          });
        fireRemoveCartItem({
          removedData: removedData,
          removedItem: removedItem,
          screenName: ROUTES.PRODUCT_DETAIL,
          isPriorityDc: dc?.is_priority_dc,
        });
      }
    };

    return (
      <View style={COMMON_STYLES.flex1}>
        {dc?.app_blocked ? (
          <OutstandingCard
            onCardClick={() => setOpen(true)}
            days={dc.app_block_days}
            amount={dc.app_block_amount}
            onCTAClick={() =>
              startOutstandingAmountPaymentFlow(dc.app_block_amount)
            }
          />
        ) : null}
        <View>
          <ScrollView
            ref={scrollViewRef}
            style={BG_STYLES.bgWhite}
            onLayout={(e) =>
              updateYOffset(e.nativeEvent.layout, SCROLL_VIEW_KEYS.SCROLL_VIEW)
            }
          >
            <View style={[COMMON_STYLES.relative]}>
              <Carousel
                data={[productDetail.image]}
                renderItem={(imageUrl, index) => (
                  <TouchableOpacity
                    key={index}
                    onPress={handleImageClick}
                    style={[
                      COMMON_STYLES.pv1,
                      COMMON_STYLES.alignItemsCenter,
                      COMMON_STYLES.fullWidth,
                      { width: DIMENSION_WIDTH },
                    ]}
                  >
                    <LazyImage
                      imageUrl={imageUrl || ""}
                      fallBackIcon={
                        <Image
                          source={DefaultProductImage as ImageSourcePropType}
                          style={styles.image}
                        />
                      }
                      imgStyles={styles.image}
                    />
                    <ZoomLensIcon
                      width={34}
                      height={34}
                      style={[COMMON_STYLES.absolute, styles.zoomStyle]}
                    />
                  </TouchableOpacity>
                )}
              />
              <ModalImageCarousal
                isVisible={isVisible}
                onClose={() => setIsVisible(false)}
                images={[productDetail.image]}
              />
              <View
                style={[
                  productDetail?.similar_product_count &&
                  productDetail.bundles_available
                    ? COMMON_STYLES.justifyCenter
                    : COMMON_STYLES.justifyStart,
                  COMMON_STYLES.fullHeight,
                  COMMON_STYLES.absolute,
                  COMMON_STYLES.pv1,
                  styles.imageBtnContainer,
                ]}
              >
                {productDetail?.similar_product_count ? (
                  <ImageBtn
                    icon={<AlternateItemsIcon width={18} height={18} />}
                    name={t("view_alternate")}
                    onPress={onPressAlternate}
                    style={
                      !productDetail.bundles_available
                        ? COMMON_STYLES.mt1
                        : null
                    }
                  />
                ) : null}
                {productDetail.bundles_available ? (
                  <ImageBtn
                    icon={<ComboItemsIcon width={18} height={18} />}
                    name={t("view_combo")}
                    onPress={onPressCombo}
                    style={COMMON_STYLES.mt1}
                  />
                ) : null}
              </View>
            </View>

            <View
              style={[
                COMMON_STYLES.ph1,
                COMMON_STYLES.pb1,
                COMMON_STYLES.divider4x,
              ]}
            >
              {selectedVariant?.badge_tags[0] ? (
                <View style={[COMMON_STYLES.row, COMMON_STYLES["mb0.5"]]}>
                  <Label
                    text={selectedVariant?.badge_tags[0].name}
                    bgColor="bgYellow20"
                  />
                </View>
              ) : undefined}

              <Text
                style={[
                  TEXT_STYLES.textNeutral80,
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                ]}
              >
                {productDetail.brand?.name}
              </Text>
              <Text
                style={[
                  TEXT_STYLES.textNeutral90,
                  TYPOGRAPHY.TEXT1,
                  VARIANTS_STYLES.EMPHASIZED,
                ]}
              >
                {productDetail.name}
              </Text>
              {selectedVariant && selectedVariant.info_tags.length > 0 ? (
                <View style={[COMMON_STYLES.row, COMMON_STYLES["mt1/2"]]}>
                  {selectedVariant.info_tags.map((tag) => (
                    <Label
                      key={tag.key}
                      text={tag.name}
                      bgColor="bgNeutral10"
                      textColor="textNeutral80"
                      leftIcon={
                        <LazyImage
                          imageUrl={tag.icon as string}
                          imgStyles={{ height: 16, width: 16 }}
                        />
                      }
                    />
                  ))}
                </View>
              ) : null}
            </View>

            {licenseStatus ? (
              <View style={COMMON_STYLES.divider4x}>
                <LicenceWarningCard
                  status={licenseStatus}
                  isOrderBlocked={blockOrder && !dc.app_blocked}
                  featureCheck={featureCheck}
                />
              </View>
            ) : null}
            {!(blockOrder && !dc.app_blocked) && (
              <>
                <View
                  style={[
                    COMMON_STYLES.pl1,
                    COMMON_STYLES.pb1,
                    COMMON_STYLES["pt1/2"],
                  ]}
                >
                  <View
                    style={[
                      COMMON_STYLES.row,
                      COMMON_STYLES.alignItemsCenter,
                      COMMON_STYLES["mb1/4"],
                    ]}
                  >
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION2,
                        VARIANTS_STYLES.BASE,
                        COMMON_STYLES["mr1/4"],
                      ]}
                    >
                      {t("select_pack_size")}
                    </Text>
                    <ArrowIcon height={12} width={17} />
                  </View>

                  <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                    <View style={[COMMON_STYLES["mt1/2"], COMMON_STYLES.row]}>
                      {productDetail.variants.map((variant, index) => (
                        <View key={index} style={[COMMON_STYLES.relative]}>
                          <Chip
                            label={variant.pack_attribute}
                            onClick={() => {
                              setSelectedVariant(variant);
                              setTooltip(defaultTooltipState);
                              if (variant.id !== selectedVariant?.id) {
                                fireProductVariantChangeEvent({
                                  selectedVariant: variant,
                                  prevVariant: selectedVariant,
                                  isPriorityDc: dc?.is_priority_dc,
                                  screenName: ROUTES.PRODUCT_DETAIL,
                                  previousStep: navigationFrom,
                                });
                              }
                            }}
                            state={
                              variant.id === selectedVariant?.id
                                ? CHIP_STATES.ACTIVE
                                : variant.orderable_quantity > 0
                                ? CHIP_STATES.INACTIVE
                                : CHIP_STATES.DISABLED
                            }
                          />
                          {cartItems[variant.id]?.qty > 0 && (
                            <VariantChipBadge
                              qty={cartItems[variant.id].qty}
                              style={styles.badgePosition}
                            />
                          )}
                        </View>
                      ))}
                    </View>
                  </ScrollView>

                  {isAIMS() && selectedVariant?.orderable_quantity ? (
                    <TouchableOpacity
                      onPress={() => setSelectedProductInventory(true)}
                    >
                      <View
                        style={[
                          COMMON_STYLES.row,
                          COMMON_STYLES["pt1/2"],
                          COMMON_STYLES.justifyStart,
                        ]}
                      >
                        <InfoIcon
                          height={16}
                          width={16}
                          style={COMMON_STYLES["mr1/4"]}
                        />
                        <Text
                          style={[
                            TYPOGRAPHY.CAPTION1,
                            VARIANTS_STYLES.BASE,
                            TEXT_STYLES.textNeutral80,
                          ]}
                        >
                          {t("available_inventory")}:{" "}
                        </Text>
                        <Text
                          style={[
                            TYPOGRAPHY.CAPTION1,
                            VARIANTS_STYLES.MEDIUM,
                            TEXT_STYLES.textNeutral80,
                          ]}
                        >
                          {selectedVariant.orderable_quantity.toLocaleString(
                            "en-IN"
                          )}{" "}
                          {t("units")}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  ) : null}
                </View>
                {selectedVariant ? (
                  <View style={COMMON_STYLES.ph1}>
                    <VariantCard
                      key={selectedVariant?.id}
                      discountPrice={
                        (infoFromCart || selectedVariant)?.discounts_info
                          ?.discount_unit_price
                      }
                      constituentUnitPrice={
                        (infoFromCart || selectedVariant)?.discounts_info
                          ?.discount_constituent_unit_price ||
                        (infoFromCart || selectedVariant).constituent_unit_price
                      }
                      cashback={
                        (infoFromCart || selectedVariant)?.discounts_info
                          ?.cashback_per_unit
                      }
                      totalAddedQuantity={infoFromCart?.total_added_quantity}
                      price={selectedVariant.dehaat_price}
                      uom={(infoFromCart || selectedVariant)?.constituent_uom}
                      quantity={cartItems[selectedVariant.id]?.qty}
                      updateQuantityCallBack={handleQtyUpdate}
                      onDeleteBtnClick={() => onDelete()}
                      isOutOfStock={
                        selectedVariant.orderable_quantity <
                          Number(selectedVariant.min_moq_value || 1) &&
                        !dc.app_blocked
                      }
                      moq={parseInt(selectedVariant?.min_moq_value || "1")}
                      moqDisplayLabel={moqFormatter(
                        selectedVariant?.moq_label_value,
                        selectedVariant?.moq_type,
                        selectedVariant?.constituent_uom
                      )}
                      isPriceLocked={dc?.app_blocked}
                      onRelvealPriceClick={() => {
                        if (!isAIMS()) setOpen(true);
                      }}
                      avilableInventory={Math.min(
                        selectedVariant.orderable_quantity,
                        MAX_INVENTORY_ALLOWED
                      )}
                      unit="unit"
                      untaxedDehaatPrice={selectedVariant.untaxed_dehaat_price}
                      dehaatPrice={selectedVariant.dehaat_price}
                    />
                    {tooltip.show && (
                      <View style={COMMON_STYLES["mb0.5"]}>
                        <Tooltip
                          onClose={() => setTooltip(defaultTooltipState)}
                          text={getTooltipContent()} //@todo: update logic to get content
                          bgColor="purple120"
                        />
                      </View>
                    )}
                    {productDetail.is_clearance_sale ? (
                      <Strip
                        heading={t("get_this_item_cheaper")}
                        subHeading={t(
                          "this_item_is_available_in_clearance_sale"
                        )}
                        actionTxt={t("view_sale")}
                        imageOrIcon={
                          <RupeeClearanceIcon height={16} width={12} />
                        }
                        bgColor="bgOrange20"
                        textColor="orange120"
                        onClick={() =>
                          navigation.navigate(ROUTES.CLEARANCE_SALE_LIST, {
                            id: productDetail.id,
                          })
                        }
                      />
                    ) : null}
                    {!dc?.app_blocked &&
                    !(
                      selectedVariant.orderable_quantity <
                      Number(selectedVariant.min_moq_value || 1)
                    ) &&
                    selectedVariant.discounts_info?.discounts?.length ? (
                      <>
                        <View
                          style={[COMMON_STYLES.pt1, COMMON_STYLES["pb1/2"]]}
                          onLayout={(e) =>
                            updateYOffset(
                              e.nativeEvent.layout,
                              SCROLL_VIEW_KEYS.DISCOUNT
                            )
                          }
                        >
                          <OfferAndDiscount
                            discountsInfo={
                              infoFromCart?.discounts_info ||
                              selectedVariant.discounts_info
                            }
                            uom={selectedVariant.constituent_uom}
                            setTooltip={(
                              slab: Slab,
                              rewardType: REWARD_TYPE,
                              prepaid: boolean
                            ) =>
                              setTooltip({
                                show: true,
                                slab,
                                rewardType,
                                prepaid,
                              })
                            }
                          />
                        </View>
                      </>
                    ) : null}
                  </View>
                ) : null}

                {selectedVariant ? (
                  <Schemes
                    key={selectedVariant.id}
                    variantId={selectedVariant.id}
                  />
                ) : null}

                {productDetail.bundles_available && (
                  <ComboOffers
                    id={productDetail.id}
                    captureYOffset={(layout) =>
                      updateYOffset(layout, SCROLL_VIEW_KEYS.COMBO)
                    }
                    isOrderingBlocked={dc.app_blocked}
                    handleRevealPriceClick={() => {
                      if (!isAIMS()) setOpen(true);
                    }}
                  />
                )}

                {productDetail.similar_product_count ? (
                  <SimiliarProductsList
                    id={productDetail.id}
                    noOfAlternateProducts={productDetail.similar_product_count}
                    technicalContents={productDetail.technical_contents}
                    captureYOffset={(layout) =>
                      updateYOffset(layout, SCROLL_VIEW_KEYS.ALTERNATE)
                    }
                    parentProductVariant={selectedVariant}
                    navigationFrom={navigationFrom || ""}
                  />
                ) : null}
              </>
            )}
            {productDetail.description ? (
              <View style={COMMON_STYLES.p1}>
                <Text
                  style={[
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.SEMI_EMPHASIZED,
                    TEXT_STYLES.textNeutral100,
                  ]}
                >
                  {t("description")}
                </Text>
                <Text
                  style={[
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.BASE,
                    COMMON_STYLES.mb1,
                    TEXT_STYLES.textNeutral90,
                  ]}
                >
                  {productDetail.description}
                </Text>
              </View>
            ) : null}
          </ScrollView>
        </View>

        <CheckoutStrip landingFrom={ROUTES.PRODUCT_DETAIL} />
        <OutstandingAmountDrawer
          days={dc.app_block_days}
          amount={dc.app_block_amount}
          isOpen={open}
          onClose={() => setOpen(false)}
          onCtaClick={() => {
            startOutstandingAmountPaymentFlow(dc.app_block_amount);
            setOpen(false);
          }}
        />
        {productDetail.bundles_available && (
          <ComboBottomDrawer
            id={productDetail.id}
            displayVariantId={selectedVariant?.id!}
            isOpen={isbottomDrawerVisible}
            onClose={() => setIsBottomDrawerVisible(false)}
          />
        )}
        {selectedVariant && (
          <InsufficientInventoryModal
            isVisible={inventoryErrorState.isVisible}
            onCTAClick={() => setInventoryErrorState({ isVisible: false })}
            name={inventoryErrorState.name}
            availableInventory={inventoryErrorState.availableInventory}
          />
        )}
        <ProductInventoryBottomDrawer
          visible={selectedProductInventory}
          onClose={() => setSelectedProductInventory(false)}
          preOrderQuantity={selectedVariant?.pre_order_quantity}
          totalQuantity={selectedVariant?.total_quantity}
        />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  image: { height: 128, width: 128 },
  imageBtnContainer: {
    right: 32,
  },
  badgePosition: {
    right: 8,
    top: -8,
  },
  zoomStyle: { bottom: 0, left: "60%" },
});

export default ProductDetail;
