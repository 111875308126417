import React from "react";
import { COMMON_STYLES, Strip, formatCurrency } from "@dehaat/dds";
import { useTranslation } from "react-i18next";
import { SCROLL_VIEW_KEYS } from "app/partner-ordering-ui/constants/Product";
import RupeeSymbolIcon from "app/partner-ordering-ui/icons/RupeeSymbol";
import { DiscountsInfo } from "app/partner-ordering-ui/models/Product";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";

interface Props {
  id: string;
  isBundle: boolean;
  discountInfo?: DiscountsInfo | null;
  constituentUom: string;
  quantity?: number;
  onClick?: (stripType: SCROLL_VIEW_KEYS) => void;
}

const OfferStrip = ({
  id,
  isBundle,
  discountInfo,
  constituentUom,
  quantity = 0,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  let heading = "";
  let cta = "";

  if (isBundle && quantity === 0) {
    heading = t("get_discount_with_combo");
    cta = t("view_combos");
  }

  if (discountInfo?.best_price) {
    heading = `Get for ${formatCurrency(discountInfo.best_price)}`;
    if (discountInfo?.best_constituent_unit_price && constituentUom) {
      heading =
        heading +
        ` @${formatCurrency(
          discountInfo.best_constituent_unit_price
        )}/${constituentUom}`;
    }
    if (isBundle) {
      cta = t("view_offers_and_combos");
    } else cta = t("view_offers");
  }

  const getSlabActionText = () => {
    const slab = discountInfo?.remaining_no_of_slabs;
    if (slab) {
      return `+${slab} more slab${slab > 1 ? "s" : ""}`;
    }
    return;
  };

  return (
    <>
      {discountInfo?.recommended_text && quantity > 0 ? (
        <Strip
          bgColor={
            discountInfo?.is_max_discount_achieved
              ? "bgSuccess100"
              : "bgPurple100"
          }
          containerStyle={COMMON_STYLES["mb1/2"]}
          heading={discountInfo.recommended_text}
          onClick={() => onClick?.(SCROLL_VIEW_KEYS.DISCOUNT)}
          actionTxt={getSlabActionText()}
          imageOrIcon={<RupeeSymbolIcon height={12} width={12} />}
        />
      ) : null}
      {!!heading && (
        <Strip
          bgColor={"bgPurple100"}
          heading={heading}
          actionTxt={cta}
          onClick={() =>
            onClick?.(
              cta.includes("Offers")
                ? SCROLL_VIEW_KEYS.DISCOUNT
                : SCROLL_VIEW_KEYS.COMBO
            )
          }
          imageOrIcon={<RupeeSymbolIcon height={12} width={12} />}
          stripLabel={
            isAIMS() &&
            discountInfo?.best_price_applicable_channels?.includes("DBA") &&
            !discountInfo?.best_price_applicable_channels?.includes("AIMS")
              ? t("applicable_only_on_dba")
              : undefined
          }
        />
      )}
    </>
  );
};

export default OfferStrip;
