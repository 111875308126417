import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COLORS,
  COMMON_STYLES,
  CrossIcon,
  FloatingLabelInput,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import BackCaret from "app/partner-ordering-ui/icons/BackCaret";
import { SCREEN_WIDTH } from "app/partner-ordering-ui/utils/helpers/common";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import OutstandingAmountDrawer from "app/partner-ordering-ui/components/drawers/OutstandingAmountDrawer";
import {
  logEvent,
  startOutstandingAmountPaymentFlow,
} from "app/utils/appConfig";

import { LocalSearchScreenProps, ROUTES } from "../routes/types";
import { Product } from "../models/Product";
import RenderProductList from "../components/productList/list";
import {
  MIXPANEL_EVENT,
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "../constants/eventNames";
import { isAIMS } from "../utils/helpers/isAIMSApp";

const LocalSearch = ({ route, navigation }: LocalSearchScreenProps) => {
  const { itemList, searchKey, navigatingFrm } = route?.params;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [filterItemList, setFilteredItemList] = useState(itemList);

  const dc = useAppSelector(selectDCData);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (itemList?.length > 0) {
      const filterList = itemList?.filter((item: Record<string, string>) =>
        item[searchKey]?.toLowerCase().startsWith(inputValue.toLowerCase())
      );
      setFilteredItemList(filterList);
    }
  }, [inputValue]);

  const onInputFocus = () => {
    const mixPanelPayload = {
      [MIXPANEL_KEYS.SCREEN_NAME]: ROUTES.LOCAL_SEARCH,
      [MIXPANEL_KEYS.APP_PAGE]: navigatingFrm,
      [MIXPANEL_KEYS.SEARCH_TYPE]: [MIXPANEL_PROPERTIES.SEARCH_TYPE_TEXT],
    };
    logEvent(
      MIXPANEL_EVENT.PRODUCT_SEARCH_INITIATED,
      JSON.stringify(mixPanelPayload)
    );
  };

  return (
    <>
      <View style={[COMMON_STYLES.flex1, BG_STYLES.bgWhite]}>
        <View style={[COMMON_STYLES.m1]}>
          <FloatingLabelInput
            leftIcon={<BackCaret width={24} height={24} strokeWidth={2} />}
            rightIcon={
              inputValue.length > 0 && <CrossIcon width={18} height={18} />
            }
            onRightIconClick={() => setInputValue("")}
            onLeftIconClick={() => navigation.goBack()}
            placeholder={t("search_placeholder_text")}
            value={inputValue}
            onChangeText={(val: string) => setInputValue(val)}
            placeholderTextColor={COLORS.neutral70}
            varient="search"
            onFocus={() => {
              setInputValue("");
            }}
            onPressIn={() => onInputFocus()}
          />
        </View>
        {
          <>
            {filterItemList.length ? (
              <RenderProductList
                products={filterItemList as Product[]}
                isOrderingBlocked={dc.app_blocked}
                handleRevealPriceClick={() => {
                  if (!isAIMS()) setOpen(true);
                }}
                loadingNext={false}
                fetchNext={() => {}}
                showAlternateItems={false}
                showComboDrawer={false}
                navigatingFrom={ROUTES.LOCAL_SEARCH}
                getProducts={() => {}}
              />
            ) : (
              <Text
                style={[
                  TYPOGRAPHY.TEXT1,
                  VARIANTS_STYLES.MEDIUM,
                  TEXT_STYLES.textBlack,
                  COMMON_STYLES.textCenter,
                ]}
              >
                {t("no_offer_product")}
              </Text>
            )}
          </>
        }
        <OutstandingAmountDrawer
          days={dc.app_block_days}
          amount={dc.app_block_amount}
          isOpen={open}
          onClose={() => setOpen(false)}
          onCtaClick={() => {
            startOutstandingAmountPaymentFlow(dc.app_block_amount);
            setOpen(false);
          }}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  cross: {
    padding: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    height: 32,
    width: 48,
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderLeftColor: COLORS.neutral20,
    borderLeftWidth: 1,
  },
  inputContainer: {
    width: SCREEN_WIDTH - 40,
    height: 42,
    marginVertical: 16,
    paddingHorizontal: 0,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: COLORS.neutral20,
    backgroundColor: COLORS.white,
    marginHorizontal: 4,
  },
});

export default LocalSearch;
