import React, { useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import { startOutstandingAmountPaymentFlow } from "app/utils/appConfig";

import { CustomProductListScreenProps, ROUTES } from "../routes/types";
import { CustomProductListParams } from "../models/CustomList";
import RenderProductList from "../components/productList/list";
import useSearch, { SearchQuery } from "../hooks/useSearch";
import ShimmerProductCardHz from "../components/cards/ProductCardHzShimmer";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import OutstandingCard from "../components/cards/OutstandingCard";
import OutstandingAmountDrawer from "../components/drawers/OutstandingAmountDrawer";
import { isAIMS } from "../utils/helpers/isAIMSApp";
import {updateSchemeId} from "app/partner-ordering-ui/store/reducer/cart";
import { useTranslation } from "react-i18next";


const CustomProductList = ({
  route,
  navigation,
}: CustomProductListScreenProps) => {
  const { params } = route;
  let listParams: null | CustomProductListParams;
  const dc = useAppSelector(selectDCData);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  let query: SearchQuery = {};

  if (params) {
    listParams = params as CustomProductListParams;
    if (listParams.ids) {
      query.id__in = listParams.ids;
    } 
    else if (listParams.variants_ids) {
      query.variant_id__in = listParams.variants_ids
    }
    if (isAIMS()) {
      query.partner_id = dc.partner_id;
    }
  }
  const { products, fetchNext, loading, loadingNext, getProducts } = useSearch(
    query,
    false
  );

  const getTitle = () => {
    if (listParams?.scheme_id) {
      return isAIMS() ? `Sale \n${dc.name}` : "Products included in scheme";
    } else {
      return listParams?.title;
    }
  }

  useEffect(() => {
    if (listParams) {
      navigation.setOptions({
        title: getTitle(),
      });

      if (listParams.scheme_id) {
        dispatch(
          updateSchemeId({
            schemeId: listParams.scheme_id
          })
        );
      }
    }
    return () => {
      dispatch(updateSchemeId({
        schemeId: null
      }))
    }
  }, []);

  useObserverInternetActivity();

  return (
    <>
      {dc.app_blocked ? (
        <OutstandingCard
          onCardClick={() => setOpen(true)}
          days={dc.app_block_days}
          amount={dc.app_block_amount}
          onCTAClick={() =>
            startOutstandingAmountPaymentFlow(dc.app_block_amount)
          }
        />
      ) : null}
      {loading ? (
        <ShimmerProductCardHz noOfShimmers={5} />
      ) : (
        <RenderProductList
          loadingNext={loadingNext}
          fetchNext={fetchNext}
          products={products}
          navigatingFrom={ROUTES.CUSTOM_PRODUCTS_LIST}
          getProducts={() =>
            getProducts(undefined, undefined, undefined, undefined, query)
          }
          isOrderingBlocked={dc.app_blocked}
          handleRevealPriceClick={() => {
            if (!isAIMS()) setOpen(true);
          }}
        />
      )}
      <OutstandingAmountDrawer
        days={dc.app_block_days}
        amount={dc.app_block_amount}
        isOpen={open}
        onClose={() => setOpen(false)}
        onCtaClick={() => {
          startOutstandingAmountPaymentFlow(dc.app_block_amount);
          setOpen(false);
        }}
      />
    </>
  );
};

export default CustomProductList;
