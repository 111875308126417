import { API } from "app/partner-ordering-ui/models/API";
import { post } from "app/partner-ordering-ui/utils/axios/index";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import { logAPIExceptionAtSentry } from "../utils/helpers/common";

export interface Response {
  url: string;
  success_url: string;
  failure_url: string;
  pending_url: string;
  pg_type: string;
  session_id: string;
}

export interface Request {
  entity_id?: number | null;
  entity_type: string;
}

const createSession = async (
  entityId: number | null = null,
  partnerId?: string,
  totalAmount?: number,
  entityType: string = "order_request"
) => {
  const url = "/payments/transactions";
  try {
    const response = await post<API<Partial<Response>>>(url, {
      entity_id: entityId,
      entity_type: entityType,
      total_amount: totalAmount,
      partner_id: partnerId,
      channel: "Mobile",
      platform: isAIMS() ? "AIMS" : "DBA",
      lob: "Input",
    });
    return response.data;
  } catch (e) {
    logAPIExceptionAtSentry(e);
    console.error(e);
  }
  return null;
};

export default createSession;
