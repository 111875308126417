import React, { useEffect, useState } from "react";
import {
  Image,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import {
  ProductDetailScreenProps,
  ROUTES,
} from "app/partner-ordering-ui/routes/types";
import {
  BG_STYLES,
  COMMON_STYLES,
  Carousel,
  DefaultProductImage,
  ImageWithUnitLabel,
  Label,
  LazyImage,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import CheckoutStrip from "app/partner-ordering-ui/components/CheckoutStrip";
import fetchProductDetail from "app/partner-ordering-ui/apis/fetchProductDetail";
import {
  BundledProduct,
  InventoryErrorModalState,
  Product,
} from "app/partner-ordering-ui/models/Product";
import VariantCard from "app/partner-ordering-ui/components/productDetail/VariantCard";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import {
  DIMENSION_WIDTH,
  SCREEN_WIDTH,
} from "app/partner-ordering-ui/utils/helpers/common";
import { useTranslation } from "react-i18next";
import {
  rnNavigation,
  startOutstandingAmountPaymentFlow,
} from "app/utils/appConfig";
import { selectDCData } from "app/store/reducer/native";
import { MAX_INVENTORY_ALLOWED } from "../constants/Common";
import InsufficientInventoryModal from "../components/resuable/InsufficientInventoryModal";
import OutstandingCard from "../components/cards/OutstandingCard";
import OutstandingAmountDrawer from "../components/drawers/OutstandingAmountDrawer";
import ZoomLensIcon from "../icons/ZoomLens";
import ModalImageCarousal from "../components/resuable/ModalImageCarousal";
import {
  fireClickToZoomImage,
  fireComboProductAddToCartEvent,
} from "../utils/helpers/mixpanel";
import { MIXPANEL_KEYS } from "../constants/eventNames";
import {
  getCombinedLicenseDetails,
  getDisplayVariant,
} from "../utils/helpers/product";
import LicenceWarningCard from "../components/cards/LicenceWarningCard";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import OfferAndDiscount from "app/partner-ordering-ui/components/productDetail/OfferAndDiscount";
import { isAIMS } from "../utils/helpers/isAIMSApp";

const ComboDetailPage = ({ route }: ProductDetailScreenProps) => {
  const id = route.params.id;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [productDetail, setProductDetail] = useState<Product | null>();
  const [isImageModalVisible, setImageModalVisibility] = useState(false);
  const { cartItems, updateCartInfo, getNumberOfItemsInCart, resetCartItems } =
    useCart();

  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dc = useAppSelector(selectDCData);

  useObserverInternetActivity();

  useEffect(() => {
    const fetchAndUpdateProductDetails = async () => {
      setLoading(true);
      const response = await fetchProductDetail(id, dc.sales_office_id);
      setProductDetail(response ? response.results[0] : null);
      setLoading(false);
    };
    fetchAndUpdateProductDetails();
  }, []);

  if (loading) {
    return <Spinner type="on-screen" />;
  }

  if (productDetail) {
    const {
      id,
      actual_price,
      discounted_price,
      bundled_products,
      orderable_quantity,
      discounts_info,
      constituent_uom,
    } = productDetail?.bundles[0];
    if (bundled_products) {
      const { licenseStatus, blockOrder, featureCheck } =
        getCombinedLicenseDetails(bundled_products);
      const handleQtyUpdate = (qty: number) => {
        if (qty === 1) {
          dispatch(
            addItemToCart({
              id,
              product: productDetail,
            })
          );
          fireComboProductAddToCartEvent(
            {
              product: productDetail?.bundles[0],
              isPriorityDc: dc?.is_priority_dc,
              productPosition: 0,
              screenName: ROUTES.COMBO_DETAIL,
            },
            {
              [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]:
                productDetail?.similar_product_count,
              [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: false,
              [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: false,
            }
          );
        } else {
          const availableQty = Math.min(
            MAX_INVENTORY_ALLOWED,
            orderable_quantity
          );
          if (qty <= availableQty) dispatch(updateCartItem({ id, qty }));
          else {
            dispatch(updateCartItem({ id, qty: availableQty }));
            setInventoryErrorState({
              isVisible: true,
              name: productDetail.name,
              availableInventory: availableQty,
            });
          }
        }
      };
      const handleImageClick = () => {
        setImageModalVisibility(true);
        fireClickToZoomImage(
          {
            screenName: ROUTES.PRODUCT_DETAIL,
            product: productDetail,
            variant: getDisplayVariant(productDetail.variants),
            isPriorityDc: dc?.is_priority_dc,
          },
          {
            [MIXPANEL_KEYS.SKU_ID]: productDetail.bundles[0].id,
            [MIXPANEL_KEYS.TEMPALTE_ID]: productDetail.id,
          }
        );
      };

      const images = bundled_products.map((bp) => bp.image) || [];
      const units = bundled_products.map((bp) => bp.units) || [];
      const bundledProductsSum: number = bundled_products.reduce(
        (acc: number, currentItem: BundledProduct) => {
          return acc + Number(currentItem.actual_price) * currentItem.units;
        },
        0
      );
      return (
        <View style={COMMON_STYLES.flex1}>
          {dc?.app_blocked ? (
            <OutstandingCard
              onCardClick={() => setOpen(true)}
              days={dc.app_block_days}
              amount={dc.app_block_amount}
              onCTAClick={() =>
                startOutstandingAmountPaymentFlow(dc.app_block_amount)
              }
            />
          ) : null}
          <ScrollView style={BG_STYLES.bgWhite}>
            <Carousel
              data={[images]}
              renderItem={(imageUrl, index) => (
                <TouchableOpacity
                  key={index}
                  onPress={handleImageClick}
                  style={[
                    COMMON_STYLES.pv1,
                    COMMON_STYLES.alignItemsCenter,
                    COMMON_STYLES.fullWidth,
                    { width: DIMENSION_WIDTH },
                    COMMON_STYLES.row,
                    COMMON_STYLES.justifyCenter,
                  ]}
                >
                  {imageUrl.map((url, index) => (
                    <View key={index}>
                      <ImageWithUnitLabel
                        key={index}
                        image={{ url: url, text: `${units[index]} pc` }}
                        fallBackProductImageOrIcon={
                          <Image
                            source={DefaultProductImage as ImageSourcePropType}
                            style={styles.image}
                          />
                        }
                        containerStyle={[
                          styles.image,
                          BG_STYLES.bgWhite,
                          COMMON_STYLES.mr1,
                        ]}
                        imgLabelStyle={[
                          COMMON_STYLES.absolute,
                          COMMON_STYLES.justifyCenter,
                          styles.imgLabelStyle,
                        ]}
                      />
                      {images.length - 1 === index ? (
                        <ZoomLensIcon
                          width={34}
                          height={34}
                          style={[COMMON_STYLES.absolute, styles.zoomStyle]}
                        />
                      ) : null}
                    </View>
                  ))}
                </TouchableOpacity>
              )}
            />
            <View
              style={[
                COMMON_STYLES.ph1,
                COMMON_STYLES.pb1,
                COMMON_STYLES.divider4x,
              ]}
            >
              <View style={[COMMON_STYLES.row, COMMON_STYLES["mb1/2"]]}>
                <Label
                  text={t("combo_offer")}
                  containerStyle={BG_STYLES.bgPurple100}
                  textColor="textWhite"
                />
              </View>
              <Text
                style={[
                  TEXT_STYLES.textNeutral90,
                  TYPOGRAPHY.TEXT1,
                  VARIANTS_STYLES.EMPHASIZED,
                ]}
              >
                {productDetail.name}
              </Text>
            </View>
            {licenseStatus ? (
              <View style={COMMON_STYLES.divider4x}>
                <LicenceWarningCard
                  status={licenseStatus}
                  isOrderBlocked={blockOrder && !dc.app_blocked}
                  featureCheck={featureCheck}
                />
              </View>
            ) : null}

            <>
              {!(blockOrder && !dc.app_blocked) && (
                <View style={[COMMON_STYLES.ph1, COMMON_STYLES.divider4x]}>
                  <VariantCard
                    key={id}
                    discountPrice={Number(discounted_price)}
                    cashback={Number(actual_price) - Number(discounted_price)}
                    price={Number(actual_price)}
                    quantity={cartItems[id]?.qty}
                    updateQuantityCallBack={handleQtyUpdate}
                    onDeleteBtnClick={() => dispatch(deleteCartItem({ id }))}
                    isOutOfStock={orderable_quantity < 1}
                    unit="combo"
                    isPriceLocked={dc.app_blocked}
                    onRelvealPriceClick={() => {
                      if (!isAIMS()) setOpen(true);
                    }}
                    avilableInventory={Math.min(
                      orderable_quantity,
                      MAX_INVENTORY_ALLOWED
                    )}
                  />
                </View>
              )}
              <View style={[COMMON_STYLES.p1, COMMON_STYLES.divider4x]}>
                <Text
                  style={[
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.EMPHASIZED,
                    TEXT_STYLES.textNeutral90,
                    COMMON_STYLES["mb1/4"],
                  ]}
                >
                  {t("price_per_combo")}
                </Text>
                {bundled_products.map((bp) => (
                  <View
                    key={bp.id}
                    style={[
                      COMMON_STYLES.row,
                      COMMON_STYLES["mt1/4"],
                      COMMON_STYLES.justifyBetween,
                    ]}
                  >
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral90,
                      ]}
                    >
                      {bp.name} ({bp.pack_attribute} x {bp.units}{" "}
                      {bp.pack_display_name})
                    </Text>
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral90,
                      ]}
                    >
                      {formatCurrency(Number(bp.actual_price) * bp.units)}
                    </Text>
                  </View>
                ))}
                <View
                  style={[
                    COMMON_STYLES.fullWidth,
                    COMMON_STYLES["mt3/4"],
                    styles.totalView,
                  ]}
                >
                  <Text
                    style={[
                      COMMON_STYLES.textRight,
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.SEMI_EMPHASIZED,
                      TEXT_STYLES.textNeutral90,
                    ]}
                  >
                    {formatCurrency(bundledProductsSum)}
                  </Text>
                </View>
                <View
                  style={[
                    COMMON_STYLES["mt1"],
                    COMMON_STYLES["ph1"],
                    COMMON_STYLES["pv1/2"],
                    BG_STYLES.bgOrange10,
                    COMMON_STYLES["br1/2"],
                  ]}
                >
                  <Text style={[TEXT_STYLES.textNeutral90]}>
                    {t("combo_return_message")}
                  </Text>
                </View>
              </View>

              {discounts_info?.discounts?.length ? (
                <>
                  <View style={[COMMON_STYLES.ph1, COMMON_STYLES["pb1/2"]]}>
                    <OfferAndDiscount
                      discountsInfo={discounts_info}
                      uom={constituent_uom}
                      isBundle
                    />
                  </View>
                </>
              ) : null}

              <View style={[COMMON_STYLES.p1]}>
                <Text
                  style={[
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.EMPHASIZED,
                    TEXT_STYLES.textNeutral90,
                  ]}
                >
                  {t("you_are_ordering")}
                </Text>
                {bundled_products.map((bp) => {
                  let totalVolume =
                    bp.volume * bp.units * (cartItems[id]?.qty || 0);
                  let totalQuantity = bp.units * (cartItems[id]?.qty || 0);
                  let formattedVolume;
                  if (
                    totalVolume < 1 &&
                    bp.volume_lower_factor &&
                    bp.volume_lower_uom
                  ) {
                    formattedVolume = totalVolume * bp.volume_lower_factor;
                    if (!Number.isInteger(formattedVolume))
                      formattedVolume = formattedVolume.toFixed(2);
                    formattedVolume =
                      formattedVolume + " " + bp.volume_lower_uom;
                  } else {
                    formattedVolume = totalVolume;
                    if (!Number.isInteger(formattedVolume))
                      formattedVolume = formattedVolume.toFixed(2);
                    formattedVolume = formattedVolume + " " + bp.volume_uom;
                  }

                  return (
                    <TouchableOpacity
                      key={bp.id}
                      style={[
                        COMMON_STYLES.ph1,
                        COMMON_STYLES["pv1/2"],
                        COMMON_STYLES.row,
                        COMMON_STYLES.justifyBetween,
                        COMMON_STYLES.alignItemsCenter,
                        BG_STYLES.bgNeutral00,
                        COMMON_STYLES["mt1/2"],
                        COMMON_STYLES["br1/2"],
                      ]}
                      onPress={() =>
                        rnNavigation({
                          name: ROUTES.PRODUCT_DETAIL,
                          params: { id: bp.product_id },
                        })
                      }
                    >
                      <LazyImage
                        imageUrl={bp.image}
                        imgStyles={styles.imgStyle}
                        fallBackIcon={
                          <Image
                            source={DefaultProductImage as ImageSourcePropType}
                            style={styles.imgStyle}
                          />
                        }
                      />
                      <View style={[COMMON_STYLES.flex1, COMMON_STYLES.ml1]}>
                        <Text
                          style={[
                            TYPOGRAPHY.CAPTION1,
                            VARIANTS_STYLES.BASE,
                            TEXT_STYLES.textNeutral90,
                          ]}
                        >
                          {bp.name}
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={[
                            TYPOGRAPHY.CAPTION1,
                            VARIANTS_STYLES.BASE,
                            TEXT_STYLES.textNeutral90,
                          ]}
                        >
                          {t("volume")}

                          <Text style={[VARIANTS_STYLES.EMPHASIZED]}>
                            {totalVolume === 0 ? 0 : formattedVolume}
                          </Text>
                        </Text>
                        <View
                          style={[
                            COMMON_STYLES.row,
                            COMMON_STYLES.justifyBetween,
                          ]}
                        >
                          <Text
                            style={[
                              TYPOGRAPHY.CAPTION1,
                              VARIANTS_STYLES.BASE,
                              TEXT_STYLES.textNeutral90,
                            ]}
                          >
                            {t("qty")}
                          </Text>
                          <Text
                            style={[
                              TYPOGRAPHY.CAPTION1,
                              VARIANTS_STYLES.EMPHASIZED,
                              TEXT_STYLES.textNeutral90,
                            ]}
                          >
                            {totalQuantity || 0}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </>
          </ScrollView>
          <CheckoutStrip landingFrom={ROUTES.COMBO_DETAIL} />
          <InsufficientInventoryModal
            isVisible={inventoryErrorState.isVisible}
            onCTAClick={() => setInventoryErrorState({ isVisible: false })}
            name={productDetail.name}
            availableInventory={inventoryErrorState.availableInventory}
          />
          <OutstandingAmountDrawer
            days={dc.app_block_days}
            amount={dc.app_block_amount}
            isOpen={open}
            onClose={() => setOpen(false)}
            onCtaClick={() => {
              startOutstandingAmountPaymentFlow(dc.app_block_amount);
              setOpen(false);
            }}
          />
          <ModalImageCarousal
            isVisible={isImageModalVisible}
            onClose={() => setImageModalVisibility(false)}
            images={images}
          />
        </View>
      );
    }
  }
};

const styles = StyleSheet.create({
  image: { height: 104, width: 80, borderWidth: 0 },
  imgStyle: { height: 50, width: 40 },
  imgLabelStyle: {
    top: 0,
    right: -15,
    width: 31,
    height: 17,
    borderRadius: 4,
  },
  totalView: {
    borderTopWidth: 1,
    borderTopColor: "#C5CED6",
  },
  zoomStyle: { bottom: 0, left: "60%" },
});

export default ComboDetailPage;
