import { get } from "app/partner-ordering-ui/utils/axios/index";
import { SchemeDetailData } from "../models/Scheme";
import { isAIMS } from "../utils/helpers/isAIMSApp";
import { SCHEME_TYPE } from "../constants/Scheme";

const fetchSchemesForVariant = async (
  variantId: string | number,
  partnerId?: number
) => {
  const url = `/schemes/v2`;
  try {
    const response = await get<{ data: SchemeDetailData[] }>(url, {
      variant_id: variantId,
      partner_id: isAIMS() ? partnerId : undefined,
      type: SCHEME_TYPE.TBS
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default fetchSchemesForVariant;
