import { get } from "../axios/index";
import { Order } from "app/sell-to-farmer/models/orders";

const fetchOrderDetails = async (orderId: string, lang = "en") => {
  try {
    const response = await get<Order>(
      `/hyperlocal/vendor/v3/orders/${orderId}`
    );
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default fetchOrderDetails;
