import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import DotSeperatorIcon from "app/partner-ordering-ui/icons/DotSeperator";
import OfferAppliedIcon from "app/partner-ordering-ui/icons/OfferApplied";
import { AppliedDiscount } from "app/partner-ordering-ui/models/Cart";

interface Props {
  appliedDiscounts?: AppliedDiscount[];
}

const RenderAppliedDiscount = ({ appliedDiscounts = [] }: Props) => {
  const appliedDiscount = appliedDiscounts[0];
  const { t } = useTranslation();
  if (appliedDiscount) {
    return (
      <View style={[COMMON_STYLES.row, COMMON_STYLES["mt3/4"]]}>
        <OfferAppliedIcon style={COMMON_STYLES["mt1/4"]} />
        <View style={COMMON_STYLES["ml0.5"]}>
          <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.EMPHASIZED,
                TEXT_STYLES.textPurple120,
              ]}
            >
              {appliedDiscount.discount_text}
            </Text>
            <DotSeperatorIcon style={styles["mh1/4"]} />
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.EMPHASIZED,
                TEXT_STYLES.textPurple120,
              ]}
            >
              {t("offer_applied", { count: 1 })}
            </Text>
          </View>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textPurple100,
            ]}
          >
            {appliedDiscount.discount_subtext}
          </Text>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  "mh1/4": { marginHorizontal: 4 },
});

export default RenderAppliedDiscount;
