import React from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import {
  BG_STYLES,
  COLORS,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { ProductCategory } from "../models/Product";

interface Props {
  activeCategory: ProductCategory;
  categories: ProductCategory[];
  onTabPress: (category: ProductCategory) => void;
}

const ProductTabs = ({ activeCategory, categories, onTabPress }: Props) => {
  const renderTab = (category: ProductCategory) => {
    const isActive = activeCategory.id === category.id;
    return (
      <TouchableOpacity
        key={category.id}
        onPress={() => onTabPress(category)}
        style={[
          { marginRight: 24, paddingBottom: 4 },
          isActive && styles.activeTab,
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            isActive ? VARIANTS_STYLES.SEMI_EMPHASIZED : VARIANTS_STYLES.BASE,
            isActive ? TEXT_STYLES.textPrimary100 : TEXT_STYLES.textNeutral70,
          ]}
        >
          {category.name}
        </Text>
      </TouchableOpacity>
    );
  };
  return (
    <View
      style={[BG_STYLES.bgWhite, COMMON_STYLES.pt1, COMMON_STYLES["pb1/2"]]}
    >
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        style={styles.scrollContainer}
        contentContainerStyle={COMMON_STYLES.ph1}
      >
        {categories.map((category) => renderTab(category))}
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  activeTab: {
    borderBottomColor: COLORS.primary100,
    borderBottomWidth: 2,
    paddingBottom: 2,
  },
  scrollContainer: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.neutral10,
  },
});

export default ProductTabs;
