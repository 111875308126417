import React, { PropsWithChildren, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Pressable,
  TouchableOpacity,
} from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  BORDER_STYLES,
  COLORS,
  COMMON_STYLES,
  DefaultProductImage,
  DeleteIcon,
  Label,
  LazyImage,
  PlusMinusButtonWithInput,
  ProductTags,
  RupeeCircleIcon,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import {
  AppliedDiscount,
  DeliveryInfo,
  ErrorCardItem,
} from "app/partner-ordering-ui/models/Cart";
import {
  DATE_FORMATS,
  formatDate,
} from "app/partner-ordering-ui/utils/helpers/date";
import RenderAppliedDiscount from "./RenderAppliedDiscount";
import CartItemPriceInfo from "./CartItemPriceInfo";
import { DeliveryIcon } from "app/partner-ordering-ui/assets/images";
import { moqFormatter } from "app/partner-ordering-ui/utils/helpers/product";
import { MOQ_TYPE } from "app/partner-ordering-ui/constants/Cart";
import WarningRedIcon from "app/partner-ordering-ui/icons/WarningRed";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import ZoomLensIcon from "app/partner-ordering-ui/icons/ZoomLens";
import ModalImageCarousal from "../resuable/ModalImageCarousal";
import { REWARD_TYPE } from "app/partner-ordering-ui/models/Product";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";

interface Props {
  id: string;
  discountedContituentPrice?: number | null;
  infoTags?: ProductTags[];
  name: string;
  packAttribute?: string;
  unitPrice: number;
  constituentUnitPrice: number | null;
  uom: string;
  totalPrice: number;
  totalVolume?: number;
  imageUrl: string;
  quantity: number;
  discountedPrice?: number | null;
  maxDiscountPrice: string;
  handleNegotiablePrice: () => void;
  handleQuantityUpdate: (qty: number) => void;
  handleRemoveCartItem: () => void;
  deliveryInfo?: DeliveryInfo[];
  isNotReturnable?: boolean;
  moq?: number | null;
  moqType?: MOQ_TYPE | null;
  moqLabelValue?: string;
  appliedDiscounts?: AppliedDiscount[];
  rewardType?: REWARD_TYPE;
  totalInventory?: number;
  error?: ErrorCardItem;
  productStatus?: string;
  negotiatedPrice: boolean;
}

const CartItem = ({
  id,
  discountedContituentPrice = 0,
  infoTags,
  name,
  packAttribute,
  unitPrice,
  constituentUnitPrice,
  uom,
  totalPrice,
  totalVolume = 0,
  imageUrl,
  quantity,
  discountedPrice = 0,
  maxDiscountPrice,
  negotiatedPrice,
  handleNegotiablePrice,
  handleQuantityUpdate,
  handleRemoveCartItem,
  deliveryInfo,
  isNotReturnable = false,
  appliedDiscounts = [],
  moq = 1,
  moqType = MOQ_TYPE.QUANTITY,
  moqLabelValue = "1",
  rewardType = "INSTANT",
  children,
  totalInventory = MAX_INVENTORY_ALLOWED,
  error = {},
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const isError = moq ? Number(moq) > quantity : false;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleImageClick = () => {
    setIsVisible(true);
  };

  return (
    <View
      style={[
        BG_STYLES.bgWhite,
        COMMON_STYLES.br1,
        COMMON_STYLES["mb1/2"],
        isError && [BORDER_STYLES.borderError100, COMMON_STYLES.b1],
      ]}
    >
      <View style={[COMMON_STYLES.ph1, COMMON_STYLES["pt3/4"]]}>
        {isNotReturnable && (
          <View style={COMMON_STYLES.row}>
            <Label text={t("non_returnable")} bgColor="bgNeutral00" />
          </View>
        )}
        {infoTags ? (
          <View style={COMMON_STYLES.row}>
            {infoTags.map((tag) => (
              <Label
                key={tag.key}
                text={tag.name}
                leftIcon={
                  <LazyImage
                    imageUrl={tag.icon as string}
                    fallBackIcon={null}
                    imgStyles={styles.image16x16}
                  />
                }
                bgColor="bgNeutral00"
              />
            ))}
          </View>
        ) : null}

        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.justifyBetween,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          <View style={[COMMON_STYLES.row, COMMON_STYLES.flex1]}>
            <TouchableOpacity onPress={handleImageClick}>
              <LazyImage
                imageUrl={imageUrl}
                fallBackIcon={
                  <Image
                    source={DefaultProductImage}
                    style={styles.productImage}
                  />
                }
                imgStyles={styles.productImage}
              />
              <ZoomLensIcon
                width={14}
                height={14}
                style={[COMMON_STYLES.absolute, styles.zoomStyle]}
              />
            </TouchableOpacity>
            <ModalImageCarousal
              isVisible={isVisible}
              onClose={() => setIsVisible(false)}
              images={[imageUrl]}
            />
            <View style={COMMON_STYLES.flex1}>
              <View style={COMMON_STYLES.row}>
                <View style={[COMMON_STYLES["ml1/4"], COMMON_STYLES.flex1]}>
                  <Text
                    numberOfLines={2}
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.SEMI_EMPHASIZED,
                      TEXT_STYLES.textNeutral100,
                      COMMON_STYLES["mr0.5"],
                    ]}
                  >
                    {name}
                  </Text>
                  {packAttribute ? (
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral80,
                        COMMON_STYLES["mt1/8"],
                      ]}
                    >
                      {packAttribute}
                    </Text>
                  ) : null}
                  <CartItemPriceInfo
                    price={unitPrice}
                    constituentUnitPrice={constituentUnitPrice}
                    constituentUom={uom}
                    discountPrice={discountedPrice}
                    discountConstituentUnitPrice={discountedContituentPrice}
                    isCashback={rewardType === "CASHBACK"}
                  />
                </View>
                <View style={[COMMON_STYLES.ml1, styles.width120]}>
                  <PlusMinusButtonWithInput
                    onDeleteClick={handleRemoveCartItem}
                    onMinusClick={() => handleQuantityUpdate(quantity - 1)}
                    onPlusClick={() => handleQuantityUpdate(quantity + 1)}
                    onQuantityUpdate={handleQuantityUpdate}
                    quantity={quantity}
                    size={SIZE.small}
                    minimumQty={moq ? Number(moq) : undefined}
                    maximumQty={Math.min(totalInventory, MAX_INVENTORY_ALLOWED)}
                  />
                  {rewardType === "CASHBACK" ? (
                    <View
                      style={[
                        COMMON_STYLES.row,
                        COMMON_STYLES.justifyEnd,
                        COMMON_STYLES.alignItemsCenter,
                        COMMON_STYLES["mt1/2"],
                      ]}
                    >
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          TEXT_STYLES.textNeutral80,
                        ]}
                      >
                        {t("billing_price")}
                      </Text>
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.SEMI_EMPHASIZED,
                          TEXT_STYLES.textNeutral100,
                          COMMON_STYLES["ml0.5"],
                        ]}
                      >
                        {formatCurrency(unitPrice)}
                      </Text>
                    </View>
                  ) : null}
                  <View
                    style={[
                      COMMON_STYLES.row,
                      COMMON_STYLES.justifyEnd,
                      COMMON_STYLES.alignItemsCenter,
                      COMMON_STYLES["mt1/4"],
                    ]}
                  >
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral80,
                      ]}
                    >
                      {t("item_total")}
                    </Text>
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.SEMI_EMPHASIZED,
                        TEXT_STYLES.textNeutral100,
                        COMMON_STYLES["ml0.5"],
                      ]}
                    >
                      {formatCurrency(totalPrice)}
                    </Text>
                  </View>
                  {totalVolume ? (
                    <View
                      style={[
                        COMMON_STYLES.row,
                        COMMON_STYLES.justifyEnd,
                        COMMON_STYLES.alignItemsCenter,
                        COMMON_STYLES["mt1/4"],
                      ]}
                    >
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          TEXT_STYLES.textNeutral80,
                        ]}
                      >
                        {t("volume_total")}
                      </Text>
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.SEMI_EMPHASIZED,
                          TEXT_STYLES.textNeutral100,
                          COMMON_STYLES["ml0.5"],
                        ]}
                      >
                        {`${totalVolume}${uom}`}
                      </Text>
                    </View>
                  ) : null}

                  {moq && moq > 1 ? (
                    <View
                      style={[
                        COMMON_STYLES.row,
                        COMMON_STYLES.justifyEnd,
                        COMMON_STYLES.alignItemsCenter,
                        COMMON_STYLES["mt1/4"],
                      ]}
                    >
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          isError
                            ? TEXT_STYLES.textError100
                            : TEXT_STYLES.textNeutral80,
                        ]}
                      >
                        {t("min_order_label")}
                      </Text>
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.SEMI_EMPHASIZED,
                          isError
                            ? TEXT_STYLES.textError100
                            : TEXT_STYLES.textNeutral100,
                          COMMON_STYLES["ml0.5"],
                        ]}
                      >
                        {moqFormatter(moqLabelValue, moqType, uom)}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        </View>
        {Object.values(error).length ||
        (totalInventory && totalInventory < quantity) ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            <View style={COMMON_STYLES.row}>
              <WarningRedIcon />
              <View style={[COMMON_STYLES.flex1, COMMON_STYLES["ml1/4"]]}>
                {Object.values(error).map((value, index) => (
                  <Text
                    key={index}
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.BASE,
                      TEXT_STYLES.textOrange100,
                    ]}
                  >
                    {t(value.key, value.params)}
                  </Text>
                ))}
                {totalInventory && totalInventory < quantity ? (
                  <Text
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.BASE,
                      TEXT_STYLES.textOrange100,
                    ]}
                  >
                    {t("max_qty_error", { count: totalInventory })}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
        ) : null}
        {deliveryInfo ? (
          <View>
            {isAIMS() && maxDiscountPrice ? (
              <View
                style={[
                  COMMON_STYLES.row,
                  BORDER_STYLES.borderNeutral10,
                  COMMON_STYLES["pt1/2"],
                  COMMON_STYLES["mt3/4"],
                  { borderTopWidth: 1 },
                ]}
              >
                <RupeeCircleIcon
                  width={20}
                  height={20}
                  style={COMMON_STYLES["mr0.5"]}
                />
                <View>
                  <Text style={[TEXT_STYLES.textNeutral80,TYPOGRAPHY.CAPTION1]}>
                    {t('minimum_selling_price',{amount: formatCurrency(maxDiscountPrice)})} 
                  </Text>
                  <Pressable
                    onPress={handleNegotiablePrice}
                    style={({ pressed }) => 
                      pressed && COMMON_STYLES["opacity1/2"]
                    }
                  >
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.SEMI_EMPHASIZED,
                        TEXT_STYLES.textPrimary100,
                        COMMON_STYLES["mt1/4"],
                      ]}
                    >
                      {negotiatedPrice? t("modify_negotiable_price"): t("enter_negotiable_price")}
                    </Text>
                  </Pressable>
                </View>
              </View>
            ) : null}
            <View
              style={[
                COMMON_STYLES["pt1/2"],
                COMMON_STYLES["mt3/4"],
                BORDER_STYLES.borderNeutral10,
                { borderTopWidth: 1 },
              ]}
            >
              {deliveryInfo.map((info, index) => {
                const date = info.eta_to
                  ? formatDate(info.eta_to, DATE_FORMATS["DD Mmm"])
                  : "";
                return (
                  <View key={index} style={COMMON_STYLES.row}>
                    <Image source={DeliveryIcon} style={styles.image20x20} />
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral80,
                        COMMON_STYLES["ml0.5"],
                      ]}
                    >
                      {t("single_sku_delivery_info", {
                        count: info.quantity,
                        isMulipleUnits: info.quantity > 1 ? "s" : "",
                        date,
                      })}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
        ) : null}

        {children}
        <RenderAppliedDiscount appliedDiscounts={appliedDiscounts} />
      </View>
      <View
        style={[
          COMMON_STYLES.b1,
          BORDER_STYLES.borderNeutral10,
          COMMON_STYLES["mt3/4"],
        ]}
      >
        <Pressable
          onPress={handleRemoveCartItem}
          style={({ pressed }) => [
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES.justifyCenter,
            COMMON_STYLES["pv1/2"],
            COMMON_STYLES.ph1,
            pressed && COMMON_STYLES["opacity1/2"],
          ]}
        >
          <DeleteIcon
            svgProps={styles.image16x16}
            pathProps={{ stroke: COLORS.neutral90 }}
          />
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral90,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("remove")}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

export default CartItem;

const styles = StyleSheet.create({
  productImage: {
    width: 48,
    height: 48,
    resizeMode: "contain",
  },
  image16x16: { height: 16, width: 16 },
  image20x20: { height: 20, width: 20 },
  zoomStyle: { top: 34, left: 29 },
  width120: { width: 120 },
});
