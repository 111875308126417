import { formatCurrency } from "@dehaat/dds";
import { DiscountsInfo } from "app/partner-ordering-ui/models/Product";

interface OfferHeadingCtaType {
  isBundle: boolean
  quantity: number
  discountInfo: DiscountsInfo
  constituentUom: string
}
const getOfferHeadingCta = (options: OfferHeadingCtaType) => {
  const {
    isBundle,
    quantity,
    discountInfo,
    constituentUom,
  } = options
  let heading = "";
  let cta = "";

  if (isBundle && quantity === 0) {
    heading = "Get discount with combo";
    cta = "View combos";
  }

  if (discountInfo?.best_price) {
    heading = `Get for ${formatCurrency(discountInfo.best_price)}`;
    if (discountInfo?.best_constituent_unit_price && constituentUom) {
      heading =
        heading +
        ` @${formatCurrency(
          discountInfo.best_constituent_unit_price
        )}/${constituentUom}`;
    }
    if (isBundle) {
      cta = "View Offers & Combos";
    } else cta = "View Offers";
  }
  return { cta, heading }
}

export {
  getOfferHeadingCta,
}
