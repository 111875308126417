const buildType = process.env.NODE_ENV
  ? process.env.NODE_ENV === "production"
    ? "prod"
    : "debug"
  : "debug";

const Config = {
  APP_CODE: process.env.APP_CODE,
  BUILD_TYPE: buildType,
  VERSION_CODE: 1,
  VERSION_NAME: "0.0.1",
  INPUT_BACKEND_URL: process.env.INPUT_BACKEND_URL,
  CLIENT_ID: process.env.KEYCLOAK_CLIENT_ID,
  OIDC_URL: process.env.OIDC_URL,
  KHETI_URL: process.env.KHETI_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
};

export default Config;
