import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { COMMON_STYLES, PriceInfo } from "@dehaat/dds";
import { BlurPrice } from "app/partner-ordering-ui/assets/images";
import Cashback from "../resuable/Cashback";

interface Props {
  actualPrice: number;
  discountPrice: number;
  priceLocked?: boolean;
}

const ComboPriceInfo = ({
  actualPrice,
  discountPrice,
  priceLocked = false,
}: Props) => {
  const discountAmount = actualPrice - discountPrice;

  if (priceLocked)
    return (
      <Image source={BlurPrice} style={[styles.h36, COMMON_STYLES.halfWidth]} />
    );
  return (
    <View>
      <PriceInfo
        price={actualPrice}
        discountPrice={discountAmount ? discountPrice : 0}
      />
      <Cashback
        amount={discountAmount}
        unit={"combo"}
        style={COMMON_STYLES.mt0}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  h36: {
    height: 36,
  },
});

export default ComboPriceInfo;
