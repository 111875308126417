function getDateXDaysBefore(x: number): string {
    const today = new Date();
    
    // Subtract X days
    today.setDate(today.getDate() - x);
    
    // Format the date as YYYY-MM-DD
    return today.toISOString().split('T')[0];
  }

  export default getDateXDaysBefore
