import React, { useEffect, useState } from "react";
import { Text, View, ScrollView } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  BottomDrawer,
  COLORS,
  COMMON_STYLES,
  PARAGRAPH_STYLES,
  RadioButton,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import {
  CartData,
  CreditDetails,
  PrepaidDetails,
} from "app/partner-ordering-ui/models/Cart";
import { PaymentMethods } from "app/partner-ordering-ui/models/Payment";
import { PAYMENT_LABEL } from "app/partner-ordering-ui/constants/Cart";
import {
  MIXPANEL_EVENT,
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "app/partner-ordering-ui/constants/eventNames";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import { logEvent } from "app/utils/appConfig";
import {
  fireClickedOnPlaceOrderEvent,
  fireClickPlaceOrderButtonEvent,
} from "app/partner-ordering-ui/utils/helpers/mixpanel";
import HandIcon from "app/partner-ordering-ui/icons/Hand";
import Sentry from "app/utils/sentry";

import PaymentBreakupCard from "./PaymentBreakup";
import Footer from "./Footer";

interface Props {
  payableAmount: number;
  prepaid?: PrepaidDetails;
  credit?: CreditDetails;
  handleCreateOrder: (paymentMethod: PaymentMethods) => void;
  cartData: CartData;
}

const CartPayment = ({
  payableAmount,
  prepaid,
  credit,
  handleCreateOrder,
  cartData,
}: Props) => {
  const { t } = useTranslation();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<{
    visible: boolean;
    message: string;
  }>({ visible: false, message: "" });
  const [selectedIndex, setSelectedIndex] = useState<number>(
    prepaid ? (prepaid?.enabled ? 0 : 1) : 0
  );
  const selectedPaymentMode = selectedIndex ? credit : prepaid;
  const dc = useAppSelector(selectDCData);

  const actionText = selectedPaymentMode?.net_payable
    ? t("pay_now_to_place_order", {
        amount: formatCurrency(selectedPaymentMode.net_payable),
      })
    : t("place_order");

  const list: {
    radioElement: JSX.Element;
    value: PaymentMethods;
  }[] = [];

  const onCreditViewBreakupClick = () => {
    const mixPanelPayload = {
      [MIXPANEL_KEYS.CREDIT_LINE_AMOUNT_POSTPAID_AMOUNT]:
        credit?.paid_using_credit_limit,
      [MIXPANEL_KEYS.CREDIT_NOTE_AMOUNT_POSTPAID]:
        credit?.total_cashback_discount,
      [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
      [MIXPANEL_KEYS.MINIUM_PAY_AMOUNT_POSTPAID]: credit?.net_payable,
      [MIXPANEL_KEYS.PAYABLE_AMOUNT]: credit?.payable_amount,
      [MIXPANEL_KEYS.POSTPAID_EFFECTIVE_AMOUNT]: credit?.effective_amount,
      [MIXPANEL_KEYS.SCREEN_NAME]: MIXPANEL_PROPERTIES.PAYMENT_METHOD_SELECTION,
    };
    logEvent(
      MIXPANEL_EVENT.CLICK_ON_VIEW_BREAKUP_POSTPAID_AMOUNT,
      JSON.stringify(mixPanelPayload)
    );
  };

  if (prepaid) {
    list.push({
      radioElement: (
        <PaymentBreakupCard
          isEnabled={prepaid.enabled}
          breakup={prepaid}
          label={PAYMENT_LABEL.CASH}
          selectedIndex={selectedIndex}
        />
      ),
      value: PaymentMethods.PREPAID,
    });
  }

  if (credit) {
    list.push({
      radioElement: (
        <PaymentBreakupCard
          isEnabled={credit.enabled!}
          breakup={credit}
          label={PAYMENT_LABEL.CREDIT}
          selectedIndex={selectedIndex}
          onViewBreakupClick={onCreditViewBreakupClick}
        />
      ),
      value: PaymentMethods.CREDIT,
    });
  }

  useEffect(() => {
    const mixpanelPayload = {
      [MIXPANEL_KEYS.CREDIT_NOTE_AMOUNT_PREPAID]:
        prepaid?.total_cashback_discount,
      [MIXPANEL_KEYS.DIFFERENCE_PAYMENT_AMOUNTS]:
        (credit?.effective_amount || 0) - (prepaid?.effective_amount || 0),
      [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
      [MIXPANEL_KEYS.MINIUM_PAY_AMOUNT_PREPAID]: prepaid?.net_payable,
      [MIXPANEL_KEYS.PAYABLE_AMOUNT]: prepaid?.payable_amount,
      [MIXPANEL_KEYS.PREPAID_EFFECTIVE_AMOUNT]: prepaid?.effective_amount,
      [MIXPANEL_KEYS.SCREEN_NAME]: MIXPANEL_PROPERTIES.PAYMENT_METHOD_SELECTION,
    };
    logEvent(
      MIXPANEL_EVENT.CHOOSE_PAYMENT_METHOD_PAGE_VIEWED,
      JSON.stringify(mixpanelPayload)
    );
  }, []);

  const onPlaceOrderClick = () => {
    if (list[selectedIndex]) {
      fireClickPlaceOrderButtonEvent({
        isPriorityDc: dc?.is_priority_dc,
        credit,
        selectedIndex,
        prepaid,
        noOfCartItem: Object.keys(cartData)?.length,
      });
      handleCreateOrder(list[selectedIndex].value);
      fireClickedOnPlaceOrderEvent({
        dc,
        payAmount: selectedPaymentMode?.payable_amount || 0,
        cartData,
      });
    } else {
      Sentry.captureException(
        new Error("onPlaceOrderClick -> list[selectedIndex] in missing!")
      );
    }
  };

  return (
    <View style={[BG_STYLES.bgWhite, COMMON_STYLES.flex1]}>
      <ScrollView>
        <View style={COMMON_STYLES.p1}>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            {t("payable_amount")}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.HEADING3,
              VARIANTS_STYLES.EMPHASIZED,
              TEXT_STYLES.textNeutral100,
              COMMON_STYLES["mt1/4"],
            ]}
          >
            {formatCurrency(payableAmount)}
          </Text>
        </View>
        <View style={[COMMON_STYLES.ph1, COMMON_STYLES.pt1]}>
          <RadioButton
            selected={selectedIndex}
            list={list}
            onClick={(index) => {
              if (!prepaid?.enabled && list[index].value === "prepaid") {
                setIsErrorModalOpen({
                  visible: true,
                  message: prepaid?.disabled_reason || "",
                });
              } else if (!credit?.enabled && list[index].value === "credit") {
                setIsErrorModalOpen({
                  visible: true,
                  message: credit?.disabled_reason || "",
                });
              } else {
                setSelectedIndex(index);
              }
            }}
            containerStyle={{
              borderWidth: 2,
              borderColor:
                credit?.enabled || prepaid?.enabled
                  ? COLORS.neutral40
                  : COLORS.neutral20,
              borderRadius: 12,
              padding: 12,
              alignItems: "flex-start",
              position: "relative",
              marginBottom: 16,
            }}
            selectedRadioContainerStyle={{
              borderColor: COLORS.primary100,
            }}
          />
        </View>
      </ScrollView>
      <BottomDrawer
        visible={isErrorModalOpen.visible}
        heading={t("option_not_available")}
        onClose={() => setIsErrorModalOpen({ visible: false, message: "" })}
        modalStyle={COMMON_STYLES.pb2}
      >
        <View
          style={[
            COMMON_STYLES.flex1,
            COMMON_STYLES.justifyCenter,
            COMMON_STYLES.alignItemsStart,
            COMMON_STYLES["ph3/2"],
            COMMON_STYLES.mb1,
          ]}
        >
          <View
            style={[
              COMMON_STYLES.row,
              COMMON_STYLES.alignItemsCenter,
              COMMON_STYLES["mb1"],
            ]}
          >
            <HandIcon />
            <Text
              style={[
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.MEDIUM,
                TEXT_STYLES.textError100,
                COMMON_STYLES["ml0.5"],
              ]}
            >
              {t("payment_error")}
            </Text>
          </View>
          <Text
            style={[
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral100,
              PARAGRAPH_STYLES.BoldT1,
            ]}
          >
            {isErrorModalOpen.message}
          </Text>
        </View>
      </BottomDrawer>
      <Footer onCTAClick={onPlaceOrderClick} text={actionText} />
    </View>
  );
};

export default CartPayment;
