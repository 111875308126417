import React from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  BottomDrawer,
  Button,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { useTranslation } from "react-i18next";

interface Props {
  visible?: boolean;
  onClose: () => void;
  preOrderQuantity?: number;
  totalQuantity?: number;
}

const ProductInventoryBottomDrawer = ({
  visible,
  onClose,
  preOrderQuantity,
  totalQuantity,
}: Props) => {
  const { t } = useTranslation();
  if (!visible) {
    return null;
  }

  return (
    <BottomDrawer
      heading={t("product_inventory")}
      onClose={onClose}
      visible={visible}
    >
      <View
        style={[
          COMMON_STYLES["p3/4"],
          COMMON_STYLES["mt1/2"],
          COMMON_STYLES["br1/4"],
          { backgroundColor: "#4749A00A" },
        ]}
      >
        <View style={[COMMON_STYLES.row, COMMON_STYLES.justifyBetween]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {t("physical_inventory")}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {totalQuantity} {t("units")}
          </Text>
        </View>
        <Text
          style={[
            TYPOGRAPHY.CAPTION1,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            COMMON_STYLES["pt1/4"],
            styles.fixedWidth,
          ]}
        >
          {t("avl_at_warehouse")}
        </Text>
      </View>

      <View
        style={[
          COMMON_STYLES["p3/4"],
          COMMON_STYLES["mt1/2"],
          COMMON_STYLES["br1/4"],
          { backgroundColor: "#4749A00A" },
        ]}
      >
        <View style={[COMMON_STYLES.row, COMMON_STYLES.justifyBetween]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {t("virtual_inventory")}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral90,
            ]}
          >
            {preOrderQuantity} {t("units")}
          </Text>
        </View>
        <Text
          style={[
            TYPOGRAPHY.CAPTION1,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            COMMON_STYLES["pt1/4"],
            styles.fixedWidth,
          ]}
        >
          {t("will_arrive_at_warehouse")}
        </Text>
      </View>
      <Text
        style={[
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.BASE,
          TEXT_STYLES.textNeutral80,
          COMMON_STYLES["mt1/2"],
        ]}
      >
        {t("separate_orders_inventory")}
      </Text>
      <Button
        buttonStyle={COMMON_STYLES.mt1}
        text={t("okay")}
        onClick={onClose}
      />
    </BottomDrawer>
  );
};

const styles = StyleSheet.create({
  fixedWidth: {
    width: 193,
  },
});

export default ProductInventoryBottomDrawer;
