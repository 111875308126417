import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import LinearGradient from "react-native-linear-gradient";

import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import { SLAB_DISCOUNT_TYPE } from "app/partner-ordering-ui/constants/Product";
import { Slab } from "../../models/Product";

interface Props {
  isPrePaid?: boolean;
  effectiveConstituentPrice?: number | null;
  details: Slab;
  isApplied: boolean;
  onClick: () => void;
  uom: string | null;
  isBundle: Boolean;
}

interface RenderTextProps {
  text: string;
  color: keyof typeof TEXT_STYLES;
  isHighlighted?: boolean;
  opacity?: number;
}

const RenderText = ({
  text,
  color,
  isHighlighted,
  opacity = 1,
}: RenderTextProps) => {
  return (
    <Text
      style={[
        TYPOGRAPHY.TEXT2,
        isHighlighted ? VARIANTS_STYLES.EMPHASIZED : VARIANTS_STYLES.BASE,
        TEXT_STYLES[color],
        { opacity },
      ]}
    >
      {text}
    </Text>
  );
};

const SlabStrip = ({
  isPrePaid,
  effectiveConstituentPrice = 0,
  details,
  isApplied,
  onClick,
  uom,
  isBundle,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Pressable
      onPress={onClick}
      style={[
        COMMON_STYLES.ph1,
        COMMON_STYLES["pv1/2"],
        details.is_recommended && { paddingTop: 20 },
        isApplied
          ? BG_STYLES.bgSuccess100
          : BG_STYLES[isPrePaid ? "bgBlue10" : "bgPurple10"],
        COMMON_STYLES["mb0.5"],
        COMMON_STYLES["br1/2"],
        COMMON_STYLES.relative,
      ]}
    >
      <View style={COMMON_STYLES.row}>
        <RenderText
          text={`Save ${isBundle ? "extra " : ""}`}
          color={
            isApplied
              ? "textWhite"
              : isPrePaid
              ? "textBlue100"
              : "textPurple100"
          }
          opacity={isApplied ? 0.6 : 1}
        />
        <RenderText
          text={`${formatCurrency(details.discount_per_unit)}${
            details.uom ? ` per ${isBundle ? "combo" : details.uom}` : ""
          }`}
          color={
            isApplied
              ? "textWhite"
              : isPrePaid
              ? "textBlue120"
              : "textPurple120"
          }
          isHighlighted
        />
        {isBundle ? (
          <RenderText
            text={`${isPrePaid ? " on cash payment" : ""}`}
            color={
              isApplied
                ? "textWhite"
                : isPrePaid
                ? "textBlue100"
                : "textPurple100"
            }
          />
        ) : null}
        {details.slab_id ? (
          <RenderText
            text={" | Order "}
            color={
              isApplied
                ? "textWhite"
                : isPrePaid
                ? "textBlue100"
                : "textPurple100"
            }
            opacity={isApplied ? 0.6 : 1}
          />
        ) : null}
        {details.slab_id ? (
          <RenderText
            text={`${
              details.slab_uom === SLAB_DISCOUNT_TYPE.rupee
                ? `${details.slab_uom}${details.min_qty}${
                    details.max_qty
                      ? ` - ${details.slab_uom}${details.max_qty}`
                      : "+"
                  }`
                : `${details.min_qty}${
                    details.max_qty
                      ? ` - ${details.max_qty} ${details.slab_uom}`
                      : `+ ${details.slab_uom}`
                  } `
            }`}
            color={
              isApplied
                ? "textWhite"
                : isPrePaid
                ? "textBlue120"
                : "textPurple120"
            }
            isHighlighted
          />
        ) : null}
      </View>
      {isApplied && effectiveConstituentPrice ? (
        <View style={[COMMON_STYLES.row, COMMON_STYLES["mt1/4"]]}>
          <RenderText
            text={"Effective Price "}
            color={"textWhite"}
            opacity={0.6}
          />
          <RenderText
            text={`${formatCurrency(effectiveConstituentPrice)}/${uom}`}
            color={"textWhite"}
            isHighlighted
          />
        </View>
      ) : null}
      {details.is_recommended ? (
        <LinearGradient
          colors={["#EEC340", "#FFFA8A", "#DEAC17", "#FEFE94"]}
          start={{ x: 0.2, y: 1.0 }}
          end={{ x: 1.0, y: 0 }}
          locations={[0.0, 0.1958, 0.95, 1.0]}
          style={[COMMON_STYLES.absolute, styles.label]}
        >
          <Text
            style={[
              TYPOGRAPHY.CAPTION2,
              VARIANTS_STYLES.EMPHASIZED,
              TEXT_STYLES.textNeutral100,
            ]}
          >
            {t("recommended")}
          </Text>
        </LinearGradient>
      ) : null}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  label: {
    top: 0,
    right: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    backgroundColor: "#EEC340",
  },
});

export default SlabStrip;
