import React from 'react';
import {Svg, Path, SvgProps, PathProps} from 'react-native-svg';

interface Props {
  svgProps?: SvgProps;
  pathProps?: PathProps;
}
const FilterIcon = ({svgProps, pathProps}: Props) => {
  return (
    <Svg viewBox="0 0 15 10" fill="none" {...svgProps}>
      <Path
        d="M5.83333 9.16667C5.83333 9.6269 6.20643 10 6.66667 10H8.33333C8.79357 10 9.16667 9.6269 9.16667 9.16667C9.16667 8.70643 8.79357 8.33333 8.33333 8.33333H6.66667C6.20643 8.33333 5.83333 8.70643 5.83333 9.16667ZM0.833334 0C0.373096 0 0 0.373096 0 0.833333C0 1.29357 0.373096 1.66667 0.833333 1.66667H14.1667C14.6269 1.66667 15 1.29357 15 0.833333C15 0.373096 14.6269 0 14.1667 0H0.833334ZM2.5 5C2.5 5.46024 2.8731 5.83333 3.33333 5.83333H11.6667C12.1269 5.83333 12.5 5.46024 12.5 5C12.5 4.53976 12.1269 4.16667 11.6667 4.16667H3.33333C2.8731 4.16667 2.5 4.53976 2.5 5Z"
        {...pathProps}
      />
    </Svg>
  );
};

export default FilterIcon;
