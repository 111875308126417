import React, { PropsWithChildren } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  BUTTON_TYPE,
  Button,
  COLORS,
  COMMON_STYLES,
  DeleteIcon,
  Label,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import RenderComboImages from "./RenderComboImages";

interface Props {
  name: string;
  images: { url: string; text: string }[];
  handleRemoveCartItem: () => void;
  label: string;
}

const InactiveCartBundleItem = ({
  name,
  images,
  handleRemoveCartItem,
  label,
  children,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <View
      style={[
        BG_STYLES.bgWhite,
        COMMON_STYLES.br1,
        COMMON_STYLES["mb1/2"],
        COMMON_STYLES.pb1,
      ]}
    >
      <View style={[COMMON_STYLES.ph1, COMMON_STYLES["pt3/4"]]}>
        {label ? (
          <View style={COMMON_STYLES.row}>
            <Label
              text={label}
              textColor="textError100"
              bgColor="bgNeutral00"
            />
          </View>
        ) : null}
        <RenderComboImages images={images} />
        <View style={[COMMON_STYLES["mt3/4"], COMMON_STYLES.flex1]}>
          <View style={COMMON_STYLES.row}>
            <View style={[COMMON_STYLES["ml1/4"], COMMON_STYLES.flex1]}>
              <Text
                numberOfLines={2}
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.SEMI_EMPHASIZED,
                  TEXT_STYLES.textNeutral100,
                ]}
              >
                {name}
              </Text>
            </View>
            <Button
              type={BUTTON_TYPE.SECONDARY}
              text={t("remove")}
              onClick={handleRemoveCartItem}
              iconLeft={
                <DeleteIcon
                  svgProps={styles.image16x16}
                  pathProps={{ stroke: COLORS.primary100 }}
                />
              }
              size={SIZE.small}
            />
          </View>
        </View>
      </View>
      {children}
    </View>
  );
};

export default InactiveCartBundleItem;

const styles = StyleSheet.create({
  image16x16: { height: 16, width: 16 },
});
