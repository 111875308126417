import { FILTER_VALUES } from "../constants/Common";
import { Product, ProductCategory } from "../models/Product";
import { get } from "app/partner-ordering-ui/utils/axios/index";
import { isDBA } from "../utils/helpers/isAIMSApp";
import { logExceptionAndGetTraceIds } from "../utils/helpers/common";

interface Params extends Record<string, any> {
  search?: string;
  pageSize: number;
  pageNumber: number;
  categoryId?: number | string;
  subCategoryId?: number | string;
  sort?: string;
}

interface Response {
  count: number;
  previous: number | null;
  next: number | null;
  results: Product[];
  filters: Record<string, ProductCategory[]>;
}

const fetchProductsList = async ({
  search,
  categoryId,
  pageSize,
  pageNumber,
  subCategoryId,
  sort,
  ...params
}: Params) => {
  const url = "/products/v2";
  try {
    const response = await get<{ data: Response }>(url, {
      coupons_info: true,
      page_size: pageSize,
      page_number: pageNumber,
      dehaat_price_gt: isDBA() ? 0 : undefined,
      untaxed_dehaat_price__gt: isDBA() ? 0 : undefined,
      include: "bundles",
      category_id: categoryId ? categoryId : undefined,
      sub_category_id: subCategoryId,
      sort: sort || FILTER_VALUES.IN_STOCK,
      inventory__gte: sort === FILTER_VALUES.IN_STOCK ? 1 : undefined,
      search,
      ...params,
    });
    return response.data;
  } catch (e) {
    const message = logExceptionAndGetTraceIds(e);
    throw new Error(message);
  }
};

export default fetchProductsList;
