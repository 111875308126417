import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { COMMON_STYLES } from "@dehaat/dds";

import fetchClearanceProducts from "app/partner-ordering-ui/apis/fetchClearanceProducts";
import ShimmerProductCardHz from "app/partner-ordering-ui/components/cards/ProductCardHzShimmer";
import { ClearanceProduct } from "app/partner-ordering-ui/models/Product";
import { ClearanceSaleListScreenProps } from "app/partner-ordering-ui/routes/types";
import RenderClearanceList from "app/partner-ordering-ui/components/productList/clearanceSale";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import OutstandingAmountDrawer from "app/partner-ordering-ui/components/drawers/OutstandingAmountDrawer";
import { startOutstandingAmountPaymentFlow } from "app/utils/appConfig";

import OutstandingCard from "../components/cards/OutstandingCard";
import LogErrorModal from "../components/LogError";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import { isAIMS } from "../utils/helpers/isAIMSApp";

const ClearanceSaleList = ({
  route,
  navigation,
}: ClearanceSaleListScreenProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ClearanceProduct[]>([]);
  const [open, setOpen] = useState(false);

  const dc = useAppSelector(selectDCData);

  useObserverInternetActivity();

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const result = await fetchClearanceProducts(
        route.params?.id,
        dc.sales_office_id
      );
      setProducts(result.results);
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <View style={COMMON_STYLES.flex1}>
      {dc.app_blocked ? (
        <OutstandingCard
          onCardClick={() => setOpen(true)}
          days={dc.app_block_days}
          amount={dc.app_block_amount}
          onCTAClick={() =>
            startOutstandingAmountPaymentFlow(dc.app_block_amount)
          }
        />
      ) : null}
      {loading ? (
        <ShimmerProductCardHz noOfShimmers={6} />
      ) : (
        <RenderClearanceList
          products={products}
          isOrderingBlocked={dc.app_blocked}
          handleRevealPriceClick={() => {
            if (!isAIMS()) setOpen(true);
          }}
        />
      )}
      <OutstandingAmountDrawer
        days={dc.app_block_days}
        amount={dc.app_block_amount}
        isOpen={open}
        onClose={() => setOpen(false)}
        onCtaClick={() => {
          startOutstandingAmountPaymentFlow(dc.app_block_amount);
          setOpen(false);
        }}
      />
      <LogErrorModal
        visible={!!error}
        message={error}
        onClose={() => setError("")}
      />
    </View>
  );
};

export default ClearanceSaleList;
