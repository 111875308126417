import React from "react";
import Svg, { G, Path, Defs, ClipPath, SvgProps } from "react-native-svg";

const ArrowIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 16 16" {...props}>
      <G clipPath="url(#a)">
        <Path
          d="M8.47 3.137a.665.665 0 0 0-.94.94l3.25 3.256H3.334a.667.667 0 1 0 0 1.334h7.446l-3.25 3.256a.665.665 0 0 0 .94.94l4.392-4.392a.667.667 0 0 0 0-.942L8.471 3.137Z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ArrowIcon;
