import { ProductVariant } from "app/partner-ordering-ui/models/Product";
import Config from "app/utils/config";

interface errorArrayProps {
  untaxed_dehaat_price?: string;
  dehaat_price?: string;
}

export const isAIMS = () => Config.APP_CODE === "aims";

export const isDBA = () => Config.APP_CODE === "dehaat_business";

export const errorArray = (variant: ProductVariant | errorArrayProps) => {
  const array: string[] = [];
  if (isAIMS()) {
    if (
      variant.untaxed_dehaat_price === "0.00" ||
      variant.untaxed_dehaat_price === null
    ) {
      array.push("gst_to_be_updated");
    }
    if (variant.dehaat_price === "0.00" || variant.dehaat_price === null) {
      array.push("price_to_be_updated");
    }
  }
  return array;
};
