import React from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, Text, View } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import {
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { DISCOUNT_TYPE } from "app/partner-ordering-ui/constants/Scheme";
import SchemeCashbackIcon from "app/partner-ordering-ui/icons/SchemeCashback";
import { SchemeInfo } from "app/partner-ordering-ui/models/SchemeInfo";
import {
  getFormattedSchemeCashback,
  getFormattedValueToReachNextSlab,
} from "app/partner-ordering-ui/utils/helpers/scheme";

interface Props {
  schemeInfo: SchemeInfo;
}

const SchemeProgressiveNudge = ({ schemeInfo }: Props) => {
  const { t } = useTranslation();

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={["#FFC575", "#FFD08C", "#FFE2B9"]}
      style={[COMMON_STYLES["p3/4"]]}
    >
      <View
        style={[
          COMMON_STYLES.row,
          COMMON_STYLES.justifyBetween,
          COMMON_STYLES.alignItemsCenter,
        ]}
      >
        {schemeInfo.current_discount ? (
          <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
            <SchemeCashbackIcon
              width={16}
              height={16}
              style={[COMMON_STYLES["mr1/4"]]}
            />
            <Text
              style={[
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textNeutral100,
              ]}
            >
              {t("scheme_cashback", {
                amount: getFormattedSchemeCashback(
                  schemeInfo.current_discount,
                  schemeInfo.uom || "Unit",
                  schemeInfo.discount_type
                ),
              })}
            </Text>
          </View>
        ) : (
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral100,
            ]}
          >
            {" "}
            {t("no_cashback_earned")}
          </Text>
        )}

        <View style={[COMMON_STYLES.alignItemsEnd]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral100,
            ]}
          >
            {schemeInfo.next_discount
              ? t("for_cashback", {
                  amount: getFormattedSchemeCashback(
                    schemeInfo.next_discount,
                    schemeInfo.uom || "Unit",
                    schemeInfo.discount_type
                  ),
                })
              : t("you_reached_max_cashback")}
          </Text>

          {(schemeInfo.value_to_reach_next_slab !== null ||
            schemeInfo.discount_type !== DISCOUNT_TYPE.DISCOUNT_AMOUNT) && (
            <Text
              style={[
                TYPOGRAPHY.CAPTION2,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textNeutral100,
                COMMON_STYLES["mt1/8"],
              ]}
            >
              {schemeInfo.value_to_reach_next_slab
                ? t("add_more_item", {
                    amount: getFormattedValueToReachNextSlab(
                      schemeInfo.value_to_reach_next_slab,
                      schemeInfo.uom || "Unit",
                      schemeInfo.slab_type
                    ),
                  })
                : t("add_products_to_avail_more_cashback")}
            </Text>
          )}
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 36,
    height: 50,
  },
});

export default SchemeProgressiveNudge;
