import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const ArrowIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 17 13" {...props}>
      <Path
        d="M15.596 6.415a.904.904 0 0 1-.169.49l-3.343 4.114a.783.783 0 0 1-1.089.109.804.804 0 0 1-.108-1.09L13.2 7.187H1.967a.771.771 0 0 1 0-1.543h11.234l-2.314-2.852c-.257-.314-.2-.826.108-1.09.32-.272.833-.21 1.09.11l3.342 4.113c.13.16.158.296.169.49Z"
        fill="#5A6066"
      />
    </Svg>
  );
};

export default ArrowIcon;
