export const AUTH_ID = "AuthId";

export const MIXPANEL_EVENT = {
  PRODUCT_SEARCH_INITIATED: "Product Search Initiated",
  PRODUCT_SEARCH_CALL_INITIATED: "Product Search Network Call Initiated",
  PRODUCT_VIEWED: "Product Viewed",
  PRODUCT_CLICKED: "Product Clicked",
  PRODUCT_VARIANT_CHANGED: "Product Variant Changed",
  CART_SCREEN_VIEWED: "Cart Screen Viewed",
  COUPON_APPLIED: "Coupon Applied",
  WALLET_WIDGET_ENABLED: "wallet_widget_enabled",
  GO_TO_CART: "Goto Cart",
  CLICK_ON_BILLING_TOTAL: "Click on Billing Total Details Button",
  PRODUCT_LIST_SCREEN_VIEWD: "Product List Screen Viewed",
  PRODUCT_DESCRIPTION_SCREEN_VIEWED: "Product Description Screen Viewed",
  CLICK_ON_OFFER_STRIP: "Click On Offer Strip",
  PRODUCT_ADD_TO_CART: "Product Added To Cart",
  PRODUCT_SEARCH_DONE: "Product Search Done",
  CLICK_ON_ALTERNATE_PRODUCT: "Click To View Alternate Product",
  CLICK_PAYMENT_BUTTON: "Click On Choose Payment Method Button",
  CHOOSE_PAYMENT_METHOD_PAGE_VIEWED: "Choose Payment Method Page Viewed",
  CLICK_ON_VIEW_BREAKUP_POSTPAID_AMOUNT:
    "Click on View Breakup in Postpaid Amount",
  CLICK_ON_PLACE_ORDER_BUTTON_PAYMENT_PAGE:
    "Click on Place Order Button on Payment Page",
  CLICKED_PLACE_ORDER: "Clicked on Place Order",
  ORDER_PLACED_OVERALL: "Order Placed Overall",
  PRODUCT_REMOVED_FROM_CART: "Product Removed from Cart",
  CLICKED_VIEW_COMBOS_QUICK_LINK: "Clicked View Combos Quicklink in PDP",
  CLICKED_VIEW_ALTERNATE_QUICK_LINK: "Clicked View Alternate Quicklink in PDP",
  CLICK_TO_ZOOM_THE_IMAGE: "Click To Zoom the Image",
  RECENT_SEARCH_ITEM_CLICKED: "Recent Search Item Clicked",
  CLEAR_ALL_CLICKED: "Clear All Clicked",
  WALLET_OPTED_IN_CART: "wallet_opted_in_cart",
  CURATED_PRODUCT_CLICKED: "Curated Product Clicked",
  CLICKED_ADD_TO_EXPAND_COMBO_CAROUSEL: "Clicked Add To Expand Combo Carousal",
  ORDER_PLACED: "Order Placed",
  PAYMENT_INITIATED: "Payment Initiated",
  MAKE_PAYMENT: "Make Payment", // OrderPaymentViewModel -> trackPaymentInitiated()
  CREATE_PG_SESSION_RESULT: "PG Session Result",
  PREPAID_PAYMENT_INITIATED: "Prepaid Payment Initiated",
};

export const MIXPANEL_PROPERTIES = {
  SEARCH_AUTO_SUGGEST: "Auto suggest",
  SEARCH_TYPE_TEXT: "Text",
  BEST_SELLER: "best_seller",
  DEHAAT_CHOICE: "dehaat_choice",
  FREE_INSURANCE: "free_insurance",
  OFFER_STRIP: "offer_strip",
  VARIANT_BOTTOM_SHEET: "variant_bottom_sheet",
  BUY_PRODUCTS: "Buy Products",
  BUY_PRODUCTS_PAGE: "Buy Products Page",
  PRODUCT_DESCRIPTION: "Product Description",
  ALTERNATE_PRODUCTS: "Alternate Products",
  INSTOCK: "InStock",
  OOS: "OOS",
  PAYMENT_METHOD_SELECTION: "Payment Method Selection Page",
  PREORDER: "preorder",
  CREDIT: "credit",
  DEBIT: "debit",
  REGULAR: "Regular",
  PLP_ROUTE: "PDP_via_PLP_via_homepage",
};

export const MIXPANEL_KEYS = {
  ALTERNATE_PRODUCT_SHOWN: "# of Alternate Products Shown",
  ATTRIBUTES: "Attributes",
  BEST_PRICE: "Best Price",
  BEST_SELLER_BADGE: "Bestseller Badge",
  BRAND: "Brand",
  CLEARACNE_SALE_TAGE: "Clearance Sale Tag",
  DEHAAT_CHOICE_BADGE: "Dehaat Choice Badge",
  FEW_QTY_LEFT_TAG: "Few Qty Left Tag",
  FREE_INSURANCE_TAG: "Free Insurance Tag",
  IS_ALTERNATE: "Is Alternate",
  IS_BUNDLE: "Is Bundle",
  IS_BUNDLE_IN_BOTTOM: "Is Bundle In Bottom Sheet",
  IS_BUNDLE_IN_CAROUSAL: "Is Bundle In Carousel",
  IS_PRIORITY_DC: "Is Priority DC",
  IS_PRODUCT_INSTOCK: "Is Product InStock",
  IS_RETURNABLE: "Is Returnable",
  LISTING_PRICE: "Listing Price",
  OFFER_STRIP: "Offer Strip",
  OFFER_STRIP_CTA_NAME: "Offer Strip CTA Name",
  PREPAID_TAG: "Prepaid Tag",
  PRICE_PER_UOM: "Price Per UOM",
  PRODUCT_CATEGORY: "Product Category",
  PRODUCT_POSITION: "Product Position",
  PRODUCT_SUB_CATEGORY: "Product Sub Category",
  PRODUCT_TECHNICAL_NAME: "Product Technical Name",
  PRODCT_TEMPLATE_NAME: "Product Template Name",
  SCREEN_NAME: "Screen Name",
  TEMPALTE_ID: "Template ID",
  SKU_ID: "SKU ID",
  VIEW_ALTERNATE_CTA_AVAILABLE: "View Alternate Product CTA Available",
  FROM_PROGRESSIVE_NUDGE: "Is From Progressive Nudge",
  FROM_BOTTOM_SHEET: "Is From Variant Bottom Sheet",
  APP_PAGE: "App Page",
  SEARCH_TYPE: "Search Type",
  NEW_PACK_SIZE: "New Pack Size",
  NEW_SKU_ID: "New SKU ID",
  OLD_PACK_SIZE: "Old Pack Size",
  OLD_SKU_ID: "Old SKU ID",
  PRODUCT_TEMPLATE_ID: "Product Template ID",
  SKU_COUPON_APPLIED: "No. of SKUs with coupons applied",
  AVAILABLE_CREDIT_LIMIT: "Available Credit Limit",
  CART_VALUE: "Cart Value",
  CREDIT_LIMIT: "Credit Limit",
  LANDING_FROM: "Landing From",
  NUMBER_OF_ITEMS: "Number Of Items",
  OOS_ITEM_COUNT: "OOS Item Count",
  PAYABLE_AMOUNT: "Payable Amount",
  PRE_ORDER_ITEMS_COUNT: "Pre-Order Items Count",
  COUPON_COUNT: "Coupon Count",
  COUPON_NAME: "Coupon Name",
  IS_COUPON_APPLIED: "Is Coupon Applied",
  WALLET_WIDGET: "wallet_widget",
  SHOW_STOCKS_RUNNING_OUT: "Show Stocks Running Out",
  PRODUCT_LIST_VIEW_TYPE: "Product List View Type",
  PRODUCT_LIST_NAME: "Product List Name",
  PREVIOUS_STEP: "Previous Step",
  IMAGE_AVAILABLE: "Image Available",
  PRODUCT_DESCRIPTION_AVAILABLE: "Product Description Available",
  VIEW_BUNDLE_CTA_AVAILABLE: "View Bundles CTA Available",
  PRODUCT_GROUP_ID: "Product Group ID",
  PRODUCT_TYPE: "Product Type",
  CURATED_LIST_ID: "Curated List ID",
  CURATED_LIST_NAME: "Curated List Name",
  FLAT_COUPON_AVAILABLE: "Flat Coupon Available",
  FLAT_PREPAID_COUPON_AVAILABLE: "Flat Prepaid Coupon Available",
  VOLUMN_RATE_COUPON_AVAILABLE: "Volume Rate Coupon Available",
  IS_FROM_VARIANT_BOTTOM_SHEET: "Is From Variant Bottom Sheet",
  CAR_WIDGET_TYPE: "Car Widget Type",
  SEARCH_KEYWORD: "Search Keyword",
  SEARCH_RESULT_PRODUCT_COUNT: "Search Result Product Count",
  AUTO_COMPLETE_POISITION: "Autocomplete suggestion Position clicked",
  AUTO_COMPLETE_CLICKED: "Autocomplete suggestion clicked",
  INSTOCK_PRODUCT_ID_COUNT: "Instock Product ID Count",
  INSTOCK_PRODUCT_ID_LIST: "Instock Product ID List",
  OUTSTOCK_PRODUCT_ID_COUNT: "OOS Product ID Count",
  OUTSTOCK_PRODUCT_ID_LIST: "OOS Product ID List",
  POSITION_CLICKED: "Position Clicked",
  PRODUCT_LIST_ID: "Product List ID",
  ALTERNATE_PRODUCT_PACK_SIZE_PRICE:
    "Alternate Product Pack Size For Which Price Is Shown",
  INSTOCK_SKUS_COUNT: "InStock SKUs Count",
  PARENT_SKU_INSTOCK_STATUS: "Parent SKU InStock Status",
  PARENT_SKU_PACK_SIZE: "Parent SKU Pack Size",
  PARENT_SKU_PRIZE: "Parent SKU Price",
  PARENT_TEMPLATE_ID: "Parent Template ID",
  PRICE_SHOWN_ON_PRODUCT_TEMPLATE: "Price Shown on Product Template",
  PRODUCT_TEMPLATE_POSITION: "Product Template Position",
  WALLET_AMOUNT_USED: "Wallet Amount used",
  PREPAID_EFFECTIVE_AMOUNT: "Prepaid Effective Amount",
  CREDIT_NOTE_AMOUNT_PREPAID: "Credit Note Amount in Prepaid Option",
  MINIUM_PAY_AMOUNT_PREPAID: "Minimum Pay Amount in Prepaid Option",
  DIFFERENCE_PAYMENT_AMOUNTS: "Difference in payment amounts",
  POSTPAID_EFFECTIVE_AMOUNT: "Postpaid Effective Amount",
  MINIUM_PAY_AMOUNT_POSTPAID: "Minimum Pay Amount in Postpaid Option",
  CREDIT_NOTE_AMOUNT_POSTPAID: "Credit Note Amount in Postpaid Option",
  CREDIT_LINE_AMOUNT_POSTPAID_AMOUNT: "Credit Line Amount in Postpaid Option",
  ITEM_COUNT: "itemCount",
  ORDER_VALUE: "orderValue",
  PAYMENT_METHOD_CHOSEN: "Payment Method Chosen",
  FINAL_MINIUM_AMOUNT_ON_CTA: "Final Minimum Amount on CTA",
  IS_DCF: "Is DCF",
  CART_TYPE: "cartType",
  CREDIT_LIMIT_EXHAUSTED: "creditLimitExhausted",
  DC_CREDIT_LINE_STATUS: "DC Credit Line Status",
  DC_OVERDUE_FLAG: "DC Overdue Flag",
  DC_PREPAID_FLAG: "DC Prepaid Flag",
  IS_PARTIAL_ORDER: "Is Partial Order",
  ORDER_AMOUNT: "Order Amount",
  ORDER_ID: "Order Id",
  PRODUCT_LIST: "Product List",
  PRODUCT_TAG: "PRODUCT_TAG",
  SKU_REMOVE_BUTTON_CART_CLICKED: "SKU Remove Button in Cart Clicked",
  PRODUCT_TEMPLATE_NAME: "Product Template Name",
  ACTUAL_SEARCH_TERM: "Actual Search Term",
  SUGGESTED_SEARCH_TERM: "Suggested Search Term",
  RECENT_SEARCH_ITEM_RANK: "Recent Search Item Rank",
  RECENT_SEARCH_ITEM_COUNT: "Recent Search Item Count",
  WALLET_OPTED: "Wallet Opted",
  REMOTE_CONFIG_FOR_BUNDLE: "Remote Config For Bundle",
  COUPON_DISCOUNT_AMOUNT: "Coupon Discount Amount",
  ORDER_QUANTITY: "Order Quantity",
  ADDED_TO_CART_PAGE: "added_to_cart_page",
  SOURCE: "Source",
  TOTAL_PAYABLE_AMOUNT: "TOTAL_PAYABLE_AMOUNT",
  ENTITY_TYPE: "Entity Type",
  ENTITY_ID: "Entity Id",
  PG_TYPE: "PG Type",
  SESSION_ERROR: "Error",
};
