import React from "react";
import { Svg, Path, SvgProps } from "react-native-svg";

interface Props extends SvgProps {
  arrowSvgColor?: string;
}

const UploadLicenceIcon = ({ arrowSvgColor = "#F2C94C", ...props }: Props) => {
  return (
    <Svg fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M12.397 3.804 11.823 0H3.4C2.132 0 1.125 1.033 1.125 2.301v15.927a2.265 2.265 0 0 0 2.275 2.28h7.99l5.756-8.714V5.579l-4.749-1.775Z"
        fill="#27AE60"
      />
      <Path
        d="m11.824.005 5.307 5.574H13.6a1.773 1.773 0 0 1-1.775-1.775V.005Z"
        fill="#219653"
      />
      <Path
        d="M11.618 11.95h-6.8a.524.524 0 0 0-.521.522c0 .287.235.522.522.522h6.8a.523.523 0 0 0 .521-.522.52.52 0 0 0-.522-.522ZM4.819 10.385h5.286a.524.524 0 0 0 .522-.522.524.524 0 0 0-.522-.522H4.82a.523.523 0 0 0-.522.522c0 .287.235.522.522.522ZM9.902 14.612H4.819a.524.524 0 0 0-.522.522c0 .287.235.521.522.521h5.083a.523.523 0 0 0 .521-.521.523.523 0 0 0-.521-.522Z"
        fill="#fff"
      />
      <Path
        d="M17.13 10.755A6.655 6.655 0 0 1 16.2 24a6.644 6.644 0 0 1-5.85-3.486 6.655 6.655 0 0 1 5.85-9.826c.313.005.62.026.928.067Z"
        fill={arrowSvgColor}
      />
      <Path
        d="M16.573 13.495a.525.525 0 0 0-.38-.167.526.526 0 0 0-.382.167l-3.058 3.283a.52.52 0 0 0 .381.876c.141 0 .287-.057.392-.167l2.16-2.312v5.7c0 .286.235.521.522.521a.523.523 0 0 0 .522-.522v-5.699l2.145 2.312a.52.52 0 1 0 .762-.71l-3.064-3.282Z"
        fill="#fff"
      />
    </Svg>
  );
};

export default UploadLicenceIcon;
