import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import Toast from "app/components/reusable/Toast";

const DEFAULT_TOAST_VALUE = {
  SHORT: 2000,
  LONG: 3500,
};

export const ToastAndroid = {
  SHORT: DEFAULT_TOAST_VALUE.SHORT,
  LONG: DEFAULT_TOAST_VALUE.LONG,
  show: (message: string, duration: number) => {
    showToast(message, duration);
  },
  showWithGravity: (message: string, duration: number, gravity: number) => {
    showToast(message, duration);
  },
  showWithGravityAndOffset: (
    message: string,
    duration: number,
    gravity: number,
    xOffset: number,
    yOffset: number
  ) => {
    showToast(message, duration);
  },
};

// Create a new root for rendering each toast dynamically
const showToast = (message: string, duration: number) => {
  const toastContainer = document.createElement("div"); // Create a new div for each toast
  document.body.appendChild(toastContainer); // Append it to the body

  const toastRoot = ReactDOM.createRoot(toastContainer); // Create a new root for each toast

  const removeToast = () => {
    toastRoot.unmount(); // Unmount the toast from the React DOM
    document.body.removeChild(toastContainer); // Remove the toast's container from the DOM
  };

  toastRoot.render(
    <ToastWrapper message={message} duration={duration} onClose={removeToast} />
  );
};

interface Props {
  message: string;
  duration: number;
  onClose: () => void; // Callback to remove toast when closed
}

// Component to handle showing the toast
const ToastWrapper = ({ message, duration, onClose }: Props) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false); // Hide the toast after the duration
      onClose(); // Remove the toast from the DOM
    }, duration);

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, [duration, onClose]);

  return show ? <Toast message={message} /> : null; // Show the toast
};

export default ToastAndroid;
