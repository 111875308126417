import React from "react";
import { Pressable, View, Text, StyleSheet } from "react-native";
import {
  COMMON_STYLES,
  COLORS,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  TEXT_STYLES,
} from "@dehaat/dds";

import BackCaret from "app/partner-ordering-ui/icons/BackCaret";
import LensIcon from "app/partner-ordering-ui/icons/Lens";

interface Props {
  text: string;
  onClick: () => void;
}

const SuggestionItem = ({ text, onClick }: Props) => {
  return (
    <Pressable
      onPress={onClick}
      style={[
        COMMON_STYLES.row,
        COMMON_STYLES.alignItemsCenter,
        COMMON_STYLES.justifyBetween,
        styles.container,
      ]}
    >
      <View
        style={[
          COMMON_STYLES.row,
          COMMON_STYLES.alignItemsCenter,
          COMMON_STYLES["pt1/2"],
          COMMON_STYLES.pb1,
          COMMON_STYLES.flex1,
        ]}
      >
        <LensIcon svgProps={{ width: 20, height: 20 }} />
        <Text
          style={[
            COMMON_STYLES["ml0.5"],
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral90,
          ]}
        >
          {text}
        </Text>
      </View>
      <BackCaret
        width={24}
        height={24}
        style={{ transform: [{ rotateY: "180deg" }] }}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.neutral20,
  },
});

export default SuggestionItem;
