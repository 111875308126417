import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { NoInternetImage } from "app/partner-ordering-ui/assets/images";

const NoInternet = () => {
  const { t } = useTranslation();

  return (
    <View
      style={[
        COMMON_STYLES.flex1,
        COMMON_STYLES.justifyCenter,
        BG_STYLES.bgNeutral10,
      ]}
    >
      <View style={[COMMON_STYLES.alignItemsCenter]}>
        <Image source={NoInternetImage} style={styles.img180X180} />
        <Text
          style={[
            TYPOGRAPHY.HEADING2,
            VARIANTS_STYLES.EMPHASIZED,
            TEXT_STYLES.textNeutral90,
            COMMON_STYLES.mt1,
          ]}
        >
          {t("no_internet")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral70,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {t("no_internet_info")}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  img180X180: {
    height: 180,
    width: 180,
  },
});

export default NoInternet;
