import React from "react";
import { Provider } from "react-redux";
import { withTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { initAxiosInstances, initSentry } from "app/utils/initializer";
import store from "app/store";

import AppNavigator from "./AppNavigator";
import SentryFallback from "app/components/reusable/SentryFallback";

initAxiosInstances();
initSentry();

const AppRoutes = () => {
  return (
    <Sentry.ErrorBoundary fallback={<SentryFallback />}>
      <Provider store={store}>
        <AppNavigator />
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

export default withTranslation()(AppRoutes);
