import React, { useState, useRef } from "react";
import { RefreshControl, Text, View } from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  BUTTON_STATUS,
  BUTTON_TYPE,
  COMMON_STYLES,
  ProductCardHorizontal,
  ProductComboCardHz,
  ProductTags,
  Strip,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import ProductComboList from "../list/ProductComboList";
import ProductPriceInfo from "../ProductPriceInfo";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";

import { SingleProduct } from "app/partner-ordering-ui/models/Cart";
import ComboPriceInfo from "../ComboPriceInfo";
import AlternateItems from "./AlternateItems";
import ShimmerProductCardHz from "../../cards/ProductCardHzShimmer";
import RenderList from "../RenderList";
import OfferStrip from "../list/OfferStrip";
import LicenseInfoTag from "../../resuable/LicenseInfoTag";
import {
  InventoryErrorModalState,
  Product,
  ProductVariant,
} from "app/partner-ordering-ui/models/Product";
import { rnNavigation } from "app/utils/appConfig";
import LockIcon from "app/partner-ordering-ui/icons/LockIcon";
import {
  checkIsMultipleVariants,
  getCombinedLicenseDetails,
  getDisplayVariant,
  getLicenceDetails,
  moqFormatter,
} from "app/partner-ordering-ui/utils/helpers/product";
import { useTranslation } from "react-i18next";
import RupeeClearanceIcon from "app/partner-ordering-ui/icons/RupeeClearance";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import {
  LICENSE_STATUS,
  PRODUCT_TYPE,
} from "app/partner-ordering-ui/constants/Product";
import { ViewProductType } from "app/partner-ordering-ui/models/MixpanelEvent";
import { selectDCData } from "app/store/reducer/native";
import {
  fireClickOnOfferStripEvent,
  fireProductClickedEvent,
  fireProductViewedEvent,
  fireComboProductAddToCartEvent,
  fireProductAddToCartEvent,
} from "app/partner-ordering-ui/utils/helpers/mixpanel";
import {
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "app/partner-ordering-ui/constants/eventNames";
import { getOfferHeadingCta } from "app/partner-ordering-ui/utils/helpers/offer";
import {
  errorArray,
  isAIMS,
} from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import InfoIcon from "app/partner-ordering-ui/icons/InfoIcon";
import { openWhatsApp } from "app/partner-ordering-ui/utils/helpers/common";

interface Props {
  loadingNext: boolean;
  fetchNext: () => void;
  products: Product[];
  getProducts: () => void;
  isOrderingBlocked?: boolean;
  handleRevealPriceClick?: () => void;
}

const RenderSearchResults = ({
  loadingNext,
  products,
  getProducts,
  fetchNext,
  isOrderingBlocked = false,
  handleRevealPriceClick,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const { cartItems, cartInfo } = useCart();
  const dc = useAppSelector(selectDCData);
  const loadedItems = useRef(new Set());

  const isProductInStock = (variant: ProductVariant) =>
    variant.orderable_quantity < Number(variant.min_moq_value || 1) &&
    !dc.app_blocked;

  const onViewableItemsChanged = (options: ViewProductType) =>
    options.viewableItems.forEach((viewItemConfig) => {
      if (
        viewItemConfig.isViewable &&
        !loadedItems.current.has(viewItemConfig.key)
      ) {
        const viewItem = viewItemConfig?.item;
        const displayVariant = getDisplayVariant(viewItem.variants);
        fireProductViewedEvent({
          product: viewItem,
          variant: displayVariant,
          isPriorityDc: dc?.is_priority_dc,
          screenName: ROUTES.PRODUCT_SEARCH,
          isProductInStock: isProductInStock(displayVariant),
          productPosition: viewItemConfig.index + 1,
        });
        loadedItems.current.add(viewItemConfig.key);
      }
    });

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: {
        itemVisiblePercentThreshold: 100,
      },
      onViewableItemsChanged: onViewableItemsChanged,
    },
  ]);

  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });

  const renderListItem = (product: Product, index: number) => {
    const handleInventoryUpdate = (
      id: number | string,
      qty: number,
      maxInventory: number
    ) => {
      if (qty <= maxInventory) dispatch(updateCartItem({ id, qty }));
      else {
        dispatch(updateCartItem({ id, qty: maxInventory }));
        setInventoryErrorState({
          isVisible: true,
          name: product.name,
          availableInventory: maxInventory,
        });
      }
    };

    if (product.type === "bundle") {
      const {
        id,
        name,
        bundled_products,
        actual_price,
        discounted_price,
        orderable_quantity,
      } = product.bundles[0];
      const quantity = cartItems[id]?.qty;

      const images = bundled_products.map((p) => ({
        url: p.image,
        text: `${p.units} \nPkt`,
      }));

      const onQtyUpdate = (qty: number) => {
        const availableQty = Math.min(
          MAX_INVENTORY_ALLOWED,
          orderable_quantity
        );
        handleInventoryUpdate(id, qty, availableQty);
      };

      const { licenseStatus, blockOrder } =
        getCombinedLicenseDetails(bundled_products);

      const onCardClick = () => {
        rnNavigation({
          name: ROUTES.COMBO_DETAIL,
          params: {
            id: product.id,
          },
        });

        fireProductClickedEvent(
          {
            product: product,
            variant: displayVariant,
            productPosition: index + 1,
            isPriorityDc: dc?.is_priority_dc,
            screenName: ROUTES.PRODUCT_LIST,
            isOfferStrip: false,
          },
          {
            [MIXPANEL_KEYS.SKU_ID]: product.bundles[0].id,
            [MIXPANEL_KEYS.TEMPALTE_ID]: product.id,
            [MIXPANEL_KEYS.PREPAID_TAG]: product.bundles[0].is_prepaid,
          }
        );
      };
      return orderable_quantity ? (
        <ProductComboCardHz
          key={id}
          containerStyle={COMMON_STYLES["mb0.5"]}
          images={images}
          productName={name}
          offerLabel={t("combo_offer")}
          onDeleteClick={() => dispatch(deleteCartItem({ id }))}
          onPlusClick={() => onQtyUpdate(quantity + 1)}
          onMinusClick={() => onQtyUpdate(quantity - 1)}
          onQunatityUpdate={(qty) => onQtyUpdate(qty)}
          cta={{
            onClick: () => {
              if (isOrderingBlocked) {
                handleRevealPriceClick?.();
              } else if (
                blockOrder &&
                licenseStatus === LICENSE_STATUS.PENDING
              ) {
                openWhatsApp?.();
              } else {
                dispatch(addItemToCart({ id, product }));
                fireComboProductAddToCartEvent(
                  {
                    product: product?.bundles[0],
                    isPriorityDc: dc?.is_priority_dc,
                    screenName: ROUTES.PRODUCT_LIST,
                    productPosition: index + 1,
                  },
                  {
                    [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]:
                      product?.similar_product_count,
                    [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: false,
                    [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: false,
                  }
                );
              }
            },
            text: isOrderingBlocked
              ? t("price")
              : blockOrder
              ? t("upload_license")
              : t("add"),
            iconRight: isOrderingBlocked ? (
              <LockIcon height={16} width={16} />
            ) : undefined,
            type:
              isOrderingBlocked || blockOrder
                ? BUTTON_TYPE.SECONDARY
                : BUTTON_TYPE.PRIMARY,
            buttonStyle: isOrderingBlocked
              ? BORDER_STYLES.borderBlue10
              : blockOrder && licenseStatus === LICENSE_STATUS.SUBMITTED
              ? COMMON_STYLES.displayNone
              : undefined,
            textStyle: blockOrder ? TYPOGRAPHY.CAPTION1 : null,
          }}
          priceInfo={
            <ComboPriceInfo
              actualPrice={Number(actual_price)}
              discountPrice={Number(discounted_price)}
              priceLocked={isOrderingBlocked}
            />
          }
          onCardClick={onCardClick}
          quantity={quantity}
          availableInventory={Math.min(
            orderable_quantity,
            MAX_INVENTORY_ALLOWED
          )}
        >
          {!isOrderingBlocked && blockOrder && (
            <LicenseInfoTag
              licenseStatus={licenseStatus}
              style={[COMMON_STYLES.mt1, COMMON_STYLES["mb1/2"]]}
            />
          )}
        </ProductComboCardHz>
      ) : null;
    }

    const displayVariant = getDisplayVariant(product.variants);
    const { blockOrder, licenseStatus } = getLicenceDetails({
      categoryType: product.category.type,
      subCategoryId: product.sub_category.id,
    });
    const errorRemark = errorArray(displayVariant);

    const infoFromCart = cartInfo.products.find(
      (item) =>
        item.id === displayVariant.id &&
        item.product_type === PRODUCT_TYPE.REGULAR
    ) as SingleProduct;

    const quantity = cartItems[displayVariant.id]?.qty || 0;
    const id = displayVariant.id;

    const isOutOfStock =
      displayVariant.orderable_quantity <
      Number(displayVariant.min_moq_value || 1);

    const handleQtyUpdate = (qty: number) => {
      const availableQty = Math.min(
        displayVariant.orderable_quantity,
        MAX_INVENTORY_ALLOWED
      );
      handleInventoryUpdate(id, qty, availableQty);
    };

    const onOfferStripClick = (
      stripType: string,
      offerStripDetails: { cta: string; heading: string }
    ) => {
      rnNavigation({
        name: ROUTES.PRODUCT_DETAIL,
        params: {
          id: product.id,
          variantId: id.toString(),
          scrollKey: stripType,
        },
      });
      fireProductClickedEvent({
        product: product,
        variant: displayVariant,
        productPosition: index + 1,
        isPriorityDc: dc?.is_priority_dc,
        isOfferStrip: true,
        screenName: ROUTES.PRODUCT_SEARCH,
        navigationFrm: MIXPANEL_PROPERTIES.OFFER_STRIP,
      });
      fireClickOnOfferStripEvent({
        product: product,
        variant: displayVariant,
        isPriorityDc: dc?.is_priority_dc,
        screenName: ROUTES.PRODUCT_SEARCH,
        offerStripName: offerStripDetails.cta,
      });
    };

    const onCardClick = () => {
      rnNavigation({
        name: ROUTES.PRODUCT_DETAIL,
        params: { id: product.id, variantId: displayVariant?.id },
      });
      fireProductClickedEvent({
        product: product,
        variant: displayVariant,
        productPosition: index + 1,
        isPriorityDc: dc?.is_priority_dc,
        screenName: ROUTES.PRODUCT_SEARCH,
        isOfferStrip: !blockOrder && !isOrderingBlocked,
      });
    };
    const addUnitInfoTag = (
      orderableQuantity: number,
      infoTags: ProductTags[]
    ) => {
      const unitTag: ProductTags[] = isAIMS()
        ? [
            {
              icon: <InfoIcon width={16} height={16} />,
              key: "units",
              name: `${orderableQuantity.toLocaleString("en-IN")} Units`,
              rank: 2,
            },
          ]
        : [];

      const tags: ProductTags[] = unitTag.concat(infoTags);

      return tags;
    };

    return (
      <ProductCardHorizontal
        badgeTags={displayVariant.badge_tags}
        brandName={product.brand?.name}
        containerStyle={[COMMON_STYLES["mb1/2"], BG_STYLES.bgWhite]}
        infoTags={
          addUnitInfoTag(
            displayVariant.orderable_quantity,
            displayVariant.info_tags
          ) || []
        }
        imageUrl={product.image || ""}
        key={product.id}
        hasMultipleVariants={checkIsMultipleVariants(product)}
        onCardClick={() => onCardClick()}
        discountInfo={
          errorRemark.length > 0 ? null : (
            <ProductPriceInfo
              price={displayVariant.dehaat_price}
              constituentUnitPrice={displayVariant.constituent_unit_price}
              constituentUom={displayVariant.constituent_uom}
              discountsInfo={(infoFromCart || displayVariant).discounts_info}
              priceLocked={isOrderingBlocked}
            />
          )
        }
        productName={product.name}
        onDeleteClick={() =>
          dispatch(deleteCartItem({ id: displayVariant.id }))
        }
        remark={
          errorRemark.length > 0 ? (
            <Text
              style={[
                VARIANTS_STYLES.BASE,
                TYPOGRAPHY.CAPTION1,
                TEXT_STYLES.textError100,
              ]}
            >
              {t(errorRemark[0])}
              {errorRemark[1] ? "\n" + t(errorRemark[1]) : ""}
            </Text>
          ) : null
        }
        onPlusClick={() => dispatch(updateCartItem({ id, qty: quantity + 1 }))}
        onMinusClick={() => dispatch(updateCartItem({ id, qty: quantity - 1 }))}
        onQunatityUpdate={handleQtyUpdate}
        packAttribute={displayVariant.pack_attribute}
        unitOfMeasure={displayVariant.constituent_uom}
        quantity={quantity}
        handleVariantSelect={() => setSelectedProduct(product)}
        totalAddedQuantity={infoFromCart?.total_added_quantity || 0}
        isOutOfStock={isOutOfStock && !isOrderingBlocked}
        showUploadLicenseCta={!isOrderingBlocked && blockOrder}
        onUploadLicenceCTAClick={
          !isOrderingBlocked &&
          blockOrder &&
          licenseStatus === LICENSE_STATUS.PENDING
            ? openWhatsApp
            : undefined
        }
        minimumOrderQuanity={
          errorRemark.length > 0
            ? 0
            : parseInt(displayVariant?.min_moq_value || "1")
        }
        cta={{
          onClick: () => {
            if (isAIMS() && errorRemark.length > 0) {
              return;
            }
            if (isOrderingBlocked) {
              handleRevealPriceClick?.();
            } else {
              dispatch(addItemToCart({ id, product }));
              fireProductAddToCartEvent({
                product: product,
                variant: displayVariant,
                isPriorityDc: dc?.is_priority_dc,
                screenName: ROUTES.PRODUCT_SEARCH,
                productPosition: index + 1,
              });
            }
          },
          text: isOrderingBlocked ? t("price") : t("add"),
          iconRight: isOrderingBlocked ? (
            <LockIcon height={16} width={16} />
          ) : undefined,
          type: isOrderingBlocked ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY,
          buttonStyle: isOrderingBlocked
            ? BORDER_STYLES.borderBlue10
            : undefined,
          status:
            isAIMS() && errorRemark.length > 0
              ? BUTTON_STATUS.DISABLED
              : BUTTON_STATUS.ACTIVE,
        }}
        availableInventory={Math.min(
          displayVariant.orderable_quantity,
          MAX_INVENTORY_ALLOWED
        )}
        moqDisplayValue={moqFormatter(
          displayVariant.moq_label_value,
          displayVariant.moq_type,
          displayVariant.constituent_uom
        )}
      >
        {product.is_clearance_sale && (
          <Strip
            heading={t("get_this_item_cheaper")}
            subHeading={t("this_item_is_available_in_clearance_sale")}
            actionTxt={t("view_sale")}
            imageOrIcon={<RupeeClearanceIcon height={16} width={12} />}
            bgColor="bgOrange20"
            textColor="orange120"
            onClick={() =>
              rnNavigation({
                name: ROUTES.CLEARANCE_SALE_LIST,
                params: {
                  id: product.id,
                },
              })
            }
            containerStyle={[COMMON_STYLES.mh1, COMMON_STYLES["mb0.5"]]}
          />
        )}

        {!blockOrder && !isOrderingBlocked && (
          <View style={[COMMON_STYLES.mh1, COMMON_STYLES.mb1]}>
            {!isOutOfStock && (
              <OfferStrip
                id={product.id}
                isBundle={product.bundles_available}
                discountInfo={(infoFromCart || displayVariant).discounts_info}
                constituentUom={displayVariant.constituent_uom}
                quantity={quantity}
                onClick={(stripType) => {
                  const offerStripDetails = getOfferHeadingCta({
                    isBundle: product.bundles_available,
                    discountInfo: (infoFromCart || displayVariant)
                      .discounts_info,
                    constituentUom: displayVariant.constituent_uom,
                    quantity: quantity,
                  });
                  onOfferStripClick(stripType, offerStripDetails);
                }}
              />
            )}
            {product.bundles_available && quantity > 0 && (
              <ProductComboList
                id={product.id}
                currentScreen={ROUTES.PRODUCT_SEARCH}
                eventData={{
                  product: product,
                  variant: displayVariant,
                  productPosition: index,
                }}
              />
            )}
          </View>
        )}
        {!isOrderingBlocked && blockOrder && (
          <LicenseInfoTag licenseStatus={licenseStatus} />
        )}
        {product.similar_product_count ? (
          <AlternateItems
            id={product.id}
            technicalContent={product.technical_contents?.[0]}
            onSelectProduct={(product) => setSelectedProduct(product)}
            isOrderingBlocked={isOrderingBlocked}
            handleRevealPriceClick={handleRevealPriceClick}
          />
        ) : null}
      </ProductCardHorizontal>
    );
  };

  return (
    <RenderList
      selectedProduct={selectedProduct}
      listProps={{
        data: products,
        renderItem: ({ item, index }) => renderListItem(item, index),
        onEndReached: fetchNext,
        ListFooterComponent: loadingNext ? <ShimmerProductCardHz /> : null,
        viewabilityConfigCallbackPairs: viewabilityConfigCallbackPairs.current,
        refreshControl: (
          <RefreshControl refreshing={false} onRefresh={getProducts} />
        ),
      }}
      removeSelectedProduct={() => setSelectedProduct(undefined)}
      inventoryModalState={inventoryErrorState}
      resetModalState={() => setInventoryErrorState({ isVisible: false })}
      landingFrom={ROUTES.PRODUCT_SEARCH}
    />
  );
};

export default RenderSearchResults;
