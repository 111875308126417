import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Pressable,
  TouchableOpacity,
} from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  BORDER_STYLES,
  COLORS,
  COMMON_STYLES,
  DefaultProductImage,
  DeleteIcon,
  Label,
  LazyImage,
  PlusMinusButtonWithInput,
  ProductTags,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import { DeliveryInfo, PriceError } from "../../models/Cart";
import { DATE_FORMATS, formatDate } from "../../utils/helpers/date";
import CartItemPriceInfo from "./CartItemPriceInfo";
import ExpiryClockIcon from "../../icons/ExpiryClock";
import ClearanceIcon from "../../icons/Clearance";
import { DeliveryIcon } from "../../assets/images";
import WarningRedIcon from "../../icons/WarningRed";
import { MAX_INVENTORY_ALLOWED } from "../../constants/Common";
import ZoomLensIcon from "../../icons/ZoomLens";
import ModalImageCarousal from "../resuable/ModalImageCarousal";

interface Props {
  discountedContituentPrice?: number | null;
  infoTags?: ProductTags[];
  name: string;
  packAttribute?: string;
  unitPrice: number;
  constituentUnitPrice: number | null;
  uom: string;
  totalPrice: number;
  totalVolume?: number;
  imageUrl: string;
  quantity: number;
  discountedPrice?: number | null;
  handleQuantityUpdate: (qty: number) => void;
  handleRemoveCartItem: () => void;
  deliveryInfo?: DeliveryInfo[];
  expiryDate: string;
  totalInventory?: number;
  priceError?: PriceError;
}

const ClearanceCartItem = ({
  discountedContituentPrice = 0,
  infoTags,
  name,
  packAttribute,
  unitPrice,
  constituentUnitPrice,
  uom,
  totalPrice,
  totalVolume = 0,
  imageUrl,
  quantity,
  discountedPrice = 0,
  handleQuantityUpdate,
  handleRemoveCartItem,
  deliveryInfo,
  expiryDate,
  totalInventory = MAX_INVENTORY_ALLOWED,
  priceError,
}: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleImageClick = () => {
    setIsVisible(true);
  };

  return (
    <View
      style={[BG_STYLES.bgWhite, COMMON_STYLES.br1, COMMON_STYLES["mb1/2"]]}
    >
      <View style={[COMMON_STYLES.ph1, COMMON_STYLES["pt3/4"]]}>
        {infoTags ? (
          <View style={COMMON_STYLES.row}>
            {infoTags.map((tag) => (
              <Label
                key={tag.key}
                text={tag.name}
                leftIcon={
                  <LazyImage
                    imageUrl={tag.icon as string}
                    fallBackIcon={null}
                    imgStyles={styles.image16x16}
                  />
                }
                bgColor="bgNeutral00"
              />
            ))}
            {/** Add Clearance Icon for Clearance Products */}
            <Label
              text={t("clearance_sale")}
              bgColor="bgNeutral00"
              containerStyle={COMMON_STYLES["ml0.5"]}
              leftIcon={<ClearanceIcon />}
            />
          </View>
        ) : null}

        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.justifyBetween,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          <View style={[COMMON_STYLES.row, COMMON_STYLES.flex1]}>
            <TouchableOpacity onPress={handleImageClick}>
              <LazyImage
                imageUrl={imageUrl}
                fallBackIcon={
                  <Image
                    source={DefaultProductImage}
                    style={styles.productImage}
                  />
                }
                imgStyles={styles.productImage}
              />
              <ZoomLensIcon
                width={14}
                height={14}
                style={[COMMON_STYLES.absolute, styles.zoomStyle]}
              />
            </TouchableOpacity>
            <ModalImageCarousal
              isVisible={isVisible}
              onClose={() => setIsVisible(false)}
              images={[imageUrl]}
            />
            <View style={COMMON_STYLES.flex1}>
              <View style={COMMON_STYLES.row}>
                <View style={[COMMON_STYLES["ml1/4"], COMMON_STYLES.flex1]}>
                  <Text
                    numberOfLines={2}
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.SEMI_EMPHASIZED,
                      TEXT_STYLES.textNeutral100,
                    ]}
                  >
                    {name}
                  </Text>
                  {packAttribute ? (
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral80,
                        COMMON_STYLES["mt1/8"],
                      ]}
                    >
                      {packAttribute}
                    </Text>
                  ) : null}
                  <CartItemPriceInfo
                    price={unitPrice}
                    constituentUnitPrice={constituentUnitPrice || undefined}
                    constituentUom={uom}
                    discountPrice={discountedPrice || 0}
                    discountConstituentUnitPrice={
                      discountedContituentPrice || 0
                    }
                  />
                </View>
                <View style={[COMMON_STYLES.ml1, styles.width120]}>
                  <PlusMinusButtonWithInput
                    onDeleteClick={handleRemoveCartItem}
                    onMinusClick={() => handleQuantityUpdate(quantity - 1)}
                    onPlusClick={() => handleQuantityUpdate(quantity + 1)}
                    onQuantityUpdate={handleQuantityUpdate}
                    quantity={quantity}
                    size={SIZE.small}
                    maximumQty={Math.min(totalInventory, MAX_INVENTORY_ALLOWED)}
                  />

                  <View
                    style={[
                      COMMON_STYLES.row,
                      COMMON_STYLES.justifyEnd,
                      COMMON_STYLES.alignItemsCenter,
                      COMMON_STYLES["mt1/4"],
                    ]}
                  >
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.BASE,
                        TEXT_STYLES.textNeutral80,
                      ]}
                    >
                      {t("item_total")}
                    </Text>
                    <Text
                      style={[
                        TYPOGRAPHY.CAPTION1,
                        VARIANTS_STYLES.SEMI_EMPHASIZED,
                        TEXT_STYLES.textNeutral100,
                        COMMON_STYLES["ml0.5"],
                      ]}
                    >
                      {formatCurrency(totalPrice)}
                    </Text>
                  </View>
                  {totalVolume ? (
                    <View
                      style={[
                        COMMON_STYLES.row,
                        COMMON_STYLES.justifyEnd,
                        COMMON_STYLES.alignItemsCenter,
                        COMMON_STYLES["mt1/4"],
                      ]}
                    >
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          TEXT_STYLES.textNeutral80,
                        ]}
                      >
                        {t("volume_total")}
                      </Text>
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.SEMI_EMPHASIZED,
                          TEXT_STYLES.textNeutral100,
                          COMMON_STYLES["ml0.5"],
                        ]}
                      >
                        {`${totalVolume}${uom}`}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        </View>
        {totalInventory && totalInventory < quantity ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.BASE,
                TEXT_STYLES.textOrange100,
              ]}
            >
              {t("max_qty_error", { count: totalInventory })}
            </Text>
          </View>
        ) : null}
        {priceError ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            <View style={[COMMON_STYLES.row]}>
              <WarningRedIcon style={COMMON_STYLES["mr1/4"]} />
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                  TEXT_STYLES.textOrange100,
                ]}
              >
                {t("price_change_error", {
                  previous: formatCurrency(priceError.previous),
                  current: formatCurrency(priceError.current),
                })}
              </Text>
            </View>
          </View>
        ) : null}
        {deliveryInfo || expiryDate ? (
          <View
            style={[
              COMMON_STYLES["pt1/2"],
              COMMON_STYLES["mt3/4"],
              BORDER_STYLES.borderNeutral10,
              { borderTopWidth: 1 },
            ]}
          >
            <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
              <ExpiryClockIcon />
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                  TEXT_STYLES.textNeutral80,
                  COMMON_STYLES["ml0.5"],
                ]}
              >
                {t("product_expires_on", {
                  date: formatDate(
                    parseInt(expiryDate),
                    DATE_FORMATS["DD Mmm YYYY"]
                  ),
                })}
              </Text>
            </View>
            {deliveryInfo?.map((info, index) => {
              const date = info.eta_to
                ? formatDate(info.eta_to, DATE_FORMATS["DD Mmm"])
                : "";
              return (
                <View key={index} style={COMMON_STYLES.row}>
                  <Image source={DeliveryIcon} style={styles.image20x20} />
                  <Text
                    style={[
                      TYPOGRAPHY.CAPTION1,
                      VARIANTS_STYLES.BASE,
                      TEXT_STYLES.textNeutral80,
                      COMMON_STYLES["ml1/4"],
                    ]}
                  >
                    {t("single_sku_delivery_info", {
                      count: info.quantity,
                      isMulipleUnits: info.quantity > 1 ? "s" : "",
                      date,
                    })}
                  </Text>
                </View>
              );
            })}
          </View>
        ) : null}
      </View>
      <View
        style={[
          COMMON_STYLES.b1,
          BORDER_STYLES.borderNeutral10,
          COMMON_STYLES["mt3/4"],
        ]}
      >
        <Pressable
          onPress={handleRemoveCartItem}
          style={({ pressed }) => [
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES.justifyCenter,
            COMMON_STYLES["pv1/2"],
            COMMON_STYLES.ph1,
            pressed && COMMON_STYLES["opacity1/2"],
          ]}
        >
          <DeleteIcon
            svgProps={styles.image16x16}
            pathProps={{ stroke: COLORS.neutral90 }}
          />
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral90,
              COMMON_STYLES["ml0.5"],
            ]}
          >
            {t("remove")}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

export default ClearanceCartItem;

const styles = StyleSheet.create({
  productImage: {
    width: 48,
    height: 48,
    resizeMode: "contain",
  },
  image16x16: { height: 16, width: 16 },
  image20x20: { height: 20, width: 20 },
  zoomStyle: { top: 34, left: 29 },
  width120: { width: 120 },
});
