import React, { useState } from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  COMMON_STYLES,
  ProductCardHorizontal,
  Strip,
} from "@dehaat/dds";

import {
  GrowthPlanBasketProduct,
  GrowthPlanBasketProductVariant,
  InventoryErrorModalState,
} from "app/partner-ordering-ui/models/Product";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import { useAppDispatch } from "app/hooks/reduxHooks";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import ProductPriceInfo from "../ProductPriceInfo";
import { SingleProduct } from "app/partner-ordering-ui/models/Cart";

import GrowthBasketProductStrip from "./GrowthBasketProductStrip";
import { rnNavigation } from "app/utils/appConfig";
import {
  checkIsMultipleVariants,
  getDisplayVariant,
  moqFormatter,
} from "app/partner-ordering-ui/utils/helpers/product";
import RupeeClearanceIcon from "app/partner-ordering-ui/icons/RupeeClearance";
import RenderList from "../RenderList";
import ProductComboList from "../list/ProductComboList";
import AlternateItemsList from "../list/AlternateItemsList";
import OfferStrip from "../list/OfferStrip";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import { PRODUCT_TYPE } from "app/partner-ordering-ui/constants/Product";
import { fireProductAddToCartEvent } from "app/partner-ordering-ui/utils/helpers/mixpanel";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";

interface Props {
  products: GrowthPlanBasketProduct[];
}

const RenderGrowthBasketList = ({ products }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { cartItems, cartInfo } = useCart();
  const [selectedProduct, setSelectedProduct] =
    useState<GrowthPlanBasketProduct>();

  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });
  const dc = useAppSelector(selectDCData);

  const renderListItem = (product: GrowthPlanBasketProduct, index: number) => {
    if (product.type !== "bundle") {
      const displayVariant = getDisplayVariant(
        product.variants
      ) as GrowthPlanBasketProductVariant;
      const infoFromCart = cartInfo.products.find(
        (item) =>
          item.id === displayVariant.id &&
          item.product_type === PRODUCT_TYPE.REGULAR
      ) as SingleProduct;

      const quantity = cartItems[displayVariant.id]?.qty || 0;
      const id = displayVariant.id;
      const isOutOfStock =
        displayVariant.orderable_quantity <
        Number(displayVariant.min_moq_value || 1);

      const handleQtyUpdate = (qty: number) => {
        const availableQty = Math.min(
          displayVariant.orderable_quantity,
          MAX_INVENTORY_ALLOWED
        );
        if (qty <= availableQty) dispatch(updateCartItem({ id, qty }));
        else {
          dispatch(updateCartItem({ id, qty: availableQty }));
          setInventoryErrorState({
            isVisible: true,
            name: product.name,
            availableInventory: availableQty,
          });
        }
      };

      return (
        <ProductCardHorizontal
          badgeTags={displayVariant.badge_tags}
          brandName={product.brand?.name}
          containerStyle={[COMMON_STYLES["mb1/2"], BG_STYLES.bgWhite]}
          infoTags={displayVariant.info_tags || []}
          imageUrl={product.image || ""}
          key={product.id}
          hasMultipleVariants={checkIsMultipleVariants(product)}
          onCardClick={() =>
            rnNavigation({
              name: ROUTES.PRODUCT_DETAIL,
              params: {
                id: product.id,
                variantId: id.toString(),
              },
            })
          }
          discountInfo={
            <ProductPriceInfo
              price={displayVariant.dehaat_price}
              constituentUnitPrice={displayVariant.constituent_unit_price}
              constituentUom={displayVariant.constituent_uom}
              discountsInfo={(infoFromCart || displayVariant).discounts_info}
            />
          }
          productName={product.name}
          onDeleteClick={() =>
            dispatch(deleteCartItem({ id: displayVariant.id }))
          }
          onPlusClick={() =>
            dispatch(updateCartItem({ id, qty: quantity + 1 }))
          }
          onMinusClick={() =>
            dispatch(updateCartItem({ id, qty: quantity - 1 }))
          }
          onQunatityUpdate={handleQtyUpdate}
          packAttribute={displayVariant.pack_attribute}
          unitOfMeasure={displayVariant.constituent_uom}
          quantity={quantity}
          handleVariantSelect={() => setSelectedProduct(product)}
          totalAddedQuantity={infoFromCart?.total_added_quantity || 0}
          isOutOfStock={isOutOfStock}
          minimumOrderQuanity={parseInt(displayVariant?.min_moq_value || "1")}
          cta={{
            onClick: () => {
              dispatch(addItemToCart({ id, product }));
              fireProductAddToCartEvent({
                product: product,
                variant: displayVariant,
                screenName: ROUTES.GROWTH_PLAN_LIST,
                productPosition: index + 1,
                isPriorityDc: dc?.is_priority_dc,
              });
            },
            text: t("add"),
          }}
          moqDisplayValue={moqFormatter(
            displayVariant.moq_label_value,
            displayVariant.moq_type,
            displayVariant.constituent_uom
          )}
          availableInventory={Math.min(
            displayVariant.orderable_quantity,
            MAX_INVENTORY_ALLOWED
          )}
        >
          <View style={[COMMON_STYLES.mh1, COMMON_STYLES.mb1]}>
            {displayVariant.is_in_growth_basket && (
              <GrowthBasketProductStrip
                plannedQty={displayVariant.planned_qty}
                orderedQty={displayVariant.ordered_qty || 0}
              />
            )}
            {product.is_clearance_sale && (
              <Strip
                heading={t("get_this_item_cheaper")}
                subHeading={t("this_item_is_available_in_clearance_sale")}
                actionTxt={t("view_sale")}
                imageOrIcon={<RupeeClearanceIcon height={16} width={12} />}
                bgColor="bgOrange20"
                textColor="orange120"
                onClick={() =>
                  rnNavigation({
                    name: ROUTES.CLEARANCE_SALE_LIST,
                    params: {
                      id: product.id,
                    },
                  })
                }
                containerStyle={COMMON_STYLES["mb0.5"]}
              />
            )}
            <OfferStrip
              id={product.id}
              isBundle={product.bundles_available}
              discountInfo={
                (infoFromCart || displayVariant).discounts_info || {}
              }
              constituentUom={displayVariant.constituent_uom}
              quantity={quantity}
              onClick={(stripType) =>
                rnNavigation({
                  name: ROUTES.PRODUCT_DETAIL,
                  params: {
                    id: product.id,
                    variantId: id.toString(),
                    scrollKey: stripType,
                  },
                })
              }
            />
            {product.bundles_available && quantity > 0 && (
              <ProductComboList
                id={product.id}
                currentScreen={ROUTES.GROWTH_PLAN_LIST}
                eventData={{
                  product: product,
                  variant: displayVariant,
                  productPosition: index,
                }}
              />
            )}
            {isOutOfStock && product.similar_product_count ? (
              <AlternateItemsList
                id={product.id}
                noOfAlternateProducts={product.similar_product_count}
                technicalContents={product.technical_contents}
                containerStyle={COMMON_STYLES["mt5/4"]}
              />
            ) : null}
          </View>
        </ProductCardHorizontal>
      );
    }
    return <></>;
  };

  return (
    <RenderList
      selectedProduct={selectedProduct}
      removeSelectedProduct={() => setSelectedProduct(undefined)}
      listProps={{
        data: products,
        renderItem: ({ item, index }) =>
          renderListItem(item as GrowthPlanBasketProduct, index),
      }}
      inventoryModalState={inventoryErrorState}
      resetModalState={() => setInventoryErrorState({ isVisible: false })}
      landingFrom={ROUTES.GROWTH_PLAN_LIST}
    />
  );
};

export default RenderGrowthBasketList;
