import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ScrollView, View, RefreshControl } from "react-native";
import { useTranslation } from "react-i18next";

import CreditLimitTracker from "app/partner-ordering-ui/components/cart/CreditLimitTracker";
import {
  BG_STYLES,
  COMMON_STYLES,
  HorizontalStepper,
  formatCurrency,
} from "@dehaat/dds";

import validateCart from "app/partner-ordering-ui/apis/validateCart";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import {
  checkIsInactiveOrOOSProductExists,
  checkIsMoqErrorExists,
  createValidateCartQuery,
  getWalletBalance,
  updateCartData,
} from "app/partner-ordering-ui/utils/helpers/cart";
import {
  AmountDetails,
  CartBundleSKUInfo,
  CartClearanceSKUInfo,
  CartSingleSKUInfo,
  Discount,
  PRODUCT_STATUS,
  ProductTag,
  DeliveryInfo,
  CartData,
  CartItem as CartItems,
  ISnackbar,
  ValidationError,
} from "app/partner-ordering-ui/models/Cart";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import CartItem from "app/partner-ordering-ui/components/cart/CardItem";
import OrderDetail from "app/partner-ordering-ui/components/cart/OrderDetail";
import Footer from "app/partner-ordering-ui/components/cart/Footer";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  deleteCartItem,
  resetCart,
  updateCartItem,
  updateValidateCartData,
  updateNegotiablePrice,
} from "app/partner-ordering-ui/store/reducer/cart";
import { selectDCData } from "app/store/reducer/native";
import { PaymentMethods } from "app/partner-ordering-ui/models/Payment";
import {
  finishCurrentActivity,
  logEvent,
  openOrderHistory,
} from "app/utils/appConfig";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import CartPayment from "app/partner-ordering-ui/components/cart/Payment";
import { PRODUCT_TYPE } from "app/partner-ordering-ui/constants/Product";
import ClearanceCartItem from "app/partner-ordering-ui/components/cart/ClearanceItem";
import ComboCartItem from "app/partner-ordering-ui/components/cart/ComboItem";
import CartDynamicFooter from "app/partner-ordering-ui/components/cart/CartDynamicFooter";
import CartMOQStrip from "app/partner-ordering-ui/components/cart/CartMOQStrip";
import useCartErrors from "app/partner-ordering-ui/hooks/useCartErrors";
import { CART_ERROR_CODES } from "app/partner-ordering-ui/constants/Cart";
import InactiveCartItem from "app/partner-ordering-ui/components/cart/InactiveItemCard";
import AlternateItemsList from "app/partner-ordering-ui/components/productList/list/AlternateItemsList";
import ApplicableSchemes from "app/partner-ordering-ui/components/cart/ApplicableSchemes";
import RemoveItemConfirmationModal from "app/partner-ordering-ui/components/cart/RemoveItemConfirmationModal";
import NoItemCart from "app/partner-ordering-ui/components/cart/NoItemCart";

import useFocusHook from "../../hooks/useFocusHook";
import InsufficientInventoryModal from "../../components/resuable/InsufficientInventoryModal";
import { InventoryErrorModalState, Product } from "../../models/Product";
import { MAX_INVENTORY_ALLOWED } from "../../constants/Common";
import {
  MIXPANEL_EVENT,
  MIXPANEL_KEYS,
  MIXPANEL_PROPERTIES,
} from "../../constants/eventNames";
import InactiveCartBundleItem from "./ComboItemInactive";
import PriceNegotiableBottomDrawer from "./PriceNegotiableBottomDrawer";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import Snackbar from "../resuable/Snackbar";
import LogErrorModal from "../LogError";
import { fireRemoveCartItem } from "app/partner-ordering-ui/utils/helpers/mixpanel";
import DuplicateOrderModal from "app/partner-ordering-ui/components/cart/DuplicateOrderModal";
import ToastAndroid from "app/mocks/ToastAndroid";

interface ModalState {
  visible: boolean;
  id?: string | number;
  qty?: number;
  batchId?: string;
}

const initialModalState = {
  visible: false,
};

interface Props {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  onCreateOrder: (
    cartData: CartData,
    amountDetails: any,
    cartId: string,
    paymentMethod: PaymentMethods,
    isWalletApplied: boolean,
    ibId: string
  ) => void;
  landingFrom: string;
}

const RenderCart = ({
  activeIndex,
  setActiveIndex,
  onCreateOrder,
  loading,
  setLoading,
  landingFrom,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [tags, setTags] = useState<ProductTag[]>([]);
  const [amountDetails, setAmountDetails] = useState<AmountDetails>();
  const { isError, error, updateError, resetError } = useCartErrors();
  const [isWalletApplied, setIsWalletApplied] = useState(true);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [modalVisible, setModalVisible] =
    useState<ModalState>(initialModalState);
  const [validationError, setValidationError] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: "" });
  const [snackbar, setSnackbarState] = useState<ISnackbar>({ visible: false }); // Used to show Warning or validation message on snackbar

  const {
    cartData,
    cartItems,
    getNumberOfItemsInCart,
    cartId,
    updateCartId,
    minOrderAmount,
    getNoOfLotInCart,
  } = useCart();

  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });

  const [priceNegotiableModalState, setPriceNegotiableModalState] = useState<{
    visible: boolean;
    key?: string | number;
    data?: CartSingleSKUInfo;
  }>({ visible: false });

  const isFocused = useFocusHook();

  const dc = useAppSelector(selectDCData);

  const noOfItemsInCart = getNumberOfItemsInCart();
  const [cartMOQ, setCartMOQ] = useState(minOrderAmount);

  const walletBalance = getWalletBalance(
    amountDetails?.payment_details.payment_breakup
  );

  const checkandUpdateErrorInCart = (data: CartData) => {
    let isValid = true;
    const noOfInactiveOrOOSItems = checkIsInactiveOrOOSProductExists(data);
    if (noOfInactiveOrOOSItems) {
      isValid = false;
      setSnackbarState({
        visible: true,
        title: t("remove_item_cart_title"),
        message: t("inactive_item_alert_msg", {
          count: noOfInactiveOrOOSItems,
        }),
      });
    } else {
      const isMoqIndividualReached = checkIsMoqErrorExists(data);
      if (isMoqIndividualReached) {
        setSnackbarState({
          visible: true,
          message: `${isMoqIndividualReached} item/s in your cart has not reached the minimum order quantity.`,
        });
        isValid = false;
      } else if (isError) {
        if (error[CART_ERROR_CODES.MOQ_CART]) {
          const { key, params } = error[CART_ERROR_CODES.MOQ_CART];
          setSnackbarState({
            visible: true,
            message: t(key, params),
          });
        }
        isValid = false;
      }
    }

    return isValid;
  };

  const fireCartMixapanelEvent = (
    eventName: string,
    payload?: Record<string, unknown>
  ) => {
    const mixPanelPayload = {
      [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
      ...payload,
    };
    if (eventName !== MIXPANEL_EVENT.WALLET_OPTED_IN_CART) {
      mixPanelPayload[MIXPANEL_KEYS.SCREEN_NAME] = ROUTES.MY_CART;
    }
    logEvent(eventName, JSON.stringify(mixPanelPayload));
  };

  const validateCartAPICall = async (
    products: Record<string, any>[],
    walletApplied = true
  ) => {
    try {
      const response = await validateCart({
        cart_id: cartId,
        products,
        wallet_applied: walletApplied,
        partner_id: dc?.partner_id,
      });
      return response;
    } catch (e: any) {
      const errorCode =
        e.statusCode === 409
          ? CART_ERROR_CODES.DUPLICATE_ORDER
          : CART_ERROR_CODES.VALIDATE_CART_FAILED;
      updateError(errorCode, { key: e.message });
      return null;
    }
  };

  const checkValidationError = (validationErrors: ValidationError[]) => {
    let error = "";
    if (validationErrors) {
      for (let i = 0; i < validationErrors.length; i++) {
        error += i + 1 + ". " + validationErrors[i].message + "\n";
      }
      setValidationError({ isError: true, message: error });
      setSnackbarState({ visible: true, message: error });
    }
  };

  const validateCartInfo = async (
    walletApplied = true,
    navigateIfValid = false
  ) => {
    setLoading(true);
    try {
      const queryProducts = createValidateCartQuery(cartData);
      const response = await validateCartAPICall(queryProducts, walletApplied);
      const updatedData = updateCartData(cartData, response);
      const info = Object.keys(updatedData).map((key) => updatedData[key]);

      if (info) {
        const subCategory = info.map((item) => item.subCategory.name);
        const couponName: string[] = [];
        const preOrderItems: string[] = [];
        info.forEach((item) =>
          item.deliveryInfo?.forEach((element) => {
            if (element.type === "preorder") {
              preOrderItems.push(element.type);
            }

            ((item as CartSingleSKUInfo).appliedDiscounts || []).forEach(
              (element) => couponName.push(element.identifier)
            );
          })
        );
        const SKUID = info.map((item) => item.productId);
        const outOfStockItems = info.filter(
          (item) => item.totalOrderInventory === 0
        );
        const couponCount = info.filter(
          (item) =>
            ((item as CartSingleSKUInfo).appliedDiscounts || []).length > 0
        );

        fireCartMixapanelEvent(MIXPANEL_EVENT.CART_SCREEN_VIEWED, {
          [MIXPANEL_KEYS.SKU_COUPON_APPLIED]: couponCount.length,
          [MIXPANEL_KEYS.AVAILABLE_CREDIT_LIMIT]: dc?.available_credit,
          [MIXPANEL_KEYS.CART_VALUE]: amountDetails?.cart_details.order_total,
          [MIXPANEL_KEYS.CREDIT_LIMIT]: dc?.credit_limit,
          [MIXPANEL_KEYS.LANDING_FROM]: landingFrom,
          [MIXPANEL_KEYS.NUMBER_OF_ITEMS]: getNoOfLotInCart(),
          [MIXPANEL_KEYS.OOS_ITEM_COUNT]: outOfStockItems?.length,
          [MIXPANEL_KEYS.PAYABLE_AMOUNT]:
            amountDetails?.payment_details?.amount_payable,
          [MIXPANEL_KEYS.PRE_ORDER_ITEMS_COUNT]: preOrderItems.length,
          [MIXPANEL_KEYS.SKU_ID]: SKUID,
          [MIXPANEL_KEYS.PRODUCT_SUB_CATEGORY]: subCategory,
        });
        fireCartMixapanelEvent(MIXPANEL_EVENT.COUPON_APPLIED, {
          [MIXPANEL_KEYS.COUPON_COUNT]: couponCount.length,
          [MIXPANEL_KEYS.COUPON_NAME]: couponName,
          [MIXPANEL_KEYS.IS_COUPON_APPLIED]: couponCount.length > 0,
        });
        fireCartMixapanelEvent(MIXPANEL_EVENT.WALLET_WIDGET_ENABLED, {
          [MIXPANEL_KEYS.WALLET_WIDGET]: isWalletApplied,
        });
      }

      dispatch(updateValidateCartData({ data: updatedData })).finally(() => {
        if (response) {
          setTags(response.product_tags);
          setAmountDetails(response.amount_details);
          updateCartId(response.cart_id);
          setCartMOQ(response.min_moq_value || minOrderAmount);
          setDiscounts(response.discounts || []);
          if (!response.any_payment_method_enabled) {
            checkValidationError(response.validation_errors!);
          }
          if (navigateIfValid) {
            if (response.is_valid) {
              setActiveIndex(1);
              fireClickOnChoosePayment();
            } else {
              checkandUpdateErrorInCart(updatedData);
            }
          }
        }
      });
    } catch (err) {
      console.log(err);
      ToastAndroid.show(
        "Something went wrong. Please try again later",
        ToastAndroid.SHORT
      );
    }
    setLoading(false);
  };

  const handleRemoveItem = () => {
    dispatch(
      deleteCartItem({
        id: modalVisible.id!,
        batchId: modalVisible.batchId,
      })
    );
    setModalVisible(initialModalState);
    let removedData:
      | CartSingleSKUInfo
      | CartBundleSKUInfo
      | CartClearanceSKUInfo
      | undefined;
    let removedItem: Product | undefined;
    cartData &&
      Object.values(cartData)?.forEach((item) => {
        if (item.id === modalVisible.id) {
          removedData = item;
        }
      });
    cartItems &&
      Object.values(cartItems)?.forEach((item: CartItems) => {
        if (item?.query?.id === modalVisible.id) {
          removedItem = item?.detail;
        }
      });

    fireRemoveCartItem({
      removedData: removedData,
      removedItem: removedItem,
      screenName: ROUTES.MY_CART,
      isPriorityDc: dc?.is_priority_dc,
    });
  };

  const onCloseModalOrNotRemoveHandler = () => {
    setModalVisible(initialModalState);
  };

  const onRemoveCartItemClick = (
    id: string | number,
    qty?: number,
    batchId?: string
  ) => setModalVisible({ visible: true, id, qty, batchId });

  useEffect(() => {
    const orderTotal = amountDetails?.cart_details?.order_total;
    if (isFocused && orderTotal && orderTotal < cartMOQ)
      updateError(CART_ERROR_CODES.MOQ_CART, {
        key: "moq_value",
        params: { value: formatCurrency(cartMOQ) },
      });
    else resetError(CART_ERROR_CODES.MOQ_CART);
  }, [isFocused, cartMOQ, amountDetails?.cart_details?.order_total]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isFocused && !activeIndex && noOfItemsInCart > 0) {
      timer = setTimeout(validateCartInfo, 500);
    }
    return () => clearTimeout(timer);
  }, [activeIndex, isFocused, noOfItemsInCart]);

  const fireClickOnChoosePayment = () => {
    let preOrder = [];
    cartData &&
      Object.values(cartData)?.map(
        (
          product: CartSingleSKUInfo | CartBundleSKUInfo | CartClearanceSKUInfo
        ) => {
          product?.deliveryInfo?.forEach((delivery: DeliveryInfo) => {
            if (delivery?.type === MIXPANEL_PROPERTIES.PREORDER) {
              preOrder.push(delivery.type);
            }
          });
        }
      );
    const mixpanelPayload = {
      [MIXPANEL_KEYS.AVAILABLE_CREDIT_LIMIT]: dc.available_credit,
      [MIXPANEL_KEYS.CART_VALUE]: amountDetails?.cart_details.order_total,
      [MIXPANEL_KEYS.CREDIT_LIMIT]: dc.credit_limit,
      [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc.is_priority_dc,
      [MIXPANEL_KEYS.NUMBER_OF_ITEMS]: noOfItemsInCart,
      [MIXPANEL_KEYS.PAYABLE_AMOUNT]: amountDetails?.cart_details.order_total,
      [MIXPANEL_KEYS.PRE_ORDER_ITEMS_COUNT]: preOrder.length,
      [MIXPANEL_KEYS.SCREEN_NAME]: ROUTES.MY_CART,
      [MIXPANEL_KEYS.WALLET_AMOUNT_USED]: walletBalance,
    };
    logEvent(
      MIXPANEL_EVENT.CLICK_PAYMENT_BUTTON,
      JSON.stringify(mixpanelPayload)
    );
  };

  return (
    <View style={COMMON_STYLES.flex1}>
      {loading && isFocused && <Spinner type="on-screen" />}
      <View
        style={[
          COMMON_STYLES["pv1/2"],
          BG_STYLES.bgWhite,
          COMMON_STYLES.divider,
        ]}
      >
        <HorizontalStepper
          numberOfSteps={2}
          titles={[t("review_cart"), t("payment")]}
          activeIndex={activeIndex}
          onTabClick={(index) => setActiveIndex(index)}
        />
      </View>
      {!noOfItemsInCart ? (
        <>
          <CreditLimitTracker
            totalCreditLimit={dc.credit_limit}
            availableCreditLimit={dc.available_credit}
            creditLimitAccepted={dc.limit_enhancement_accepted}
            limitEnhancementAmount={dc.limit_enhancement_amount}
          />
          <NoItemCart />
        </>
      ) : activeIndex && amountDetails ? (
        <CartPayment
          payableAmount={amountDetails.payment_details.amount_payable || 0}
          credit={amountDetails.payment_mode_breakup.credit}
          prepaid={amountDetails.payment_mode_breakup.prepaid}
          cartData={cartData}
          handleCreateOrder={(paymentMethod) =>
            onCreateOrder(
              cartData,
              amountDetails,
              cartId!,
              paymentMethod,
              isWalletApplied,
              dc?.ib_id!
            )
          }
        />
      ) : (
        <View style={COMMON_STYLES.flex1}>
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={false} onRefresh={validateCartInfo} />
            }
          >
            <CreditLimitTracker
              totalCreditLimit={dc.credit_limit}
              availableCreditLimit={dc.available_credit}
              creditLimitAccepted={dc.limit_enhancement_accepted}
              limitEnhancementAmount={dc.limit_enhancement_amount}
            />
            <View
              style={[
                BG_STYLES.bgNeutral10,
                COMMON_STYLES.p1,
                COMMON_STYLES.flex1,
              ]}
            >
              {Object.keys(cartData).map((key) => {
                const info = cartData[key];
                const {
                  id,
                  quantity,
                  name,
                  isBundle,
                  deliveryInfo,
                  productType,
                  totalOrderInventory,
                  productStatus,
                  moq,
                  variantInfo,
                  productId,
                  licenseStatus,
                } = info;

                const batchId = (info as CartClearanceSKUInfo).batchId;

                const isInactive = productStatus === PRODUCT_STATUS.INACTIVE;
                const isOOS = moq
                  ? totalOrderInventory < moq
                  : totalOrderInventory === 0;
                const isLicenseInactive =
                  licenseStatus === PRODUCT_STATUS.INACTIVE;

                if (isInactive || isOOS || isLicenseInactive) {
                  let label = isInactive
                    ? "product_inactive"
                    : isOOS
                    ? "out_of_stock"
                    : "inactive_license";

                  if (isBundle) {
                    return (
                      <InactiveCartBundleItem
                        key={id}
                        name={name}
                        images={(info as CartBundleSKUInfo).images}
                        handleRemoveCartItem={() => onRemoveCartItemClick(id)}
                        label={t(label)}
                      />
                    );
                  }
                  return (
                    <InactiveCartItem
                      key={id}
                      name={name}
                      imageUrl={""}
                      handleRemoveCartItem={() => onRemoveCartItemClick(id)}
                      label={t(label)}
                      packAttribute={variantInfo?.pack_attribute}
                    >
                      {(info as CartSingleSKUInfo).similarProductCount ? (
                        <View style={COMMON_STYLES.ph1}>
                          <AlternateItemsList
                            id={productId}
                            noOfAlternateProducts={
                              (info as CartSingleSKUInfo).similarProductCount
                            }
                            technicalContents={
                              (info as CartSingleSKUInfo).technicalContents
                            }
                            containerStyle={COMMON_STYLES["mt3/4"]}
                            allowCollaplse={false}
                          />
                        </View>
                      ) : null}
                    </InactiveCartItem>
                  );
                }

                const handleQuantityUpdate = (qty: number) => {
                  const availableQty = Math.min(
                    totalOrderInventory,
                    MAX_INVENTORY_ALLOWED
                  );
                  if (qty <= availableQty)
                    dispatch(updateCartItem({ id, qty, batchId }));
                  else {
                    dispatch(
                      updateCartItem({
                        id,
                        qty: availableQty,
                        batchId,
                      })
                    );
                    setInventoryErrorState({
                      isVisible: true,
                      name,
                      availableInventory: availableQty,
                    });
                  }
                };

                if (isBundle) {
                  const comboInfo = info as CartBundleSKUInfo;
                  return (
                    <ComboCartItem
                      key={key}
                      name={comboInfo.name}
                      unitPrice={comboInfo.unitPrice}
                      totalPrice={comboInfo.unitPrice * comboInfo.quantity}
                      images={comboInfo.images}
                      quantity={comboInfo.quantity}
                      discountedPrice={comboInfo.discountedPrice}
                      handleQuantityUpdate={handleQuantityUpdate}
                      handleRemoveCartItem={() =>
                        onRemoveCartItemClick(id, quantity)
                      }
                      deliveryInfo={comboInfo.deliveryInfo || undefined}
                      error={comboInfo.error}
                      totalInventory={comboInfo.totalOrderInventory}
                    />
                  );
                }

                if (productType === PRODUCT_TYPE.CLEARANCE) {
                  const clearanceCartData = info as CartClearanceSKUInfo;
                  const tag = tags.find(
                    (tag) => tag.batch_id === clearanceCartData.batchId
                  );

                  return (
                    <ClearanceCartItem
                      infoTags={tag?.info_tags}
                      key={key}
                      name={clearanceCartData.name}
                      unitPrice={clearanceCartData.unitPrice}
                      constituentUnitPrice={
                        clearanceCartData.constituentUnitPrice
                      }
                      uom={clearanceCartData.uom || ""}
                      totalPrice={
                        (clearanceCartData.discountedPrice ||
                          clearanceCartData.unitPrice) *
                        clearanceCartData.quantity
                      }
                      totalVolume={clearanceCartData.totalVolume || 0}
                      imageUrl={clearanceCartData.images[0]}
                      packAttribute={
                        clearanceCartData.variantInfo.pack_attribute
                      }
                      quantity={clearanceCartData.quantity}
                      discountedPrice={clearanceCartData.discountedPrice}
                      discountedContituentPrice={
                        clearanceCartData.discountedContituentPrice
                      }
                      handleQuantityUpdate={handleQuantityUpdate}
                      handleRemoveCartItem={() => {
                        onRemoveCartItemClick(
                          id,
                          clearanceCartData.quantity,
                          clearanceCartData.batchId
                        );
                      }}
                      deliveryInfo={clearanceCartData.deliveryInfo}
                      expiryDate={clearanceCartData.expiryDate}
                      totalInventory={clearanceCartData.totalOrderInventory}
                    />
                  );
                }

                const tag = tags.find(
                  (tag) => Number(tag.product_id) === Number(id)
                );
                const singleSKUCartData = info as CartSingleSKUInfo;
                return (
                  <React.Fragment key={key}>
                    <CartItem
                      infoTags={tag?.info_tags}
                      id={id}
                      name={name}
                      unitPrice={singleSKUCartData.unitPrice}
                      constituentUnitPrice={
                        singleSKUCartData.constituentUnitPrice
                      }
                      uom={singleSKUCartData.uom}
                      totalPrice={singleSKUCartData.unitPrice * quantity}
                      totalVolume={singleSKUCartData.totalVolume}
                      imageUrl={singleSKUCartData.image}
                      packAttribute={
                        singleSKUCartData.variantInfo?.pack_attribute
                      }
                      quantity={quantity}
                      discountedPrice={singleSKUCartData.discountedPrice}
                      discountedContituentPrice={
                        singleSKUCartData.discountedContituentPrice
                      }
                      maxDiscountPrice={
                        singleSKUCartData.discretionaryCouponScheme
                          ?.min_dehaat_price!
                      }
                      negotiatedPrice={
                        singleSKUCartData.negotiatedPrice !== undefined
                      }
                      handleNegotiablePrice={() => {
                        if (
                          isAIMS() &&
                          singleSKUCartData.discretionaryCouponScheme
                        ) {
                          setPriceNegotiableModalState({
                            key,
                            visible: true,
                            data: singleSKUCartData,
                          });
                        }
                      }}
                      handleQuantityUpdate={handleQuantityUpdate}
                      handleRemoveCartItem={() =>
                        onRemoveCartItemClick(id, quantity)
                      }
                      deliveryInfo={deliveryInfo || []}
                      moq={singleSKUCartData.moq}
                      moqType={singleSKUCartData.moqType}
                      moqLabelValue={
                        singleSKUCartData.moqLabelValue || undefined
                      }
                      appliedDiscounts={singleSKUCartData.appliedDiscounts}
                      rewardType={singleSKUCartData.rewardType}
                      totalInventory={singleSKUCartData.totalOrderInventory}
                      error={singleSKUCartData.error}
                      productStatus={singleSKUCartData.productStatus}
                    />
                  </React.Fragment>
                );
              })}

              <ApplicableSchemes discounts={discounts} cartItems={cartItems} />
              <OrderDetail
                amountPayable={
                  amountDetails?.payment_details.amount_payable || 0
                }
                orderTotal={amountDetails?.cart_details.order_total || 0}
                cartBreakup={amountDetails?.cart_details.cart_breakup || []}
                paymentBreakup={
                  amountDetails?.payment_details.payment_breakup || []
                }
                deliveryFee={amountDetails?.cart_details?.delivery_fee || 0}
                fireEvent={() =>
                  fireCartMixapanelEvent(MIXPANEL_EVENT.CLICK_ON_BILLING_TOTAL)
                }
              />
            </View>
          </ScrollView>
          {priceNegotiableModalState.data ? (
            <PriceNegotiableBottomDrawer
              name={priceNegotiableModalState.data.name}
              packAttribute={
                priceNegotiableModalState.data.variantInfo?.pack_attribute
              }
              imageUrl={priceNegotiableModalState.data.image}
              unitPrice={priceNegotiableModalState.data.unitPrice}
              maxDiscountPrice={
                priceNegotiableModalState.data.discretionaryCouponScheme
                  ?.min_dehaat_price!
              }
              uom={priceNegotiableModalState.data.uom}
              visible={priceNegotiableModalState.visible}
              onClose={() => setPriceNegotiableModalState({ visible: false })}
              onConfirm={(value) => {
                dispatch(
                  updateNegotiablePrice({
                    key: priceNegotiableModalState.key!,
                    price: value,
                  })
                );
                setPriceNegotiableModalState({ visible: false });
              }}
            />
          ) : null}

          {modalVisible.id ? (
            <RemoveItemConfirmationModal
              isVisible={modalVisible.visible}
              onClose={onCloseModalOrNotRemoveHandler}
              handleRemoveItem={handleRemoveItem}
            />
          ) : null}

          <InsufficientInventoryModal
            isVisible={inventoryErrorState.isVisible}
            onCTAClick={() => setInventoryErrorState({ isVisible: false })}
            name={inventoryErrorState.name}
            availableInventory={inventoryErrorState.availableInventory}
          />

          <Footer
            disabled={
              isError ||
              !amountDetails?.cart_details?.order_total ||
              validationError.isError
            }
            onCTAClick={() => {
              const isCartValid = checkandUpdateErrorInCart(cartData);
              if (isCartValid) {
                if (!validationError.isError) {
                  validateCartInfo(isWalletApplied, true);
                } else {
                  setSnackbarState({
                    visible: true,
                    message: validationError.message,
                  });
                }
              }
            }}
            text={t("choose_payment_method")}
          >
            {error[CART_ERROR_CODES.MOQ_CART] ? (
              <CartMOQStrip
                moq={cartMOQ}
                orderValue={amountDetails?.cart_details?.order_total || 0}
              />
            ) : null}
            {!isError && (
              <CartDynamicFooter
                walletBalance={walletBalance}
                saving={amountDetails?.payment_mode_breakup?.prepaid?.savings}
                payableAmount={amountDetails?.payment_details?.amount_payable}
                onWalletCheck={() => {
                  setIsWalletApplied((prev) => !prev);
                  fireCartMixapanelEvent(MIXPANEL_EVENT.WALLET_WIDGET_ENABLED, {
                    [MIXPANEL_KEYS.WALLET_WIDGET]: isWalletApplied,
                  });
                  fireCartMixapanelEvent(MIXPANEL_EVENT.WALLET_OPTED_IN_CART, {
                    [MIXPANEL_KEYS.WALLET_OPTED]: isWalletApplied,
                  });
                }}
                isWalletApplied={isWalletApplied}
              />
            )}
          </Footer>
        </View>
      )}
      {snackbar.visible && (
        <View
          style={[
            COMMON_STYLES.absolute,
            COMMON_STYLES.bottom,
            COMMON_STYLES.left,
            COMMON_STYLES.right,
            COMMON_STYLES.m1,
          ]}
        >
          <Snackbar
            title={snackbar.title || ""}
            message={snackbar.message}
            onClose={() => setSnackbarState({ visible: false })}
          />
        </View>
      )}
      {error[CART_ERROR_CODES.VALIDATE_CART_FAILED] ? (
        <LogErrorModal
          visible={true}
          message={error[CART_ERROR_CODES.VALIDATE_CART_FAILED].key}
          onClose={() => resetError(CART_ERROR_CODES.VALIDATE_CART_FAILED)}
        />
      ) : null}

      {error[CART_ERROR_CODES.DUPLICATE_ORDER] ? (
        <DuplicateOrderModal
          onCtaClick={() => {
            dispatch(resetCart());
            openOrderHistory();
            resetError(CART_ERROR_CODES.DUPLICATE_ORDER);
            finishCurrentActivity();
          }}
        />
      ) : null}
    </View>
  );
};

export default RenderCart;
