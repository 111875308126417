import { post } from "app/partner-ordering-ui/utils/axios/index";
import { ValidateCart } from "../models/Cart";
import { logExceptionAndGetTraceIdsWithStatusCode } from "../utils/helpers/common";
import { API } from "../models/API";
import CustomError from "../utils/helpers/customError";

const validateCart = async (request: any) => {
  const url = "/sale-management/validate-cart/v3";
  const queryParams = {
    is_bundle_coupon_allowed: true,
    is_payment_page_exist: true,
  };
  try {
    const response = await post<API<ValidateCart>>(
      url,
      request,
      undefined,
      queryParams
    );
    return response.data;
  } catch (e: any) {
    const { message, statusCode } = logExceptionAndGetTraceIdsWithStatusCode(e);
    throw new CustomError(message, statusCode || 0);
  }
};

export default validateCart;
