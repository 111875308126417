import { OrderCancelReasonObj } from "app/sell-to-farmer/models/orders";
import { get } from "../axios/index";

const fetchOrderCancelReasons = async () => {
  try {
    const response = await get<OrderCancelReasonObj[]>(
      "/hyperlocal/v2/reasons?action=CANCEL&entity=ORDER&requester=VENDOR"
    );
    return response;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export default fetchOrderCancelReasons;
