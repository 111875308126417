import React from "react";
import { StyleSheet, Text, View ,Pressable } from "react-native";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";

import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import { SCREEN_WIDTH } from "app/partner-ordering-ui/utils/helpers/common";
import { useTranslation } from "react-i18next";
import { openPendingDocuments } from "app/utils/appConfig";

interface LabelProps {
  name: string;
  value: number | string;
}

const Label = ({ name, value }: LabelProps) => {
  return (
    <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
      <Text
        style={[
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.BASE,
          TEXT_STYLES.textNeutral70,
        ]}
      >
        {name}:
      </Text>
      <Text
        style={[
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.SEMI_EMPHASIZED,
          TEXT_STYLES.textNeutral80,
          COMMON_STYLES["ml1/4"],
        ]}
      >
        {formatCurrency(value)}
      </Text>
    </View>
  );
};

interface Props {
  totalCreditLimit: number;
  availableCreditLimit: number;
  creditLimitAccepted:boolean;
  limitEnhancementAmount:number;
}

const CreditProgressBar = ({
  totalCreditLimit,
  availableCreditLimit,
  creditLimitAccepted,
  limitEnhancementAmount

}: Props) => {
  const { t } = useTranslation();
  const progress = (availableCreditLimit / totalCreditLimit) * 100;
  return (
    <View
      style={[COMMON_STYLES.pb1, COMMON_STYLES["ph5/4"], BG_STYLES.bgWhite]}
    >
      <View
        style={[
          COMMON_STYLES.row,
          COMMON_STYLES.justifyBetween,
          COMMON_STYLES.pt1,
        ]}
      >
        <Label name={t("credit_limit")} value={totalCreditLimit} />
        <Label
          name={t("avl_credit_limit")}
          value={parseInt(availableCreditLimit.toString())}
        />
      </View>
      <View
        style={[
          COMMON_STYLES["mt1/4"],
          COMMON_STYLES["br1/2"],
          BG_STYLES.bgNeutral20,
          COMMON_STYLES.justifyEnd,
          COMMON_STYLES.row,
          styles.progressBarContainer,
        ]}
      >
        <View
          style={[
            COMMON_STYLES.fullHeight,
            COMMON_STYLES["br1/2"],
            styles.progressBar,
            { width: `${progress}%`, maxWidth: SCREEN_WIDTH - 40 },
          ]}
        /> 
      </View>
      <Pressable onPress={ () => openPendingDocuments()}>
      {!isAIMS() && !creditLimitAccepted && limitEnhancementAmount != 0.0 &&
      <Text
      style={[
        TEXT_STYLES.textPrimary100
      ]} >
      {t("credit_limit_kam_pad_rahi_hai")}</Text>}
      </Pressable>
    </View>
  );
};

export default CreditProgressBar;

const styles = StyleSheet.create({
  progressBarContainer: { height: 6 },
  progressBar: { backgroundColor: "#53A600" }, //todo: unknown color
});

