import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { NoProductFound } from "app/partner-ordering-ui/assets/images";
import React from "react";
import { Image, Text, View } from "react-native";
import { useTranslation } from "react-i18next";

const NoSearchResult = () => {
  const { t } = useTranslation();
  return (
    <View
      style={[
        COMMON_STYLES.row,
        COMMON_STYLES.justifyCenter,
        COMMON_STYLES.alignItemsCenter,
        BG_STYLES.bgWhite,
        COMMON_STYLES.flex1,
      ]}
    >
      <View>
        <Text
          style={[
            TYPOGRAPHY.HEADING3,
            VARIANTS_STYLES.EMPHASIZED,
            TEXT_STYLES.textNeutral100,
            COMMON_STYLES.textCenter,
          ]}
        >
          {t("no_results_found")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            COMMON_STYLES.textCenter,
            COMMON_STYLES["mt1/4"],
          ]}
        >
          {t("suggestions_to_get_better_results")}
        </Text>
        <Image source={NoProductFound} style={{ height: 335, width: 332 }} />
      </View>
    </View>
  );
};

export default NoSearchResult;
