import React from "react";
import { useTranslation } from "react-i18next";

import { Strip, formatCurrency } from "@dehaat/dds";
import WarningRedIcon from "app/partner-ordering-ui/icons/WarningRed";

interface Props {
  moq: number;
  orderValue: number;
}

const CartMOQStrip = ({ moq, orderValue }: Props) => {
  const { t } = useTranslation();
  return (
    <Strip
      bgColor="bgYellow10"
      heading={t("moq_value", { value: formatCurrency(moq) })}
      subHeading={t("your_cart_value", { value: formatCurrency(orderValue) })}
      textColor="neutral90"
      imageOrIcon={<WarningRedIcon />}
    />
  );
};

export default CartMOQStrip;
