import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";
import LinearGradient from "react-native-linear-gradient";
import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import { getCurrentMonthName } from "app/partner-ordering-ui/utils/helpers/date";
import GrowthBasketIcon from "app/partner-ordering-ui/icons/GrowthBasket";
import ArrowIcon from "app/partner-ordering-ui/icons/Arrow";
import { rnNavigation } from "app/utils/appConfig";

const GrowthBasketBanner = () => {
  const { t } = useTranslation();
  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      colors={["#002312", "#01A451"]}
      style={[
        COMMON_STYLES.m1,
        BG_STYLES.bgPrimary120,
        COMMON_STYLES.br1,
        COMMON_STYLES["p3/4"],
      ]}
    >
      <TouchableOpacity
        onPress={() => rnNavigation({ name: ROUTES.GROWTH_PLAN_LIST })}
        style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}
      >
        <GrowthBasketIcon height={32} width={32} />
        <View style={[COMMON_STYLES["ml0.5"], COMMON_STYLES.flex1]}>
          <View style={COMMON_STYLES.row}>
            <Text
              style={[
                TYPOGRAPHY.TEXT2,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textWhite,
                COMMON_STYLES.flex1,
              ]}
            >
              {getCurrentMonthName()} {t("growth_plan")}
            </Text>
            <ArrowIcon height={16} width={16} />
          </View>

          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textWhite,
              COMMON_STYLES["mt1/4"],
            ]}
          >
            {t("odering_from_this_months")}
          </Text>
        </View>
      </TouchableOpacity>
    </LinearGradient>
  );
};

export default GrowthBasketBanner;
