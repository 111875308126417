import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const CalenderIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 22 22" {...props}>
      <Path
        d="M20.61 8.107v10.887a2.608 2.608 0 0 1-2.608 2.608H3.935a2.866 2.866 0 0 1-2.55-2.55V8.12h19.239l-.014-.014Z"
        fill="#1AB6D8"
      />
      <Path
        d="M20.614 6.044v2.622H1.375V6.044a2.76 2.76 0 0 1 2.78-2.765h1.632v1.118c0 .63.502 1.131 1.132 1.131.63 0 1.132-.501 1.132-1.131V3.279h5.887v1.118c0 .63.502 1.131 1.132 1.131.63 0 1.132-.501 1.132-1.131V3.279h1.633a2.77 2.77 0 0 1 2.78 2.765Z"
        fill="#0E8BB3"
      />
      <Path
        d="M8.048 2.687v2.135c0 .759-.502 1.375-1.132 1.375-.63 0-1.132-.616-1.132-1.375V2.687c0-.76.502-1.375 1.132-1.375.63 0 1.132.616 1.132 1.375ZM16.194 2.687v2.135c0 .759-.501 1.375-1.132 1.375-.63 0-1.131-.616-1.131-1.375V2.687c0-.76.501-1.375 1.131-1.375s1.132.616 1.132 1.375Z"
        fill="#00526C"
      />
      <Path
        d="M6.862 11.827H5.86c-.401 0-.714.373-.714.817 0 .444.325.816.714.816h1.002c.401 0 .715-.372.715-.816 0-.444-.326-.817-.715-.817ZM11.508 11.827h-1.003c-.4 0-.714.373-.714.817 0 .444.326.816.714.816h1.003c.4 0 .714-.372.714-.816 0-.444-.326-.817-.714-.817ZM16.152 11.827H15.15c-.401 0-.715.373-.715.817 0 .444.326.816.715.816h1.002c.401 0 .715-.372.715-.816 0-.444-.326-.817-.715-.817ZM16.152 16.224H15.15c-.401 0-.715.372-.715.816 0 .445.326.817.715.817h1.002c.401 0 .715-.372.715-.817 0-.444-.326-.816-.715-.816ZM6.862 16.224H5.86c-.401 0-.714.372-.714.816 0 .445.325.817.714.817h1.002c.401 0 .715-.372.715-.817 0-.444-.326-.816-.715-.816ZM11.508 16.224h-1.003c-.4 0-.714.372-.714.816 0 .445.326.817.714.817h1.003c.4 0 .714-.372.714-.817 0-.444-.326-.816-.714-.816Z"
        fill="#fff"
      />
    </Svg>
  );
};

export default CalenderIcon;
