import React from "react";

import {
  Discount,
  DiscountsInfo,
  REWARD_TYPE,
  Slab,
} from "../../models/Product";
import BestDiscountHeader from "./BestDiscountHeader";
import DiscountFooter from "./DiscountFooter";
import RenderDiscount from "./RenderDiscount";
import { SLAB_DISCOUNT_TYPE } from "app/partner-ordering-ui/constants/Product";

interface Props {
  discountsInfo?: DiscountsInfo;
  uom?: string | null;
  isBundle?: boolean;
  setTooltip?: (slab: Slab, rewardType: REWARD_TYPE, prepaid: boolean) => void;
}

const OfferAndDiscount = ({
  discountsInfo,
  uom = null,
  isBundle = false,
  setTooltip,
}: Props) => {
  if (discountsInfo) {
    const {
      applied_discounts,
      discounts,
      best_price,
      best_constituent_unit_price,
    } = discountsInfo;

    const prepaidDiscounts: Discount[] = [];
    const creditDiscounts: Discount[] = [];
    let recommendedSlab: Slab | null = null;
    let isBestSlabPrepaid = false;

    discounts?.forEach((discount) => {
      const isRecommendedSlab = discount.slabs.find(
        (slab) => slab.is_recommended
      );
      if (isRecommendedSlab) {
        recommendedSlab = isRecommendedSlab;
        isBestSlabPrepaid = discount.is_prepaid;
      }
      if (discount.is_prepaid) {
        prepaidDiscounts.push(discount);
      } else {
        creditDiscounts.push(discount);
      }
    });

    const getBestSlabText = () => {
      const { min_qty, slab_uom } = recommendedSlab!;
      if (min_qty) {
        return slab_uom === SLAB_DISCOUNT_TYPE.rupee
          ? `${slab_uom}${min_qty}`
          : `${min_qty} ${slab_uom || ""}`;
      }
    };

    return (
      <>
        {recommendedSlab ? (
          <BestDiscountHeader
            bestPrice={best_price}
            bestConstituentPrice={best_constituent_unit_price}
            slabUom={(recommendedSlab as Slab)?.uom}
            uom={uom}
            slabText={getBestSlabText()}
            isPrepaid={isBestSlabPrepaid}
            discountPerUnit={(recommendedSlab as Slab).discount_per_unit}
          />
        ) : null}
        {prepaidDiscounts.length > 0 && (
          <RenderDiscount
            discountsInfo={discountsInfo}
            discounts={prepaidDiscounts}
            prepaid
            appliedDiscounts={applied_discounts}
            onDiscountClick={(slab, rewardType, isPrepaid) =>
              setTooltip?.(slab, rewardType, isPrepaid)
            }
            uom={uom}
            isBundle={isBundle}
          />
        )}
        {creditDiscounts.length > 0 && (
          <RenderDiscount
            discountsInfo={discountsInfo}
            discounts={creditDiscounts}
            prepaid={false}
            appliedDiscounts={applied_discounts}
            onDiscountClick={(slab, rewardType, isPrepaid) =>
              setTooltip?.(slab, rewardType, isPrepaid)
            }
            uom={uom}
            isBundle={isBundle}
          />
        )}
        <DiscountFooter />
      </>
    );
  }
};

export default OfferAndDiscount;
