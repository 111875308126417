import React, { useEffect, useRef, useState } from "react";
import { FlatList, LayoutRectangle, Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BORDER_STYLES,
  BUTTON_TYPE,
  COMMON_STYLES,
  ProductComboCardVertical,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import fetchProductsList from "../../apis/fetchProductBundles";
import { InventoryErrorModalState, Product } from "../../models/Product";

import Spinner from "../resuable/Spinner";
import useCart from "../../hooks/useCart";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "../../store/reducer/cart";
import { ROUTES } from "../../routes/types";
import { rnNavigation } from "../../../utils/appConfig";
import ComboPriceInfo from "../productList/ComboPriceInfo";
import LockIcon from "app/partner-ordering-ui/icons/LockIcon";
import { ViewProductType } from "app/partner-ordering-ui/models/MixpanelEvent";
import {
  getBundleImages,
  getComboVisible,
} from "app/partner-ordering-ui/utils/helpers/product";
import { fireComboProductViewedEvent } from "app/partner-ordering-ui/utils/helpers/mixpanel";
import { selectDCData } from "app/store/reducer/native";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import InsufficientInventoryModal from "../resuable/InsufficientInventoryModal";

interface Props {
  id: number | string;
  captureYOffset: (layout: LayoutRectangle) => void;
  isOrderingBlocked: boolean;
  handleRevealPriceClick?: () => void;
}

const ComboOffers = ({
  id,
  captureYOffset,
  isOrderingBlocked = false,
  handleRevealPriceClick,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [comboList, setComboList] = useState<Product[]>([]);
  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });
  const { cartItems } = useCart();
  const dc = useAppSelector(selectDCData);

  const fetchComboList = async () => {
    const response = await fetchProductsList(
      id,
      dc.sales_office_id,
      dc.partner_id
    );
    if (response) {
      setComboList(response.results);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchComboList();
  }, []);

  const loadedItems = useRef(new Set());

  const onViewableItemsChanged = (options: ViewProductType) =>
    options.viewableItems.forEach((viewItemConfig) => {
      if (
        viewItemConfig.isViewable &&
        !loadedItems.current.has(viewItemConfig?.item?.id)
      ) {
        const viewItem = viewItemConfig?.item;
        fireComboProductViewedEvent({
          combo: viewItem,
          isPriorityDc: dc?.is_priority_dc,
          screenName: ROUTES.PRODUCT_DETAIL,
          isProductInStock:
            viewItem?.bundles[0]?.orderable_quantity > 0 && !dc.app_blocked,
          productPosition: viewItemConfig.index + 1,
        });
        loadedItems.current.add(viewItemConfig?.item?.id);
      }
    });

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: {
        itemVisiblePercentThreshold: 100,
      },
      onViewableItemsChanged: onViewableItemsChanged,
    },
  ]);

  const renderItem = (product: Product, index: number) => {
    const { id: comboId, bundles } = product;
    const {
      id,
      name,
      actual_price,
      discounted_price,
      bundled_products,
      orderable_quantity,
    } = bundles?.[0];
    const quantity = cartItems[id]?.qty;

    const handleQuantityUpdate = (qty: number) => {
      const availableQty = Math.min(MAX_INVENTORY_ALLOWED, orderable_quantity);
      if (qty <= availableQty) dispatch(updateCartItem({ id, qty }));
      else {
        dispatch(updateCartItem({ id, qty: availableQty }));
        setInventoryErrorState({
          isVisible: true,
          name: product.name,
          availableInventory: availableQty,
        });
      }
    };

    return orderable_quantity ? (
      <ProductComboCardVertical
        containerStyle={index !== comboList.length - 1 && COMMON_STYLES.mr1}
        key={id}
        images={getBundleImages(bundled_products)}
        numberOfProductInCombo={bundled_products.length}
        productName={name}
        onDeleteClick={() => dispatch(deleteCartItem({ id }))}
        onPlusClick={() => handleQuantityUpdate(quantity + 1)}
        onMinusClick={() => handleQuantityUpdate(quantity - 1)}
        onQunatityUpdate={(qty) => handleQuantityUpdate(qty)}
        cta={{
          onClick: () => {
            if (isOrderingBlocked) handleRevealPriceClick?.();
            else {
              dispatch(addItemToCart({ id, product }));
              rnNavigation({
                name: ROUTES.COMBO_DETAIL,
                params: { id: comboId },
              });
            }
          },
          text: isOrderingBlocked ? t("price") : t("add"),
          iconRight: isOrderingBlocked ? (
            <LockIcon height={16} width={16} />
          ) : undefined,
          type: isOrderingBlocked ? BUTTON_TYPE.SECONDARY : BUTTON_TYPE.PRIMARY,
          buttonStyle: isOrderingBlocked
            ? BORDER_STYLES.borderBlue10
            : undefined,
        }}
        discountInfo={
          <ComboPriceInfo
            actualPrice={Number(actual_price)}
            discountPrice={Number(discounted_price)}
            priceLocked={isOrderingBlocked}
          />
        }
        quantity={quantity}
        availableInventory={Math.min(
          orderable_quantity,
          MAX_INVENTORY_ALLOWED
        )}
        onCardClick={() =>
          rnNavigation({
            name: ROUTES.COMBO_DETAIL,
            params: { id: comboId },
          })
        }
      />
    ) : null;
  };

  return getComboVisible(comboList) ? (
    <View
      style={COMMON_STYLES.p1}
      onLayout={(e) => captureYOffset(e.nativeEvent.layout)}
    >
      <Text
        style={[
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.SEMI_EMPHASIZED,
          TEXT_STYLES.textNeutral90,
        ]}
      >
        {t("combo_offers_for_you")}
      </Text>
      <FlatList
        data={comboList}
        horizontal
        renderItem={({ item, index }) => renderItem(item, index)}
        ListEmptyComponent={isLoading ? <Spinner type="in-screen" /> : null}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
      />
      <InsufficientInventoryModal
        isVisible={inventoryErrorState.isVisible}
        onCTAClick={() => setInventoryErrorState({ isVisible: false })}
        name={inventoryErrorState.name}
        availableInventory={inventoryErrorState.availableInventory}
      />
    </View>
  ) : null;
};

export default ComboOffers;
