import * as React from "react";
import Svg, { SvgProps, G, Path, Defs, ClipPath, Rect } from "react-native-svg";

const SchemesIcon = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <G id="color 1" clipPath="url(#clip0_4942_108397)">
      <Path
        id="Vector"
        d="M0.414062 15.4245C0.414063 14.3194 0.853049 13.2596 1.63445 12.4782C2.41585 11.6968 3.47566 11.2578 4.58073 11.2578H18.7474C18.9684 11.2578 19.1804 11.3456 19.3367 11.5019C19.4929 11.6582 19.5807 11.8701 19.5807 12.0911V18.7578C19.5807 18.9788 19.4929 19.1908 19.3367 19.3471C19.1804 19.5033 18.9684 19.5911 18.7474 19.5911H4.58073C3.47566 19.5911 2.41585 19.1522 1.63445 18.3708C0.853049 17.5894 0.414063 16.5295 0.414062 15.4245Z"
        fill="white"
      />
      <Path
        id="Vector_2"
        d="M19.1641 12.091V18.7576C19.1641 18.8682 19.1202 18.9741 19.042 19.0523C18.9639 19.1304 18.8579 19.1743 18.7474 19.1743H9.16406V11.6743H18.7474C18.8579 11.6743 18.9639 11.7182 19.042 11.7964C19.1202 11.8745 19.1641 11.9805 19.1641 12.091Z"
        fill="#FFBE1D"
      />
      <Path
        id="Vector_3"
        d="M1.63401 18.3704C0.852877 17.5891 0.414062 16.5295 0.414062 15.4246C0.414062 14.3198 0.852877 13.2601 1.63401 12.4788L11.634 2.47878C11.7114 2.4013 11.8033 2.33983 11.9045 2.2979C12.0056 2.25596 12.1141 2.23438 12.2236 2.23438C12.3331 2.23438 12.4415 2.25596 12.5427 2.2979C12.6439 2.33983 12.7358 2.4013 12.8132 2.47878L17.5423 7.17461C17.6198 7.25201 17.6813 7.34391 17.7232 7.44508C17.7652 7.54625 17.7867 7.65468 17.7867 7.7642C17.7867 7.87371 17.7652 7.98215 17.7232 8.08331C17.6813 8.18448 17.6198 8.27639 17.5423 8.35378L7.54234 18.3538C7.15624 18.7442 6.69679 19.0544 6.19041 19.2666C5.68403 19.4788 5.14069 19.5889 4.59164 19.5904C4.04258 19.592 3.49864 19.485 2.99106 19.2756C2.48349 19.0663 2.0223 18.7586 1.63401 18.3704Z"
        fill="white"
      />
      <Path
        id="Vector_4"
        d="M12.5377 2.75634L17.2519 7.47051C17.33 7.54865 17.3739 7.65461 17.3739 7.76509C17.3739 7.87558 17.33 7.98154 17.2519 8.05968L10.4752 14.8359L5.17188 9.53259L11.9485 2.75676C12.0267 2.67865 12.1326 2.63477 12.2431 2.63477C12.3536 2.63477 12.4596 2.67823 12.5377 2.75634Z"
        fill="#7C6F66"
      />
      <Path
        id="Vector_5"
        d="M5.1751 10.7109L8.12135 13.6568L7.53219 14.2464L4.58594 11.3001L5.1751 10.7109Z"
        fill="#FFBE1D"
      />
      <Path
        id="Vector_6"
        d="M8.33333 12.5078V16.6745H7.5V12.5078H8.33333Z"
        fill="#FFBE1D"
      />
      <Path
        id="Vector_7"
        d="M4.58073 19.591C3.47566 19.591 2.41585 19.152 1.63445 18.3706C0.853049 17.5892 0.414063 16.5294 0.414062 15.4243V1.25765C0.414062 1.03664 0.50186 0.824674 0.65814 0.668394C0.81442 0.512114 1.02638 0.424316 1.2474 0.424316H7.91406C8.13508 0.424316 8.34704 0.512114 8.50332 0.668394C8.6596 0.824674 8.7474 1.03664 8.7474 1.25765V15.4243C8.7474 16.5294 8.30841 17.5892 7.52701 18.3706C6.74561 19.152 5.6858 19.591 4.58073 19.591Z"
        fill="white"
      />
      <Path
        id="Vector_8"
        d="M1.2526 0.841309H7.91927C8.02978 0.841309 8.13576 0.885207 8.2139 0.963347C8.29204 1.04149 8.33594 1.14747 8.33594 1.25798V10.8413H0.835938V1.25798C0.835938 1.14747 0.879836 1.04149 0.957976 0.963347C1.03612 0.885207 1.1421 0.841309 1.2526 0.841309ZM1.66927 11.6746H5.83594V12.508H1.66927V11.6746Z"
        fill="#FFBE1D"
      />
      <Path
        id="Vector_9"
        d="M4.58594 16.6743C5.27629 16.6743 5.83594 16.1147 5.83594 15.4243C5.83594 14.734 5.27629 14.1743 4.58594 14.1743C3.89558 14.1743 3.33594 14.734 3.33594 15.4243C3.33594 16.1147 3.89558 16.6743 4.58594 16.6743Z"
        fill="#7C6F66"
      />
      <Path
        id="Vector_10"
        d="M6.2474 15.4245C6.2474 15.0948 6.14965 14.7726 5.96651 14.4985C5.78338 14.2244 5.52308 14.0108 5.21854 13.8847C4.91399 13.7585 4.57888 13.7255 4.25558 13.7898C3.93228 13.8541 3.63531 14.0129 3.40222 14.246C3.16913 14.4791 3.0104 14.776 2.94609 15.0993C2.88178 15.4226 2.91478 15.7577 3.04093 16.0623C3.16708 16.3668 3.3807 16.6271 3.65478 16.8103C3.92886 16.9934 4.25109 17.0911 4.58073 17.0911C5.02276 17.0911 5.44668 16.9156 5.75924 16.603C6.0718 16.2904 6.2474 15.8665 6.2474 15.4245ZM3.7474 15.4245C3.7474 15.2597 3.79627 15.0985 3.88784 14.9615C3.97941 14.8245 4.10956 14.7177 4.26183 14.6546C4.4141 14.5915 4.58165 14.575 4.74331 14.6072C4.90496 14.6393 5.05344 14.7187 5.16999 14.8352C5.28653 14.9518 5.3659 15.1003 5.39805 15.2619C5.43021 15.4236 5.4137 15.5911 5.35063 15.7434C5.28756 15.8957 5.18075 16.0258 5.0437 16.1174C4.90666 16.2089 4.74555 16.2578 4.58073 16.2578C4.35972 16.2578 4.14775 16.17 3.99147 16.0137C3.83519 15.8575 3.7474 15.6455 3.7474 15.4245Z"
        fill="#4C2C7A"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_4942_108397">
        <Rect
          width={20}
          height={20}
          fill="white"
          transform="translate(0 0.0078125)"
        />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SchemesIcon;
