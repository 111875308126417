import React from "react";
import { Path, Svg, SvgProps } from "react-native-svg";

const AlternateItemsIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 19 18" {...props}>
      <Path
        d="M15 5.504H7.5a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5H15a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5Z"
        stroke="#33393D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13.5 5.504v-1c0-.825-.675-1.5-1.5-1.5H4.5c-.825 0-1.5.675-1.5 1.5v7.5c0 .825.675 1.5 1.5 1.5H6"
        stroke="#33393D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default AlternateItemsIcon;
