import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const PaynowDisabledIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 22 22" {...props}>
      <Path
        fill="#CED8E0"
        d="M4.945 14.134s2.842-2.965 7.248-4.53c0 0 1.195-.59 4.187.316 3.212.975 3.583 1.043 3.583 1.043s-4.022 3.817-6.699 5.258C10.587 17.65 5.99 19.05 5.99 19.05l-.412.165-.632-5.08Z"
      />
      <Path
        fill="#C5CED6"
        d="m19.042 4.898-.125 2.206-.602 11.641-.113 2.256-2.167-.113-2.306-.126-2.206-.112.113-2.218.614-11.642.113-2.243 4.474.238 2.205.113Z"
      />
      <Path
        fill="#F5F8FA"
        d="m12.786 4.982-.794 15.255 5.82.303.793-15.255-5.819-.303Z"
      />
      <Path
        fill="#C5CED6"
        d="M15.298 14.938a2.18 2.18 0 1 0 0-4.36 2.18 2.18 0 0 0 0 4.36Z"
        opacity=".6"
      />
      <Path
        fill="#C5CED6"
        d="M19.026 4.895 18.913 7.1a2.168 2.168 0 0 1-2.068-1.855c-.025-.163-.025-.313-.025-.463l2.206.113ZM14.593 4.646a2.184 2.184 0 0 1-1.854 2.106 2.143 2.143 0 0 1-.489.012l.113-2.243 2.23.113v.012ZM18.317 18.736l-.126 2.255-2.168-.112a2.185 2.185 0 0 1 1.855-2.118c.138-.025.288-.025.426-.025h.013ZM13.704 20.277c.025.163.025.326.012.476l-2.193-.112.113-2.218a2.203 2.203 0 0 1 2.068 1.854Z"
      />
      <Path
        fill="#C5CED6"
        d="m21.993 5-.439 2.168-2.33 11.428-.451 2.206-2.13-.439-2.269-.464-2.155-.438.438-2.18 2.331-11.416.451-2.206 4.386.902L21.993 5Z"
      />
      <Path
        fill="#F5F8FA"
        d="M15.788 4.173 12.734 19.14l5.71 1.165 3.053-14.967-5.71-1.165Z"
      />
      <Path
        fill="#C5CED6"
        d="M17.102 14.416a2.18 2.18 0 1 0 0-4.36 2.18 2.18 0 0 0 0 4.36Z"
        opacity=".6"
      />
      <Path
        fill="#C5CED6"
        d="m21.991 4.999-.438 2.168a2.18 2.18 0 0 1-1.73-2.607L21.992 5ZM17.624 4.124A2.17 2.17 0 0 1 15.48 5.93c-.163 0-.338-.013-.489-.05l.451-2.193 2.193.45-.012-.012ZM19.225 18.59l-.451 2.206-2.118-.439A2.18 2.18 0 0 1 18.8 18.54c.138 0 .288.013.426.038v.012ZM14.456 19.43c0 .162-.013.325-.05.476l-2.156-.439.439-2.18a2.201 2.201 0 0 1 1.767 2.142Z"
      />
      <Path
        fill="#CED8E0"
        d="M19.542 14.878s0 .096-.028.15c-.15.317-.494.564-.768.783-.206.165-.426.302-.646.453h-.027a11.2 11.2 0 0 1-.659.426c-2.21 1.318-3.734 2.21-4.393 2.54-.645.329-2.059 1.152-7.193.082l.179-.55.082-.068 5.148-4.297s1.647.59 2.388.59c.646 0 1.304-.411 1.908-.59.508-.15 1.016-.37 1.552-.439a3.39 3.39 0 0 1 1.62.192c.233.096.796.412.823.728h.014Z"
      />
      <Path
        fill="#C5CED6"
        d="M17.438 16.696c-2.21 1.318-3.734 2.21-4.393 2.54-.646.33-2.06 1.153-7.193.082l.178-.549.082-.068c.426.082 3.24.617 5.313.411 0 0 1.263-.26 3.514-1.496 2.293-1.263 4.146-2.196 4.448-2.992a.413.413 0 0 1 .178.26s.083.495-2.141 1.812h.014ZM15.259 14.518a5.944 5.944 0 0 1-.975.37c-.343.097-.686.165-1.057.152a3.301 3.301 0 0 1-1.043-.234 4.512 4.512 0 0 1-.934-.453c-.014 0-.027-.027 0-.055 0-.013.028-.013.041-.013l.989.247c.33.082.659.165.974.192.646.027 1.318-.124 2.005-.206Z"
      />
      <Path
        fill="#C5CED6"
        d="m3.56 20.776 2.1-.384a.712.712 0 0 0 .576-.783l-.81-6.136a.7.7 0 0 0-.796-.604l-2.1.33a.61.61 0 0 0-.522.7l.824 6.342a.619.619 0 0 0 .727.535Z"
      />
    </Svg>
  );
};

export default PaynowDisabledIcon;
