import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { StackActions } from "@react-navigation/native";

import {
  Button,
  BUTTON_STATUS,
  COLORS,
  COMMON_STYLES,
  PARAGRAPH_STYLES,
  SIZE,
  TEXT_STYLES,
} from "@dehaat/dds";

import { useAppDispatch } from "app/hooks/reduxHooks";
import Config from "app/utils/config";
import sendOtp from "app/utils/apis/sendOtp";
import validateOtp from "app/utils/apis/validateOtp";
import { isValidOTP } from "../utils/login";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import verifyContact from "app/partner-ordering-ui/apis/verifyContact";
import { setToken } from "app/store/reducer/authToken";
import { LADNING_IMAGE, OTP } from "app/assets/images";
import { setCookies } from "app/utils/storage";
import { rnNavigation } from "app/utils/appConfig";
import { ROUTES as STFROUTE } from "app/sell-to-farmer/routes/types";
import {
  DIMENSION_HEIGHT,
  DIMENSION_WIDTH,
} from "app/partner-ordering-ui/utils/helpers/common";
import { isDesktop } from "app/sell-to-farmer/constants/common";
import { navigationRef } from "app/partner-ordering-ui/utils/RootNavigation";

interface Props {
  number: string;
  maxLength?: number;
  containerStyles?: ViewStyle;
  onSubmit?: (otp: string) => void;
}

// in sec
const RETRY_DURATION = 30;
// only being used to trigger effect. Using a hypothetical value
const MAX_RETRY_COUNT = 1000;

function OTPContainer({
  number,
  maxLength = 6,
  containerStyles,
  onSubmit,
}: Props) {
  const dispatch = useAppDispatch();
  const timerRef = useRef<number>(0);
  const [otpArray, setOtpArray] = useState(new Array(maxLength).fill(""));
  const [error, setError] = useState<string | null>(null);
  const [retryTime, setRetryTime] = useState(RETRY_DURATION);
  // only being used to trigger effect and start counter again
  const [pendingRetryCount, setPendingRetryCount] = useState(MAX_RETRY_COUNT);
  const [loading, setLoading] = useState(false);
  const [retryOtpLoading, setRetryOtpLoading] = useState(false);
  const [whatsAppCheck, setWhatsAppCheck] = useState(true);

  const isSubmitButtonEnabled = isValidOTP(otpArray.join(""), maxLength);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRefsArr = otpArray.map(() => useRef<TextInput>(null));

  // Change in a otp input box
  const handleOtpChange = (index: number) => {
    return (value: string) => {
      if (isNaN(Number(value))) {
        // do nothing when a non digit is pressed
        return;
      }
      setError(null);
      const otpArrayCopy = [...otpArray];
      otpArrayCopy[index] = value;
      setOtpArray(otpArrayCopy);

      // focus on next input box
      // value !== '' will check if there is a backspace key or some other key pressed from keyboard
      if (value !== "" && index < maxLength) {
        const next = inputRefsArr[index + 1];
        if (next && next.current) {
          next.current.focus();
        }
      }
    };
  };

  // when mobile keyboard backspace is pressed
  const handleOtpKeyPress = (index: number) => {
    return (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (event.nativeEvent.key === "Backspace" && index > 0) {
        if (otpArray[index] !== "") {
          otpArray[index] = "";
        } else {
          const prev = inputRefsArr[index - 1];
          if (prev && prev.current) {
            prev.current.focus();
          }
        }
      }
    };
  };

  const handleNavigation = async (otp: string, isAutofetched = false) => {
    setLoading(true);
    try {
      const response = await validateOtp(
        Config.CLIENT_ID as string,
        number,
        otp
      );
      if (response) {
        dispatch(setToken(response));
        setCookies(response as Record<string, any>);
        const contactDetails = await verifyContact(number);
        // TODO: Chnage logic once distiction btw vendor and dc is established.
        if (contactDetails && contactDetails.data.matched) {
          navigationRef.dispatch(StackActions.pop());
          rnNavigation({ name: STFROUTE.FARMER_ORDERS, replace: true });
        } else {
          navigationRef.dispatch(StackActions.pop());
          rnNavigation({ name: STFROUTE.FARMER_ORDERS, replace: true });
        }
      } else {
        setError("Not a valid user. Please contact Dehaat Support Team.");
      }
    } catch {
      setError("uh oh! The OTP you entered is invalid ");
    }
    setLoading(false);
  };

  // Clicking mobile keyboard submit
  const submitOtp = async () => {
    if (isSubmitButtonEnabled) {
      const joinedOTP = otpArray.join("");
      if (onSubmit) {
        onSubmit(joinedOTP);
      } else {
        handleNavigation(joinedOTP);
      }
    }
  };

  // Clicking retry otp btn
  const handleRetryOtpPress = async () => {
    setRetryOtpLoading(true);
    const isOtpTriggered = await sendOtp(number, Config.CLIENT_ID as string);
    if (isOtpTriggered) {
      setOtpArray(new Array(maxLength).fill(""));
      if (inputRefsArr[0].current) {
        inputRefsArr[0].current.focus();
      }
      setRetryTime(RETRY_DURATION);
      setPendingRetryCount((count) => count - 1);
    }
    setRetryOtpLoading(false);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    // @ts-expect-error
    timerRef.current = setInterval(() => {
      if (retryTime <= 0) {
        clearInterval(timerRef.current);
      } else {
        setRetryTime((timer) => timer - 1);
      }
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingRetryCount]);

  return (
    <View
      style={[
        COMMON_STYLES.flex1,
        isDesktop && COMMON_STYLES.row,
        containerStyles,
      ]}
    >
      {onSubmit ? null : (
        <View>
          <Image source={LADNING_IMAGE} style={styles.landingImage} />
        </View>
      )}
      <View
        style={[
          COMMON_STYLES.flex1,
          COMMON_STYLES.p1,
          styles.innerContainer,
          {
            marginLeft: isDesktop && !onSubmit ? 50 : 0,
            marginTop: isDesktop && !onSubmit ? 200 : 0,
          },
        ]}
      >
        {(loading || retryOtpLoading) && <Spinner type="on-screen" />}
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={[
              COMMON_STYLES.justifyCenter,
              COMMON_STYLES.alignItemsCenter,
              COMMON_STYLES["pv1/2"],
            ]}
          >
            {isDesktop || onSubmit ? (
              <Image source={OTP} style={styles.image} />
            ) : null}
          </View>
          <Text
            style={[
              PARAGRAPH_STYLES.MediumT1,
              TEXT_STYLES.textBlack,
              COMMON_STYLES["mb0.5"],
            ]}
          >
            Verification code
          </Text>
          <Text style={[PARAGRAPH_STYLES.MediumT2, COMMON_STYLES["mb0.5"]]}>
            We have sent an OTP to {number}
          </Text>
          <Text
            style={[
              TEXT_STYLES.textPrimary100,
              PARAGRAPH_STYLES.MediumT3,
              COMMON_STYLES["mb0.5"],
            ]}
          >
            Enter OTP
          </Text>
          <View
            style={[
              COMMON_STYLES.row,
              COMMON_STYLES.alignItemsCenter,
              COMMON_STYLES.justifyCenter,
              COMMON_STYLES.b1,
              COMMON_STYLES["br1/2"],
              COMMON_STYLES.ph1,
            ]}
          >
            {inputRefsArr.map((ref, index) => (
              <TextInput
                key={index}
                ref={ref}
                autoFocus={index === 0 ? true : undefined}
                value={otpArray[index]}
                onChangeText={handleOtpChange(index)}
                onKeyPress={handleOtpKeyPress(index)}
                onSubmitEditing={submitOtp}
                maxLength={1}
                keyboardType="numeric"
                style={[
                  styles.input,
                  COMMON_STYLES.flex1,
                  COMMON_STYLES["m0.5"],
                  error ? styles.inputError : styles.inputNonError,
                ]}
              />
            ))}
          </View>
          {error && (
            <Text style={[COMMON_STYLES["mt1/2"], TEXT_STYLES.textError100]}>
              Invalid OTP - Please Try Again
            </Text>
          )}
          {onSubmit ? null : (
            <View style={COMMON_STYLES["mt3/2"]}>
              <View
                style={[
                  COMMON_STYLES.row,
                  COMMON_STYLES.justifyBetween,
                  COMMON_STYLES.alignItemsCenter,
                  COMMON_STYLES.fullWidth,
                ]}
              >
                <Text style={[styles.paragraph, styles.textNeutral70]}>
                  Didn't receive OTP?
                </Text>
                {retryTime > 0 && (
                  <Text
                    style={[
                      styles.paragraph,
                      styles.textNeutral70,
                      styles.highlighted,
                    ]}
                  >
                    {retryTime >= 10 ? retryTime : `0${retryTime}`} sec
                  </Text>
                )}
              </View>
              <View style={styles.mt3}>
                <Text
                  style={[
                    styles.paragraph,
                    styles.highlighted,
                    styles.textNeutral100,
                  ]}
                >
                  Resend again
                </Text>
                <View
                  style={[
                    styles.footer,
                    COMMON_STYLES.row,
                    COMMON_STYLES["mt1/2"],
                  ]}
                >
                  <TouchableOpacity
                    style={[
                      styles.button,
                      COMMON_STYLES.b1,
                      COMMON_STYLES["pv1/2"],
                      COMMON_STYLES["br1/2"],
                    ]}
                    disabled={retryTime > 0}
                    onPress={handleRetryOtpPress}
                  >
                    <Text
                      style={[
                        retryTime > 0
                          ? styles.disabledButton
                          : TEXT_STYLES.textPrimary100,
                        styles.buttonText,
                      ]}
                    >
                      By SMS
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <BouncyCheckbox
                isChecked={whatsAppCheck}
                onPress={(isChecked: boolean) => setWhatsAppCheck(isChecked)}
                text="Stay connected with Dehaat on Whatsapp"
                size={18}
                style={styles.checkboxContainer}
                fillColor={COLORS.primary100}
                iconStyle={styles.checkbox}
                innerIconStyle={styles.checkbox}
                textStyle={[PARAGRAPH_STYLES.RegularT2, styles.checkBoxText]}
              />
            </View>
          )}
        </ScrollView>
        <Button
          text="Submit"
          onClick={submitOtp}
          size={SIZE.large}
          status={
            isSubmitButtonEnabled
              ? BUTTON_STATUS.ACTIVE
              : BUTTON_STATUS.DISABLED
          }
          buttonStyle={{ marginTop: onSubmit ? 50 : 0 }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    flexDirection: isDesktop ? "row" : "column",
  },
  button: {
    borderColor: "#D9D9D9",
    width: 96,
  },

  buttonText: {
    alignSelf: "center",
    fontSize: 14,
    fontWeight: "600",
  },
  checkBoxText: {
    textDecorationLine: "none",
  },
  checkbox: {
    borderRadius: 3,
  },
  checkboxContainer: {
    marginBottom: 10,
    marginTop: 15,
  },
  disabledButton: {
    color: "#A0A0A0",
  },
  footer: {
    gap: 16,
  },
  highlighted: {
    fontWeight: "bold",
  },
  image: {
    height: 200,
    resizeMode: "cover",
    width: 200,
  },
  input: {
    fontSize: 16,
    textAlign: "center",
    width: 40,
  },
  inputError: {
    borderBottomColor: COLORS.error100,
    borderBottomWidth: 2, // Error border color
  },
  inputNonError: {
    borderBottomColor: "#CCCCCC",
    borderBottomWidth: 2,
  },
  mt3: {
    marginTop: 12,
  },
  nonErrorBorder: {
    borderColor: "#CCCCCC",
  },
  paragraph: {
    fontSize: 16,
  },
  textNeutral100: {
    color: "#333333",
  },
  textNeutral70: {
    color: "#A0A0A0",
  },
  innerContainer: {
    maxWidth: 520,
  },
  landingImage: {
    height: isDesktop ? DIMENSION_HEIGHT : DIMENSION_HEIGHT / 2,
    width: isDesktop ? DIMENSION_WIDTH / 2 : DIMENSION_WIDTH,
  },
});

export default OTPContainer;
