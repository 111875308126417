import React from "react";
import { View, Text } from "react-native";
import { useTranslation } from "react-i18next";
import {
  COMMON_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  TEXT_STYLES,
} from "@dehaat/dds";

const DiscountFooter = () => {
  const { t } = useTranslation();
  return (
    <View style={COMMON_STYLES["pv1/2"]}>
      <Text
        style={[
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textNeutral90,
        ]}
      >
        {t("only_1_coupon_applicable")}
      </Text>
      <Text
        style={[
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.BASE,
          TEXT_STYLES.textNeutral90,
          COMMON_STYLES["mt1/4"],
        ]}
      >
        {t("best_coupon_will_be_auto_applied")}
      </Text>
    </View>
  );
};

export default DiscountFooter;
