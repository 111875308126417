import { post } from "../axios/auth";
type Response = {otp_sent: 'success' | 'failed'};

const sendOtp = async (mobileNumber: string, client_id: string) => {
    const body = {
        mobile_number: mobileNumber,
        client_id,
    };
    try {
        const {otp_sent} = await post<Response>(
            'realms/dehaat/custom/sendOTP',
            body,
        );
        return otp_sent === 'success';
    } catch (e) {
        return false;
    }
};

export default sendOtp;
