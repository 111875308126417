import { FILTER_VALUES } from "../constants/Common";
import { Product, ProductCategory } from "../models/Product";
import { get } from "app/partner-ordering-ui/utils/axios/index";
import { isDBA } from "../utils/helpers/isAIMSApp";

interface Params {
  widgetId: string;
  gridChildId?: string;
  salesOfficeId: string;
  filter: FILTER_VALUES;
}

interface Response {
  count: number;
  previous: number | null;
  next: number | null;
  results: Product[];
}

const fetchWidgetProductsList = async ({
  widgetId,
  gridChildId,
  salesOfficeId,
  filter,
}: Params) => {
  const url =  `/widget/${widgetId}/products`;

  const params: Record<string, string | number | boolean | undefined> = {
    coupons_info: true,
    page_size: 20,
    page_number: 1,
    dehaat_price_gt: isDBA() ? 0 : undefined,
    untaxed_dehaat_price__gt: isDBA() ? 0 : undefined,
    include: "bundles",
    sales_office_id: salesOfficeId,
    sort: filter || FILTER_VALUES.IN_STOCK,
    grid_child_id: gridChildId
  };
  if (filter === FILTER_VALUES.IN_STOCK) {
    params.inventory_gte = 1;
  }

  try {
    const response = await get<{ data: Response }>(url, params);
    return response.data;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default fetchWidgetProductsList;
