import React from "react";
import { NativeStackNavigationOptions } from "@react-navigation/native-stack";

import SearchIcon2 from "app/partner-ordering-ui/icons/SearchIcon2";
import ProductDetail from "app/partner-ordering-ui/screens/productDetail";
import MyCart from "app/partner-ordering-ui/screens/cart";
import ProductSearch from "app/partner-ordering-ui/screens/productSearch";
import ComboDetailPage from "app/partner-ordering-ui/screens/comboDetail";
import GrowthPlanList from "app/partner-ordering-ui/screens/growthPlanList";
import ClearanceSaleList from "app/partner-ordering-ui/screens/clearanceList";
import CheckoutStrip from "app/partner-ordering-ui/components/CheckoutStrip";
import ProductList from "app/partner-ordering-ui/screens/productList";
import { ROUTES as ORDERING_ROUTES } from "app/partner-ordering-ui/routes/types";
import { ROUTES as STFRoutes } from "app/sell-to-farmer/routes/types";
import OrderSuccess from "app/partner-ordering-ui/screens/orderSuccess";
import OrderFailure from "app/partner-ordering-ui/screens/orderFailure";
import OrderPending from "app/partner-ordering-ui/screens/orderPending";
import CuretadList from "app/partner-ordering-ui/screens/curatedList";
import CumulativeOffers from "app/partner-ordering-ui/screens/cumulativeOffer";
import LocalSearch from "app/partner-ordering-ui/screens/localSearch";
import HeaderIcon from "app/partner-ordering-ui/components/cart/HeaderIcon";
import CustomProductList from "app/partner-ordering-ui/screens/customProductList";
import { rnNavigation } from "app/utils/appConfig";
import { ROUTES } from "./types";
import FarmerOrderListing from "app/sell-to-farmer/screens/orderListing";
import { isDesktop } from "app/sell-to-farmer/constants/common";
import OrderDetails from "app/sell-to-farmer/screens/orderDetails";

export const routesOrdering: Record<
  string,
  { component: React.FC<any>; options?: NativeStackNavigationOptions }
> = {
  [ORDERING_ROUTES.PRODUCT_SEARCH]: {
    component: ProductSearch,
    options: {
      headerShown: false,
    },
  },
  [ORDERING_ROUTES.PRODUCT_LIST]: {
    component: ProductList,
    options: {
      title: "Buy Products",
      headerRight: () => (
        <SearchIcon2
          height={40}
          width={40}
          onPress={() =>
            rnNavigation({
              name: ORDERING_ROUTES.PRODUCT_SEARCH,
              params: { navigatingFrm: ORDERING_ROUTES.PRODUCT_LIST },
            })
          }
        />
      ),
      headerLeft: () => <></>, //Hide Back From List Since It's Opened In Fragment
    },
  },
  [ORDERING_ROUTES.PRODUCT_DETAIL]: {
    component: ProductDetail,
    options: {
      title: "Product Description",
    },
  },
  [ORDERING_ROUTES.COMBO_DETAIL]: {
    component: ComboDetailPage,
    options: {
      title: "Product Description",
    },
  },
  [ORDERING_ROUTES.MY_CART]: {
    component: MyCart,
    options: {
      title: "Cart",
      headerRight: () => <HeaderIcon />,
    },
  },

  [ORDERING_ROUTES.GROWTH_PLAN_LIST]: {
    component: GrowthPlanList,
    options: {
      title: "Growth Plan Basket",
      headerRight: () => (
        <SearchIcon2
          height={40}
          width={40}
          onPress={() =>
            rnNavigation({
              name: ORDERING_ROUTES.PRODUCT_SEARCH,
              params: {
                navigatingFrm: ORDERING_ROUTES.GROWTH_PLAN_LIST,
              },
            })
          }
        />
      ),
    },
  },
  [ORDERING_ROUTES.CLEARANCE_SALE_LIST]: {
    component: ClearanceSaleList,
    options: {
      title: "Clearance Sale",
    },
  },
  [ORDERING_ROUTES.CHECKOUT_BANNER]: {
    component: CheckoutStrip,
    options: {
      headerShown: false,
    },
  },
  [ORDERING_ROUTES.ORDER_SUCCESS]: {
    component: OrderSuccess,
    options: {
      headerShown: false,
    },
  },
  [ORDERING_ROUTES.ORDER_FAILURE]: {
    component: OrderFailure,
    options: {
      headerShown: false,
    },
  },
  [ORDERING_ROUTES.ORDER_PENDING]: {
    component: OrderPending,
    options: {
      headerShown: false,
    },
  },
  [ORDERING_ROUTES.CURATED_LIST]: {
    component: CuretadList,
    options: {
      title: "Curated List",
    },
  },
  [ORDERING_ROUTES.CUMULATIVE_OFFERS]: {
    component: CumulativeOffers,
    options: {
      title: "Offer Details",
    },
  },
  [ORDERING_ROUTES.LOCAL_SEARCH]: {
    component: LocalSearch,
    options: {
      headerShown: false,
    },
  },
  [ORDERING_ROUTES.CUSTOM_PRODUCTS_LIST]: {
    component: CustomProductList,
  },
};

export const routesSTF: Record<
  string,
  { component: React.FC<any>; options?: NativeStackNavigationOptions }
> = {
  [STFRoutes.FARMER_ORDERS]: {
    component: FarmerOrderListing,
    options: {
      headerShown: !isDesktop,
      title: "Online Order",
      headerBackVisible: false,
    },
  },
  [STFRoutes.ORDER_DETAILS]: {
    component: OrderDetails,
    options: {
      headerShown: true,
      title: "Order Details",
    },
  },
};

export const linking = (() => {
  const screens: Record<string, string> = {};
  screens[ROUTES.LOGIN] = ROUTES.LOGIN;
  Object.keys({ ...routesOrdering, ...routesSTF }).forEach((route) => {
    screens[route] = route;
  });

  return {
    config: { screens },
    prefixes: [],
  };
})();
