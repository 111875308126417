import { ClearanceProduct } from "../models/Product";
import { get } from "app/partner-ordering-ui/utils/axios/index";
import { logExceptionAndGetTraceIds } from "../utils/helpers/common";

interface Response {
  count: number;
  previous: number | null;
  next: number | null;
  results: ClearanceProduct[];
}

const fetchClearanceProducts = async (
  productId: string,
  salesOfficeId: number,
  limit = 10,
  offset = 0
) => {
  const url = `/products/clearance`;
  try {
    const response = await get<{ data: Response }>(url, {
      limit,
      offset,
      sales_office_id: salesOfficeId,
      top_product: productId,
      coupons_info: true,
    });
    return response.data;
  } catch (e) {
    throw new Error(logExceptionAndGetTraceIds(e));
  }
};

export default fetchClearanceProducts;
