import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { COMMON_STYLES } from "@dehaat/dds";

import ProductTabs from "app/partner-ordering-ui/components/Tabs";
import Filters from "app/partner-ordering-ui/components/Filters";
import useSearch from "app/partner-ordering-ui/hooks/useSearch";
import {
  ProductCategory,
  QuickFilter,
} from "app/partner-ordering-ui/models/Product";

import RenderProductList from "app/partner-ordering-ui/components/productList/list";
import ShimmerProductCardHz from "app/partner-ordering-ui/components/cards/ProductCardHzShimmer";
import LicenceWarningCard from "app/partner-ordering-ui/components/cards/LicenceWarningCard";
import GrowthBasketBanner from "app/partner-ordering-ui/components/productList/growthBasket/GrowthBasketBanner";
import { getLicenceDetails } from "app/partner-ordering-ui/utils/helpers/product";
import fetchGrowthBannerData from "app/partner-ordering-ui/apis/fetchGrowthBannerData";
import OutstandingCard from "app/partner-ordering-ui/components/cards/OutstandingCard";
import { useAppSelector } from "app/hooks/reduxHooks";
import {
  selectDCData,
  selectFertilizerSubCatList,
} from "app/store/reducer/native";
import OutstandingAmountDrawer from "app/partner-ordering-ui/components/drawers/OutstandingAmountDrawer";
import { startOutstandingAmountPaymentFlow } from "app/utils/appConfig";
import { fireProductListScreenViewedEvent } from "../utils/helpers/mixpanel";
import { ProductListScreenProps, ROUTES } from "../routes/types";
import { isAIMS, isDBA } from "../utils/helpers/isAIMSApp";
import LogErrorModal from "../components/LogError";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import { PRODUCT_CONSTANTS } from "../constants/Product";

interface ListPageData {
  activeCategory: ProductCategory;
  activeQuickFilter: QuickFilter | null;
  appliedFilter: string;
}

const CUSTOM_ALL_CATEGORY: ProductCategory = {
  id: "",
  name: "All",
  type: "all",
  children: [],
};

const initialPageData = {
  activeCategory: CUSTOM_ALL_CATEGORY,
  activeQuickFilter: null,
  appliedFilter: "",
};

const ProductList = ({ navigation }: ProductListScreenProps) => {
  const [pageData, setPageData] = useState<ListPageData>(initialPageData);
  const [displayGrowthBanner, setDisplayGrowthBanner] = useState(false);
  const [open, setOpen] = useState(false);

  const dc = useAppSelector(selectDCData) || {};

  const subCategoryList = useAppSelector(selectFertilizerSubCatList);

  const licenseInfo = getLicenceDetails({
    categoryType: pageData.activeCategory.type,
    subCategoryId: pageData.activeQuickFilter?.id || "", // listing check
  });

  const [{ licenseStatus, blockOrder, featureCheck }, setLicenseInfo] =
    useState(licenseInfo);

  const getFilterQuery = () => {
    const query: Record<string, string> = { sort: pageData.appliedFilter };
    if (pageData.activeQuickFilter?.id) {
      query.subCategoryId = pageData.activeQuickFilter?.id;
    } else {
      query.categoryId = pageData.activeCategory?.id;
    }
    if (isAIMS()) {
      query.partner_id = dc.partner_id;
    }
    return query;
  };

  const {
    error,
    loading,
    categories,
    products,
    getProducts,
    loadingNext,
    fetchNext,
    setError,
  } = useSearch(getFilterQuery());

  useObserverInternetActivity();

  const getListHeader = () => {
    if (pageData.activeCategory.id) {
      if (
        licenseStatus &&
        (pageData.activeCategory.type !== PRODUCT_CONSTANTS.FERTILIZERS ||
          subCategoryList.includes(pageData.activeQuickFilter?.id))
      ) {
        return (
          <LicenceWarningCard
            status={licenseStatus}
            isOrderBlocked={blockOrder && !dc.app_blocked}
            featureCheck={featureCheck}
          />
        );
      }
      return <></>;
    }
    return displayGrowthBanner && <GrowthBasketBanner />;
  };

  useEffect(() => {
    const newSubCategoryLicenseInfo = getLicenceDetails({
      categoryType: pageData.activeCategory.type,
      subCategoryId: pageData.activeQuickFilter?.id || "",
    });
    setLicenseInfo(newSubCategoryLicenseInfo);
  }, [pageData.activeCategory.type, pageData.activeQuickFilter?.id]);

  useEffect(() => {
    //add banner display logic
    const fetchDisplayBannerStatus = async () => {
      const response = await fetchGrowthBannerData();
      if (response && response.display_banner) {
        setDisplayGrowthBanner(response.display_banner);
      } else {
        setDisplayGrowthBanner(false);
      }
    };
    if (isDBA()) {
      fetchDisplayBannerStatus();
    }
  }, []);

  useEffect(() => {
    fireProductListScreenViewedEvent(dc?.is_priority_dc);
  }, []);

  useEffect(() => {
    if (dc) {
      navigation.setOptions({
        title: isAIMS() ? `Sale \n${dc.name}` : "Buy Products",
      });
    }
  }, [dc.name]);

  return (
    <View style={COMMON_STYLES.flex1}>
      {dc.app_blocked ? (
        <OutstandingCard
          onCardClick={() => setOpen(true)}
          days={dc.app_block_days}
          amount={dc.app_block_amount}
          onCTAClick={() =>
            startOutstandingAmountPaymentFlow(dc.app_block_amount)
          }
        />
      ) : null}

      {categories.length > 0 && (
        <>
          <ProductTabs
            activeCategory={pageData.activeCategory}
            categories={[CUSTOM_ALL_CATEGORY, ...categories]}
            onTabPress={(category) =>
              setPageData((prev) => ({
                ...prev,
                activeCategory: category,
                activeQuickFilter: null,
              }))
            }
          />
          {
            <Filters
              activeQuickFilter={pageData.activeQuickFilter}
              appliedFilter={pageData.appliedFilter}
              onQuickFilterClick={(filter) =>
                setPageData((prev) => ({ ...prev, activeQuickFilter: filter }))
              }
              quickFilters={pageData.activeCategory.children}
              onApplyFilter={(filter) =>
                setPageData((prev) => ({ ...prev, appliedFilter: filter }))
              }
            />
          }
        </>
      )}
      {loading ? (
        <ShimmerProductCardHz noOfShimmers={5} />
      ) : (
        <RenderProductList
          products={products}
          getProducts={() =>
            getProducts(
              undefined,
              undefined,
              undefined,
              undefined,
              getFilterQuery()
            )
          }
          fetchNext={fetchNext}
          loadingNext={loadingNext}
          isViewOnlyCard={blockOrder && !dc.app_blocked}
          listHeader={getListHeader()}
          isHeaderSticky={!!pageData.activeCategory.id}
          isOrderingBlocked={dc.app_blocked}
          handleRevealPriceClick={() => setOpen(true)}
          navigatingFrom={ROUTES.PRODUCT_LIST}
        />
      )}
      <OutstandingAmountDrawer
        days={dc.app_block_days}
        amount={dc.app_block_amount}
        isOpen={open}
        onClose={() => setOpen(false)}
        onCtaClick={() => {
          startOutstandingAmountPaymentFlow(dc.app_block_amount);
          setOpen(false);
        }}
      />
      <LogErrorModal
        visible={!!error}
        message={error}
        onClose={() => setError("")}
      />
    </View>
  );
};

export default ProductList;
