import React from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";
const ZoomLensIcon = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 34 34" fill="none" {...props}>
      <G opacity=".85">
        <Path
          d="M0 17C0 7.611 7.611 0 17 0s17 7.611 17 17-7.611 17-17 17S0 26.389 0 17Z"
          fill="#F5F8FA"
        />
        <Path
          d="M12.195 16.094h7.796M16.094 12.196v7.796M16.095 25.19a9.095 9.095 0 1 0 0-18.19 9.095 9.095 0 0 0 0 18.19ZM22.586 22.594l5.197 5.197"
          stroke="#33393D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};
export default ZoomLensIcon;
