import React from "react";
import { View, Text, StyleSheet, Image, BackHandler } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  Button,
  COMMON_STYLES,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { EmptyCartItem } from "app/partner-ordering-ui/assets/images";
import { clearNavigationBackStack } from "app/utils/appConfig";
import { defaultBackPressCallback } from "app/partner-ordering-ui/utils/helpers/common";

const NoItemCart = () => {
  const { t } = useTranslation();

  const handleButtonClick = async () =>
    clearNavigationBackStack
      ? await clearNavigationBackStack()
      : defaultBackPressCallback();

  return (
    <View
      style={[
        BG_STYLES.bgNeutral10,
        COMMON_STYLES.alignItemsCenter,
        COMMON_STYLES.flex1,
        COMMON_STYLES.justifyCenter,
      ]}
    >
      <View
        style={[
          COMMON_STYLES.alignItemsCenter,
          COMMON_STYLES.justifyCenter,
          styles.width264,
        ]}
      >
        <Image
          source={EmptyCartItem}
          style={[COMMON_STYLES["mb3/2"], styles.image120x120]}
        />
        <Text
          style={[
            COMMON_STYLES["mb1/2"],
            TEXT_STYLES.textNeutral80,
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
          ]}
        >
          {t("empty_cart_message")}
        </Text>
        <Text
          style={[
            COMMON_STYLES["mb3/2"],
            COMMON_STYLES.textCenter,
            TEXT_STYLES.textNeutral80,
            TYPOGRAPHY.TEXT2,
          ]}
        >
          {t("empty_cart_detail_message")}
        </Text>
        <Button
          onClick={handleButtonClick}
          text={t("browse_button")}
          size={SIZE.small}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  width264: {
    width: 264,
  },
  image120x120: {
    width: 120,
    height: 120,
  },
});
export default NoItemCart;
