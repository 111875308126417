import { useState } from "react";

import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";

import {
  fetchAndSetCartInfo,
  resetCart,
  selectCartData,
  selectCartId,
  selectCartInfo,
  selectClearanceItems,
  selectItems,
  selectSchemeId,
  setCartId,
} from "../store/reducer/cart";
import { countItems } from "../utils/helpers/cart";
import { selectMOAValue } from "../../store/reducer/native";

const useCart = () => {
  const [isCartLoading, setIsCartLoading] = useState(true);
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector(selectItems);
  const clearanceItems = useAppSelector(selectClearanceItems);
  const cartInfo = useAppSelector(selectCartInfo);
  const cartId = useAppSelector(selectCartId);
  const cartData = useAppSelector(selectCartData);
  const minOrderAmount = useAppSelector(selectMOAValue);
  const schemeId = useAppSelector(selectSchemeId);

  const updateCartId = (id: string) => dispatch(setCartId({ id }));

  const resetCartItems = () => dispatch(resetCart());

  const updateCartInfo = () => {
    setIsCartLoading(true);
    dispatch(fetchAndSetCartInfo()).finally(() => setIsCartLoading(false));
  };

  const getNumberOfItemsInCart = () => {
    const totalRegularItems = countItems(cartItems);
    const totalClearanceItems = countItems(clearanceItems);
    return totalClearanceItems + totalRegularItems;
  };

  const getNoOfLotInCart = () => {
    const totalRegularLots = Object.keys(cartItems).length;
    const totalClearanceLots = Object.keys(clearanceItems).length;
    return totalRegularLots + totalClearanceLots;
  };

  return {
    cartId,
    cartItems,
    cartData,
    cartInfo,
    clearanceItems,
    getNumberOfItemsInCart,
    minOrderAmount,
    updateCartInfo,
    updateCartId,
    isCartLoading,
    setIsCartLoading,
    getNoOfLotInCart,
    resetCartItems,
    schemeId,
  };
};

export default useCart;
