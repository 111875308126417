import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  BUTTON_TYPE,
  COMMON_STYLES,
  ProductComboCardVertical,
  Strip,
  TYPOGRAPHY,
} from "@dehaat/dds";
import fetchProductsList from "app/partner-ordering-ui/apis/fetchProductBundles";
import {
  InventoryErrorModalState,
  Product,
  ProductVariant,
} from "app/partner-ordering-ui/models/Product";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import { useAppDispatch, useAppSelector } from "app/hooks/reduxHooks";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import { rnNavigation, getFromRemoteConfig } from "app/utils/appConfig";
import ProductComboCardVerticalShimmer from "../../cards/ProductComboCardVerticalShimmer";
import {
  getBundleImages,
  getCombinedLicenseDetails,
  getComboVisible,
} from "app/partner-ordering-ui/utils/helpers/product";
import ComboPriceInfo from "../ComboPriceInfo";
import { selectDCData } from "app/store/reducer/native";
import {
  fireComboProductAddToCartEvent,
  fireExpandComboCarousal,
} from "app/partner-ordering-ui/utils/helpers/mixpanel";
import { MIXPANEL_KEYS } from "app/partner-ordering-ui/constants/eventNames";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";
import InsufficientInventoryModal from "../../resuable/InsufficientInventoryModal";
import { LICENSE_STATUS } from "app/partner-ordering-ui/constants/Product";

interface Props {
  id: number | string;
  currentScreen: string;
  eventData?: {
    product: Product;
    variant: ProductVariant;
    productPosition: number;
  };
}

const ProductComboList = ({ id, currentScreen, eventData }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [comboList, setComboList] = useState<Product[]>([]);
  const [inventoryErrorState, setInventoryErrorState] =
    useState<InventoryErrorModalState>({
      isVisible: false,
    });
  const [showBundlesOnly, setShowBundlesOnly] = useState(false);
  const { t } = useTranslation();
  const dc = useAppSelector(selectDCData);

  const { cartItems } = useCart();
  const dispatch = useAppDispatch();

  const fetchComboList = async () => {
    const response = await fetchProductsList(
      id,
      dc.sales_office_id,
      dc.partner_id
    );
    if (response) {
      setComboList(response.results);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchComboList();
  }, []);

  useEffect(() => {
    getFromRemoteConfig("show_bundles_only").then((value: boolean) => {
      setShowBundlesOnly(value);
      if (eventData) {
        fireExpandComboCarousal({
          variant: eventData.variant,
          product: eventData.product,
          productPosition: eventData!.productPosition + 1,
          showBundlesOnly: showBundlesOnly,
          isPriorityDc: dc?.is_priority_dc,
          screenName: currentScreen,
        });
      }
    });
  }, []);
  return getComboVisible(comboList) ? (
    <View style={COMMON_STYLES["mv1/2"]}>
      <Strip
        bgColor="bgPurple100"
        heading="Get discount with combo | Select any"
        containerStyle={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />
      <View style={[BG_STYLES.bgPurple10, COMMON_STYLES.p1]}>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {isLoading ? (
            <>
              {[1, 2].map((key) => (
                <ProductComboCardVerticalShimmer
                  key={key}
                  containerStyle={COMMON_STYLES.mr1}
                /> //TODO: Move SHIMMER to DDS and consume here
              ))}
            </>
          ) : (
            <View style={[COMMON_STYLES.row]}>
              {comboList.map((product, index) => {
                const { id: comboId, bundles } = product;
                const {
                  id,
                  name,
                  actual_price,
                  discounted_price,
                  bundled_products,
                  orderable_quantity,
                } = bundles[0];
                const quantity = cartItems[id]?.qty;

                const handleQuantityUpdate = (qty: number) => {
                  const availableQty = Math.min(
                    MAX_INVENTORY_ALLOWED,
                    orderable_quantity
                  );
                  if (qty <= availableQty)
                    dispatch(updateCartItem({ id, qty }));
                  else {
                    dispatch(updateCartItem({ id, qty: availableQty }));
                    setInventoryErrorState({
                      isVisible: true,
                      name: product.name,
                      availableInventory: availableQty,
                    });
                  }
                };

                const { licenseStatus, blockOrder } =
                  getCombinedLicenseDetails(bundled_products);

                const images = getBundleImages(bundled_products);
                return orderable_quantity ? (
                  <ProductComboCardVertical
                    containerStyle={
                      index !== comboList.length - 1 && COMMON_STYLES.mr1
                    }
                    key={id}
                    images={images}
                    numberOfProductInCombo={bundled_products.length}
                    productName={name}
                    onDeleteClick={() => dispatch(deleteCartItem({ id }))}
                    onPlusClick={() => handleQuantityUpdate(quantity + 1)}
                    onMinusClick={() => handleQuantityUpdate(quantity - 1)}
                    onQunatityUpdate={(qty) => handleQuantityUpdate(qty)}
                    cta={{
                      onClick: () => {
                        if (
                          !(
                            (blockOrder &&
                              licenseStatus === LICENSE_STATUS.PENDING) ||
                            licenseStatus === LICENSE_STATUS.SUBMITTED
                          )
                        ) {
                          dispatch(addItemToCart({ id, product }));
                          fireComboProductAddToCartEvent(
                            {
                              product: product?.bundles[0],
                              screenName: currentScreen,
                              productPosition: index + 1,
                              isPriorityDc: dc?.is_priority_dc,
                            },
                            {
                              [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]:
                                product?.similar_product_count,
                              [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: false,
                              [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: true,
                            }
                          );
                        }
                        rnNavigation({
                          name: ROUTES.COMBO_DETAIL,
                          params: {
                            id: comboId,
                          },
                        });
                      },
                      text: blockOrder ? t("view_product") : t("add"),
                      type: blockOrder
                        ? BUTTON_TYPE.SECONDARY
                        : BUTTON_TYPE.PRIMARY,
                      textStyle: blockOrder ? TYPOGRAPHY.CAPTION1 : null,
                    }}
                    discountInfo={
                      <ComboPriceInfo
                        actualPrice={Number(actual_price)}
                        discountPrice={Number(discounted_price)}
                      />
                    }
                    quantity={quantity}
                    availableInventory={Math.min(
                      orderable_quantity,
                      MAX_INVENTORY_ALLOWED
                    )}
                    onCardClick={() =>
                      rnNavigation({
                        name: ROUTES.COMBO_DETAIL,
                        params: {
                          id: comboId,
                        },
                      })
                    }
                  />
                ) : null;
              })}
            </View>
          )}
        </ScrollView>
      </View>
      <InsufficientInventoryModal
        isVisible={inventoryErrorState.isVisible}
        onCTAClick={() => setInventoryErrorState({ isVisible: false })}
        name={inventoryErrorState.name}
        availableInventory={inventoryErrorState.availableInventory}
      />
    </View>
  ) : null;
};

export default ProductComboList;
