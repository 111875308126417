import React from "react";
import { useTranslation } from "react-i18next";
import { Image, StyleSheet, Text, View } from "react-native";
import {
  BUTTON_STATUS,
  BUTTON_TYPE,
  Button,
  COMMON_STYLES,
  CenterModal,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { RemoveItemPopUpIcon } from "app/partner-ordering-ui/assets/images";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  handleRemoveItem: () => void;
}

const RemoveItemConfirmationModal = ({
  isVisible,
  onClose,
  handleRemoveItem,
}: Props) => {
  const { t } = useTranslation();

  return (
    <CenterModal onClose={onClose} isVisible={isVisible}>
      <View
        style={[COMMON_STYLES.alignItemsCenter, COMMON_STYLES.justifyCenter]}
      >
        <Image source={RemoveItemPopUpIcon} style={styles.image80x80} />
        <View style={[COMMON_STYLES.mt1, COMMON_STYLES.pb1]}>
          <Text
            style={[
              TEXT_STYLES.textNeutral90,
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              styles.titleStyle,
            ]}
          >
            {t("remove_item_alert")}
          </Text>
          <View
            style={[
              COMMON_STYLES.justifyCenter,
              COMMON_STYLES.row,
              styles.margin20,
            ]}
          >
            <Button
              buttonStyle={[COMMON_STYLES.mr1]}
              onClick={handleRemoveItem}
              text={t("remove_button")}
              type={BUTTON_TYPE.PRIMARY}
              size={SIZE.small}
              status={BUTTON_STATUS.DESTRUCTIVE}
            />

            <Button
              onClick={onClose}
              text={t("dont_remove_button")}
              type={BUTTON_TYPE.PRIMARY}
              size={SIZE.small}
            />
          </View>
        </View>
      </View>
    </CenterModal>
  );
};

const styles = StyleSheet.create({
  image80x80: {
    width: 80,
    height: 80,
  },
  margin20: {
    margin: 20,
  },
  titleStyle: {
    textAlign: "center",
    width: 275,
  },
});

export default RemoveItemConfirmationModal;
