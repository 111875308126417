import React from "react";
import { Svg, SvgProps, Path, Rect, G } from "react-native-svg";

const SearchIcon2 = (props: SvgProps) => {
  return (
    <Svg fill="none" {...props}>
      <Rect width="39" height="39" x=".5" y=".5" stroke="#E3E3E3" rx="7.5" />
      <G
        stroke="#4D4D4D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <Path d="M18.875 26.75a7.875 7.875 0 1 0 0-15.75 7.875 7.875 0 0 0 0 15.75ZM24.444 24.444 29 29" />
      </G>
    </Svg>
  );
};

export default SearchIcon2;
