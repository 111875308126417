import React, { ReactElement } from "react";
import { COLORS, COMMON_STYLES, RadioButton } from "@dehaat/dds";
import { Batch } from "app/partner-ordering-ui/models/Product";
import ClearanceListVariantCard from "./ClearanceListVariantCard";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";

interface Props {
  batches: Batch[];
  onCardClick: (batch: Batch, index: number) => void;
  selectedIndex: number;
}

const ClearanceVariantSelect = ({
  batches,
  onCardClick,
  selectedIndex,
}: Props) => {
  const dc = useAppSelector(selectDCData);
  const getRadioItems = () => {
    const radioElements: Array<{
      radioElement: ReactElement;
      value: Batch;
    }> = [];
    {
      batches?.map((batch: Batch, index) => {
        const item = {
          radioElement: (
            <ClearanceListVariantCard
              key={batch.batchId}
              conatinerStyle={[COMMON_STYLES["mb1/2"]]}
              packAttribute={batch.packAttribute}
              discountedPrice={batch.discountPrice}
              remark={null} //@todo: Update the logic to show error remarks on clearance items for AIMS
              price={batch.price}
              unitOfMeasure={batch.constituentUom}
              isPriceLocked={dc?.app_blocked}
              onCardClick={() => onCardClick(batch, index)}
              expiry={batch.expiry}
            />
          ),
          value: batch,
        };
        radioElements.push(item);
      });
      return radioElements;
    }
  };

  const list = getRadioItems();

  return (
    <RadioButton
      selected={selectedIndex}
      list={list}
      onClick={(index) => onCardClick(list[index].value, index)}
      containerStyle={{
        borderWidth: 2,
        borderColor: COLORS.purple10,
        borderRadius: 12,
        padding: 12,
        alignItems: "flex-start",
        position: "relative",
        marginBottom: 16,
        backgroundColor: COLORS.purple10,
      }}
      selectedRadioContainerStyle={{
        borderColor: COLORS.purple100,
      }}
    />
  );
};

export default ClearanceVariantSelect;
