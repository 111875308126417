import React from "react";
import { ClipPath, Defs, G, Path, Svg, SvgProps } from "react-native-svg";

const CartIcon = (props: SvgProps) => {
  return (
    <Svg width="17" height="18" fill="none" {...props}>
      <G
        clipPath="url(#a)"
        stroke="#0F8040"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M3.754 5.063h11.914l-1.753 6.496a1.122 1.122 0 0 1-.382.591c-.185.147-.41.226-.641.225H6.53a1.018 1.018 0 0 1-.641-.225 1.121 1.121 0 0 1-.382-.59L3.104 2.657a.564.564 0 0 0-.192-.295.512.512 0 0 0-.32-.113H1.477M13.693 16.5c.39 0 .708-.336.708-.75s-.317-.75-.708-.75c-.391 0-.709.336-.709.75s.318.75.709.75ZM5.904 16.5c.39 0 .708-.336.708-.75S6.295 15 5.904 15c-.392 0-.709.336-.709.75s.317.75.709.75Z" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h17v18H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default CartIcon;
