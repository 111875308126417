import React, { useEffect } from "react";
import { TouchableOpacity } from "react-native";
import { NativeStackNavigationOptions } from "@react-navigation/native-stack";

import {
  CuratedListScreenProps,
  ROUTES,
} from "app/partner-ordering-ui/routes/types";
import { rnNavigation } from "app/utils/appConfig";

import { CuratedListRouteParams } from "../models/CuratedList";
import CuratedItemsList from "../components/productList/curatedList/RenderCuretedItems";

import ProductGroupCuratedList from "../components/productList/curatedList/GroupCuratedList";
import SearchIcon2 from "../icons/SearchIcon2";
import { fireCuratedListScreenViewEvent } from "../utils/helpers/mixpanel";
import useObserverInternetActivity from "../hooks/useInternetActivity";

const CuratedList = ({ route, navigation }: CuratedListScreenProps) => {
  const params = route.params as never as CuratedListRouteParams;

  useObserverInternetActivity();

  useEffect(() => {
    if (params?.screen_name) {
      const option: Partial<NativeStackNavigationOptions> = {
        title: params.screen_name,
      };
      if (params.curated_list_meta_data) {
        option.headerRight = () => (
          <TouchableOpacity>
            <SearchIcon2
              height={40}
              width={40}
              onPress={() => {
                return rnNavigation({
                  name: ROUTES.PRODUCT_SEARCH,
                  params: {
                    navigatingFrm: ROUTES.CURATED_LIST,
                  },
                });
              }}
            />
          </TouchableOpacity>
        );
      }
      navigation.setOptions(option);
    }
  }, []);

  useEffect(() => {
    if (params.list_id) {
      fireCuratedListScreenViewEvent(
        params?.product_list_viewed_analytics_data
      );
    }
  }, []);

  if (params.curated_list_meta_data) {
    return <ProductGroupCuratedList data={params.curated_list_meta_data} widgetId={params.widget_id} />;
  }

  return (
    <CuratedItemsList
      id={params.product_group_id!}
      listId={params.list_id}
      navigatingFrom={params.title}
      widgetId={params.widget_id}
    />
  );
};

export default CuratedList;
