import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "app/resources";
import {
  NAMESPACE,
  SUPPORTED_LANG,
} from "app/partner-ordering-ui/constants/Common";

const config = {
  compatibilityJSON: "v3",
  defaultNS: NAMESPACE.common,
  resources: resources,
  debug: true,
  //language to use if translations in user language are not available
  fallbackLng: SUPPORTED_LANG.en,
  supportedLngs: Object.keys(SUPPORTED_LANG),
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    useSuspense: false,
  },
};

i18n.use(initReactI18next).init(config);

export default i18n;
