import React, { ReactNode } from "react";
import { Text, TextInput, View, StyleSheet } from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  COLORS,
  COMMON_STYLES,
  PARAGRAPH_STYLES,
  TEXT_STYLES,
} from "@dehaat/dds";

interface Props {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  title: string;
  placeholder: string;
  value: string;
  onChangeText: (text: string) => void;
  maxLength?: number;
}

const BigInput = ({
  leftIcon,
  rightIcon,
  title,
  placeholder,
  value,
  onChangeText,
  maxLength,
}: Props) => {
  return (
    <View
      style={[
        COMMON_STYLES.row,
        COMMON_STYLES.br1,
        COMMON_STYLES.b1,
        BORDER_STYLES.borderNeutral10,
        COMMON_STYLES.justifyCenter,
        styles.container,
      ]}
    >
      {leftIcon && (
        <View
          style={[
            BG_STYLES.bgNeutral10,
            styles.leftIconContainer,
            COMMON_STYLES.justifyCenter,
            COMMON_STYLES.alignItemsCenter,
          ]}
        >
          {leftIcon}
        </View>
      )}
      <View
        style={[
          leftIcon
            ? [
                styles.inputContainerRightRadius,
                COMMON_STYLES.flex1,
                BG_STYLES.bgWhite,
                COMMON_STYLES.justifyCenter,
              ]
            : [
                styles.inputConatinerLeftRadius,
                COMMON_STYLES.flex1,
                BG_STYLES.bgWhite,
                COMMON_STYLES.justifyCenter,
              ],
          leftIcon && rightIcon
            ? styles.inputWithBothIcons
            : leftIcon || rightIcon
            ? styles.inputWithOneIcon
            : styles.inputWithNoIcon,
        ]}
      >
        <Text style={[PARAGRAPH_STYLES.MediumT3, TEXT_STYLES.textPrimary100]}>
          {title}
        </Text>
        <TextInput
          placeholder={placeholder}
          placeholderTextColor={COLORS.neutral70}
          value={value}
          onChangeText={onChangeText}
          style={styles.textInput}
          maxLength={maxLength}
          keyboardType="numeric"
        />
      </View>
      {rightIcon && (
        <View
          style={[
            BG_STYLES.bgNeutral10,
            styles.rightIconContainer,
            COMMON_STYLES.justifyCenter,
            COMMON_STYLES.alignItemsCenter,
          ]}
        >
          {rightIcon}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 64,
  },
  leftIconContainer: {
    paddingHorizontal: 10,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    maxWidth: "20%",
  },
  rightIconContainer: {
    paddingHorizontal: 10,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    maxWidth: "20%",
  },

  inputContainerRightRadius: {
    paddingTop: 5,
    paddingHorizontal: 10,
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  inputConatinerLeftRadius: {
    paddingTop: 5,
    paddingHorizontal: 10,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  inputWithBothIcons: {
    width: "60%",
  },
  inputWithOneIcon: {
    width: "80%",
  },
  inputWithNoIcon: {
    width: "100%",
  },
  textInput: {
    textAlignVertical: "top",
    textAlign: "left",
    height: 35, //Todo: How to handle outline
    paddingHorizontal: 10,
  },
});

export default BigInput;
