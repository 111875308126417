import React from 'react';
import Svg, { G, Path, PathProps, SvgProps } from 'react-native-svg';

interface Props{
    svgProps: SvgProps,
    pathProps: PathProps
}

const DropDownIcon = ({svgProps, pathProps}: Props) => (
    <Svg fill="none" viewBox="0 0 24 24" {...svgProps} stroke={"red"}>
        <G id="Icon 24">
            <Path
                id="Vector"
                d="M19.5026 15.0071L12.0013 7.50586L4.5 15.0071"
                stroke="#4C2C7A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                {...pathProps}
            />
        </G>
    </Svg>
);

export default DropDownIcon;