import React from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity } from "react-native";
import {
  COLORS,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import LensIcon from "app/partner-ordering-ui/icons/Lens";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import { rnNavigation } from "app/utils/appConfig";

const HeaderIcon = () => {
  const { t } = useTranslation();
  return (
    <TouchableOpacity
      onPress={() => rnNavigation({
        name: ROUTES.PRODUCT_SEARCH,
        params: { navigatingFrm: ROUTES.MY_CART }
      })}
      style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}
    >
      <Text
        style={[
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textPrimary100,
        ]}
      >
        {t("add_item_cart")}
      </Text>
      <LensIcon
        svgProps={{
          height: 20,
          width: 20,
          style: { marginHorizontal: 4 },
        }}
        pathProps={{ stroke: COLORS.primary100, fill: COLORS.primary100 }}
      />
    </TouchableOpacity>
  );
};

export default HeaderIcon;
