import React from 'react';
import Svg, {Circle, ClipPath, Defs, Ellipse, G, Path, SvgProps} from 'react-native-svg';

const LockBannerSvg = (props: SvgProps) => (
    <Svg viewBox={'0 0 75 108'} fill="none" {...props}>
        <Circle cx="64.5" cy="49.5" r="64.5" fill="#F2969E" />
        <Circle cx="69" cy="49" r="51" fill="#FFD9DC" />
        <Ellipse cx="64.5" cy="48" rx="29.5" ry="29" fill="#fff" />
        <G clipPath="url(#a)">
            <Path
                d="M69.602 47.964c-.093-.292-.21-.5-.368-.645a1.105 1.105 0 0 0-.266-.171 1.081 1.081 0 0 0-.149-.05l-.01-.004a1.58 1.58 0 0 0-.209-.035l-.016-.002c-.045-.004-.095-.005-.143-.007h-.427v-.762h-.02c.008-.785.046-1.56-.011-2.33-.153-2.061-1.744-3.852-3.769-4.31-.274-.062-.554-.1-.832-.148h-.639c-.144.024-.288.05-.433.07-2.378.346-4.202 2.437-4.208 4.826v1.586H58.1v1.068h-.416c-.049.002-.1.003-.145.007l-.014.002c-.075.008-.145.019-.21.035l-.007.002a1.111 1.111 0 0 0-.153.052 1.103 1.103 0 0 0-.266.172c-.158.146-.275.353-.367.644 0 .002-.003.004-.004.006-.01.06-.019.122-.019.185v8.24c0 .073.008.143.021.212.019.056.037.111.058.166.137.35.39.574.75.682.023.007.045.016.068.024.069.013.139.021.211.021h10.91c.073 0 .145-.008.215-.022.066-.021.132-.043.196-.07a1.1 1.1 0 0 0 .65-.735l.025-.07c.013-.066.02-.134.021-.204-.001-2.804-.023-5.631-.023-8.435Zm-5.806 4.725c-.1.072-.128.145-.127.26.003.527.003 1.053.003 1.579v.002a.608.608 0 0 1-1.219 0v-.003c0-.528 0-1.055.002-1.582 0-.108-.023-.179-.118-.249a1.196 1.196 0 0 1-.397-1.478c.233-.515.8-.815 1.337-.708.594.12 1.01.6 1.015 1.192.004.409-.164.746-.496.987Zm2.114-6.401v.762h-5.706v-.566a.279.279 0 0 0 .01-.091c-.002-.43-.006-.86 0-1.29.005-.37-.011-.746.042-1.11.212-1.443 1.539-2.473 3.028-2.374 1.426.095 2.594 1.31 2.622 2.742.012.642.004 1.285.005 1.927Z"
                fill="#CC293F"
            />
        </G>
        <Defs>
            <ClipPath id="a">
                <Path
                    fill="#fff"
                    transform="translate(52 38)"
                    d="M0 0h24v24H0z"
                />
            </ClipPath>
        </Defs>
    </Svg>
);

export default LockBannerSvg;