import { get } from "app/partner-ordering-ui/utils/axios/index";
import { DehaatCenterInfo } from "../models/DehaatCenter";

interface Response {
  data: DehaatCenterInfo[];
}

const fetchDehaatCenterInfo = async () => {
  const url = "/customer-management/my-dehaat-center";
  try {
    const response = await get<Response>(url);
    return response.data[0];
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default fetchDehaatCenterInfo;
