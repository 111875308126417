import React, { useEffect } from "react";
import { Pressable, Text, View } from "react-native";
import {
  BG_STYLES,
  COMMON_STYLES,
  EM,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  CrossIcon,
} from "@dehaat/dds";

import ErrorIcon from "app/partner-ordering-ui/icons/Error";

interface Props {
  title?: string;
  message?: string;
  duration?: number;
  onClose?: () => void;
}

const Snackbar = ({ title, message, duration, onClose }: Props) => {
  useEffect(() => {
    if (duration) {
      setTimeout(() => onClose?.(), duration);
    }
  }, []);

  return (
    <View
      style={[
        COMMON_STYLES["p3/4"],
        COMMON_STYLES["br1/2"],
        BG_STYLES.bgError10,
        { width: "100%" },
        COMMON_STYLES["mt1/2"],
      ]}
    >
      <View
        style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter, { gap: EM }]}
      >
        <ErrorIcon style={{ alignSelf: "flex-start" }} width={24} height={24} />
        <View style={{ flex: 1 }}>
          {title ? (
            <Text
              style={[
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TYPOGRAPHY.TEXT1,
                TEXT_STYLES.textError120,
              ]}
            >
              {title}
            </Text>
          ) : null}
          {message ? (
            <Text
              style={[
                VARIANTS_STYLES.BASE,
                TYPOGRAPHY.TEXT2,
                TEXT_STYLES.textNeutral80,
              ]}
            >
              {message}
            </Text>
          ) : null}
        </View>
        <Pressable onPress={onClose}>
          <CrossIcon width={24} height={24} />
        </Pressable>
      </View>
    </View>
  );
};

export default Snackbar;
