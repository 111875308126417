import React, { useEffect } from "react";
import { Text, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  Button,
  COMMON_STYLES,
  CheckoutWidget,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import CartIcon from "../icons/Cart";
import { ROUTES } from "../routes/types";
import useCart from "../hooks/useCart";
import {
  logEvent,
  rnNavigation,
  toggleCheckoutStrip,
} from "../../utils/appConfig";
import { MIXPANEL_EVENT, MIXPANEL_KEYS } from "../constants/eventNames";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import useFocusHook from "../hooks/useFocusHook";

interface Props {
  landingFrom: string;
}

const CheckoutStrip = ({ landingFrom }: Props) => {
  const { t } = useTranslation();
  const isFocused = useFocusHook();

  const {
    getNoOfLotInCart,
    getNumberOfItemsInCart,
    cartInfo,
    updateCartInfo,
    resetCartItems,
  } = useCart();

  const noOfItemsInCart = getNumberOfItemsInCart();
  const noOfLotsInCart = getNoOfLotInCart();
  const dc = useAppSelector(selectDCData);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isFocused) {
      if (noOfItemsInCart > 0) {
        timer = setTimeout(updateCartInfo, 500);
      } else {
        resetCartItems();
      }
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isFocused, noOfItemsInCart]);

  const fireGoToCartEvent = (eventName: string) => {
    const mixPanelPayload = {
      [MIXPANEL_KEYS.AVAILABLE_CREDIT_LIMIT]: dc.available_credit,
      [MIXPANEL_KEYS.CART_VALUE]: cartInfo.cart_info.payable,

      [MIXPANEL_KEYS.IS_PRIORITY_DC]: dc?.is_priority_dc,
      [MIXPANEL_KEYS.NUMBER_OF_ITEMS]: getNoOfLotInCart(),
      [MIXPANEL_KEYS.SCREEN_NAME]: landingFrom,
      [MIXPANEL_KEYS.SHOW_STOCKS_RUNNING_OUT]: false,
    };
    logEvent(eventName, JSON.stringify(mixPanelPayload));
  };

  useEffect(() => {
    toggleCheckoutStrip(noOfItemsInCart > 0);
  }, [noOfItemsInCart]);

  if (noOfItemsInCart > 0) {
    if (cartInfo.cart_info.payable) {
      return (
        <CheckoutWidget
          checkoutCTAText={t("go_to_cart")}
          noOfItemsInCart={noOfLotsInCart}
          onCheckoutCTAClick={() => {
            rnNavigation({
              name: ROUTES.MY_CART,
              params: { landingFrom: landingFrom },
            });
            fireGoToCartEvent(MIXPANEL_EVENT.GO_TO_CART);
          }}
          orderValue={cartInfo.cart_info.payable}
          cashbackInfo={
            cartInfo.cart_info.cashback
              ? `${t("cashback")}: ${formatCurrency(
                  cartInfo.cart_info.cashback
                )}`
              : ""
          }
        />
      );
    }
    return (
      <View
        style={[
          COMMON_STYLES.ph1,
          BG_STYLES.bgPrimary100,
          COMMON_STYLES["pv1/2"],
          COMMON_STYLES.row,
          COMMON_STYLES.alignItemsCenter,
          COMMON_STYLES.justifyBetween,
        ]}
      >
        <View>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral00,
            ]}
          >
            {noOfLotsInCart} {t("item_in_your_cart")}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral00,
              COMMON_STYLES["opacity1/2"],
            ]}
          >
            {t("complete_placing_order")}
          </Text>
        </View>
        <Button
          onClick={() => {
            rnNavigation({
              name: ROUTES.MY_CART,
              params: { landingFrom: landingFrom },
            });
            fireGoToCartEvent(MIXPANEL_EVENT.GO_TO_CART);
          }}
          buttonStyle={[BG_STYLES.bgWhite, COMMON_STYLES.alignSelfCenter]}
          textStyle={TEXT_STYLES.textPrimary100}
          iconLeft={<CartIcon height={16} width={16} />}
          text={t("go_to_cart")}
        />
      </View>
    );
  }
};

export default CheckoutStrip;
