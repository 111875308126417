import React from "react";
import { Text } from "react-native";
import { TEXT_STYLES, TYPOGRAPHY, VARIANTS_STYLES } from "@dehaat/dds";

import { DATE_FORMATS, formatDate } from "../../../utils/helpers/date";
import { useTranslation } from "react-i18next";

interface Props {
  timeInSeconds: string;
}

const ExpiryNote = ({ timeInSeconds }: Props) => {
  const { t } = useTranslation();
  if (timeInSeconds)
    return (
      <Text
        style={[
          TEXT_STYLES.textNeutral70,
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.BASE,
        ]}
      >
        {t("product_expiry", {
          date: formatDate(
            parseInt(timeInSeconds),
            DATE_FORMATS["DD Mmm YYYY"]
          ),
        })}
      </Text>
    );
};

export default ExpiryNote;
