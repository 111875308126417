import React from "react";
import { FlatList } from "react-native";
import { ClearanceProduct } from "app/partner-ordering-ui/models/Product";
import { ROUTES as ORDERING_ROUTES } from "app/partner-ordering-ui/routes/types";
import CheckoutStrip from "../../CheckoutStrip";
import RenderClearanceListItem from "./RenderClearanceListItem";

interface Props {
  products: ClearanceProduct[];
  isOrderingBlocked?: boolean;
  handleRevealPriceClick?: () => void;
}

const RenderClearanceList = ({
  products,
  isOrderingBlocked = false,
  handleRevealPriceClick,
}: Props) => {
  return (
    <>
      <FlatList
        data={products}
        renderItem={({ item, index }) => (
          <RenderClearanceListItem
            isOrderingBlocked={isOrderingBlocked}
            product={item}
            handleRevealPriceClick={handleRevealPriceClick}
            index={index}
          />
        )}
      />
      <CheckoutStrip landingFrom={ORDERING_ROUTES.CLEARANCE_SALE_LIST} />
    </>
  );
};

export default RenderClearanceList;
