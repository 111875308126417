import React from "react";
import { Path, Svg, SvgProps } from "react-native-svg";

const OfferAppliedDisabledIcon = (props: SvgProps) => (
  <Svg width="14" height="14" fill="none" {...props}>
    <Path
      fill="#C5CED6"
      d="M16.34 7.112c-.284-.296-.576-.6-.686-.868-.102-.245-.108-.652-.114-1.046-.012-.732-.024-1.561-.6-2.138-.577-.577-1.407-.589-2.139-.6-.393-.006-.8-.012-1.045-.114-.267-.11-.572-.403-.868-.685C10.371 1.163 9.783.6 9 .6c-.783 0-1.37.562-1.889 1.06-.295.282-.6.575-.868.685-.243.102-.651.108-1.045.114-.732.011-1.562.023-2.138.6-.577.577-.585 1.406-.6 2.138-.006.394-.012.8-.114 1.046-.11.267-.403.572-.686.868C1.163 7.629.6 8.217.6 9c0 .783.563 1.37 1.06 1.889.283.295.575.6.686.867.102.246.108.652.114 1.046.01.732.023 1.561.6 2.138.576.577 1.406.589 2.138.6.394.006.8.012 1.045.114.267.11.573.403.868.686.518.497 1.106 1.06 1.889 1.06.783 0 1.37-.563 1.888-1.06.296-.283.6-.576.868-.686.245-.102.652-.108 1.045-.114.732-.011 1.562-.023 2.139-.6.576-.577.588-1.406.6-2.138.006-.394.012-.8.114-1.046.11-.267.402-.572.685-.867.497-.518 1.06-1.106 1.06-1.889 0-.783-.563-1.37-1.06-1.888Zm-3.916.513-4.2 4.2a.6.6 0 0 1-.849 0l-1.8-1.8a.6.6 0 1 1 .85-.85L7.8 10.553l3.775-3.776a.6.6 0 0 1 .85.849Z"
    />
  </Svg>
);

export default OfferAppliedDisabledIcon;
