import {
  COLORS,
  FONT_FAMILY_SEMIBOLD,
  TEXT_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import React from "react";
import { View, Text, StyleSheet } from "react-native";
import RupeeIcon from "./icons/Rupee";

interface Props {
  itemsTotal: number;
  deliveryCharges: string;
  couponDiscount?: string;
  subTotal: string;
}
const BillDetails = ({
  itemsTotal,
  deliveryCharges,
  couponDiscount,
  subTotal,
}: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text>Item Total</Text>
        <Text>{formatCurrency(itemsTotal)}</Text>
      </View>
      {couponDiscount ? (
        <View style={styles.row}>
          <Text>Coupon Discount</Text>
          <Text style={[TEXT_STYLES.textPrimary100]}>
            - {formatCurrency(couponDiscount)}
          </Text>
        </View>
      ) : null}
      <View style={styles.row}>
        <Text>Delivery Charges</Text>
        <Text>{formatCurrency(deliveryCharges)}</Text>
      </View>
      <View style={styles.line} />
      <View style={styles.row}>
        <Text style={styles.boldText}>Total Amount</Text>
        <Text style={styles.boldText}>{formatCurrency(subTotal)}</Text>
      </View>
      {couponDiscount ? (
        <View style={[styles.row, styles.creditInfo]}>
          <View style={styles.labelCont}>
            <RupeeIcon />
            <Text style={styles.creditText}>Credit note available</Text>
          </View>
          <Text style={styles.creditText}>
            {formatCurrency(couponDiscount)}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.white,
    paddingTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 16,
    borderRadius: 12,
    gap: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  line: {
    borderBottomColor: COLORS.neutral10,
    borderBottomWidth: 1,
    marginVertical: 10,
  },
  boldText: {
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
  creditInfo: {
    backgroundColor: COLORS.purple10,
    padding: 8,
    borderRadius: 8,
  },
  creditText: {
    color: COLORS.purple100,
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
  labelCont: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
});

export default BillDetails;
