import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { ErrorCow } from "app/partner-ordering-ui/assets/images";

const SentryFallback = () => {
  const { t } = useTranslation();

  return (
    <View
      style={[
        COMMON_STYLES.flex1,
        COMMON_STYLES.justifyCenter,
        BG_STYLES.bgNeutral10,
        COMMON_STYLES.relative,
      ]}
    >
      <View style={COMMON_STYLES.alignItemsCenter}>
        <Image source={ErrorCow} style={styles.img160X280} />
        <Text
          style={[
            TYPOGRAPHY.HEADING2,
            VARIANTS_STYLES.EMPHASIZED,
            TEXT_STYLES.textNeutral90,
            COMMON_STYLES.mt1,
          ]}
        >
          {t("unknown_error")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral70,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {t("retry_message")}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  h88: {
    height: 88,
  },
  img160X280: {
    height: 160,
    width: 280,
  },
});

export default SentryFallback;
