import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { COMMON_STYLES } from "@dehaat/dds";

import { GrowthPlanBasketProduct } from "app/partner-ordering-ui/models/Product";
import fetchGrowthBasketProductsList from "app/partner-ordering-ui/apis/fetchGrowthPlanBasketItems";
import ShimmerProductCardHz from "app/partner-ordering-ui/components/cards/ProductCardHzShimmer";
import RenderGrowthBasketList from "app/partner-ordering-ui/components/productList/growthBasket";
import LogErrorModal from "../components/LogError";
import useObserverInternetActivity from "../hooks/useInternetActivity";
import { GrowthPlanListScreenProps, ROUTES } from "../routes/types";

const GrowthPlanList = ({ navigation }: GrowthPlanListScreenProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<GrowthPlanBasketProduct[]>([]);

  const fetchProducts = async () => {
    try {
      const result = await fetchGrowthBasketProductsList();
      setProducts(result.results);
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useObserverInternetActivity();

  return (
    <View style={COMMON_STYLES.flex1}>
      {loading ? (
        <ShimmerProductCardHz noOfShimmers={6} />
      ) : (
        <RenderGrowthBasketList products={products} />
      )}
      <LogErrorModal
        visible={!!error}
        message={error}
        onClose={() => setError("")}
      />
    </View>
  );
};

export default GrowthPlanList;
