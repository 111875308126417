import {
  NAMESPACE,
  SUPPORTED_LANG,
} from "app/partner-ordering-ui/constants/Common";
import commonEn from "app/locales/en/common.json";
import commonHi from "app/locales/hi/common.json";
import commonBn from "app/locales/bn/common.json";
import commonMr from "app/locales/mr/common.json";
import commonGu from "app/locales/gu/common.json";

const resources = {
  [SUPPORTED_LANG.en]: {
    [NAMESPACE.common]: commonEn,
  },
  [SUPPORTED_LANG.hi]: {
    [NAMESPACE.common]: commonHi,
  },
  [SUPPORTED_LANG.bn]: {
    [NAMESPACE.common]: commonBn,
  },
  [SUPPORTED_LANG.gu]: {
    [NAMESPACE.common]: commonGu,
  },
  [SUPPORTED_LANG.mr]: {
    [NAMESPACE.common]: commonMr,
  },
};

export default resources;
