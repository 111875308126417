import React from "react";
import { StyleProp, Text, View, ViewStyle } from "react-native";

import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

interface Props {
  qty: number;
  style?: StyleProp<ViewStyle>;
}

const VariantChipBadge = ({ qty, style }: Props) => {
  return (
    <View
      style={[
        BG_STYLES.bgPrimary100,
        COMMON_STYLES.absolute,
        COMMON_STYLES["br1/2"],
        COMMON_STYLES["ph1/2"],
        style,
      ]}
    >
      <Text
        style={[
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.SEMI_EMPHASIZED,
          TEXT_STYLES.textWhite,
        ]}
      >
        {qty}
      </Text>
    </View>
  );
};

export default VariantChipBadge;
