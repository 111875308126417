import { NativeEventEmitter } from "react-native";
import { StackActions } from "@react-navigation/core";
import { NavigationParams } from "app/partner-ordering-ui/models/Navigation";
import { navigationRef } from "app/partner-ordering-ui/utils/RootNavigation";
import { ROUTES as ORDERING_ROUTES } from "app/partner-ordering-ui/routes/types";
import fetchDehaatCenterInfo from "app/partner-ordering-ui/apis/fetchDCInfo";
import ToastAndroid from "app/mocks/ToastAndroid";
import store from "app/store";
import fetchTokenFromRefreshToken from "./apis/fetchTokenFromRefreshToken";
import Config from "./config";
import AuthToken from "app/models/Auth";
import { setToken } from "app/store/reducer/authToken";
import { logout } from "./common";

const rnNavigation = ({
  name,
  deeplink = null,
  params = null,
  replace = false,
}: NavigationParams) => {
  if (replace) {
    navigationRef.dispatch(StackActions.replace(name, params as object));
  } else
    navigationRef.navigate({
      name,
      params,
    } as never);
};

const redirectToHome = () => {
  navigationRef.reset({
    index: 0,
    routes: [{ name: ORDERING_ROUTES.PRODUCT_LIST }],
  });
};

const logEvent = (...params: any) => {
  //console.log("Hi i am mocked", params);
};

const toggleGoToCartBanner = (show: boolean) => {
  // TODO: No need in case of web only used in native env
};

const refreshAuthToken = async (): Promise<string | null> => {
  const refreshToken = store.getState().authToken.refreshToken;
  if (refreshToken) {
    try {
      const response = await fetchTokenFromRefreshToken(
        Config.OIDC_URL as string,
        refreshToken,
        Config.CLIENT_ID as string
      );
      const data: AuthToken = await response.json();
      if (!response.ok) {
        throw new Error(`API Failure with status code: ${response.status}`);
      }
      if (data?.access_token && data?.refresh_token) {
        store.dispatch(setToken(data));
        return JSON.stringify({ accessToken: data.access_token });
      }
      throw new Error("No Token Found");
    } catch (err) {
      logout();
      throw err;
    }
  }
  logout();
  return null;
};

const openLink = (url: string) => {
  window?.open(url);
};

// src/utils/paymentUtils.js

const openPaymentGatewayActivity = (
  amount: number,
  orderRequestId: string,
  requestType: string,
  orderStatus: string,
  url?: string,
  successUrl?: string,
  failureUrl?: string,
  pendingUrl?: string,
  time?: number
) => {
  if (window && url) {
    // TODO: Add logic here in case we need to add somethings
    window.location.replace(url);
  }
};

const startOutstandingAmountPaymentFlow = (...params: any) => {
  /**
   *  Not supported on dba web yet.
   *  Add Logic to show simple Use DBA App diloge
   */
  ToastAndroid.show(
    "This feature is not available on web. Please use Dehaat Business App.",
    ToastAndroid.SHORT
  );
};

const openOrderHistory = () => {
  /**
   *  Not supported on dba web yet.
   *  Need to use app for viewing the order history.
   */
  ToastAndroid.show(
    "This feature is not available on web. Please use Dehaat Business App.",
    ToastAndroid.SHORT
  );
};

const finishCurrentActivity = () => {
  /**
   *  Not supported on dba web yet.
   *  No concept of finish current actity. This will be a  mocked empty function
   */
};

const goToHome = () => {
  navigationRef.reset({
    index: 0,
    routes: [{ name: ORDERING_ROUTES.PRODUCT_LIST }],
  });
};

const goToBuyProducts = () => {
  navigationRef.reset({
    index: 0,
    routes: [{ name: ORDERING_ROUTES.PRODUCT_LIST }],
  });
};

const openOfflinePaymentSuccessActivity = (
  orderId: string,
  partnerId: string,
  ibId: string,
  dcName: string,
  requestType?: string
) => {
  ToastAndroid.show(
    "This feature is not available on web. Please use Dehaat Business App.",
    ToastAndroid.SHORT
  );
};

const getFromRemoteConfig = async (key: string): Promise<any> => {
  if (key === "show_bundles_only") {
    return Promise.resolve(false);
  }
  if (key === "max_recent_search_limit") {
    return Promise.resolve(20);
  }
  if (key === "test_dc_partner_id") {
    return "999XXX99XXX";
  }
  return "";
};

const showSessionExpiryDialog = () => {
  ToastAndroid.show("Session Expired, Please login again.", ToastAndroid.SHORT);
};

const openPendingDocuments = () => {
  ToastAndroid.show(
    "This feature is not available on web. Please use Dehaat Business App.",
    ToastAndroid.SHORT
  );
};

const getGlobalData = async () => {
  const dc = await fetchDehaatCenterInfo(); //fetch dc data
  return { dc };
};

const replaceCurrentActivity = (callback: Function) => {
  callback();
};
const clearNavigationBackStack: Function | undefined = undefined;

const openSchemeDetailActivity = (...params: any) => {
  console.log(params);
};

const initializeEventEmitter = (): NativeEventEmitter | undefined => undefined;

const PAYMENT_GATEWAY_RESULT = "payment_gatway_result";
const IS_OFFLINE_PAYMENT = "is_offline_payment";
const SUCCESS = "success";
const FAILED = "failed";
const PENDING = "pending";

export {
  rnNavigation,
  toggleGoToCartBanner as toggleCheckoutStrip,
  refreshAuthToken,
  logEvent,
  openLink,
  redirectToHome,
  openPendingDocuments,
  openPaymentGatewayActivity,
  startOutstandingAmountPaymentFlow,
  openOrderHistory,
  finishCurrentActivity,
  goToHome,
  goToBuyProducts,
  openOfflinePaymentSuccessActivity,
  getFromRemoteConfig,
  showSessionExpiryDialog,
  getGlobalData,
  replaceCurrentActivity,
  clearNavigationBackStack,
  openSchemeDetailActivity,
  initializeEventEmitter,
  PAYMENT_GATEWAY_RESULT,
  IS_OFFLINE_PAYMENT,
  SUCCESS,
  FAILED,
  PENDING,
};
