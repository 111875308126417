import React, { useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BottomDrawer,
  Button,
  COMMON_STYLES,
  DefaultProductImage,
  FloatingLabelInput,
  LazyImage,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import ZoomLensIcon from "app/partner-ordering-ui/icons/ZoomLens";
import ModalImageCarousal from "../resuable/ModalImageCarousal";
import RupeeSymbolIcon from "app/partner-ordering-ui/icons/RupeeSymbol";

interface Props {
  name: string;
  packAttribute?: string;
  unitPrice: number;
  imageUrl: string;
  visible?: boolean;
  maxDiscountPrice: string;
  uom: string;
  onClose: () => void;
  onConfirm: (value: string) => void;
}

const PriceNegotiableBottomDrawer = ({
  name,
  packAttribute,
  unitPrice,
  imageUrl,
  maxDiscountPrice,
  uom,
  visible,
  onClose,
  onConfirm,
}: Props) => {
  const [negotiablePrice, setNegotiablePrice] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  if (!visible) {
    return null;
  }

  return (
    <BottomDrawer
      heading={t("enter_negotiable_price")}
      onClose={onClose}
      visible={visible}
    >
      <View style={[COMMON_STYLES.row, COMMON_STYLES["mb3/2"]]}>
        <TouchableOpacity onPress={() => setIsVisible(true)}>
          <LazyImage
            imageUrl={imageUrl}
            fallBackIcon={
              <Image source={DefaultProductImage} style={styles.productImage} />
            }
            imgStyles={styles.productImage}
          />
          <ZoomLensIcon
            width={14}
            height={14}
            style={[COMMON_STYLES.absolute, styles.zoomStyle]}
          />
        </TouchableOpacity>
        <ModalImageCarousal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          images={[imageUrl]}
        />
        <View style={COMMON_STYLES.ml1}>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral100,
            ]}
          >
            {name}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral80,
              COMMON_STYLES["mt1/4"],
            ]}
          >
            {packAttribute}
          </Text>
          <View style={[COMMON_STYLES.row, COMMON_STYLES["mt1/4"]]}>
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.BASE,
                TEXT_STYLES.textNeutral80,
              ]}
            >
              {t("billing_price")}
            </Text>
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                TEXT_STYLES.textNeutral100,
                COMMON_STYLES["pl1/4"],
              ]}
            >
              {formatCurrency(unitPrice)}
            </Text>
          </View>
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral80,
              COMMON_STYLES["mt1/4"],
            ]}
          >
            {t("minimum_selling_price", {
              amount: formatCurrency(maxDiscountPrice),
            })}
          </Text>
        </View>
      </View>
      <FloatingLabelInput
        isFocused
        placeholder={t("enter_price")}
        keyboardType="numeric"
        value={negotiablePrice}
        leftIcon={<RupeeSymbolIcon width={20} height={20} />}
        error={error}
        errorText={
          Number(negotiablePrice) < Number(maxDiscountPrice)
            ? t("lower_discounted_price_warning", {
                amount: formatCurrency(maxDiscountPrice),
              })
            : t("higher_discounted_price_warning", {
                amount: formatCurrency(unitPrice),
              })
        }
        onFocus={() => setError(false)}
        onSubmitEditing={() =>
          setError(
            Number(negotiablePrice) < Number(maxDiscountPrice) ||
              Number(negotiablePrice) >= Number(unitPrice)
          )
        }
        containerStyle={COMMON_STYLES.alignItemsCenter}
        onChangeText={setNegotiablePrice}
      />
      <Button
        size={SIZE.large}
        onClick={() => {
          if (
            Number(maxDiscountPrice) <= Number(negotiablePrice) &&
            Number(negotiablePrice) < Number(unitPrice)
          ) {
            onConfirm(negotiablePrice);
          } else {
            setError(true);
          }
        }}
        text={t("confirm")}
        buttonStyle={COMMON_STYLES.mt1}
      />
    </BottomDrawer>
  );
};

const styles = StyleSheet.create({
  productImage: {
    width: 48,
    height: 48,
    resizeMode: "contain",
  },
  image16x16: { height: 16, width: 16 },
  image20x20: { height: 20, width: 20 },
  zoomStyle: { top: 34, left: 29 },
  width120: { width: 120 },
});
export default PriceNegotiableBottomDrawer;
