import React, { useState } from "react";
import {
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from "react-native";
import {
  COLORS,
  COMMON_STYLES,
  CaretDownIcon,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import { Breakup } from "app/partner-ordering-ui/models/Cart";

interface Props {
  label: string;
  value: number;
  breakup?: Breakup[];
  fireEvent: () => void;
  textStyle?: StyleProp<TextStyle>;
}

const RenderBreakup = ({
  label,
  value,
  breakup = [],
  fireEvent,
  textStyle,
}: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <TouchableOpacity
      onPress={() => {
        if (breakup.length > 0) {
          setOpen((prev) => !prev);
          fireEvent();
        }
      }}
    >
      <View
        style={[
          COMMON_STYLES.row,
          COMMON_STYLES.justifyBetween,
          COMMON_STYLES["mt3/4"],
        ]}
      >
        <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral80,
              COMMON_STYLES["mr0.5"],
              textStyle,
            ]}
          >
            {label}
          </Text>
          {breakup?.length > 0 && (
            <CaretDownIcon
              svgProps={{ height: 14, width: 14 }}
              pathProps={{ stroke: COLORS.black }}
            />
          )}
        </View>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            textStyle,
          ]}
        >
          {formatCurrency(value)}
        </Text>
      </View>
      {open && (
        <View style={[COMMON_STYLES["mh1/2"], COMMON_STYLES["mv1/2"]]}>
          {breakup.map((row, index) => (
            <View
              key={row.key}
              style={[
                COMMON_STYLES.row,
                COMMON_STYLES.justifyBetween,
                index !== breakup.length - 1 && COMMON_STYLES["mb1/4"],
              ]}
            >
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                  TEXT_STYLES.textNeutral70,
                  textStyle,
                ]}
              >
                {row.name}
              </Text>
              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                  TEXT_STYLES.textNeutral70,
                  textStyle,
                ]}
              >
                {formatCurrency(row.value)}
              </Text>
            </View>
          ))}
        </View>
      )}
    </TouchableOpacity>
  );
};

export default RenderBreakup;
