import { get } from "app/partner-ordering-ui/utils/axios/index";

interface Response {
  count: number;
  previous: number | null;
  next: number | null;
  results: { text: string }[];
}

const fetchProductsSearchSuggestions = async (
  search: string,
  offset: number
) => {
  const url = `/products/search-suggestions`;
  try {
    const response = await get<{ data: Response }>(url, {
      limit: 10,
      offset,
      search,
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
  return null;
};

export default fetchProductsSearchSuggestions;
