import {AxiosRequestHeaders} from 'axios';

import axios from './config';

export async function post<T>(
    url: string,
    body: Record<string, unknown> | string,
    headers?: AxiosRequestHeaders,
) {
    return (await axios.post<T>(url, body, {headers})).data;
}
