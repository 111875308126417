import React, { useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import {
  BORDER_STYLES,
  COMMON_STYLES,
  Label,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
  BG_STYLES,
  COLORS,
  EM,
} from "@dehaat/dds";

import PaynowIcon from "../../icons/PayNow";
import RoundTickIcon from "../../icons/RoundTickIcon";
import {
  AppliedDiscount,
  Discount,
  DiscountsInfo,
  REWARD_TYPE,
  Slab,
} from "../../models/Product";
import CalenderIcon from "../../icons/Calender";
import { useTranslation } from "react-i18next";

import SlabStrip from "./SlabStrip";
import { COUPON_DISCOUNT_TYPE } from "../../constants/Product";
import { ROUTES } from "../../routes/types";
import { rnNavigation } from "app/utils/appConfig";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import ArrowIcon from "app/partner-ordering-ui/icons/Arrow";
import DropDownIcon from "app/partner-ordering-ui/icons/DropDownPurple";

interface Props {
  discountsInfo?: DiscountsInfo;
  discounts: Discount[];
  prepaid: boolean;
  appliedDiscounts?: AppliedDiscount[] | null;
  onDiscountClick: (
    slab: Slab,
    rewardType: REWARD_TYPE,
    prepaid: boolean
  ) => void;
  uom: string | null;
  isBundle: boolean;
}

const RenderDiscount = ({
  discountsInfo,
  discounts,
  prepaid,
  appliedDiscounts = null,
  onDiscountClick,
  uom,
  isBundle,
}: Props) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const checkIsDiscountApplied = (discount: Discount) => {
    //Return false if applied discount is null
    if (appliedDiscounts === null) {
      return false;
    }
    // If there is any applied discount idetifier exists return true
    return (
      appliedDiscounts.findIndex(
        (appliedDiscount) => appliedDiscount.identifier === discount.identifier
      ) !== -1
    );
  };

  return (
    <>
      <View
        style={[
          COMMON_STYLES.b1,
          BORDER_STYLES.borderNeutral10,
          COMMON_STYLES["br1/2"],
          COMMON_STYLES.mt1,
        ]}
      >
        <Pressable onPress={toggleDropdown}>
          <View
            style={[
              COMMON_STYLES.row,
              COMMON_STYLES.alignItemsCenter,
              BG_STYLES[prepaid ? "bgBlue10" : "bgPurple10"],
              COMMON_STYLES["p3/4"],
              styles.topRoundedCorners
            ]}
          >
            {prepaid ? (
              <PaynowIcon height={22} width={22} />
            ) : (
              <CalenderIcon height={22} width={22} />
            )}
            <Text
              style={[
                TYPOGRAPHY.TEXT1,
                VARIANTS_STYLES.SEMI_EMPHASIZED,
                prepaid ? TEXT_STYLES.textBlue120 : TEXT_STYLES.textPurple120,
                ,
                COMMON_STYLES["ml1/4"],
              ]}
            >
              {`${prepaid ? "Pay now" : "Credit"} offers (${discounts.length})`}
            </Text>
            <View style={[COMMON_STYLES.flex1]} />

            <DropDownIcon
              svgProps={{
                height: 24,
                width: 24,
                rotation: isDropdownOpen ? 0 : 180,
              }}
              pathProps={{ stroke: COLORS[prepaid ? "blue120" : "purple100"] }}
            />
          </View>
        </Pressable>
        {isDropdownOpen && (
          <>
            {discounts?.map((discount) => {
              const isDiscountApplied = checkIsDiscountApplied(discount);
              const isAimsAvailable =
                discount?.applicable_channels.includes("AIMS");
              return (
                <Pressable
                  key={discount.identifier}
                  style={[
                    COMMON_STYLES["mh1/2"],
                    COMMON_STYLES["mv3/4"],
                    COMMON_STYLES.b1,
                    COMMON_STYLES.p1,
                    COMMON_STYLES["br1/2"],
                    isDiscountApplied
                      ? BORDER_STYLES.borderPrimary100
                      : BORDER_STYLES.borderNeutral10,
                  ]}
                >
                  {isDiscountApplied && (
                    <View
                      style={[
                        COMMON_STYLES.row,
                        COMMON_STYLES.alignItemsCenter,
                        COMMON_STYLES["mb1/2"],
                      ]}
                    >
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.EMPHASIZED,
                          TEXT_STYLES.textSuccess100,
                          COMMON_STYLES["mr1/4"],
                        ]}
                      >
                        {t("discount_auto_applied")}
                      </Text>
                      <RoundTickIcon width="12" height="13" />
                    </View>
                  )}
                  {isAIMS() && !isAimsAvailable && (
                    <View
                      style={[
                        COMMON_STYLES.absolute,
                        COMMON_STYLES.top,
                        { right: 16 },
                      ]}
                    >
                      <Label
                        text={`*${t("only_on_dba")}`}
                        size={SIZE.small}
                        containerStyle={styles.applicableOnDbaStyle}
                        textColor="textWhite"
                      />
                    </View>
                  )}
                  <Text
                    style={[
                      TYPOGRAPHY.TEXT2,
                      VARIANTS_STYLES.EMPHASIZED,
                      prepaid
                        ? TEXT_STYLES.textBlue120
                        : TEXT_STYLES.textPurple120,
                      COMMON_STYLES["mb3/4"],
                      styles.maxWidth,
                    ]}
                  >
                    {`${
                      discount.type === COUPON_DISCOUNT_TYPE.CUMULATIVE
                        ? "Order other items together, "
                        : ""
                    }Get ${
                      discount.reward_type === "INSTANT"
                        ? "instant discount"
                        : "cashback"
                    } ${
                      discount.cashback_account_type
                        ? `in your ${discount.cashback_account_type}`
                        : ""
                    }`}
                  </Text>
                  {discount.slabs.map((slab) => (
                    <SlabStrip
                      onClick={() =>
                        isAIMS() && !isAimsAvailable
                          ? null
                          : onDiscountClick(
                              slab,
                              discount.reward_type,
                              discount.is_prepaid
                            )
                      }
                      key={slab.slab_id}
                      details={slab}
                      isApplied={
                        slab.slab_id
                          ? appliedDiscounts?.[0]?.slabs?.[0]?.slab_id ===
                            slab.slab_id
                          : isDiscountApplied
                      }
                      effectiveConstituentPrice={
                        discountsInfo?.discount_constituent_unit_price
                      }
                      isPrePaid={discount.is_prepaid}
                      uom={uom}
                      isBundle={isBundle}
                    />
                  ))}
                  <View
                    style={[
                      COMMON_STYLES.row,
                      COMMON_STYLES.justifyBetween,
                      COMMON_STYLES.alignItemsCenter,
                    ]}
                  >
                    {discount.max_discount_cap_per_item ? (
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.BASE,
                          prepaid
                            ? TEXT_STYLES.textBlue120
                            : TEXT_STYLES.textPurple120,
                        ]}
                      >
                        {t("max_cashback_per_order", {
                          amount: formatCurrency(
                            discount.max_discount_cap_per_item
                          ),
                        })}
                      </Text>
                    ) : null}
                  </View>

                  {discount.total_items_count &&
                  discount.type === COUPON_DISCOUNT_TYPE.CUMULATIVE ? (
                    <Pressable
                      style={({ pressed }) =>
                        pressed && COMMON_STYLES["opacity1/2"]
                      }
                      onPress={() =>
                        rnNavigation({
                          name: ROUTES.CUMULATIVE_OFFERS,
                          params: {
                            query: JSON.stringify(
                              discount.total_items_filter_query_params
                            ),
                            identifier: discount.identifier,
                            slabs: JSON.stringify(discount.slabs),
                          },
                        })
                      }
                    >
                      <Text
                        style={[
                          TYPOGRAPHY.CAPTION1,
                          VARIANTS_STYLES.MEDIUM,
                          TEXT_STYLES.textPrimary120,
                          COMMON_STYLES.textRight,
                          COMMON_STYLES.textDecorationLine,
                        ]}
                      >
                        {`View full list of ${discount.total_items_count} items in offer`}
                      </Text>
                    </Pressable>
                  ) : null}
                </Pressable>
              );
            })}
          </>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  textUnderline: {
    textDecorationLine: "underline",
  },
  maxWidth: { maxWidth: "65%" },
  applicableOnDbaStyle: {
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: "#DC500A",
  },
  topRoundedCorners: {
    borderTopLeftRadius: EM / 2,
    borderTopRightRadius: EM / 2,
  },
});

export default RenderDiscount;

//TODO: Add this part if T&C applied
//  const [isTnCDrawerVisible, setVisibilityOfDrawer] = useState(false);

//  <Pressable onPress={() => setVisibilityOfDrawer(true)}>
//    <Text
//      style={[
//        TYPOGRAPHY.TEXT2,
//        VARIANTS_STYLES.EMPHASIZED,
//        TEXT_STYLES.textSuccess100,
//      ]}
//    >
//      T & C
//   </Text>
//  </Pressable>
/* 
    <BottomDrawer
        visible={isTnCDrawerVisible}
        onClose={() => setVisibilityOfDrawer(false)}
    >
        <Text
            style={[
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.EMPHASIZED,
              TEXT_STYLES.textNeutral90,
              COMMON_STYLES.mb1,
            ]}
          >
            Offer Terms & Conditions
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            1. Applicable only on DBA Orders
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            2. Discount credited on product delivery
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            3. Coupon applicable once per user
          </Text>
        </BottomDrawer> 
*/
