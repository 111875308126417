import React from "react";
import { ClipPath, Defs, G, Path, Svg, SvgProps } from "react-native-svg";

const ComboItemsIcon = (props: SvgProps) => {
  return (
    <Svg fill="none" viewBox="0 0 19 18" {...props}>
      <G
        clipPath="url(#a)"
        stroke="#33393D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M9.5 1.504 2 5.254l7.5 3.75 7.5-3.75-7.5-3.75ZM2 12.754l7.5 3.75 7.5-3.75" />
        <Path d="m2 9.004 7.5 3.75 7.5-3.75" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M.5.004h18v18H.5z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ComboItemsIcon;
