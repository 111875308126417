import React from "react";
import { useTranslation } from "react-i18next";
import { Image, StyleSheet, Text, View } from "react-native";
import {
  BUTTON_STATUS,
  BUTTON_TYPE,
  Button,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { PaymentFailureIcon } from "app/partner-ordering-ui/assets/images";
import {
  OrderFailureScreenProps,
  ROUTES,
} from "app/partner-ordering-ui/routes/types";
import { goToHome } from "app/utils/appConfig";

const OrderFailure = ({ navigation }: OrderFailureScreenProps) => {
  const { t } = useTranslation();
  return (
    <View style={[COMMON_STYLES.justifyBetween, COMMON_STYLES.flex1]}>
      <View style={[COMMON_STYLES.alignItemsCenter, styles.alignmentStyle]}>
        <Image
          source={PaymentFailureIcon}
          style={{ height: 120, width: 120 }}
        />
        <Text
          style={[
            TYPOGRAPHY.HEADING1,
            VARIANTS_STYLES.EMPHASIZED,
            TEXT_STYLES.textNeutral90,
            COMMON_STYLES.mt2,
          ]}
        >
          {t("order_not_placed")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textBlack,
            COMMON_STYLES["mt1/4"],
          ]}
        >
          {t("order_not_placed_desc1")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textBlack,
            COMMON_STYLES.mt1,
          ]}
        >
          {t("order_not_placed_desc2")}
        </Text>
      </View>
      <View>
        <Button
          onClick={() => navigation.replace(ROUTES.MY_CART, {})}
          text={t("back_to_cart")}
          status={BUTTON_STATUS.ACTIVE}
          buttonStyle={styles.buttonStyle}
        />
        <Button
          onClick={() => {
            goToHome();
          }}
          status={BUTTON_STATUS.ACTIVE}
          type={BUTTON_TYPE.TERTIARY}
          text={t("go_to_home")}
          buttonStyle={[COMMON_STYLES.mt1, styles.buttonStyle]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  alignmentStyle: { marginTop: 152 },
  buttonStyle: { marginHorizontal: 20 },
});

export default OrderFailure;
