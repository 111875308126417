import React, { ReactNode } from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";

import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

interface Props {
  icon: ReactNode;
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  name: string;
}

const ImageBtn = ({ icon, style, onPress, name }: Props) => {
  return (
    <TouchableOpacity
      style={[COMMON_STYLES.alignItemsCenter, style]}
      onPress={onPress}
    >
      <View
        style={[
          BG_STYLES.bgNeutral20,
          COMMON_STYLES["p3/4"],
          COMMON_STYLES["br1/4"],
        ]}
      >
        {icon}
      </View>
      <Text
        style={[
          TYPOGRAPHY.CAPTION2,
          VARIANTS_STYLES.BASE,
          TEXT_STYLES.textNeutral90,
          styles.textUnderline,
        ]}
      >
        {name}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  textUnderline: {
    textDecorationLine: "underline",
  },
});

export default ImageBtn;
