import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

// Define a type for the slice state
type NativeDataState = Record<string, any>;

// Define the initial state using that type
const initialState: NativeDataState = {};

export const native = createSlice({
  name: "nativeInfo",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setNativeData: (state, action: PayloadAction<Record<string, any>>) => {
      state.dc = action.payload.dc;
      state.token = action.payload.token;
      state.moq = action.payload.minimum_order_amount || 0;
      state.fertilizerSubCategoryIdsList =
        action.payload.fertilizer_subcategory_list || [];
    },
  },
});

export const { setToken, setNativeData } = native.actions;

export const selectNativeData = (state: RootState) => state.native;
export const selectDCData = (state: RootState) => state.native.dc;
export const selectMOAValue = (state: RootState) => state.native.moq;
export const selectFertilizerSubCatList = (state: RootState) =>
  state.native.fertilizerSubCategoryIdsList;

export default native.reducer;
