import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  ScrollView,
  Pressable,
} from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";

import {
  COMMON_STYLES,
  TEXT_STYLES,
  PARAGRAPH_STYLES,
  Button,
  SIZE,
  BUTTON_STATUS,
  COLORS,
} from "@dehaat/dds";

import sendOtp from "app/utils/apis/sendOtp";
import BigInput from "app/components/reusable/BigInput";
import Config from "app/utils/config";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import { ToastAndroid } from "app/mocks/ToastAndroid";
import { INDIAN_FLAG, LADNING_IMAGE } from "app/assets/images/index";
import { isDesktop } from "app/sell-to-farmer/constants/common";

import { LoginScreenProps, ROUTES } from "../routes/types";
import { validateMobileNumber } from "../utils/login";
import { clearStorage } from "app/utils/storage";
import { useAppDispatch } from "app/hooks/reduxHooks";
import { clearAuthData } from "app/store/reducer/authToken";
import { useIsFocused } from "@react-navigation/native";
import {
  DIMENSION_HEIGHT,
  DIMENSION_WIDTH,
} from "app/partner-ordering-ui/utils/helpers/common";
import { openLink } from "app/utils/appConfig";
import { clearAuthCookies } from "app/utils/common";

const Login = ({ navigation }: LoginScreenProps) => {
  const dispatch = useAppDispatch();
  const [number, setNumber] = useState("");
  const [whatsAppCheck, setWhatsAppCheck] = useState(true);
  const [loading, setLoading] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      dispatch(clearAuthData());
      clearStorage();
      clearAuthCookies();
    }
  }, [isFocused]);

  const onNumberChange = (value: string) => {
    setNumber(value);
  };

  const handleOTPClick = async () => {
    if (validateMobileNumber(number)) {
      setLoading(true);
      const isOtpTriggered = await sendOtp(number, Config.CLIENT_ID as string);
      if (isOtpTriggered) {
        navigation.navigate(ROUTES.OTP, {
          isLogin: true,
          number: number,
          isWhatsApp: whatsAppCheck,
        });
      } else {
        ToastAndroid.show("Invalid Mobile Number.", ToastAndroid.SHORT);
      }
      setLoading(false);
    }
  };

  return (
    <View
      style={[
        COMMON_STYLES.flex1,
        COMMON_STYLES.alignItemsCenter,
        COMMON_STYLES.relative,
        styles.outerContainer,
      ]}
    >
      {loading && <Spinner type="on-screen" />}

      <View>
        <Image source={LADNING_IMAGE} style={styles.landingImage} />
      </View>

      <View
        style={[
          COMMON_STYLES.flex1,
          COMMON_STYLES.p1,
          styles.container,
          COMMON_STYLES.fullWidth,
        ]}
      >
        <ScrollView>
          <BigInput
            title="Phone Number"
            value={number}
            placeholder="Enter your phone number"
            onChangeText={onNumberChange}
            leftIcon={<Image source={INDIAN_FLAG} style={styles.imgStyle} />}
            maxLength={10}
          />
          <Text style={[PARAGRAPH_STYLES.MediumT2, COMMON_STYLES["mt3/4"]]}>
            {"By continuing, you agree to "}
            <Pressable
              onPress={() =>
                openLink(
                  "https://policies.agrevolution.in/dba-app/#privacy-policy"
                )
              }
            >
              <Text
                style={[PARAGRAPH_STYLES.MediumT2, TEXT_STYLES.textPrimary100]}
              >
                {"Terms of service & Privacy Policy"}
              </Text>
            </Pressable>
          </Text>
          <BouncyCheckbox
            isChecked={whatsAppCheck}
            onPress={(isChecked: boolean) => setWhatsAppCheck(isChecked)}
            text={
              "I agree to receive whatsapp message and accept the data privacy statement"
            }
            size={18}
            style={styles.checkboxContainer}
            fillColor={COLORS.primary100}
            iconStyle={styles.checkbox}
            innerIconStyle={styles.checkbox}
            textStyle={[
              PARAGRAPH_STYLES.RegularT2,
              styles.checkBoxText,
              { maxWidth: isDesktop ? 450 : 300 },
            ]}
          />
        </ScrollView>
        <Button
          text="Get OTP"
          onClick={handleOTPClick}
          status={
            validateMobileNumber(number)
              ? BUTTON_STATUS.ACTIVE
              : BUTTON_STATUS.DISABLED
          }
          size={SIZE.large}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flexDirection: isDesktop ? "row" : "column",
  },
  container: {
    maxWidth: 520,
    marginLeft: isDesktop ? 50 : 0,
  },
  checkboxContainer: {
    marginTop: 15,
    marginBottom: 10,
  },
  checkbox: {
    borderRadius: 3,
  },
  checkBoxText: {
    textDecorationLine: "none",
  },
  buttonContainer: {
    borderColor: "#E0E0E0",
    elevation: 1,
  },
  imgStyle: {
    width: 42,
    height: 26,
  },
  landingImage: {
    height: isDesktop ? DIMENSION_HEIGHT : DIMENSION_HEIGHT / 2,
    width: isDesktop ? DIMENSION_WIDTH / 2 : DIMENSION_WIDTH,
  },
});

export default Login;
