import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";

import {
  BG_STYLES,
  BUTTON_STATUS,
  Button,
  COLORS,
  COMMON_STYLES,
  SIZE,
} from "@dehaat/dds";

interface Props {
  disabled?: boolean;
  onCTAClick: () => void;
  text: string;
}

const Footer = ({
  disabled = false,
  onCTAClick,
  children,
  text,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {children}
      <View
        style={[
          COMMON_STYLES.ph1,
          COMMON_STYLES.pb1,
          COMMON_STYLES["pt3/4"],
          BG_STYLES.bgWhite,
          styles.footer,
        ]}
      >
        <Button
          status={disabled ? BUTTON_STATUS.DISABLED : BUTTON_STATUS.ACTIVE}
          size={SIZE.large}
          text={text}
          onClick={onCTAClick}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  footer: {
    borderTopWidth: 1,
    borderColor: COLORS.neutral10,
  },
  pv10: {
    paddingVertical: 10,
  },
});

export default Footer;
