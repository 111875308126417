export enum PRODUCT_CONSTANTS {
  FERTILIZERS = "crop_nutrition",
  SEEDS = "seeds",
  CROP_PROTECTION = "crop_protection",
}

export enum PRODUCT_TYPE {
  "REGULAR" = "regular",
  "CLEARANCE" = "clearance",
}

export enum LICENSE_STATUS {
  "PENDING" = "pending",
  "SUBMITTED" = "submitted",
  "DONE" = "done",
}

export enum SCROLL_VIEW_KEYS {
  "COMBO" = "combo",
  "ALTERNATE" = "alternate",
  "DISCOUNT" = "discount",
  "SCROLL_VIEW" = "scroll_view",
}

export enum COUPON_DISCOUNT_TYPE {
  "CUMULATIVE" = "CUMULATIVE_VOLUME_RATE",
  "OTHERS" = "OTHERS",
}

export enum SLAB_DISCOUNT_TYPE {
  "percent" = "%",
  "rupee" = "₹",
}
