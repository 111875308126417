import React, { useState } from "react";
import { View, TouchableOpacity, Image, StyleSheet } from "react-native";
import {
  COMMON_STYLES,
  ImageWithUnitLabel,
  DefaultProductImage,
  LABEL_ALIGNMENT,
  BG_STYLES,
} from "@dehaat/dds";

import ZoomLensIcon from "app/partner-ordering-ui/icons/ZoomLens";
import ModalImageCarousal from "../resuable/ModalImageCarousal";

interface Props {
  images: {
    url: string;
    text: string;
  }[];
}

const RenderComboImages = ({ images }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleImageClick = () => {
    setIsVisible(true);
  };
  return (
    <View
      style={[
        COMMON_STYLES["ph1/2"],
        COMMON_STYLES["pt3/4"],
        { width: "100%" },
      ]}
    >
      <TouchableOpacity onPress={handleImageClick} style={COMMON_STYLES.row}>
        {images?.map((image, index) => (
          <View key={index}>
            <ImageWithUnitLabel
              image={image}
              fallBackProductImageOrIcon={
                <Image
                  source={DefaultProductImage}
                  style={styles.imageFitToParent}
                />
              }
              containerStyle={[
                styles.image66x66,
                COMMON_STYLES["ph1/2"],
                COMMON_STYLES["pv1/4"],
                BG_STYLES.bgPurple10,
                index !== 0 && COMMON_STYLES.ml1,
              ]}
              labelAlignment={LABEL_ALIGNMENT.RIGHT}
            />
            <ZoomLensIcon
              width={14}
              height={14}
              style={[
                COMMON_STYLES.absolute,
                COMMON_STYLES.bottom,
                COMMON_STYLES.right,
              ]}
            />
          </View>
        ))}
      </TouchableOpacity>
      <ModalImageCarousal
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        images={images.map(({ url }) => url)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  image66x66: {
    width: 66,
    height: 66,
  },
  imageFitToParent: { height: "100%", width: "100%" },
});

export default RenderComboImages;
