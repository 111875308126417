import React from "react";
import { NavigationContainer } from "@react-navigation/native";

import { navigationRef } from "app/partner-ordering-ui/utils/RootNavigation";

import { RootStackNavigator } from "./Stacks";
import { linking } from "./Routes";

const AppNavigator = () => {
  return (
    <NavigationContainer ref={navigationRef} linking={linking}>
      <RootStackNavigator />
    </NavigationContainer>
  );
};

export default AppNavigator;
