import {
  BG_STYLES,
  BORDER_STYLES,
  COMMON_STYLES,
  EM,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import React, { ReactNode } from "react";
import { StyleSheet, Text, View } from "react-native";

interface Props {
  schemeName: string;
  schemeContent: ReactNode;
}

const SchemeContainer = ({ schemeName, schemeContent }: Props) => {
  return (
    <>
      <View
        style={[
          COMMON_STYLES.m1,
          COMMON_STYLES.b1,
          BORDER_STYLES.borderOrange120,
          COMMON_STYLES["br1/2"],
          COMMON_STYLES.mt1,
        ]}
      >
        <View
          style={[
            COMMON_STYLES.row,
            COMMON_STYLES.alignItemsCenter,
            BG_STYLES.bgOrange10,
            COMMON_STYLES["p3/4"],
            styles.topRoundedCorners,
          ]}
        >
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textOrange120,
              COMMON_STYLES["ml1/4"],
            ]}
          >
            {schemeName}
          </Text>
        </View>
        {schemeContent}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  topRoundedCorners: {
    borderTopLeftRadius: EM / 2,
    borderTopRightRadius: EM / 2,
  },
});

export default SchemeContainer;
