import React from "react";
import { BUTTON_TYPE, Button, COLORS, CrossIcon } from "@dehaat/dds";
import {
  ORDER_ACTIONS,
  ORDER_STATUS,
  RENDER_ACTION,
} from "../constants/common";
import TickIcon from "./icons/TIckIcon";
import { openLink as downloadPdf } from "app/utils/appConfig";
import checkOrderExpiry from "../utils/helpers/checkOrderExpiry";

interface Props {
  status: ORDER_STATUS;
  shippingLink: string | null;
  manifestLink: string | null;
  isPickupOrder: boolean;
  expiresAt: string;
  onActionClick: (action: ORDER_ACTIONS) => void;
  action?: RENDER_ACTION;
}

const ActionButtons = ({
  status,
  shippingLink,
  manifestLink,
  isPickupOrder = false,
  expiresAt,
  onActionClick,
  action,
}: Props) => {
  const isOrderExpired = checkOrderExpiry(expiresAt);

  if (action) {
    switch (action) {
      case RENDER_ACTION.ACEEPT_REJECT:
        return (
          <>
            <Button
              buttonStyle={{ backgroundColor: COLORS.success10 }}
              textStyle={{ color: COLORS.success100 }}
              iconLeft={<TickIcon height={14} width={14} />}
              text="Accept Order"
              onClick={() => onActionClick(ORDER_ACTIONS.ACCEPT)}
            />

            <Button
              buttonStyle={{
                backgroundColor: COLORS.mulberry10,
                marginTop: 10,
              }}
              textStyle={{ color: COLORS.mulberry100 }}
              iconLeft={
                <CrossIcon color={COLORS.mulberry100} height={14} width={14} />
              }
              text="Reject Order"
              onClick={() => onActionClick(ORDER_ACTIONS.REJECT)}
            />
          </>
        );

      case RENDER_ACTION.CONFIRM_PICKUP:
        return (
          <Button
            type={BUTTON_TYPE.SECONDARY}
            text="Confirm Pickup"
            onClick={() => onActionClick(ORDER_ACTIONS.CONFIRM_PICKUP)}
          />
        );
      case RENDER_ACTION.DONWLOAD_OTPIONS:
        return (
          <>
            {shippingLink ? (
              <Button
                type={BUTTON_TYPE.SECONDARY}
                text="Download Shipment label"
                onClick={() => downloadPdf(shippingLink)}
              />
            ) : null}
            {manifestLink ? (
              <Button
                type={BUTTON_TYPE.SECONDARY}
                buttonStyle={{
                  marginTop: 10,
                }}
                text="Download Manifest"
                onClick={() => downloadPdf(manifestLink)}
              />
            ) : null}
          </>
        );
    }
  } else if (status === ORDER_STATUS.Pending && !isOrderExpired) {
    return (
      <>
        <Button
          buttonStyle={{ backgroundColor: COLORS.success10 }}
          textStyle={{ color: COLORS.success100 }}
          iconLeft={<TickIcon height={14} width={14} />}
          text="Accept Order"
          onClick={() => onActionClick(ORDER_ACTIONS.ACCEPT)}
        />

        <Button
          buttonStyle={{
            backgroundColor: COLORS.mulberry10,
            marginTop: 10,
          }}
          textStyle={{ color: COLORS.mulberry100 }}
          iconLeft={
            <CrossIcon color={COLORS.mulberry100} height={14} width={14} />
          }
          text="Reject Order"
          onClick={() => onActionClick(ORDER_ACTIONS.REJECT)}
        />
      </>
    );
  } else if (isPickupOrder && status === ORDER_STATUS.VendorConfirmed) {
    return (
      <Button
        type={BUTTON_TYPE.SECONDARY}
        text="Confirm Pickup"
        onClick={() => onActionClick(ORDER_ACTIONS.CONFIRM_PICKUP)}
      />
    );
  }

  return (
    <>
      {shippingLink ? (
        <Button
          type={BUTTON_TYPE.SECONDARY}
          text="Download Shipment label"
          onClick={() => downloadPdf(shippingLink)}
        />
      ) : null}
      {manifestLink ? (
        <Button
          type={BUTTON_TYPE.SECONDARY}
          buttonStyle={{
            marginTop: 10,
          }}
          text="Download Manifest"
          onClick={() => downloadPdf(manifestLink)}
        />
      ) : null}
    </>
  );
};

export default ActionButtons;
