import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { COMMON_STYLES } from "@dehaat/dds";

import { startOutstandingAmountPaymentFlow } from "app/utils/appConfig";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import fetchCuratedProductsList from "app/partner-ordering-ui/apis/fetchCuratedList";
import { Product } from "app/partner-ordering-ui/models/Product";

import ShimmerProductCardHz from "../../cards/ProductCardHzShimmer";
import RenderProductList from "../list";
import OutstandingAmountDrawer from "../../drawers/OutstandingAmountDrawer";
import { FILTER_VALUES } from "app/partner-ordering-ui/constants/Common";
import OutstandingCard from "../../cards/OutstandingCard";
import { isAIMS } from "app/partner-ordering-ui/utils/helpers/isAIMSApp";
import fetchWidgetProductsList from "app/partner-ordering-ui/apis/fetchWidgetProductList";

interface Props {
  id: string;
  listId: string;
  filter?: FILTER_VALUES;
  navigatingFrom?: string;
  widgetId?: string;
}

const CuratedItemsList = ({
  id,
  listId,
  filter = FILTER_VALUES.ALL_PRODUCTS,
  navigatingFrom,
  widgetId
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [open, setOpen] = useState(false);

  const dc = useAppSelector(selectDCData);

  useEffect(() => {
    const fetchProducts = async () => {
      !loading && setLoading(true);
      const result = widgetId ?
        await fetchWidgetProductsList({
          widgetId,
          gridChildId: id,
          salesOfficeId: dc?.sales_office_id,
          filter,
        })
        :
        await fetchCuratedProductsList({
          groupId: id,
          salesOfficeId: dc?.sales_office_id,
          filter,
        });

      if (result) {
        setProducts(result.results);
      }
      setLoading(false);
    };
    fetchProducts();
  }, [id, filter]);

  return (
    <View style={COMMON_STYLES.flex1}>
      {dc?.app_blocked ? (
        <OutstandingCard
          days={dc.app_block_days}
          amount={dc.app_block_amount}
          onCTAClick={() =>
            startOutstandingAmountPaymentFlow(dc.app_block_amount)
          }
          onCardClick={() => setOpen(true)}
        />
      ) : null}
      {loading ? (
        <ShimmerProductCardHz noOfShimmers={6} />
      ) : (
        <RenderProductList
          products={products}
          isOrderingBlocked={dc.app_blocked}
          handleRevealPriceClick={() => {
            if (!isAIMS()) setOpen(true);
          }}
          loadingNext={false}
          fetchNext={() => {}}
          navigatingFrom={navigatingFrom}
          eventObject={{
            listId: listId,
            groupId: id,
            listName: navigatingFrom,
          }}
        />
      )}
      <OutstandingAmountDrawer
        days={dc.app_block_days}
        amount={dc.app_block_amount}
        isOpen={open}
        onClose={() => setOpen(false)}
        onCtaClick={() => {
          startOutstandingAmountPaymentFlow(dc.app_block_amount);
          setOpen(false);
        }}
      />
    </View>
  );
};

export default CuratedItemsList;
