const fetchTokenFromRefreshToken = async (
    baseUrl: string,
    refreshToken: string,
    client_id: string,
    redirectUri?: string,
) => {
    const formData = new URLSearchParams();
    formData.append('refresh_token', refreshToken);
    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', client_id);
    if (redirectUri) {
        formData.append('redirect_uri', redirectUri);
    }
    return fetch(`${baseUrl}realms/dehaat/protocol/openid-connect/token`, {
        method: 'POST',
        headers: {
            accept: '*/*',
            'content-type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: formData.toString(),
    });
};

export default fetchTokenFromRefreshToken;
