import {
  BG_STYLES,
  COMMON_STYLES,
  DefaultProductImage,
  LazyImage,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";
import { BlurPrice } from "app/partner-ordering-ui/assets/images";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from "react-native";

interface Props {
  conatinerStyle: StyleProp<ViewStyle>;
  imageUrl?: string;
  variantName: string;
  packAttribute: string;
  discountedPrice?: number | null;
  price: number | string;
  unitOfMeasure: string | null;
  unitPrice: number | null;
  isPriceLocked: boolean;
  onCardClick: () => void;
  remark: string[] | null;
}

const VariantCard = ({
  conatinerStyle,
  imageUrl,
  variantName,
  packAttribute,
  discountedPrice,
  price,
  unitOfMeasure,
  unitPrice,
  isPriceLocked,
  onCardClick,
  children,
  remark,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  return (
    <Pressable
      onPress={onCardClick}
      style={[
        BG_STYLES.bgPurple10,
        COMMON_STYLES["p1/2"],
        COMMON_STYLES["br1/2"],
        conatinerStyle,
      ]}
    >
      <View style={[COMMON_STYLES.row]}>
        <LazyImage
          imageUrl={imageUrl}
          fallBackIcon={
            <Image source={DefaultProductImage} style={styles.image} />
          }
          imgStyles={styles.image}
        />
        <View style={[COMMON_STYLES.flex1, COMMON_STYLES["ml3/4"]]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral100,
            ]}
          >
            {variantName}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            {packAttribute}
          </Text>
          {isPriceLocked ? (
            <Image source={BlurPrice} style={[{ width: "50%" }]} />
          ) : (
            <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
              <Text
                style={[
                  TYPOGRAPHY.TEXT1,
                  VARIANTS_STYLES.EMPHASIZED,
                  TEXT_STYLES.textNeutral90,
                  COMMON_STYLES["mr0.5"],
                ]}
              >
                {remark && remark.length > 0
                  ? null
                  : formatCurrency(discountedPrice ? discountedPrice : price)}
              </Text>
              {discountedPrice ? (
                <Text
                  style={[
                    TYPOGRAPHY.CAPTION1,
                    VARIANTS_STYLES.BASE,
                    TEXT_STYLES.textNeutral70,
                    COMMON_STYLES.strikeText,
                    COMMON_STYLES["mr0.5"],
                  ]}
                >
                  {formatCurrency(price)}
                </Text>
              ) : null}
              {remark && remark.length > 0 ? (
                <Text
                  style={[
                    VARIANTS_STYLES.BASE,
                    TYPOGRAPHY.CAPTION1,
                    TEXT_STYLES.textError100,
                  ]}
                >
                  {t(remark[0]) || null}
                  {remark[1] ? "\n" + t(remark[1]) : null}
                </Text>
              ) : null}
              {unitPrice ? (
                <Text
                  style={[
                    TYPOGRAPHY.TEXT2,
                    VARIANTS_STYLES.BASE,
                    TEXT_STYLES.textNeutral80,
                  ]}
                >
                  {formatCurrency(unitPrice)}/{unitOfMeasure}
                </Text>
              ) : null}
            </View>
          )}
        </View>
      </View>
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 36,
    height: 50,
  },
});

export default VariantCard;
