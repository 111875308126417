import { useCallback, useEffect, useState } from "react";

import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import ToastAndroid from "app/mocks/ToastAndroid";

import fetchProductsList from "../apis/fetchProducts";
import { Product, ProductCategory } from "../models/Product";
import { isAIMS } from "../utils/helpers/isAIMSApp";

export interface SearchQuery extends Record<string, any> {
  search?: string;
  categoryId?: string | number;
  subCategoryId?: string | number;
  sort?: string;
}

const useSearch = (query: SearchQuery, allowEmptySearch = true, limit = 20) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const { search, categoryId, subCategoryId, sort, ...params } = query;
  const [page, setPage] = useState<number | null>(1);

  useEffect(() => {
    if (search) setPage(1);
  }, [search]);

  const dc = useAppSelector(selectDCData);
  const [error, setError] = useState("");

  const fetchProducts = async (
    search?: string,
    categoryId?: string,
    subCategoryId?: string,
    sort?: string,
    params?: Record<string, any>
  ) => {
    if (page !== null) {
      const response = await fetchProductsList({
        search,
        pageNumber: page,
        pageSize: limit,
        categoryId,
        subCategoryId,
        sort,
        sales_office_id: dc.sales_office_id,
        ...params,
      });
      setCategories(response.filters["categories"]);
      if (page === 1) {
        setProducts(response.results);
      } else {
        setProducts([...products, ...response.results]);
      }
      if (response.next) {
        setPage(response.next);
      } else {
        setPage(null);
      }
    }
  };

  const fetchNext = async () => {
    if (page !== null) {
      try {
        setLoadingNext(true);
        await fetchProducts(
          search,
          categoryId?.toString(),
          subCategoryId?.toString(),
          sort,
          params
        );
      } catch (e: any) {
        setPage(null);
        ToastAndroid.show(e.message, ToastAndroid.LONG);
      } finally {
        setLoadingNext(false);
      }
    }
  };

  const getProducts = useCallback(
    async (
      search?: string,
      categoryId?: string,
      subCategoryId?: string,
      sort?: string,
      params?: Record<string, any>
    ) => {
      try {
        setLoading(true);
        await fetchProducts(search, categoryId, subCategoryId, sort, params);
        setError("");
      } catch (e: any) {
        setPage(null);
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [limit]
  );

  useEffect(() => {
    if (
      search ||
      categoryId ||
      subCategoryId ||
      sort ||
      allowEmptySearch ||
      (isAIMS() ? Object.keys(params).length > 1 : Object.keys(params).length)
    ) {
      getProducts(
        search,
        categoryId?.toString(),
        subCategoryId?.toString(),
        sort,
        params
      );
    }
  }, [
    search,
    categoryId,
    subCategoryId,
    sort,
    allowEmptySearch,
    JSON.stringify(params),
  ]);

  return {
    error,
    products,
    categories,
    getProducts,
    loading,
    loadingNext,
    fetchNext,
    setError,
  };
};

export default useSearch;
