import { USER_ROLES } from "../constants/Common";
import { get } from "../utils/axios/index";

interface Response {
  data: {
    matched: boolean;
  };
}
const verifyContact = async (
  number: string,
  role = USER_ROLES.DEHAAT_COORDINATOR
) => {
  try {
    const response = await get<Response>("/contacts/verification", {
      number,
      role,
    });
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default verifyContact;
