import React, { useState, useEffect, useCallback, ReactElement } from "react";
import { OrderCancelReasonObj } from "../models/orders";
import { StyleSheet, Text, View } from "react-native";
import fetchOrderCancelReasons from "../utils/apis/fetchOrderCancelReasons";
import {
  Button,
  RadioButton,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

interface Props {
  handleReject: (reason: OrderCancelReasonObj) => void;
}

const RejectReasonsModal = ({ handleReject }: Props) => {
  const [reasons, setReasons] = useState<OrderCancelReasonObj[]>([]);
  const [selectedReason, setSelectedReason] = useState<number>(-1);

  useEffect(() => {
    const getReasons = async () => {
      const reasons = await fetchOrderCancelReasons();
      setReasons(reasons);
    };

    getReasons();
  }, []);

  const getReasonsList = useCallback(() => {
    const radioElements: Array<{
      radioElement: ReactElement;
      value: string | number;
    }> = [];

    reasons.forEach(({ code, reason }) => {
      radioElements.push({
        radioElement: (
          <Text
            style={[
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral100,
              styles.option,
            ]}
          >
            {reason}
          </Text>
        ),
        value: code,
      });
    });

    return radioElements;
  }, [reasons]);

  const handleSubmit = () => {
    if (selectedReason < 0) return;
    handleReject(reasons[selectedReason]);
  };

  return (
    <View>
      <RadioButton
        list={getReasonsList()}
        onClick={(index) => setSelectedReason(index)}
        selected={selectedReason}
        containerStyle={{ width: 300, alignItems: "center" }}
      />

      <Button
        buttonStyle={styles.submitBtn}
        text="Submit"
        onClick={handleSubmit}
      />
    </View>
  );
};

export default RejectReasonsModal;

const styles = StyleSheet.create({
  option: {
    marginLeft: 30,
  },
  submitBtn: {
    marginTop: 10,
  },
});
