export const storeData = <T>(key: string, value: T) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  } catch (e) {
    // saving error
  }
};

export const getData = <T, K>(key: string, defaultValue?: K) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue ? (JSON.parse(jsonValue) as T) : defaultValue;
  } catch (e) {
    // error reading value
  }
};

export const multiGet = (keys: string[]) => {
  const valuesMap: Record<string, unknown> = {};
  try {
    keys.forEach((key) => {
      valuesMap[key] = getData(key);
    });

    return valuesMap;
  } catch (e) {
    const defaultValueMap: Record<string, unknown> = {};
    keys.forEach((key) => {
      defaultValueMap[key] = null;
    });
    return defaultValueMap;
  }
};

export const multiSet = (keyValuePairs: [string, any][]) => {
  keyValuePairs.forEach(([key, value]) => storeData(key, value));
};

export const clearStorage = async () => {
  localStorage.clear();
};

export const removeData = (key: string) => {
  localStorage.removeItem(key);
};

export const multiRemove = (keys: string[]) => {
  keys.forEach((key) => localStorage.removeItem(key));
};

export const getCookies = <T extends Record<string, any>>(): T => {
  const cookies: any = {};
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  cookieArray.forEach((cookie) => {
    const [key, value] = cookie.trim().split("=");
    if (key && value) {
      cookies[key as keyof T] = value as any;
    }
  });
  return cookies;
};

export const setCookies = (cookies: Record<string, string>) => {
  const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString();
  Object.keys(cookies).forEach((key) => {
    document.cookie = `${key}=${cookies[key]}; expires=${expires}; path=/; Secure; SameSite=Strict`;
  });
};

export const removeCookies = (keys: string[]) => {
  keys.forEach((key) => {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};
