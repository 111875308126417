import React, { useEffect } from "react";
import { BackHandler, TouchableOpacity, Platform } from "react-native";
import { COMMON_STYLES } from "@dehaat/dds";

import { defaultBackPressCallback } from "app/partner-ordering-ui/utils/helpers/common";
import { navigationRef } from "app/partner-ordering-ui/utils/RootNavigation";

import BackCaret from "../../icons/BackCaret";

interface Props {
  onBackPress?: () => boolean;
  dependency?: string;
}

const CustomBackButton = ({ onBackPress, dependency = "" }: Props) => {
  const hideBackButton = Platform.OS === "web" && !navigationRef.canGoBack();
  useEffect(() => {
    if (Platform.OS === "android") {
      BackHandler.addEventListener(
        "hardwareBackPress",
        onBackPress || defaultBackPressCallback
      );
    }
    return () => {
      if (Platform.OS === "android") {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          onBackPress || defaultBackPressCallback
        );
      }
    };
  }, [dependency]);

  if (hideBackButton) {
    return <></>;
  }
  return (
    <TouchableOpacity
      onPress={onBackPress || defaultBackPressCallback}
      style={COMMON_STYLES["ph1/2"]}
    >
      <BackCaret
        height={24}
        width={24}
        strokeWidth={2}
        style={COMMON_STYLES["mr0.5"]}
      />
    </TouchableOpacity>
  );
};

export default CustomBackButton;
