import React, { PropsWithChildren, useEffect, useState } from "react";
import { useAppDispatch } from "app/hooks/reduxHooks";
import { loadStorageAndUpdateStore } from "app/partner-ordering-ui/utils/helpers/init";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import { setNativeData } from "app/store/reducer/native";
import { getGlobalData } from "app/utils/appConfig";
import useAuth from "app/hooks/useAuth";
import { ScreenStackProps } from "react-native-screens";
import ToastAndroid from "app/mocks/ToastAndroid";

/**
 * A React provider for providing App related information to its children.
 *
 * @author Yogender Jangir <yogender.jangir@agrevolution.in>
 */

const AppProvider = ({ children }: PropsWithChildren<ScreenStackProps>) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken } = useAuth();
  /**
   * To be triggered on mounting of the component to fetch user details
   * and to set the state of the component accordingly
   */

  useEffect(() => {
    let error = "";
    if (accessToken) {
      getGlobalData()
        .then((data) => {
          if (data.dc) {
            loadStorageAndUpdateStore(data.dc.ib_id, data.dc.ib_id)
              .then(() => {
                dispatch(setNativeData({ ...data, token: accessToken }));
              })
              .catch((e: any) => {
                error = "Not able to load and update store data";
                throw e;
              });
          } else {
            error = "No DC Data found.";
            throw error;
          }
        })
        .catch((e: any) => {
          console.error(e);
          if (!error) {
            error = "Not able to fetch global data from native.";
          }
          ToastAndroid.show(error, ToastAndroid.SHORT);
        })
        .finally(() => {
          setIsLoading(false);
        });

      console.log("Error: ", error);
    }
  }, [accessToken]);

  return <>{isLoading ? <Spinner /> : children}</>;
};

export default AppProvider;
