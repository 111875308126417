import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const RupeeClearanceIcon = (props: SvgProps) => {
  return (
    <Svg width="12" height="16" fill="none" {...props}>
      <Path
        d="m11.256 8.004.663-1.146a.6.6 0 0 0-.219-.82l-1.147-.664V4.053a.6.6 0 0 0-.6-.601H8.63L7.97 2.305a.606.606 0 0 0-.6-.296.596.596 0 0 0-.221.076l-1.147.663-1.148-.663a.6.6 0 0 0-.82.22L3.37 3.452H2.049a.6.6 0 0 0-.601.6v1.322L.3 6.037a.6.6 0 0 0-.22.821l.664 1.146L.08 9.151a.603.603 0 0 0 .219.821l1.147.663v1.321a.6.6 0 0 0 .601.601H3.37l.663 1.147a.607.607 0 0 0 .52.3.601.601 0 0 0 .3-.08L6 13.26l1.147.663a.601.601 0 0 0 .82-.22l.664-1.147h1.32a.6.6 0 0 0 .601-.6v-1.322l1.148-.663a.601.601 0 0 0 .219-.821l-.663-1.147Zm-6.758-3.01c-.597.096-.36.41-.19.578.168.17.19.226.19.465 0 .24.27-.014.1.155-.168.169.24.21 0 .21.447-.76-.61-1.223-.78-1.392-.168-.17-.221 1.125-.221.886 0-.24 1.178.97 1.347.801.169-.169-.685-1.702-.446-1.702Zm.18 5.768.164.03 3.605-4.806-.164-.03-3.604 4.806Zm2.824.24c-.119 0-.353-2.117-.463-2.162-.109-.045-.562 1.875-.174 1.898-.084-.084 1.532-1.43 1.487-1.539-.045-.109-1.751 1.02-1.751.902 0-.119 1.893.723 1.938.614.046-.11-1.758-1.168-1.674-1.252a.901.901 0 0 1 .292-.195c.11-.045.139 2.01.257 2.01.24 0 .557-1.983.726-1.814-.638-1.056-1.85.262-1.85.5 0 .24 1.5.938 1.85.774-.487-1.108-.4.264-.638.264Z"
        fill="#8F3000"
      />
      <Path
        d="M4.113 8.671h1.029"
        stroke="#fff"
        strokeWidth=".667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.056 5.004a.333.333 0 1 0 0 .667h1c.465 0 .79.171 1.001.409.146.164.246.37.296.591H4.056a.333.333 0 0 0 0 .667h2.297c-.05.222-.15.427-.296.59-.211.239-.536.41-1 .41a.333.333 0 1 0 0 .667c.645 0 1.154-.246 1.498-.633a2.01 2.01 0 0 0 .476-1.034h1.025a.333.333 0 0 0 0-.667H7.031a2.018 2.018 0 0 0-.446-1h1.471a.333.333 0 0 0 0-.667h-4Zm-.243 3.439a.333.333 0 0 1 .471-.015l2.834 2.667a.333.333 0 1 1-.457.486L3.828 8.914a.333.333 0 0 1-.015-.471Z"
        fill="#fff"
      />
    </Svg>
  );
};

export default RupeeClearanceIcon;
