import { COLORS, FONT_FAMILY_SEMIBOLD } from "@dehaat/dds";
import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { isDesktop } from "../constants/common";
import formateDateandTime from "../utils/helpers/formateDateAndTime";

interface Props {
  orderId: string;
  farmerName: string;
  createdAt: string;
  mobileNumber: string;
  address: string;
  paymentMode: string;
  orderType: string;
  shippingBy: string;
}
const OrderSummary = ({
  orderId,
  farmerName,
  createdAt,
  mobileNumber,
  address,
  paymentMode,
  orderType,
  shippingBy,
}: Props) => {
  return (
    <View style={styles.container}>
      <View>
        <View style={styles.textContainer}>
          <Text style={styles.label}>Order ID:</Text>
          <Text style={styles.text}>{orderId}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.label}>Date & time:</Text>
          <Text style={styles.text}>{formateDateandTime(createdAt).split("|")[0]}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.label}>Payment mode:</Text>
          <Text style={styles.text}>{paymentMode}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.label}>Shipping Type:</Text>
          <Text style={styles.text}>{orderType}</Text>
        </View>
      { shippingBy? <View style={styles.textContainer}>
          <Text style={styles.label}>Shipping detail:</Text>
          <Text style={styles.text}>{shippingBy}</Text>
        </View>:null}
      </View>
      <View>
        <View style={styles.textContainer}>
          <Text style={styles.label}>Customer Name:</Text>
          <Text style={styles.text}>{farmerName}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.label}>Customer Number:</Text>
          <Text style={styles.text}>{mobileNumber}</Text>
        </View>
       {address ? <View style={styles.textContainer}>
          <Text style={styles.label}>Customer Address:</Text>
          <Text
            style={{
              ...styles.text,
              maxWidth: isDesktop ? 600 : 150,
            }}
          >
            {address}
          </Text>
        </View> : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.white,
    paddingTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 16,
    borderRadius: 12,
    flexDirection: "row",
    gap:60,
    justifyContent: isDesktop ? "flex-start" : "space-around",
  },
  textContainer: {
    flexDirection: isDesktop ? "row" : "column",
    alignItems: "center",
    marginTop: 12,
  },
  label: {
    color: isDesktop ? COLORS.neutral80 : COLORS.neutral90,
    fontFamily:  FONT_FAMILY_SEMIBOLD ,
  },
  text: {
    color: isDesktop ? COLORS.neutral90 : COLORS.neutral80,
    fontFamily: FONT_FAMILY_SEMIBOLD ,
    marginLeft:isDesktop ? 8 : 0,
    marginTop: isDesktop ? 0 : 4,
  },
});

export default OrderSummary;
