import React, { FC } from "react";
import { View, ViewProps, StyleProp, ViewStyle } from "react-native";

interface LinearGradientProps extends ViewProps {
  colors: string[];
  start?: { x: number; y: number };
  end?: { x: number; y: number };
  style?: StyleProp<ViewStyle>; // React Native style prop
}

const LinearGradient: FC<LinearGradientProps> = ({
  colors = [],
  start = { x: 0, y: 0 },
  end = { x: 1, y: 1 },
  style,
  children,
  ...props
}) => {
  // Calculate the gradient angle for web
  const angle = Math.atan2(end.y - start.y, end.x - start.x) * (180 / Math.PI);

  // Create web-specific inline CSS for linear-gradient
  const webStyle: React.CSSProperties = {
    backgroundImage: `linear-gradient(${angle}deg, ${colors.join(", ")})`,
  };

  return (
    <View {...props} style={[style, webStyle as ViewStyle]}>
      {children}
    </View>
  );
};

export default LinearGradient;
