import { useEffect, useState } from "react";
import { setToken } from "app/store/reducer/authToken";
import { useAppDispatch } from "./reduxHooks";
import AuthToken from "app/models/Auth";
import { getCookies } from "app/utils/storage";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const cookies = getCookies<AuthToken>();
  const isAuthTokenExists = !!cookies.access_token && !!cookies.refresh_token;
  const [isAuthenticated, setIsAuthenticated] = useState(isAuthTokenExists);

  useEffect(() => {
    if (isAuthTokenExists) {
      setIsAuthenticated(true);
      dispatch(setToken(cookies));
    }
  }, []);

  return { isAuthenticated, accessToken: cookies?.access_token };
};

export default useAuth;
