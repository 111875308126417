import * as React from "react"
import Svg, { G, Path, Defs, ClipPath, SvgProps } from "react-native-svg"

const SchemeCashbackIcon = (props: SvgProps) => {
    return (
    <Svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <G clipPath="url(#clip0_5608_23069)">
        <Path
          d="M14.119 6.925c-.238-.244-.482-.5-.575-.719-.094-.219-.088-.544-.094-.875-.006-.606-.019-1.3-.5-1.781-.481-.481-1.175-.494-1.781-.5-.332-.006-.669-.012-.875-.094-.207-.081-.475-.337-.719-.575C9.144 1.97 8.65 1.5 8 1.5c-.65 0-1.144.469-1.575.881-.244.238-.5.482-.719.575-.219.094-.544.088-.875.094-.606.006-1.3.019-1.781.5-.481.481-.494 1.175-.5 1.781-.006.331-.012.669-.094.875-.081.206-.337.475-.575.719C1.47 7.356 1 7.85 1 8.5c0 .65.469 1.144.881 1.575.238.244.481.5.575.719.094.218.088.543.094.875.006.606.019 1.3.5 1.781.481.481 1.175.494 1.781.5.331.006.669.013.875.094.206.081.475.337.719.575.431.412.925.881 1.575.881.65 0 1.144-.469 1.575-.881.244-.238.5-.482.719-.575.218-.094.543-.088.875-.094.606-.006 1.3-.019 1.781-.5.481-.481.494-1.175.5-1.781.006-.332.013-.669.094-.875.081-.207.337-.475.575-.719C14.53 9.644 15 9.15 15 8.5c0-.65-.469-1.144-.881-1.575zm-3.025.438l-3.663 3.5a.506.506 0 01-.694 0l-1.83-1.75a.5.5 0 11.687-.726l1.487 1.42 3.325-3.17a.5.5 0 01.688.726z"
          fill="#171B1F"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5608_23069">
          <Path fill="#fff" transform="translate(0 .5)" d="M0 0H16V16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default SchemeCashbackIcon
