import React from "react";
import Svg, { Path, PathProps, SvgProps } from "react-native-svg";

interface Props {
  pathProps?: PathProps;
  svgProps: SvgProps;
}

const LensIcon = ({ svgProps, pathProps }: Props) => {
  return (
    <Svg viewBox="0 0 16 16" {...svgProps}>
      <Path
        d="M14.8644 13.8683L12.2772 11.2756C13.1457 10.184 13.667 8.7994 13.667 7.29333C13.667 3.76397 10.8077 0.9 7.2835 0.9C3.75619 0.9 0.9 3.764 0.9 7.29333C0.9 10.8227 3.75619 13.6867 7.2835 13.6867C8.78714 13.6867 10.1667 13.1676 11.2565 12.2978L13.8439 14.8878C13.8439 14.8878 13.844 14.8878 13.844 14.8878C14.1264 15.1707 14.5821 15.1707 14.8646 14.888C15.1473 14.6082 15.1473 14.1484 14.8646 13.8686C14.8646 13.8685 14.8645 13.8684 14.8644 13.8683ZM7.2835 12.2341C4.56071 12.2341 2.34757 10.0176 2.34757 7.29333C2.34757 4.56895 4.56079 2.34952 7.2835 2.34952C10.0032 2.34952 12.2194 4.56899 12.2194 7.29333C12.2194 10.0176 10.0033 12.2341 7.2835 12.2341Z"
        fill="#646363"
        stroke="#646363"
        strokeWidth="0.2"
        {...pathProps}
      />
    </Svg>
  );
};

export default LensIcon;
