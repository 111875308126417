import { useEffect, useState } from "react";
import { useAppSelector } from "app/hooks/reduxHooks";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  StackActions,
  useNavigation,
  useRoute,
} from "@react-navigation/native";

import { selectInternetAvailability } from "../store/reducer/app";
import useFocusHook from "./useFocusHook";

import { ROUTES, RootStackParamList } from "../routes/types";

const useObserverInternetActivity = () => {
  const navigation =
    useNavigation<
      NativeStackScreenProps<RootStackParamList, ROUTES>["navigation"]
    >();
  const route =
    useRoute<NativeStackScreenProps<RootStackParamList, ROUTES>["route"]>();

  const isFocused = useFocusHook();
  const isInternetAvailable = useAppSelector(selectInternetAvailability);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (isFocused && isInternetAvailable && reload) {
      navigation.dispatch(StackActions.replace(route.name, route.params));
      setReload(false);
    }
    if (!isFocused && !isInternetAvailable) {
      setReload(true);
    }
  }, [isFocused, isInternetAvailable]);
};

export default useObserverInternetActivity;
