import React from "react";
import { BackHandler, StyleSheet, Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  BG_STYLES,
  BUTTON_TYPE,
  Button,
  COMMON_STYLES,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

import OrderInProgressIcon from "app/partner-ordering-ui/icons/PaymentPending";
import { OrderPendingScreenProps } from "app/partner-ordering-ui/routes/types";
import { finishCurrentActivity, openOrderHistory, redirectToHome } from "app/utils/appConfig";

const OrderPending = ({ route }: OrderPendingScreenProps) => {
  const { t } = useTranslation();
  const { id, amount } = route.params;
  return (
    <View
      style={[
        COMMON_STYLES["ph5/4"],
        BG_STYLES.bgWhite,
        COMMON_STYLES.flex1,
        COMMON_STYLES.justifyBetween,
      ]}
    >
      <View
        style={[
          COMMON_STYLES.flex1,
          COMMON_STYLES.alignItemsCenter,
          COMMON_STYLES.justifyCenter,
        ]}
      >
        <OrderInProgressIcon width={120} height={120} />
        <Text
          style={[
            TYPOGRAPHY.HEADING2,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
            TEXT_STYLES.textNeutral90,
            styles.priceStyle,
          ]}
        >
          {t("order_processing")}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.CAPTION1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral80,
            COMMON_STYLES.textCenter,
            COMMON_STYLES["mt3/4"],
          ]}
        >
          {t("order_id")}:{id}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            styles.orderDescription,
          ]}
        >
          {t("order_processing_desc", { amount: formatCurrency(amount) })}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.BASE,
            TEXT_STYLES.textNeutral80,
            COMMON_STYLES["mt1/2"],
          ]}
        >
          {t("amount_refund")}
        </Text>
      </View>
      <View>
        <Button
          text={t("go_to_order_history")}
          onClick={() => {
            openOrderHistory();
            finishCurrentActivity();
          }}
          buttonStyle={[COMMON_STYLES.fullWidth, COMMON_STYLES["pv3/4"]]}
          textStyle={[TYPOGRAPHY.TEXT2, VARIANTS_STYLES.SEMI_EMPHASIZED]}
          size={SIZE.large}
        />
        <Button
          text={t("go_to_home")}
          onClick={redirectToHome}
          buttonStyle={[
            COMMON_STYLES.fullWidth,
            COMMON_STYLES["mt1/2"],
            COMMON_STYLES.borderNone,
            COMMON_STYLES["pv3/4"],
          ]}
          textStyle={[TYPOGRAPHY.TEXT2, VARIANTS_STYLES.SEMI_EMPHASIZED]}
          type={BUTTON_TYPE.SECONDARY}
          size={SIZE.large}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  orderDescription: {
    marginTop: 48,
  },
  priceStyle: {
    marginTop: 55,
  },
});

export default OrderPending;
