import {
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { SchemeDetailData } from "app/partner-ordering-ui/models/Scheme";
import { formatStringDate } from "app/partner-ordering-ui/utils/helpers/date";
import { getOrderMoreNudge } from "app/partner-ordering-ui/utils/helpers/scheme";
import { openSchemeDetailActivity } from "app/utils/appConfig";
import React from "react";
import { useTranslation } from "react-i18next";
import { Pressable, Text, View } from "react-native";
import CurrentCashback from "../schemes/CurrentCashback";
import EarnCashbackNudge from "../schemes/EarnCashbackNudge";
import SchemeContainer from "../schemes/SchemeContainer";

interface Props {
  scheme: SchemeDetailData;
}

const TbsScheme = ({ scheme }: Props) => {
  const { t } = useTranslation();

  const navigateToSchemeDetails = () => {
    openSchemeDetailActivity(scheme.id, scheme.type);
  };

  const orderMoreNudge = getOrderMoreNudge(
    scheme.slabs,
    scheme.delivered || "0",
    scheme.to_be_delivered || "0",
    scheme.uom || "Unit",
    scheme.discount_type,
    scheme.slab_type
  );

  return (
    <>
      <Pressable onPress={navigateToSchemeDetails}>
        <SchemeContainer
          schemeName={scheme.name}
          schemeContent={
            <View>
              {orderMoreNudge.currentCashback && (
                <CurrentCashback cashback={orderMoreNudge.currentCashback} />
              )}

              <EarnCashbackNudge orderMoreNudge={orderMoreNudge} />

              <Text
                style={[
                  TYPOGRAPHY.CAPTION1,
                  VARIANTS_STYLES.BASE,
                  TEXT_STYLES.textNeutral80,
                  COMMON_STYLES["m0.5"],
                  COMMON_STYLES.ml2,
                ]}
              >
                {t("scheme_expires_on", {
                  date: formatStringDate(scheme.ordering_end_date),
                })}
              </Text>
            </View>
          }
        />
      </Pressable>
    </>
  );
};

export default TbsScheme;
