import React from "react";
import { Text, View } from "react-native";
import { useTranslation } from "react-i18next";
import {
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
  formatCurrency,
} from "@dehaat/dds";

interface Props {
  price: number;
  constituentUnitPrice?: number | null;
  constituentUom?: string;
  discountPrice?: number | null;
  discountConstituentUnitPrice?: number | null;
  isCashback?: boolean;
}

const CartItemPriceInfo = ({
  price,
  constituentUnitPrice,
  constituentUom,
  discountPrice = 0,
  discountConstituentUnitPrice = 0,
  isCashback = false,
}: Props) => {
  const { t } = useTranslation();
  return (
    <View style={COMMON_STYLES["mt1/2"]}>
      <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.EMPHASIZED,
            TEXT_STYLES.textNeutral100,
            COMMON_STYLES["mr1/4"],
          ]}
        >
          {formatCurrency(discountPrice || price)}
        </Text>
        {discountPrice ? (
          !isCashback ? (
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.BASE,
                TEXT_STYLES.textNeutral70,
                COMMON_STYLES.strikeText,
              ]}
            >
              {formatCurrency(price)}
            </Text>
          ) : discountConstituentUnitPrice ? (
            <Text
              style={[
                TYPOGRAPHY.CAPTION1,
                VARIANTS_STYLES.BASE,
                TEXT_STYLES.textNeutral70,
              ]}
            >{`(${formatCurrency(
              discountConstituentUnitPrice
            )}/${constituentUom})`}</Text>
          ) : null
        ) : constituentUnitPrice ? (
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral70,
            ]}
          >{`(${formatCurrency(
            constituentUnitPrice
          )}/${constituentUom})`}</Text>
        ) : null}
      </View>
      {discountPrice ? (
        isCashback ? (
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral70,
            ]}
          >
            {t("price_after_cashback")}
          </Text>
        ) : discountConstituentUnitPrice ? (
          <Text
            style={[
              TYPOGRAPHY.CAPTION1,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral70,
            ]}
          >{`(${formatCurrency(
            discountConstituentUnitPrice
          )}/${constituentUom})`}</Text>
        ) : null
      ) : null}
    </View>
  );
};

export default CartItemPriceInfo;
