import React, { useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { useTranslation } from "react-i18next";

import {
  BG_STYLES,
  BottomDrawer,
  Button,
  COMMON_STYLES,
  PARAGRAPH_STYLES,
  ProductComboCardHz,
  TEXT_STYLES,
} from "@dehaat/dds";

import ShimmerProductCardHz from "../cards/ProductCardHzShimmer";
import {
  getBundleImages,
  getComboVisible,
} from "app/partner-ordering-ui/utils/helpers/product";
import {
  addItemToCart,
  deleteCartItem,
  updateCartItem,
} from "app/partner-ordering-ui/store/reducer/cart";
import useCart from "app/partner-ordering-ui/hooks/useCart";
import { useAppDispatch } from "app/hooks/reduxHooks";
import { Product } from "app/partner-ordering-ui/models/Product";
import { rnNavigation } from "app/utils/appConfig";
import { ROUTES } from "app/partner-ordering-ui/routes/types";
import ComboPriceInfo from "../productList/ComboPriceInfo";
import fetchProductsList from "app/partner-ordering-ui/apis/fetchProductBundles";
import {
  fireComboProductAddToCartEvent,
  fireProductClickedEvent,
} from "app/partner-ordering-ui/utils/helpers/mixpanel";
import { MIXPANEL_KEYS } from "app/partner-ordering-ui/constants/eventNames";
import { useAppSelector } from "app/hooks/reduxHooks";
import { selectDCData } from "app/store/reducer/native";
import { MAX_INVENTORY_ALLOWED } from "app/partner-ordering-ui/constants/Common";

interface Props {
  id: number | string;
  displayVariantId: number | string;
  isOpen: boolean;
  onClose: () => void;
}

const ComboBottomDrawer = ({
  isOpen,
  id,
  displayVariantId,
  onClose,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [comboList, setComboList] = useState<Product[]>([]);
  const { t } = useTranslation();
  const { cartItems } = useCart();
  const dispatch = useAppDispatch();
  const dc = useAppSelector(selectDCData);

  const fetchComboList = async () => {
    const response = await fetchProductsList(
      id,
      dc.sales_office_id,
      dc.partner_id
    );
    if (response) {
      setComboList(response.results);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchComboList();
  }, []);

  return getComboVisible(comboList) ? (
    <BottomDrawer
      visible={isOpen}
      onClose={onClose}
      heading={t("combo_available")}
      modalStyle={{ height: 680 }}
    >
      <View style={COMMON_STYLES.flex1}>
        <Text
          style={[
            PARAGRAPH_STYLES.MediumT2,
            TEXT_STYLES.textNeutral90,
            COMMON_STYLES.divider,
            COMMON_STYLES["pb1/2"],
          ]}
        >
          {t("maximize_savings")}
        </Text>
        {isLoading ? (
          <ShimmerProductCardHz /> // Todo: Pick this from dds
        ) : (
          <ScrollView>
            {comboList.map((product, index) => {
              const { id: comboId, bundles } = product;
              const {
                id,
                name,
                actual_price,
                discounted_price,
                bundled_products,
                orderable_quantity,
              } = bundles[0];
              const quantity = cartItems[id]?.qty;
              const images = getBundleImages(bundled_products);
              const onQtyUpdate = (qty: number) =>
                dispatch(updateCartItem({ id, qty }));
              const onCardClick = () => {
                rnNavigation({
                  name: ROUTES.COMBO_DETAIL,
                  params: { id: comboId },
                })

                fireProductClickedEvent(
                  {
                    product: product,
                    variant: product.variants[0],
                    productPosition: index + 1,
                    isPriorityDc: dc?.is_priority_dc,
                    screenName: ROUTES.PRODUCT_LIST,
                    isOfferStrip: false,
                  },
                  {
                    [MIXPANEL_KEYS.SKU_ID]: product.bundles[0].id,
                    [MIXPANEL_KEYS.TEMPALTE_ID]: product.id,
                    [MIXPANEL_KEYS.PREPAID_TAG]: product.bundles[0].is_prepaid,
                  }
                );
              };
              return (
                <ProductComboCardHz
                  key={id}
                  containerStyle={[
                    COMMON_STYLES["mb0.5"],
                    COMMON_STYLES.divider,
                    COMMON_STYLES.pb1,
                  ]}
                  images={images}
                  productName={name}
                  offerLabel={t("combo_offer")}
                  onDeleteClick={() => dispatch(deleteCartItem({ id }))}
                  onPlusClick={() => onQtyUpdate(quantity + 1)}
                  onMinusClick={() => onQtyUpdate(quantity - 1)}
                  onQunatityUpdate={(qty) => onQtyUpdate(qty)}
                  cta={{
                    onClick: () => {
                      dispatch(addItemToCart({ id, product }))
                        .unwrap()
                        .then(() => {
                          dispatch(deleteCartItem({ id: displayVariantId }));
                          rnNavigation({
                            name: ROUTES.COMBO_DETAIL,
                            params: { id: comboId },
                          });
                          fireComboProductAddToCartEvent(
                            {
                              product: product?.bundles[0],
                              productPosition: index + 1,
                              screenName: ROUTES.PRODUCT_DETAIL,
                              isPriorityDc: dc?.is_priority_dc,
                            },
                            {
                              [MIXPANEL_KEYS.ALTERNATE_PRODUCT_SHOWN]:
                                product?.similar_product_count,
                              [MIXPANEL_KEYS.IS_BUNDLE_IN_BOTTOM]: true,
                              [MIXPANEL_KEYS.IS_BUNDLE_IN_CAROUSAL]: false,
                            }
                          );
                          onClose();
                        });
                    },
                    text: t("add"),
                  }}
                  priceInfo={
                    <ComboPriceInfo
                      actualPrice={Number(actual_price)}
                      discountPrice={Number(discounted_price)}
                    />
                  }
                  onCardClick={() =>
                    onCardClick()
                  }
                  availableInventory={Math.min(
                    orderable_quantity,
                    MAX_INVENTORY_ALLOWED
                  )}
                  quantity={quantity}
                />
              );
            })}
          </ScrollView>
        )}

        <Button
          text={t("continue_without_combo")}
          onClick={onClose}
          buttonStyle={[BG_STYLES.bgWhite, COMMON_STYLES.borderNone]}
          textStyle={[TEXT_STYLES.textPrimary100, PARAGRAPH_STYLES.BoldT1]}
        />
      </View>
    </BottomDrawer>
  ) : null;
};

export default ComboBottomDrawer;
