import React from "react";
import Svg, {
  Path,
  Defs,
  LinearGradient,
  Stop,
  SvgProps,
} from "react-native-svg";

const ClearanceIcon = (props: SvgProps) => {
  return (
    <Svg width="16" height="17" fill="none" {...props}>
      <Path
        fill="#E05109"
        d="M15 8.43a2.314 2.314 0 0 0-1.391-2.091.313.313 0 0 1-.175-.385 2.28 2.28 0 0 0-2.993-2.931c-.149.06-.324-.018-.394-.167A2.301 2.301 0 0 0 7.93 1.5a2.314 2.314 0 0 0-2.091 1.391.313.313 0 0 1-.385.175A2.28 2.28 0 0 0 2.523 6.06c.06.149-.018.324-.167.394A2.301 2.301 0 0 0 1 8.57a2.314 2.314 0 0 0 1.391 2.091.313.313 0 0 1 .175.385 2.28 2.28 0 0 0 2.993 2.931c.149-.06.324.018.394.167A2.31 2.31 0 0 0 8.07 15.5a2.314 2.314 0 0 0 2.091-1.391.313.313 0 0 1 .385-.175 2.28 2.28 0 0 0 2.931-2.993c-.06-.149.018-.324.167-.394A2.31 2.31 0 0 0 15 8.43Z"
      />
      <Path
        fill="#DD6514"
        d="M8.544 8.815c.158-.12.285-.27.383-.435.097-.165.157-.352.195-.555h.517l.413-.607h-.923a1.478 1.478 0 0 0-.127-.435 1.303 1.303 0 0 0-.218-.338h.855l.413-.607h-3.6l-.413.607h1.005c.353 0 .638.068.848.21.21.143.345.33.405.563H6.459l-.412.607h2.257a.892.892 0 0 1-.172.36 1.09 1.09 0 0 1-.315.263c-.128.067-.27.127-.428.157a2.352 2.352 0 0 1-.525.053h-.607v.607l1.62 1.74.12.135.622.668.053.052h1.095l-.053-.052-.63-.668-.127-.135-1.695-1.807c.27 0 .517-.038.735-.105.217-.068.405-.165.562-.285l-.015.007Z"
      />
      <Path
        fill="#fff"
        d="M8.458 8.612c.158-.12.285-.27.383-.435.097-.165.157-.352.195-.555h.517l.413-.607h-.923a1.478 1.478 0 0 0-.127-.435 1.303 1.303 0 0 0-.218-.338h.855l.413-.607h-3.6l-.413.607h1.005c.353 0 .638.068.848.21.21.143.345.33.405.563H6.373l-.412.607h2.257a.892.892 0 0 1-.172.36 1.09 1.09 0 0 1-.315.263c-.128.067-.27.127-.428.157a2.352 2.352 0 0 1-.525.053h-.607v.607l1.62 1.74.12.135.622.668.053.052H9.68l-.053-.052-.63-.668-.127-.135-1.695-1.807c.27 0 .517-.038.735-.105.217-.068.405-.165.562-.285l-.015.007Z"
      />
    </Svg>
  );
};

export default ClearanceIcon;
