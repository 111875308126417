import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const WarningRedIcon = (props: SvgProps) => {
  return (
    <Svg width="18" height="16" fill="none" {...props}>
      <Path
        fill="#E05109"
        d="M10.737 1.187c-.79-1.316-2.72-1.316-3.51 0L.292 12.83C-.526 14.175.47 15.9 2.05 15.9h13.895c1.58 0 2.545-1.726 1.755-3.071L10.737 1.187ZM8.894 3.82a1.11 1.11 0 0 1 1.228 1.053v.205l-.38 4.358a.777.777 0 0 1-.819.703.755.755 0 0 1-.702-.703l-.38-4.358c-.059-.644.41-1.2 1.053-1.258Zm.088 9.712a1.236 1.236 0 0 1-1.23-1.229c0-.673.557-1.228 1.23-1.228.672 0 1.228.555 1.228 1.228 0 .673-.556 1.229-1.229 1.229Z"
      />
    </Svg>
  );
};

export default WarningRedIcon;
