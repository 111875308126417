import React from "react";
import OTPContainer from "../components/OTPContainer";
import { OtpScreenProps } from "../routes/types";

const Otp = ({ route }: OtpScreenProps) => {
  const { number } = route.params;
  return <OTPContainer number={number} />;
};

export default Otp;
